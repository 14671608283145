import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoadingController, AlertController, Platform } from '@ionic/angular';
import { HttpWrapperService, HttpInputData } from './core/http/http-wrapper.service';
import { Router } from '@angular/router';
import { CommonService } from './common.service';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/mergeMap';
import { appApiResources, storageKeys } from './../app.constants';
import { environment } from 'src/environments/environment';
import { AppStateService } from './init/app-state.service';
import { EncryptionService } from './core/encryption/encryption.service';
import { LocalizationService } from 'src/app/services/core/localize/localization.service';
import { JAVASCRIPT } from 'src/app/app.constants';
import { InitService } from './init/init.service';
import { catchError, map } from 'rxjs/operators';
import { StorageService } from './core/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  isLoading: boolean = false
  activateApiData: any;
  activateApiMemberData: any;
  typeLogin: any;
  loginResponse: any;
  loginResponseDummy: any;
  loaderTxt: string;
  mobile: number;
  email: String;
  certificateCardDetails: any
  dataForReedemBenifit: any
  dataFromManageReservation: any;
  dataFromManage: any;
  redeemRequest: boolean = false;
  verifyMobile: { transactionId: any; otp: any; };
  verifyEmail: { transactionId: any; otp: any; };
  outletDashboardDetails;
  transactionType: string;
  deviceToken: string;
  resendRedeemDetals: any;
  resendredeemOtp: boolean = false;
  dashboardTab: string;
    loginFeildMobileNumber: any;
    loginMobileValid: any;
    countryCodeLogin: any;

  constructor(public http: HttpClient, public httpWrapper: HttpWrapperService,
    public loadingCtrl: LoadingController,
    public router: Router, public common: CommonService,
    private appState: AppStateService,
    private platform: Platform,
    private alertCTRL: AlertController,
    public encryptService: EncryptionService,
    private localizationService: LocalizationService,
    private initService: InitService,
    public storage: StorageService,

  ) { }


  // .Get Activate Account Api Function.
  getActivateAccount(account) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    httpParams = httpParams.set("employeeID", account);
    options.params = httpParams;
    return this.httpWrapper.get(appApiResources.accountActivityUrl, options)
      .pipe(map((response: any) => {
        this.activateApiData = response
        return response;
      })
        // .catch((error: Response) => this.common.errorHandler(error))

      )
  }

  // SEND OTP API FUNCTION..
  sendOtp(otpData, otpType, otpReuestType, resetRequest, redeemRequest) {
    console.log("otpData", otpData)
    console.log("otpType", otpType)
    console.log("otpReuestType", otpReuestType)
    console.log("resetRequest", resetRequest)
    var data = {};
    if (otpData?.accountSfId != undefined) {
      data = {
        "accountSfId": otpData.accountSfId,
        "channel": otpType == 'mobile' ? ["Mobile"] : ["Email"],

      }
    }
    if (otpData == true) {
      if (otpType == 'mobile') {
        if (resetRequest == true || resetRequest == undefined) {
          data = {
            "mobile": parseInt(this.activateApiMemberData.mobile),
            "otpFor": "redemption",
            "transactionType": "redemption",
            "email": this.activateApiMemberData.email,
          }
          this.transactionType = "redemption";
        }
        else if (resetRequest == false) {
          data = {
            "mobile": parseInt(this.activateApiMemberData.mobile),
            "otpFor": "verifyMobile",
            "transactionType": "verifyMobile"
          }
        }

      } else if (otpType == 'loginWithOtp') {
        data = {
          "mobile": Number(this.countryCodeLogin+this.loginFeildMobileNumber),
          "otpFor": "Login",
          "transactionType": "Login"
        }
      } else {
        data = {
          "email": this.activateApiMemberData.email,
          "otpFor": "verifyEmail",
          "transactionType": "verifyEmail"
        }
      }




      let options = new HttpInputData();
      return this.httpWrapper.post(otpReuestType === 'resendOtp' ? appApiResources.resendForgotOtpUrl : appApiResources.requestForgotOtpUrl, data, options)
        .pipe(map((response: any) => {
          return response;
        }), catchError((error: Response) => this.common.errorHandler(error))
        )
    }
    else {
      let data = {
        "accountSfId": otpData.accountSfId,
        "channel": otpType == 'mobile' ? ["Mobile"] : ["Email"],
        "otpFor": otpType == 'mobile' ? "verifyMobile" : 'verifyEmail',
        "transactionType": otpType == 'mobile' ? "verifyMobile" : 'verifyEmail'
      }
      let options = new HttpInputData();
      return this.httpWrapper.post(otpReuestType === 'resendOtp' ? appApiResources.resendOtpUrl : appApiResources.requestOtpUrl, data, options)
        .pipe(map((response: any) => {
          return response;
        }), catchError((error: Response) => this.common.errorHandler(error))
        )
    }
  }

  // OTP VERIFI API FUNTION.
  optVerfiyApi(otpGetData, otpCode, forgotOption, otpType) {
    console.log('otpGetData',  otpGetData, otpType)

    if (forgotOption == true) {
      var dataVerify = {};
      if (otpType == 'mobile') {
        if (this.transactionType === "redemption") {
          dataVerify = {
            "mobile": parseInt(this.activateApiMemberData.mobile),
            "transactionId": otpGetData.transactionID,
            "otp": otpCode,
            "otpFor": "redemption",
            "email": this.activateApiMemberData.email,
          }
          // this.verifyMobile = {
          //   "transactionId": otpGetData.transactionID,
          //   "otp": otpCode,
          // }
          // this.transactionType = "verifyMobile";
        } else {
          dataVerify = {
            "mobile": parseInt(this.activateApiMemberData.mobile),
            "transactionId": otpGetData.transactionID,
            "otp": otpCode,
            "otpFor": "verifyMobile"
          }
          this.verifyMobile = {
            "transactionId": otpGetData.transactionID,
            "otp": otpCode,
          }
        }
      }
      else {
        dataVerify = {
          "email": this.activateApiMemberData.email,
          "transactionId": otpGetData.transactionID,
          "otp": otpCode,
          "otpFor": "verifyEmail"
        }
        this.verifyEmail = {
          "transactionId": otpGetData.transactionID,
          "otp": otpCode,
        }
      }
      let options = new HttpInputData();
      return this.httpWrapper.post(appApiResources.verifyForgotOtpUrl, dataVerify, options)
        .pipe(map((response: any) => {
          this.transactionType = ''
          return response;
        }), catchError((error: Response) => this.common.errorHandler(error))

        )
    } else {
      let dataVerify = {
        "otpFor": "Verify",
        "otp": otpCode,
        "transactionId": otpGetData.transactionID
      }
      let options = new HttpInputData();
      return this.httpWrapper.post(appApiResources.verifyOtpUrl, dataVerify, options)
        .pipe(map((response: any) => {
          return response;
        }), catchError((error: Response) => this.common.errorHandler(error))
        )
    }
  }

  // SETUP PROFIE  API FUNCTION
  setupProfileApi(data, password) {
    let dataVerify = {
      "sfid": data.sfid,
      "declarationAccepted": true,
      "password": password,
      "preferredLanguage": this.localizationService.getDeviceLanguage()
    }
    let options = new HttpInputData();
    return this.httpWrapper.postHeader(appApiResources.acountActivateProfileUrl, dataVerify, options, true)
      .pipe(map((response: any) => {
        this.appState.saveCsrfToken(response.headers.get("csrftoken"));
        this.appState.saveSessionToken(response.headers.get("sessionid"));
        return response.body;
      }),
        catchError((error: Response) => this.common.errorHandler(error))
      )
  }


  confirmPasswordAPI(confirmPassword, changeType) {
    var passwordINFo = {};
    if (changeType == 'changePasswordType') {
      passwordINFo = {
        "mobile": parseInt(this.activateApiMemberData.mobile),
        "email": this.activateApiMemberData.email,
        "newPassword": confirmPassword,
        "mobileOtp": this.verifyMobile.otp,
        "mobileOtpTransactionId": this.verifyMobile.transactionId,
        "emailOtp": this.verifyEmail.otp,
        "emailOtpTransactionId": this.verifyEmail.transactionId
      }
      let options = new HttpInputData();
      return this.httpWrapper.postHeader(appApiResources.changePassword, passwordINFo, options)
        .pipe(map((response: any) => {
          return response;
        }),
          catchError((error: Response) => this.common.errorHandler(error))
        )
    } else {
      passwordINFo = {
        "mobile": parseInt(this.activateApiMemberData.mobile),
        "email": this.activateApiMemberData.email,
        "newPassword": confirmPassword,
        "mobileOtp": this.verifyMobile.otp,
        "mobileOtpTransactionId": this.verifyMobile.transactionId,
        "emailOtp": this.verifyEmail.otp,
        "emailOtpTransactionId": this.verifyEmail.transactionId
      }
      let options = new HttpInputData();
      return this.httpWrapper.postHeader(appApiResources.resetPassword, passwordINFo, options)
        .pipe(map((response: any) => {
          return response;
        })
        )
    }
  }

  //** Login Api Funtion  */
  loginApi(loginDetails, loginType) {
    let loginData = {}
    switch (loginType) {
      case 'EmailLogin':
        loginData['emailID'] = loginDetails.email;
        break;
      case 'NumberLogin':
        loginData['mobileNumber'] = Number(loginDetails.countryCodeSelected.dialCode + loginDetails.mobile)
        break;
    }
    loginData['password'] = loginDetails.password,
      // loginData['source'] = "Web",
      // loginData['uniqueId'] =  localStorage.getItem('pwaId');
      loginData['ipAddress'] = localStorage.getItem('ip');
      // loginData['ipAddress'] = '152.155';

    let options = new HttpInputData();
    return this.httpWrapper.postHeader(appApiResources.loginUrl, loginData, options, true)
      .pipe(map((response: any) => {
        this.appState.saveCsrfToken(response.headers.get("csrftoken"));
        this.appState.saveSessionToken(response.headers.get("sessionid"));
        return response.body;
      })
      )
  }

  getMember(mobile: number, email: String) {
    if (mobile && mobile != 0) {
      var inputMemberData = 'mobile=' + Number(mobile);
    } else if (email) {
      var inputMemberData = 'email=' + email;
    }
    let options = new HttpInputData();
    return this.httpWrapper.get('members?' + inputMemberData, options)
      .pipe(map((response: any) => {
        if (email == 'changePasswordType') {
          this.activateApiMemberData = response;
          this.activateApiMemberData.passwordType = email;
        } else {
          this.activateApiMemberData = response;
        }
        return response;
      })
      )
  }


  memberDetailHandler(response) {
    this.common.dismissLoader();
    if (response.body.message) {
      this.memberAlertHandler(response);
    } else {
      this.mobile = response.body.mobile;
      this.email = response.body.email;
      this.router.navigate(["reset-password"]);
    }
  }

  async memberAlertHandler(response) {
    const memberAlert = await this.alertCTRL.create({
      mode: 'ios',
      message: response.body.message,
      buttons: ["OK"],
      cssClass: "alertCustomCss"
    });
    await memberAlert.present();
  }
  aemDataApi(data) {
    // //console.log("aem data check");
    // //console.log(data);
    // //console.log(data['keys'].length);
    let lang = this.localizationService.getDeviceLanguage() || 'en'
    if (data['keys'].length > 0) {
      let checkData = data['keys'][0].identifier.includes(`${environment.Content}/${environment.CountryCode}/${lang}`);
      // console.log("if==>",checkData);
      if (checkData) {
      } else {
        // console.log("else==>",checkData);
        data['keys'].forEach(element => {
          element.identifier = environment.Content + JAVASCRIPT.FORWARDSLASH + environment.CountryCode + JAVASCRIPT.FORWARDSLASH + lang + element.identifier;
        });
      }
    }
    return this.httpWrapper.postAemData(environment.aem_base_url, data).pipe(map((res: any) => {
      if (this.platform.is('cordova')) {
        if (this.httpWrapper.checkNative == false) {
          return res;
        } else {
          //impotanat commenet not remove when you go test aem api in mobile this section is open 
          var data = JSON.parse(res)
          return data;
        }

      } else {
        // impotant msg when you serve in web view open this return above return data for mobile this for web view
        return res;
      }
    }, err => {
      console.error("AEM error", err);
    }));
  }

  // aemSildesDataApi() {
  //   let apiReq = {
  //   "country": "IN",
  //   "language": "en",
  //   "brand": "marriott",
  //   "program": "club-marriott",
  //   "subprogram": "club-marriott-india",
  //   "type": "HotelApp"
  //   }
  //   return this.http
  //   .post(environment.aem_sildes_url, JSON.stringify(apiReq))
  //   .toPromise()
  //   .then((res: any) => {
  //   ////console.log("This is the exporterIdentifiers ", res);
  //   return res;
  //   }, err => {
  //   console.error("onInit error", err);
  //   });
  //   }


  getOfferDetailFuncAPI(pathUrl) {
    return this.http.get(pathUrl).toPromise();
  }

  LoginSSO(ssoURL) {
    let options = new HttpInputData();
    return this.httpWrapper.Headerget('sso/login?requestId=' + ssoURL, options, true)
      .pipe(map((response: any) => {
        // console.log("response header sso ==> ", response)
        // console.log("response header sso  1 ==> ",JSON.stringify(response))
        this.appState.saveCsrfToken(response.headers.get("csrftoken"));
        this.appState.saveSessionToken(response.headers.get("sessionid"));
        return response.body;
      }),
        catchError((error: Response) => this.common.errorHandler(error))
      )
  }
  // get outlet Address
  outletDetailLocation(outletId) {
    let options = new HttpInputData();
    return this.httpWrapper.get('outlet/' + outletId, options)
      .pipe(map((response: any) => {
        return response;
      }),
        catchError((error: Response) => this.common.errorHandler(error))
      )
  };

  authenticatekeyApi() {
    return new Promise((resolve, reject) => {
      this.httpWrapper.get(`program/authenticatekey`).subscribe(
        (res) => {
          if (res) {
            localStorage.setItem(storageKeys.saveauthenticatekey, JSON.stringify(res))
            this.encryptService.afterescureKeyValue = res.secureKey;
            this.encryptService.afterescureIVKeyValue = res.secureIV;
            resolve(res);
          } else {
            reject(false);
          }
        }, error => {
          reject(error);
        });
    });
  }

  encryptiongetInitApp() {
    const appData = this.appState.getAppData();
    // console.log("appData", appData)
    if (appData.devicCheckBase64) {
      this.deviceToken = appData.devicCheckBase64.saveDevicecheckBase64
    }

    let options = new HttpInputData();
    if (this.platform.is("cordova")) {
      var datapass = {
        "device_token": this.deviceToken
      }
      if (appData.devicCheckBase64) {
        this.deviceToken = appData.devicCheckBase64.saveDevicecheckBase64
      }

    } else {
      var datapass = {
        "device_token": "asbhdjkhdkjshjdkhskjdhskjd3331"
      }
    }
    this.common.presentLoader();
    return new Promise((resolve, reject) => {
      let options = new HttpInputData();
      this.httpWrapper.postHeader('program/anonymouskey', datapass, options, true).subscribe(
        (res) => {
          this.encryptService.beforesecureKeyValue = res['body'].secureKey;
          this.encryptService.beforesecureIVKeyValue = res['body'].secureIV;
          this.appState.saveCsrfToken(res.headers.get("csrftoken"));
          // this.initService.checkFirstTimeUserStateData();
          this.common.dismissLoader()
          resolve(res)
        },
        error => {
          this.common.dismissLoader()
          reject(error)
        });
    })
  }



  outletDeailsAPIFromGuest(outletDetails) {
    return this.httpWrapper.get(`details?uniqueIdentifier=${outletDetails.outletId}&offerType=${'Outlet'}`).pipe(map((response: any) => {
      return response;
    }),
      catchError((error: Response) => this.common.errorHandler(error))
    )
  }

  loginOtpVeriApi(otpEntered,otpGetData) {
    let data = {
      "mobileNumber": Number(this.countryCodeLogin+this.loginFeildMobileNumber),
      "transactionId": otpGetData.transactionID,
      "otp": otpEntered,
      "emailID":"",
      "ipAddress":localStorage.getItem('ip') 
      // "ipAddress":'112.1545' 
    }


    let options = new HttpInputData();
    return this.httpWrapper.post('account/loginWithOtp', data, options,true)
      .map((response: any) => {
        this.appState.saveCsrfToken(response.headers.get("csrftoken"));
        this.appState.saveSessionToken(response.headers.get("sessionid"));
        return response;
      })

  }

    getIpAddress() {
      this.http.get(environment.getIPinfo).subscribe(
        (res: any) => {
          if (res && res.query) {
            console.log('ip address from url ====>>>>', res)
            localStorage.setItem('ip', res.query);
          }
        },
        (err) => {
          console.log('url error ====>>>>', err);
        }
      );
    }


}
