<ion-app>
    <ion-split-pane contentId="my-content" when="xl">
        <ion-menu id="sideMenu" style="display: none;" contentId="my-content" type="overlay"
            (ionWillClose)="updateMenuState()" (ionWillOpen)="openUpMenu()">
            <ion-header *ngIf="appComopnetNetOff" class="menuHeader">
                <ion-icon class="menuBar" mode="ios" name="menu" (click)="closeMenu()"></ion-icon>
            </ion-header>
            <ion-content class="menuContent" [scrollEvents]="true" (ionScrollStart)="onScroll()"
                (click)="contentclicked()">
                <ion-list>
                    <ion-item class="menuName" lines="full">
                        <ion-label position="stacked" class="ion-text-wrap">
                            {{ionicStorageEmployeeIdData?.userDetails?.name |titlecase}}
                        </ion-label>
                        <ion-button mode="md" *ngIf="localpolicy?.includes('GET_MEMBER_PROFILE')" fill="clear"
                            class="editProfileBtn" color="primary" (click)="openPage('edit')">
                            {{'settingPage.editProfile'| translate}}
                        </ion-button>
                    </ion-item>

                    <!-- <ion-item lines="none" *ngIf="  localpolicy?.includes('POST_REDEEM_WITHQR')">
                        <ion-icon mode="ios" (click)="openBarCodeScannerModal()" class="ca-Scanner" slot="start">
                        </ion-icon>
                        <ion-label class="cursor" (click)="openBarCodeScannerModal()">{{'settingPage.scanQR'|
                            translate}}
                        </ion-label>
                        <ion-icon mode="ios" class="ca-info-icon e-info cursor" slot="end" id="click-scanQR"
                            (click)="popover($event)"></ion-icon>
                        <ion-popover trigger="click-scanQR" showBackdrop="false" side="left" mode="ios"
                            alignment="center" triggerAction="click">
                            <ng-template>
                                <ion-content>{{'settingPage.tooltipScanQR'| translate}}
                                </ion-content>
                            </ng-template>
                        </ion-popover>
                    </ion-item> -->

                    <ion-item class="menuSearchItem" lines="none" *ngIf="localpolicy?.includes('GET_MEMBER_SEARCH')">
                        <ion-icon mode="ios" class="ca-search-icon menuIconSearch" slot="start"></ion-icon>
                        <ion-label class="cursor" (click)="openPage('search')">{{'settingPage.guestSearch'|
                            translate}}</ion-label>
                    </ion-item>

                    <ion-item-group class="menuGroup" *ngIf="outLetType === true">
                        <ion-item-divider *ngIf="localpolicy?.includes('GET_OUTLET_SET_UP_MENU')">
                            <ion-label>Setup</ion-label>
                        </ion-item-divider>

                        <ion-item lines="none" class="height-auto"
                            *ngIf="localpolicy?.includes('POST_SET_UP_OUTLET_MENU')">
                            <ion-label class="cursor" (click)="openPage('setupOutlet')"> Outlet </ion-label>
                        </ion-item>
                        <ion-item lines="none" class="height-auto"
                            *ngIf="localpolicy?.includes('POST_OUTLET_SEUP_RULES_STATUS_UPDTAE')">
                            <ion-label class="cursor" (click)="openPage('setupRules')"> Rules </ion-label>
                        </ion-item>
                        <ion-item lines="none" class="height-auto"
                            *ngIf="localpolicy?.includes('GET_OUTLET_WIDGET_SETUP_RULES_STATUS_UPDTE')">
                            <ion-label class="cursor" (click)="openPage('setupWidget')"> Widget </ion-label>
                        </ion-item>
                        <ion-item lines="none" class="height-auto"
                            *ngIf="localpolicy?.includes('POST_MENU_ITEM_Availability')">
                            <ion-label class="cursor" (click)="openPage('setupMenu')"> Menu </ion-label>
                        </ion-item>
                        <ion-item lines="none" class="height-auto"
                            *ngIf="localpolicy?.includes('GET_OUTLET_SEQUENCE_UPDATE')">
                            <ion-label class="cursor" (click)="openPage('menu')"> Edit, Sequence and Update </ion-label>
                        </ion-item>
                        <ion-item lines="none" class="height-auto" *ngIf="localpolicy?.includes('POST_MENU_PUBLISH')">
                            <ion-label class="cursor" (click)="openPage('editMenu')"> Publish </ion-label>
                        </ion-item>
                    </ion-item-group>

                    <ion-item-group class="menuGroup" *ngIf="outLetType === true">
                        <ion-item-divider *ngIf="outLetType === true">
                            <ion-label>Quick Overriding Updates</ion-label>
                        </ion-item-divider>

                        <ion-item lines="none" class="height-auto"
                            *ngIf="outLetType === true && localpolicy?.includes('GET_BLOCK_SERVICE_DETAILS')">
                            <ion-label class="cursor" (click)="openPage('blockService')"> Block Service </ion-label>
                        </ion-item>
                        <ion-item lines="none" class="height-auto"
                            *ngIf="localpolicy?.includes('GET_OUTLET_ITEM_AVAILABILITY')">
                            <ion-label class="cursor" (click)="openPage('itemNotAvailable')"> Item Not Available
                            </ion-label>
                        </ion-item>
                        <ion-item lines="none" class="height-auto"
                            *ngIf="localpolicy?.includes('POST_MENU_CREATE_OUTLET_REVENUE_MANAGEMENT')">
                            <ion-label class="cursor" (click)="openPage('revenueManagement')"> Revenue Management
                            </ion-label>
                        </ion-item>
                        <ion-item lines="none" class="height-auto"
                            *ngIf="localpolicy?.includes('POST_MENU_UPDATE_ITEM_DETAILS')">
                            <ion-label class="cursor" (click)="openPage('updateItem')"> Update Item </ion-label>
                        </ion-item>
                    </ion-item-group>

                    <!-- <ion-item lines="full" *ngIf="  localpolicy?.includes('GET_IPREGESRVATIONS')">
                        <ion-icon mode="ios" class="cursor" (click)="openPage('exception')" class="ca-exception"
                            slot="start">
                        </ion-icon>
                        <ion-label class="cursor" (click)="openPage('exception')">{{'settingPage.record_an_exception'|
                            translate}}
                        </ion-label>
                        <ion-icon mode="ios" class="ca-info-icon e-info cursor" slot="end" id="click-record"
                            (click)="popover($event)">
                        </ion-icon>
                        <ion-popover trigger="click-record" showBackdrop="false" side="left" mode="ios"
                            alignment="center" triggerAction="click">
                            <ng-template>
                                <ion-content>{{'settingPage.tooltipRecordException'| translate}}
                                </ion-content>
                            </ng-template>
                        </ion-popover>
                    </ion-item> -->

                    <!-- <ion-item lines="none" *ngIf="localpolicy?.includes('GET_MAKE_RESERVATION') && ionicStorageEmployeeIdData?.userDetails?.outletType
                        == 'Restaurant'">
                        <ion-label class="cursor" (click)="openPage('openReservation')">
                            {{'settingPage.Open_Reservation'|
                            translate}}
                        </ion-label>
                    </ion-item> -->

                    <ion-item-group class="menuGroup" *ngIf="outLetType === false">
                        <ion-item-divider>
                            <ion-label>Insights</ion-label>
                        </ion-item-divider>

                        <ion-item lines="none"
                            *ngIf="outLetType === false && (ionicStorageEmployeeIdData?.userDetails?.outletType === 'Pool' ||
                        ionicStorageEmployeeIdData?.userDetails?.outletType === 'Restaurant' || ionicStorageEmployeeIdData?.userDetails?.outletType === 'Spa')">
                            <ion-label class="cursor" (click)="openPage('viewreservations')">
                                {{'settingPage.viewReservations'| translate}}
                            </ion-label>
                        </ion-item>
                        <ion-item lines="none" *ngIf=" localpolicy?.includes('GET_REDEM_LOG')">
                            <ion-label class="cursor" (click)="openPage('redemption')">{{'settingPage.redemptionLog'|
                                translate}}
                            </ion-label>
                        </ion-item>
                        <ion-item lines="none" *ngIf="localpolicy?.includes('GET_FEEDBACK_SEARCH')">
                            <ion-label class="cursor" (click)="openPage('feedback')">{{'settingPage.feedbackHistory'|
                                translate}}
                            </ion-label>
                        </ion-item>
                        <ion-item lines="none" *ngIf="localpolicy?.includes('GET_MISREPORTS')">
                            <ion-label class="cursor" (click)="openPage('dashboard')">{{'settingPage.report'|
                                translate}}
                            </ion-label>
                        </ion-item>
                    </ion-item-group>

                    <!-- >Set up Group -->
                    <ion-item-group class="menuGroup">
                        <ion-item-divider >
                            <ion-label>Set up</ion-label>
                        </ion-item-divider>

                        <ion-item lines="none">
                            <ion-label class="cursor" (click)="openPage('outletMaster')">{{'settingPage.outlet_Master'|
                                translate}}
                            </ion-label>
                        </ion-item>
                        <ion-item lines="none"   *ngIf="outLetType === false && (ionicStorageEmployeeIdData?.userDetails?.outletType === 'Pool' ||
                        ionicStorageEmployeeIdData?.userDetails?.outletType === 'Restaurant' || ionicStorageEmployeeIdData?.userDetails?.outletType === 'Spa')">
                            <ion-label class="cursor" (click)="openPage('tableMaster')">{{'settingPage.Table_Master'|
                                translate}}
                            </ion-label>
                        </ion-item>
                        <ion-item lines="none" *ngIf="outLetType === false && (ionicStorageEmployeeIdData?.userDetails?.outletType === 'Pool' ||
                        ionicStorageEmployeeIdData?.userDetails?.outletType === 'Restaurant' || ionicStorageEmployeeIdData?.userDetails?.outletType === 'Spa')">
                            <ion-label class="cursor" (click)="openPage('editTable')">{{'settingPage.Edit_Table'|
                                translate}}
                            </ion-label>
                        </ion-item>
                        <ion-item lines="none" *ngIf="outLetType === false && (ionicStorageEmployeeIdData?.userDetails?.outletType === 'Pool' ||
                        ionicStorageEmployeeIdData?.userDetails?.outletType === 'Restaurant' || ionicStorageEmployeeIdData?.userDetails?.outletType === 'Spa')">
                            <ion-label class="cursor"
                                (click)="openPage('OutletSection')">{{'settingPage.Outlet_Section'|
                                translate}}
                            </ion-label>
                        </ion-item>
                        <ion-item lines="none" *ngIf="outLetType === false && (ionicStorageEmployeeIdData?.userDetails?.outletType === 'Pool' ||
                        ionicStorageEmployeeIdData?.userDetails?.outletType === 'Restaurant' || ionicStorageEmployeeIdData?.userDetails?.outletType === 'Spa')">
                            <ion-label class="cursor" (click)="openPage('addTags')">{{'settingPage.addTags'| translate}}
                            </ion-label>
                        </ion-item>
                        <ion-item lines="none"
                            *ngIf="localpolicy?.includes('GET_REG_MASTER') && ionicStorageEmployeeIdData?.userDetails?.outletType === 'Restaurant'">
                            <ion-label class="cursor" (click)="openPage('reservationsmaster')">
                                {{'settingPage.reservationmaster'| translate}}
                            </ion-label>
                            <ion-icon mode="ios" class="ca-info-icon e-info cursor" slot="end"
                                id="click-reservationmaster" (click)="popover($event)">
                            </ion-icon>
                            <ion-popover trigger="click-reservationmaster" showBackdrop="false" side="left" mode="ios"
                                alignment="center" triggerAction="click">
                                <ng-template>
                                    <ion-content>{{'settingPage.tooltipReservationMaster'| translate}} </ion-content>
                                </ng-template>
                            </ion-popover>
                        </ion-item>
                    </ion-item-group>

                    <!-- <ion-item lines="none" class="cursor" *ngIf="ionicStorageEmployeeIdData?.userDetails?.totalOutlets>
                        1" (click)="openPage('switchoutlet')">
                        <ion-label>{{'settingPage.switch_outlet'| translate}}</ion-label>
                    </ion-item> -->
                    <!-- <ion-item lines="none" *ngIf="localpolicy?.includes('GET_OUTLET')">
                        <ion-label class="cursor" (click)="openPage('outletdetails')">{{'settingPage.outletView'|
                            translate}}
                        </ion-label>
                    </ion-item>
                    <ion-item lines="none" *ngIf="localpolicy?.includes('GET_OUTLET_OFFER')">
                        <ion-label class="cursor" (click)="openPage('offersevents')">{{'settingPage.offersEvents'|
                            translate}}
                        </ion-label>
                    </ion-item> -->

                    <!-- Settings Group -->
                    <ion-item-group class="menuGroup">
                        <ion-item-divider>
                            <ion-label>Settings</ion-label>
                        </ion-item-divider>

                        <ion-item lines="none">
                            <ion-label class="cursor" (click)="openPage('faq')">{{'settingPage.faqs'| translate}}
                            </ion-label>
                        </ion-item>
                        <ion-item lines="none" *ngIf="outLetType === false">
                            <ion-label class="cursor" (click)="openPage('appTour')">{{'settingPage.app_Tour'|
                                translate}}
                            </ion-label>
                        </ion-item>
                        <ion-item lines="none" *ngIf="outLetType==true">
                            <ion-label (click)="openPage('appTour')">{{'settingPage.app_Tour'| translate}}
                            </ion-label>
                        </ion-item>
                        <ion-item lines="none" *ngIf="localpolicy?.includes('GET_ACCOUNT_SETTINGS')">
                            <ion-label class="cursor" (click)="openPage('setting')">{{'settingPage.settings'|
                                translate}}
                            </ion-label>
                        </ion-item>
                        <ion-item lines="none">
                            <ion-label class="cursor" (click)="openPage('logout')">{{'settingPage.logOut'|translate}}
                            </ion-label>
                        </ion-item>
                    </ion-item-group>

                    <!-- <ion-item lines="none" class="ion-hide-md">
                        <ion-icon mode="ios" class="ca-logout-icon logoutclass" slot="start"></ion-icon>
                        <ion-label class="menuLogout cursor" (click)="openPage('logout')">{{'settingPage.logOut'|
                            translate}}
                        </ion-label>
                    </ion-item> -->
                </ion-list>
            </ion-content>
        </ion-menu>

        <ion-router-outlet [swipeGesture]="false" id="my-content"></ion-router-outlet>
    </ion-split-pane>
</ion-app>