import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { OUTLET_MASTER_PAGE, TITLE, JAVASCRIPT } from 'src/app/app.constants';
import { CommonService } from 'src/app/services/common.service';
import { DashboardhomeService } from 'src/app/services/dashboardhome.service';
import { EventService } from 'src/app/services/event/event.service';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { MbowService } from 'src/app/services/mbow.service';
@Component({
  selector: 'app-userorder-details',
  templateUrl: './userorder-details.component.html',
  styleUrls: ['./userorder-details.component.scss'],
})
export class UserorderDetailsComponent implements OnInit {
  @Input() sendUserorderView: any;
  memberID: any;
  userInfo: any
  orderHistory: any;
  loaded: boolean = false
  outletCurrency;
  ionicStorage: any;
  showCurrency: any = {};

  constructor(
    private menu: MenuController,
    private plt: Platform,
    private events: EventService,
    private translate: TranslateService,
    public router: Router,
    private appState: AppStateService,
    private MbowService: MbowService,
    public common: CommonService,
    public dashboardService: DashboardhomeService,

  ) {
    this.outletCurrency = this.dashboardService.outletCurrency
    this.ionicStorage = this.appState.getAppData();
    this.ionicStorage.appInitData.currency.forEach(element => {
      if (this.outletCurrency == element.currencyName) {
        this.showCurrency = element;
      }

    });
  }

  ngOnInit() {
    this.memberID = this.sendUserorderView
  }

  ngAfterContentInit() {
    this.menu.enable(false);
    this.plt.ready().then((readySource) => {
      this.translate.get('Guest Profile').subscribe((res: string) => {
        this.events.publish('title', res);
      });
      this.profileDetails()
    });
  }

  profileDetails() {
    // this.common.presentLoader()
    this.MbowService.mbowUserDetails(this.memberID).subscribe(res => {
      if (res) {

        this.userInfo = res
        this.orderHistory = this.userInfo.orderDetails
        this.loaded = true;
      }
    }, err => {
      // this.common.dismissLoader()
      this.common.errorHandler(err);
    })
  }

}
