import { NgModule } from '@angular/core';
import { AllowSafeHtmlPipe } from './allow-safe-html/allow-safe-html.pipe';
import { MaskPipe } from './mask/mask.pipe';
import { RemoveDuplicateItemsPipe } from './removeDuplicate/remove-duplicate-items.pipe';
@NgModule({
	declarations: [AllowSafeHtmlPipe, RemoveDuplicateItemsPipe, MaskPipe],
	imports: [],
	exports: [AllowSafeHtmlPipe, MaskPipe]
})
export class PipesModule {}
