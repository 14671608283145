<div class="modalBg">
  <ion-card class="modalWrap">
    <ion-card-header class="modalHeader">
      <h2 class="modalTitle">{{'record_excep_category.choose_category'|translate}}</h2>
      <ion-icon class="modalClose ca-close-icon" (click)="closeModal()"></ion-icon>
    </ion-card-header>
    <form>
      <ion-card-content class="modalContent">
        <ion-list>
          <ion-item lines="full" *ngFor="let item of optionRecordAll; index as i">
            <div class="custom-radio">
              <input type="radio" [(ngModel)]="recordData" id="option{{item.id}}" [value]="item.id" class="custom-radio"
                (change)="selectFuntion()" [ngModelOptions]="{standalone: true}">
              <label for="option{{item.id}}">{{item.Value}}</label>
            </div>
          </ion-item>
        </ion-list>
      </ion-card-content>
      <!-- Update Button -->
      <ion-button mode="ios" expand="block" class="modalButton ion-no-margin primary-gradient" [disabled]="!recordData"
        (click)="update()"> {{'record_excep_category.select'|translate}}
      </ion-button>
    </form>
  </ion-card>
</div>