<div class="tablemodal-wrap" id="joinModalClose">
  <ion-item lines="none" class="tableModalHeader">
    <ion-label class="ion-text-wrap">
      <h1>{{titleText ? titleText : (joinApiDetails.tableNumber > 9 ? ('Table'+' '+ joinApiDetails.tableNumber) :
        ('Table'+' 0'+ joinApiDetails.tableNumber) ) }}</h1>
      <!-- <p>{{tableData?.tableDetails?.occupied}}/{{tableData?.tableDetails?.pax}} seats occupied</p> -->
    </ion-label>

    <ion-icon slot="end" class="modalClose ca-close-icon" (click)="closeModal()"></ion-icon>
  </ion-item>

  <ion-grid class="contentRow" [hidden]="isHide" class="detailGrid ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <div class="informationItem">
          <h2 class="table-number">Guest</h2>
        </div>
      </ion-col>
      <ion-col size="6">
        <div class="informationItem">
          <p>Guest Name</p>
          <h4>{{tableData?.guestName}}</h4>
        </div>
      </ion-col>
      <ion-col size="6" *ngIf="tableData?.Membership">
        <div class="informationItem">
          <p>Membership</p>
          <h4>{{tableData?.Membership}}</h4>
        </div>
      </ion-col>
      <ion-col size="12">
        <div class="title-box informationItem">
          <h2 class="table-number">Table Details</h2>
        </div>
      </ion-col>
      <ion-col size="12">
        <div class="informationItem">
          <p>Tags</p>
          <ion-chip *ngFor="let item of tableData?.tableDetails[0].tags" mode="md" style="--background: #D4DDFA;">
            <!-- <ion-img *ngIf="item.WindowSide == true" src="../../../assets/icon/tag1.svg"></ion-img>
            <ion-img *ngIf="item.highlyRequested == true" src="../../../assets/icon/tag2.svg"></ion-img> -->
            <ion-label>{{item}}</ion-label>
          </ion-chip>
          <!-- <ion-chip mode="md" style="--background: #FAE2D4;">
            <ion-img src="../../../assets/icon/tag2.svg"></ion-img>
            <ion-label>Highly-Requested</ion-label>
          </ion-chip> -->
        </div>
      </ion-col>
      <!-- <ion-col size="12">
        <div class="informationItem">
          <p>Category</p>
          <h4> <span *ngFor="let itemCategory of tableData?.tableDetails">{{itemCategory}}</span> </h4>
        </div>
      </ion-col> -->
      <ion-col size="6">
        <ion-button [disabled]="joinApiDetails.vacantTable.length==0" (click)="openAllote('isJoin')" mode="md"
          class="actionBtn join" expand="block">
          Join </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button [disabled]="joinApiDetails.vacantTable.length==0 || joinApiDetails?.joinedTableArr[0]?.isJoined== true" (click)="openAllote('isReassign')" mode="md"
          class="actionBtn assign" expand="block"> Re-Assign </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid class="contentRow" [hidden]="!isHide" [ngSwitch]="allotType" class="detailGrid ion-no-padding">
    <!-- For Re-assign -->
    <ion-row *ngSwitchCase="'isReassign'">
      <ion-col size="12" class="informationItem">
        <ion-item lines="none" class="ion-no-padding form-item">
          <ion-label class="ion-text-wrap" position="stacked">Guest Name</ion-label>
          <ion-item-group mode="md" class="customSearch">
            <span> {{tableData?.guestName}} </span>

          </ion-item-group>
        </ion-item>
      </ion-col>
      <ion-col size="7" class="informationItem pr-0">
        <ion-item mode="ios" lines="none" class="ion-no-padding form-item">
          <ion-label class="ion-text-wrap" position="stacked">New Table</ion-label>
          <div class="position-relative w-100">
            <ion-select class="input-text" interface="popover" (ionChange)="reasignTable($event)"
              [(ngModel)]="reaasignObj.newTableSelect" [interfaceOptions]="popoverOptions" placeholder="Select">
              <ion-select-option *ngFor="let tableShow of joinApiDetails.vacantTable; let z = index;"
                [value]="tableShow">
                Table {{tableShow.tableNumber}}</ion-select-option>

            </ion-select>
            <ion-icon slot="end" class="select-arrow ca-down-arrow-yellow-icon"></ion-icon>
          </div>
        </ion-item>
      </ion-col>
      <ion-col size="5" class="informationItem pl-0">
        <ion-item mode="ios" lines="none" class="ion-no-padding form-item">
          <ion-label mode="ios" class="ion-text-wrap" position="stacked">Pax</ion-label>
          <div class="position-relative w-100">
            <ion-select class="input-text" interface="popover" [interfaceOptions]="popoverOptions"
              [(ngModel)]="reaasignObj.reaasignPax" placeholder="Select">
              <ion-select-option *ngFor="let itemPax of paxArray" [value]="itemPax">
                {{itemPax}}</ion-select-option>
            </ion-select>
            <ion-icon slot="end" class="select-arrow ca-down-arrow-yellow-icon"></ion-icon>
          </div>
        </ion-item>
      </ion-col>
      <ion-col size="12" class="informationItem">
        <ion-item mode="ios" lines="none" class="ion-no-padding form-item">
          <ion-label mode="ios" class="ion-text-wrap" position="stacked">Reason for Re-assigning</ion-label>
          <ion-textarea mode="ios" rows="6" class="input-text" [(ngModel)]="reaasignObj.reaasgignReason"
            placeholder="Type here..."></ion-textarea>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-button mode="md" class="actionBtn"
          [disabled]="reaasignObj.reaasgignReason =='' || reaasignObj.reaasignPax =='' || reaasignObj.newTableSelect=='' "
          (click)="reAssignApi()" expand="block"> Re-assign </ion-button>
      </ion-col>
    </ion-row>
   
    <!-- For Join -->
    <div [formGroup]="joinMoreform">
      
      <ion-row *ngSwitchCase="'isJoin'">

        <ion-col size="7" class="informationItem pr-0">
          <ion-item mode="ios" lines="none" class="ion-no-padding form-item">
            <ion-label class="ion-text-wrap" position="stacked">1st Table</ion-label>
            <div class="position-relative w-100">
              <ion-select class="input-text" formControlName="firstTable" interface="popover"
                [interfaceOptions]="popoverOptions" placeholder="Table {{joinApiDetails.tableNumber}}">
                <!-- <ion-select-option value="{{firstTable}}"></ion-select-option> -->
              </ion-select>
              <!-- <ion-icon slot="end" class="select-arrow ca-down-arrow-yellow-icon"></ion-icon> -->
            </div>
           
          </ion-item>
        </ion-col>
        <ion-col size="5" class="informationItem pl-0">
          <ion-item mode="ios" lines="none" class="ion-no-padding form-item">
            <ion-label mode="ios" class="ion-text-wrap" position="stacked">Pax</ion-label>
            <ion-input mode="ios" class="input-text" formControlName="firstPax" [disabled]="true" maxlength="2"
              type="text" placeholder="Pax"></ion-input>
          </ion-item>
        </ion-col>

        <ng-container *ngFor="let tableShowadd of joinApiDetails?.joinedTableArr; let x = index;">
        <ion-col size="7" class="informationItem pr-0">
          <ion-item mode="ios" lines="none" class="ion-no-padding form-item">
            <ion-label class="ion-text-wrap" position="stacked">{{ordinal_suffix_of((x+2))}} Table</ion-label>
            <div class="position-relative w-100">
              <ion-select class="input-text"  interface="popover"
                [interfaceOptions]="popoverOptions" [placeholder]="'Table' +' '+ tableShowadd.tableNum">
                <!-- <ion-select-option  value="{{tableShowadd.tableNum}}"> {{tableShowadd.tableNum}}</ion-select-option> -->
              </ion-select>
              <!-- <ion-icon slot="end" class="select-arrow ca-down-arrow-yellow-icon"></ion-icon> -->
            </div>
           
          </ion-item>
        </ion-col>
        <ion-col size="5" class="informationItem pl-0">
          <ion-item mode="ios" lines="none" class="ion-no-padding form-item">
            <ion-label mode="ios" class="ion-text-wrap" position="stacked">Pax</ion-label>
            <div class="position-relative w-100">
              <ion-select class="input-text" interface="popover" [interfaceOptions]="popoverOptions"
               [placeholder]="tableShowadd.allocatedPax">
              

              </ion-select>
              <!-- <ion-icon slot="end" class="select-arrow ca-down-arrow-yellow-icon"></ion-icon> -->
            </div>
          </ion-item>
        </ion-col>
 </ng-container>
      
        <ng-container formArrayName="items" *ngFor="let item of joinMoreform.get('items').controls; let i = index">
          <ng-container [formGroupName]="i">
            <ion-col size="7" class="informationItem pr-0">
              <ion-item mode="ios" lines="none" class="ion-no-padding form-item"> 
                <ion-label class="ion-text-wrap" position="stacked">{{ordinal_suffix_of(((i+1)+joinedTableArrLength))}} Table </ion-label>
                <div class="position-relative w-100">
                  <ion-select class="input-text" formControlName="moreTable" (ionChange)="joinTableSelect($event,i)"
                    interface="popover" [interfaceOptions]="popoverOptions" placeholder="Select">
                    <ng-container *ngFor="let tableShow of joinApiDetails.vacantTable; let z = index;">
                      <ion-select-option [value]="tableShow"
                        *ngIf="(tableShow.tableStatus == 'occupied' ? joinList[i].tableNo == tableShow.tableindetifier  : tableShow.tableStatus=='available')">
                        Table {{tableShow.tableNumber}}
                      </ion-select-option>
                    </ng-container>
                  </ion-select>



                  <ion-icon slot="end" class="select-arrow ca-down-arrow-yellow-icon"></ion-icon>
                </div>
              </ion-item>
            </ion-col>

            

            <ion-col size="5" class="informationItem pl-0">
              <ion-item mode="ios" lines="none" class="ion-no-padding form-item">
                <ion-label mode="ios" class="ion-text-wrap" position="stacked">Pax</ion-label>
                <!-- <ion-input mode="ios" class="input-text"  formControlName="moreTablePax"  maxlength="2"  type="text" placeholder="0"></ion-input> -->
                <div class="position-relative w-100">
                  <ion-select class="input-text" interface="popover" [interfaceOptions]="popoverOptions"
                    formControlName="moreTablePax" placeholder="Pax" (ionChange)="joinTabePax()">
                    <ng-container
                      *ngFor="let itempax of createArray(items?.value[i]?.moreTable?.minPax,items?.value[i]?.moreTable?.maxPax),; let x = index;">
                      <ion-select-option [value]="itempax">{{itempax}}</ion-select-option>

                    </ng-container>

                  </ion-select>
                  <ion-icon slot="end" class="select-arrow ca-down-arrow-yellow-icon"></ion-icon>
                </div>
              </ion-item>
              <ion-button class="removeBtn" fill="clear" (click)="remove(i)">
               Remove 
              </ion-button>
            
            </ion-col>
          </ng-container>
        
        </ng-container>
        <ion-col size="12" class="ion-text-center">
          <ion-button [disabled]="disableJoinMore" (click)="joinMorefun()" mode="md" class="joinMore" fill="clear">
            <ion-icon class="ca-plus-icon"></ion-icon> Join More
          </ion-button>
        </ion-col>
        <ion-col size="12">
          <ion-button mode="md" class="actionBtn" expand="block" [disabled]="joinMoreform.invalid ||!enableButton" (click)="JoinAPI()">
            Join </ion-button>
        </ion-col>

      </ion-row>
    </div>
  </ion-grid>

</div>