
import { Component, OnInit } from '@angular/core';
import { Platform, MenuController } from '@ionic/angular';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DashboardhomeService } from 'src/app/services/dashboardhome.service';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { EventService } from 'src/app/services/event/event.service';
import { CommonService } from 'src/app/services/common.service';
import { CUSTOM_SELECT_ICON, JAVASCRIPT, TITLE, ADDCLUBPAGE, REGEX, ACTIVATE_RESERVATION_PAGE } from 'src/app/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { InitService } from 'src/app/services/init/init.service';
import { LocationService } from 'src/app/services/core/location/location.service';
import country from '../../../data/country-code.json';

@Component({
    selector: 'app-addprospect',
    templateUrl: './addprospect.component.html',
    styleUrls: ['./addprospect.component.scss'],
})
export class AddprospectComponent implements OnInit {
    addClubForm: FormGroup
    ionicStorageEmployeeId: any;
    sfid: any;
    salutationarray: any = [];
    editDataValue: any;
    SelectCode: any;
    countries: any = [];
    outletPropertyId: any;
    propertyId: any;
    selectGender: any;
    selectGenderID: any;
    brandName: { brandName: string; };
    countryCodeInterface = {
        header: '',
        translucent: true
      };
      salutationInterface = {
        header: '',
        translucent: true
      };
    constructor(
        public plt: Platform,
        public events: EventService,
        public utilities: UtilitiesService,
        public router: Router,
        private menu: MenuController,
        public dashboardService: DashboardhomeService,
        public app: AppStateService,
        public initService: InitService,
        public commonService: CommonService,
        public translate: TranslateService,
        private locationService: LocationService,
    ) {

    }

    ngOnInit() {
        this.countries = this.getCurrentCountryOnTop(country)
        this.validForm()
        console.log("ngOnInit")
       
        this.ionicStorageEmployeeId = this.app.getAppData();
        this.sfid = this.ionicStorageEmployeeId.userDetails.sfid
        this.addClubForm.patchValue({
            countryCodeSelected: this.countries[0]
          });
          this.countryCodeFun()
        
    }

    ngAfterContentInit() {
        this.translate.get('Components.SelectCountryText').subscribe((selecteCountryCode) => {
            this.countryCodeInterface.header = selecteCountryCode;
          })
          this.translate.get('openReservation.salutation').subscribe((salutation) => {
            this.salutationInterface.header = salutation;
          });
        console.log("ngAfterContentInit")
        this.menu.enable(false)
        this.plt.ready().then((readySource) => {
            this.translate.get(this.initService.environment.AppBrandName).subscribe((res: string) => {
                this.brandName = {
                    brandName: res
                }
            });
            this.translate.get(ADDCLUBPAGE.ADDCLUBPAGE_TITLE, this.brandName).subscribe(
                (res) => {
                    this.events.publish(TITLE.TITLE, res);
                }
            );
        });
        this.outletPropertyId = this.dashboardService.propertyId;
        this.outletPropertyId.forEach(element => {
            this.propertyId = element.hotelId;
        });
        this.salutationarray = this.ionicStorageEmployeeId.appInitData.honorifics;
        this.addClubForm.patchValue({
            genderCode: this.salutationarray[0].id
        })
        this.selectGenderID = this.salutationarray[0].id
        this.selectGender = this.salutationarray[0].Value
        console.log("ionViewDidEnter")
        const ionSelects = document.querySelectorAll(CUSTOM_SELECT_ICON.ionSelect);
        ionSelects.forEach((sel) => {
            sel.shadowRoot.querySelectorAll(CUSTOM_SELECT_ICON.selectIconInner)
                .forEach((elem) => {
                    elem.setAttribute(JAVASCRIPT.STYLE, JAVASCRIPT.DISPLAY_NONE);
                });
        });
    }



    countryCodeFun() {
        this.SelectCode = this.addClubForm.value.countryCodeSelected.dialCode
    }

    getgenderCode(gender) {
        console.log(gender);
        this.selectGender = gender.value.Value;
        this.selectGenderID = gender.value.id;
    }
    getCurrentCountryOnTop(countries) {
        let currentCountryCode = this.locationService.getCurrentCountry();
        for (let i = 0; i < countries.length; i++) {
          if (countries[i].iso2.toLowerCase() === (currentCountryCode ? currentCountryCode.toLowerCase() : 'in')) {
            countries.unshift(countries.splice(i, 1)[0]);
            break;
          }
        }
        return countries;
      }

    funChekTypeEdit() {
        this.addClubForm.patchValue({
            countryCodeSelected: this.countries[0]
          });
          this.SelectCode = this.addClubForm.value.countryCodeSelected.dialCode

    }

   
    validForm() {
        this.addClubForm = new FormGroup({
            countryCodeSelected: new FormControl(this.countries[0]),
            genderCode: new FormControl(''),
            firstName: new FormControl('', [Validators.maxLength(20),
            Validators.minLength(2), Validators.required]),
            lastName: new FormControl('', [Validators.maxLength(20),
            Validators.minLength(2), Validators.required]),
            email: new FormControl('', [Validators.pattern(REGEX.email),Validators.required ]),
            mobile: new FormControl(null, [Validators.required]),
            sourceLead: new FormControl('', [Validators.required]),
        })

        this.addClubForm.statusChanges.subscribe(res => {
            this.addClubForm.setValidators([this.utilities.validatePhoneNumber, Validators.required]);
        });
    }


    // Get value in form 
    get genderCode(): any { return this.addClubForm.get(ACTIVATE_RESERVATION_PAGE.genderCode); }
    get firstName(): any { return this.addClubForm.get(ACTIVATE_RESERVATION_PAGE.firstName); }
    get lastName(): any { return this.addClubForm.get(ACTIVATE_RESERVATION_PAGE.lastName); }
    get mobile(): any { return this.addClubForm.get(ACTIVATE_RESERVATION_PAGE.mobile); }
    get email(): any { return this.addClubForm.get(ACTIVATE_RESERVATION_PAGE.email); }
    get countryCodeSelected(): any { return this.addClubForm.get(ACTIVATE_RESERVATION_PAGE.countryCodeSelected); }
    get sourceLead(): any { return this.addClubForm.get('sourceLead'); }
    backDashboard() {
        this.menu.enable(false)
        this.router.navigateByUrl('web-dashboard')
    }
    openModal() {
        document.getElementById(ADDCLUBPAGE.AddModal).classList.add(JAVASCRIPT.SHOW);
        document.getElementById(ADDCLUBPAGE.AddModal).style.display = JAVASCRIPT.BLOCK;
    }
    closeModal() {
        document.getElementById(ADDCLUBPAGE.AddModal).classList.remove(JAVASCRIPT.SHOW);
        document.getElementById(ADDCLUBPAGE.AddModal).style.display = JAVASCRIPT.NONE;
    }
    submitClick() {
        this.prospectApi();
    }
    gotClick() {
        this.router.navigateByUrl('web-dashboard');
        document.getElementById(ADDCLUBPAGE.AddModal).classList.remove(JAVASCRIPT.SHOW);
        document.getElementById(ADDCLUBPAGE.AddModal).style.display = JAVASCRIPT.NONE;
    }
    getcountryCode() {
        this.SelectCode = this.addClubForm.value.countryCodeSelected.dialCode
    }

    // * .....add prospect api..... *//
    prospectApi() {

        this.commonService.presentLoader();
        let data = {
            "employeeID": this.ionicStorageEmployeeId.userDetails.sfid,
            "memberDetails": {
                "honorific": this.selectGenderID,
                "firstName": this.addClubForm.value.firstName,
                "lastName": this.addClubForm.value.lastName,
                "contactDetails": {
                    "countryCode": this.SelectCode,
                    "phoneNumber": this.addClubForm.value.mobile,
                },
                "email": this.addClubForm.value.email,
            },
            "propertyID": this.ionicStorageEmployeeId.userDetails.hotelPropertyId,
            "outletID": this.ionicStorageEmployeeId.userDetails.outletId,
            "source": this.addClubForm.value.sourceLead
        }
        this.dashboardService.addprospect(this.sfid, data).subscribe((res) => {
            if (res) {
                this.commonService.dismissLoader();
                this.commonService.customAlertSuccess(res.statusMessage);
                if (res.data.prospectAlreadyExists == 'false') {
                    this.addClubForm.reset();
                    this.SelectCode = ''
                    this.funChekTypeEdit()
                }


            } else {
                this.commonService.dismissLoader();
            }
        }), err => {
            ////console.log('this is error the add prospect api------>>>>"', err)
        }
    }

    onlyNumberValidate(event: any) {
        const pattern = REGEX.onlyNumberValidate;
        let curStr = event.target.value;
        event.target.value = curStr.replace(pattern, JAVASCRIPT.BLANK);
    }
}
