import { Injectable } from '@angular/core';
import { ToastController, LoadingController, AlertController, NavController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { StorageService } from './core/storage/storage.service';
import { storageKeys, COMMOM_MESSAGES_SERVICES, appApiResources, JAVASCRIPT, LOGIN_PAGE, TLC_ERROR } from 'src/app/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { LocationService } from './core/location/location.service';
import { LocalizationService } from './core/localize/localization.service';
import { environment } from "../../environments/environment";
import { AppStateService } from './init/app-state.service';
import { TokenCheck } from './tokenCheck.service';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { CookieService } from 'ngx-cookie-service';
import { EventService } from './event/event.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  internetStatus: boolean = false
  isLoading;
  isLoadingInit: any
  uuid: any
  sendFormUserInfo: any = false
  apiErrorData: any;
  isLogined: boolean;
  message: any;
  sessionExpireEnable: boolean;
  // errorall: any;
  stopSessionExpire: boolean = true;
  memberseachEnable: boolean;
  errorall: any;
  dashboardApi: boolean;
  brandName: { brandName: string; };
  outletIDD: any;
  apkversion: string;
  imageURL: any;
  isCustomAlertOpen: boolean = false;
  nutritionTranslation: any = {};
  webError: any;
  expires: boolean = false;
  
  constructor(public toaster: ToastController,
    private loader: LoadingController,
    private file: File,
    public storage: StorageService,
    private platform: Platform,
    private navCtrl: NavController,
    public alertCtrl: AlertController,
    public router: Router,
    private translate: TranslateService, private locationService: LocationService,
    private localizationService: LocalizationService,
    public appState: AppStateService,
    private tokenCheck: TokenCheck,
    private cookieService: CookieService,
    private event:EventService
  ) { }

  async presentToast(val) {
    const toast = await this.toaster.create({
      mode: 'ios',
      message: val,
      duration: 2000,
      position: "bottom"
    });
    toast.present();
  }
  public chekcFormFun() {
    this.sendFormUserInfo == true
  }
  async internetOff() {
    let msg = JAVASCRIPT.BLANK;
    this.translate.get(COMMOM_MESSAGES_SERVICES.internetOffMessage).subscribe(async (res) => {
      if (res == COMMOM_MESSAGES_SERVICES.internetOffMessage) {
        return this.againInternetOffMessageFuction();
      } else {
        msg = res;
        const alert = await this.alertCtrl.create({
          mode: 'ios',
          cssClass: 'alertCustomCss',
          message: msg,
          buttons: [this.checkLanguage(COMMOM_MESSAGES_SERVICES.GotIt)]
        });
        await alert.present();
      }
    })
  }

  async popup(message) {
    let msg = JAVASCRIPT.BLANK;
      if (message ) {


        msg = message;
        const alert = await this.alertCtrl.create({
          mode: 'ios',
          cssClass: 'alertCustomCss',
          message: msg,
          buttons: [this.checkLanguage(COMMOM_MESSAGES_SERVICES.GotIt)]
        });
        await alert.present();
      }

  }


  async againInternetOffMessageFuction() {
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      cssClass: 'alertCustomCss',
      message: COMMOM_MESSAGES_SERVICES.againInternetOffMessage,
      buttons: ['Got It']
    });
    await alert.present();
  }



  async presentLoader(message?: string) {
    this.isLoading = true;
    return await this.loader.create({
      mode: 'ios',
      message: this.checkLanguage(message ? message : COMMOM_MESSAGES_SERVICES.presentLoaderMessage),
      translucent: true,
      spinner: "bubbles",
      duration:10000,
    }).then(loadingInstance => {
      loadingInstance.present().then(() => {
        if (!this.isLoading) {
          loadingInstance.dismiss().then(() =>
            console.log('abort presenting')
          );
        }
      });
    });
  }

  async presentLoaderOnce(message?: string) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    return await this.loader.create({
      mode: 'ios',
      message: this.checkLanguage(message ? message : COMMOM_MESSAGES_SERVICES.presentLoaderMessage),
      translucent: true,
      spinner: "bubbles"
    }).then(loadingInstance => {
      loadingInstance.present().then(() => {
        if (!this.isLoading) {
          loadingInstance.dismiss().then(() =>
            console.log('abort presenting')
          );
        }
      });
    });
  }


  async initloader() {
    this.isLoadingInit = true;
    var message = JAVASCRIPT.BLANK;
    setTimeout(async () => {
      message = this.checkLanguage(COMMOM_MESSAGES_SERVICES.initloaderMessage)
      return await this.loader.create({
        mode: 'ios',
        message: message,
        translucent: true,
        spinner: "bubbles"
      }).then(loadingInstance => {
        loadingInstance.present().then(() => {
          if (!this.isLoadingInit) {
            loadingInstance.dismiss().then(() =>
              console.log('abort presenting'));
          }
        });
      });
    }, 1000);
  }
  async dismissLoaderInit() {
    this.isLoadingInit = false;
    return await this.loader.dismiss().then(() =>
      console.log('dismissed'));
  }


  async presentLoaderMsg(msg) {
    this.isLoading = true;
    return await this.loader.create({
      mode: 'ios',
      message: msg,
      translucent: true,
      spinner: 'bubbles',
      duration: 7000
    }).then(loadingInstance => {
      loadingInstance.present().then(() => {
        //console.log('presented');
        if (!this.isLoading) {
          loadingInstance.dismiss().then(() =>
            console.log('abort presenting'));
        }
      });
    });
  }

  async dismissLoader() {
    this.isLoading = false;
    return await this.loader.dismiss().then(() => {
      console.log('dismissed')

    })

  }
  


  async errorConnectionsHandlerAction() {
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      cssClass: 'alertCustomCss',
      message: this.checkLanguage(COMMOM_MESSAGES_SERVICES.errorConnectionsHandlerActionMessage),
      buttons: [this.checkLanguage(COMMOM_MESSAGES_SERVICES.GotIt)]
    });
    await alert.present();
  }


  async emailregexCheck() {
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      cssClass: 'alertCustomCss',
      message: this.checkLanguage(COMMOM_MESSAGES_SERVICES.emailRegexMsgOnLogin),
      buttons: [this.checkLanguage(COMMOM_MESSAGES_SERVICES.GotIt)]
    });
    await alert.present();
  }

  async errorRedeemQRHandlerAction() {
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      cssClass: 'alertCustomCss',
      message: this.checkLanguage(COMMOM_MESSAGES_SERVICES.errorRedeemQRHandlerActionMessage),
      buttons: [this.checkLanguage(COMMOM_MESSAGES_SERVICES.GotIt)]
    });
    await alert.present();
  }

  async errorCancelledQRHandlerAction() {
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      cssClass: 'alertCustomCss',
      message: this.checkLanguage(COMMOM_MESSAGES_SERVICES.errorcancelledQRHandlerActionMessage),
      buttons: [this.checkLanguage(COMMOM_MESSAGES_SERVICES.GotIt)]
    });
    await alert.present();
  }

  async errorRedeemQRHandler() {
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      cssClass: 'alertCustomCss',
      message: this.checkLanguage(COMMOM_MESSAGES_SERVICES.errorRedeemQRHandlerMessage),
      buttons: [this.checkLanguage(COMMOM_MESSAGES_SERVICES.GotIt)]
    });
    await alert.present();
  }

  async errorInvlidOutletQRHandlerAction() {
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      cssClass: 'alertCustomCss',
      message: this.checkLanguage(COMMOM_MESSAGES_SERVICES.errorOutletQRHandlerActionMessage),
      buttons: [this.checkLanguage(COMMOM_MESSAGES_SERVICES.GotIt)]
    });
    await alert.present();
  }
  async erroralreadyRedeemQRHandlerAction() {
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      cssClass: 'alertCustomCss',
      message: this.checkLanguage(COMMOM_MESSAGES_SERVICES.errorrAlreadyRedeemQRHandlerActionMessage),
      buttons: [this.checkLanguage(COMMOM_MESSAGES_SERVICES.GotIt)]
    });
    await alert.present();
  }

  async errorHandlerForRootAndroid() {
    console.log("inside android ")
    const errorAlert = await this.alertCtrl.create({
      mode: 'ios',
      backdropDismiss: false,
      message: this.checkLanguage(COMMOM_MESSAGES_SERVICES.errorHandlerForRootAndroidMessage),

      buttons: [{
        text: 'OK',
        handler: () => {
          navigator['app'].exitApp();
        }
      }],
      cssClass: "alertCustomCss"
    });
    await errorAlert.present();
  }

  async errorHandlerForRootIos() {
    console.log("inisde ios pop")
    const errorAlert = await this.alertCtrl.create({
      mode: 'ios',
      backdropDismiss: false,
      message: this.checkLanguage(COMMOM_MESSAGES_SERVICES.errorHandlerForRootIosMessage),
      buttons: [
      ],
      cssClass: "alertCustomCss"
    });
    await errorAlert.present();
  }

  async agentUnavailable() {
    this.translate.get(environment.AppBrandName).subscribe((res: string) => {
      this.brandName = {
        brandName: res
      }
    });
    let msg = JAVASCRIPT.BLANK;
    this.translate.get(COMMOM_MESSAGES_SERVICES.live_assistance, this.brandName).subscribe((res) => {
      msg = res;
    })
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      cssClass: 'alertCustomCss',
      message: msg,
      buttons: [this.checkLanguage(COMMOM_MESSAGES_SERVICES.GotIt)]
    });
    await alert.present();
  }



  // common error handler Function   
  async errorHandler(err) {
    console.log("err==>>>>", err)
    this.webError = err
      this.message = err.error.errorMessage;
      if (err.error.errorCode == 'TLC-664') {
        this.hitCSRFFunc()
        return;
      }
      if (this.webError.error.errorCode == 'TLC-043') { 
        this.event.publish('expireYes', JAVASCRIPT.BLANK);
        if(!this.expires){
          this.expires = true;
            const errorAlert = await this.alertCtrl.create({
              mode: 'ios',
              message: this.message ? this.message : storageKeys.somethingWentWrong,
              buttons: [{
                text: this.checkLanguage(COMMOM_MESSAGES_SERVICES.GotIt),
                handler: () => {
                    if (this.webError.error.errorCode == 'TLC-043') {
                      console.log("only Expire Case")
                      this.webSecctionTokenExpire()
                    }
                  }
              }],
              cssClass: "alertCustomCss"
            });
            console.log("someCheck")
            this.dismissLoader();
            await errorAlert.present();
        }      
        return;
      } else {
        const errorAlert = await this.alertCtrl.create({
          mode: 'ios',
          message: this.message ? this.message : storageKeys.somethingWentWrong,
          buttons: [{
            text: this.checkLanguage(COMMOM_MESSAGES_SERVICES.GotIt),
            handler: () => {}
          }],
          cssClass: "alertCustomCss"
        });
        console.log("someCheck")
        this.dismissLoader();
        await errorAlert.present();
      }
      
    
  }

  webSecctionTokenExpire() {
    this.sessionExpireEnable = true
    this.storage.remove(storageKeys.loginCheck)
    localStorage.removeItem(LOGIN_PAGE.loggedUser)
    this.dashboardApi = false
    this.storage.remove(storageKeys.userDetails)
    this.storage.remove(storageKeys.TestLogin)
    this.storage.remove(storageKeys.sessionToken)
    this.appState.saveSessionToken(JAVASCRIPT.BLANK);
    this.appState.saveEmployeIdforHeader(JAVASCRIPT.BLANK);
    this.storage.remove(storageKeys.userPageAccess)
    this.storage.remove(storageKeys.employeeidHeader)
    this.storage.remove(storageKeys.userDetailsForChat)
    this.storage.storageClear();
    this.storage.storageremoveItem(storageKeys.userPreferredLanguage);
    this.localizationService.setDefaultAppLanguage();
    this.stopSessionExpire = true;
    this.navCtrl.navigateRoot('welcome-page')
    setTimeout(() => {
      this.expires = false;
    },100)
    this.dismissLoader();
  }

  hitCSRFFunc() {
    console.log("hittt_demoFUN")
    this.tokenCheck.checkCSRFToken()
    return;
  }


  async errorHandlerForDeviceCheck() {
    const errorAlert = await this.alertCtrl.create({
      mode: 'ios',
      backdropDismiss: false,
      message: this.checkLanguage(COMMOM_MESSAGES_SERVICES.errorHandlerForDeviceCheckMessage),
      cssClass: "alertCustomCss"
    });
    await errorAlert.present();
  }

  async displayNetworkUpdate() {
    const toast = await this.toaster.create({
      message: this.checkLanguage(COMMOM_MESSAGES_SERVICES.displayNetworkUpdateMessage),
      duration: 3000
    });
    toast.present();
  }


  headerSendHttp() {

    var headers = {
      'Content-Type': appApiResources.applicationORjson,
      'appid': environment.headers.appid,
      'client_id': environment.headers.client_id,
      'client_secret': environment.headers.client_secret,
      'Country': this.locationService.getCurrentCountry() ? this.locationService.getCurrentCountry() : environment.headers.country,
      'program_id': environment.headers.program_id,
      'subProgramID': environment.headers.subprogramid,
      'uuid': this.cookieService.get('uuid'),
      'Language': this.localizationService.getDeviceLanguage(),
      'timezone': String(new Date().getTimezoneOffset()),
     
    }
    return headers
  }

  checkLanguage(val) {
    let msg = JAVASCRIPT.BLANK;
    this.translate.get(val).subscribe((res) => {
      msg = res;
    })
    return msg;
  }

  /**
  @param countriesString use JSON.stringify() to stringify countries JSON before sending it as parameter.
  */
  getCurrentCountryOnTop(countriesString: string) {
    let countries: Array<any> = JSON.parse(countriesString);
    let currentCountryCode = this.locationService.getCurrentCountry();
    let currentCountryIndex = countries.findIndex((data) => {
      return data.iso2.toLowerCase() === (currentCountryCode ? currentCountryCode.toLowerCase() : 'in');
    });
    if (currentCountryIndex > -1) {
      let currentCountry = countries.splice(currentCountryIndex, 1)[0];
      countries.unshift(currentCountry);
    }
    return countries;
  }

  paddedString(toBePadded: any, size: any = 2) {
    let s = "000000000" + (Number(toBePadded));
    let rt = s.substr(s.length - size);
    return rt;
  }

  getImageURl(imageUri) {
    return new Promise((resolve, reject) => {
      imageUri = imageUri.split('?')[0]
      var copyPath = imageUri;
      var splitPath = copyPath.split('/');
      var imageName = splitPath[splitPath.length - 1];
      var filePath = imageUri.split(imageName)[0];
      console.log("imageName-->>", imageName)
      console.log("file path -->>", filePath)
      this.imageURL = "";
      this.file.readAsDataURL(filePath, imageName).then(base64 => {
        console.log("base 64", base64)
        this.imageURL = base64;
        resolve(this.imageURL)
      }, error => {
        reject(error)
        console.log('Error in showing image--->' + error);
      });
    })
  }

  async customAlert(message: string, replace?: any) {
    if (this.isCustomAlertOpen) {
      return;
    }
    this.isCustomAlertOpen = true;
    this.translate.get(message).subscribe(async (msg: string) => {
      if (replace) {
        msg = msg.replace('{{rowValue}}', replace);
      }
      const errorAlert = await this.alertCtrl.create({
        mode: 'ios',
        backdropDismiss: false,
        message: msg,
        buttons: [{
          text: 'OK',
          handler: () => {
            this.isCustomAlertOpen = false;

            errorAlert.dismiss();
          }
        }],
        cssClass: "alertCustomCss"
      });
      await errorAlert.present();
    });
  }

  anonymousCheck(error: any) {
    return new Promise((resolve, reject) => {
      if (error.error.errorCode == TLC_ERROR.TLC664) {
        this.tokenCheck.checkCSRFToken().then(res => {
          resolve(true);
        }).catch(err => {
          reject(false)
        });;
      } else {
        reject(false);
      }
    });
  }

  getNumberWithOrdinal(number: number) {
    let ordinals = ["th", "st", "nd", "rd"];
    let value = number % 100;
    return number + (ordinals[(value - 20) % 10] || ordinals[value] || ordinals[0]);
  }

  async customAlertSuccess(message: string, replace?: any) {
    if (this.isCustomAlertOpen) {
      return;
    }
    this.isCustomAlertOpen = true;
    this.translate.get(message).subscribe(async (msg: string) => {
      if (replace) {
        msg = msg.replace('{{rowValue}}', replace);
      }
      const errorAlert = await this.alertCtrl.create({
        mode: 'ios',
        backdropDismiss: false,
        message: msg,
        buttons: [{
          text: 'OK',
          handler: () => {
            this.isCustomAlertOpen = false;
            this.router.navigate(['web-dashboard']);
            errorAlert.dismiss();
          }
        }],
        cssClass: "alertCustomCss"
      });
      await errorAlert.present();
    });
  }

  async customAlertReload(message: string, replace?: any) {
    if (this.isCustomAlertOpen) {
      return;
    }
    this.isCustomAlertOpen = true;
    this.translate.get(message).subscribe(async (msg: string) => {
      if (replace) {
        msg = msg.replace('{{rowValue}}', replace);
      }
      const errorAlert = await this.alertCtrl.create({
        mode: 'ios',
        backdropDismiss: false,
        message: msg,
        buttons: [{
          text: 'OK',
          handler: () => {
            this.isCustomAlertOpen = false;
            window.location.reload();
            errorAlert.dismiss();
          }
        }],
        cssClass: "alertCustomCss"
      });
      await errorAlert.present();
    });
  }

  



}
