<ng-container  *ngIf="deliveryTimeValues?.length">
  <div class="timeSlotWrap" *ngFor="let delTimeVal of deliveryTimeValues; let i = index;">
    <ng-container>
      <div class="row">
        <span class="window-title">{{"setupRules.Window"|translate}} {{ i + 1}}</span>
        <ion-icon slot="end" class="ca-close-icon" (click)="deleteWindow(i, lookIntoObject)"></ion-icon>
      </div>
      <div class="row">
        <span class="selectedDays">
          <ng-container *ngFor="let wDays of delTimeVal?.days">
            {{ showDays(wDays)+" "}}
          </ng-container>
        </span>
        <span class="selectedTime">
          {{ delTimeVal | timestampConvert:'start'}} {{"setupRules.to"|translate}} {{ delTimeVal | timestampConvert:'end'}}
        </span>
      </div>
    </ng-container>
  </div>  
</ng-container>
