import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, IonInfiniteScroll, Platform } from '@ionic/angular';
import { DashboardhomeService } from 'src/app/services/dashboardhome.service';
import { CommonService } from 'src/app/services/common.service';
import { ProfileService } from 'src/app/services/profile.service';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { TITLE, AWAITING_CONFIMATION_PAGE, JAVASCRIPT, COUNT } from 'src/app/app.constants';
import { EventService } from 'src/app/services/event/event.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-awaitingconfirmation',
  templateUrl: './awaitingconfirmation.component.html',
  styleUrls: ['./awaitingconfirmation.component.scss'],
})
export class AwaitingconfirmationComponent implements OnInit {
  @ViewChild(IonInfiniteScroll, { static: true }) infiniteScroll: IonInfiniteScroll;

  reservationType: any;
  currDate: number;
  prevDate: number;
  endDate: any;
  startDate: any;
  extenDate: any;
  reservationConfirmTimeArr: any = [];
  hotelContent: any;
  skelton: boolean = true;
  reservenumber: any;
  indexValue: number = 1;
  reservationDetailsArr: any = [];
  dataset: boolean = true;
  reservConfirmationData: any;
  currentDate: any;
  dateobj: any;
  reservationPhotoUrl: any = [];
  reservationStorage: any;
  changeweek: string;
  sendingSfid: any;
  enableManageScreen: boolean;
  NolistMessage:boolean = false
  constructor(
    public plt: Platform,
    public menu: MenuController,
    public events: EventService,
    public router: Router,
    public dashboardService: DashboardhomeService,
    public common: CommonService,
    private translate: TranslateService,
    public profileService: ProfileService,
    public appState: AppStateService,
  ) { }

  ionViewWillEnter() {
   
    this.menu.enable(false)
    this.plt.ready().then((readySource) => {
      this.translate.get(AWAITING_CONFIMATION_PAGE.AWAITING_CONFIMATION).subscribe((res: string) => {
        this.events.publish(TITLE.TITLE, res);
      });
    });
    this.getReservationsFuncApi(this.indexValue);
    this.reservationStorage = this.appState.getAppData();
  }

  oneMonthAdded() {
    var CurrentDate = new Date().getTime();
    this.extenDate = CurrentDate + 2629800000;
  }
 

  ngOnInit() {
    this.reservationType = this.dashboardService.reservationsTypeData;
    this.oneMonthAdded();
    // this.endDate = (new Date().toISOString()).split(JAVASCRIPT.T)[COUNT.COUNT_0];
    // this.startDate = (new Date().toISOString()).split(JAVASCRIPT.T)[COUNT.COUNT_0];
    this.endDate =  moment().format('YYYY-MM-DD')
    this.startDate =  moment().format('YYYY-MM-DD')
    this.ionViewWillEnter() 

  }
  profile(membershipNumber, customerMobile, CustomerEmail) {
    this.profileService.membershipNumber = membershipNumber;
    if(this.profileService.membershipNumber) {
      this.profileService.customerPersonalDetails = {
        'mobile': customerMobile,
        'email': CustomerEmail,
      }
      this.skelton = true;
      this.router.navigate(['guest-profile']);
    }
   
  }
  backReservation() {
    this.skelton = true;
    this.router.navigate(['web-dashboard']);
  }

  manageReservationNavigate(setSfid, membershipNumber) {
    this.profileService.membershipNumber = membershipNumber;
    this.sendingSfid = setSfid
    this.enableManageScreen = true
    // const queryParams = { id: setSfid }
    // this.skelton = true;
    // this.router.navigate(['manage-reservation'], {
    //   queryParams: queryParams
    // });
  }

  //..Infinite scroll loader rotation..//

  loadData(event) {
    this.indexValue;
    setTimeout(() => {
      event.target.complete();
      if (this.dataset == true) {
        this.indexValue++;
        this.getReservationsFuncApi(this.indexValue);
      } else {
        this.common.dismissLoader();
        event.target.disabled = true;
      }
    }, 2000);
  }

  getReservationsFuncApi(index) {
    var newArr = [];

    this.dashboardService.allReservationApi(this.reservationType, this.startDate, this.endDate, JAVASCRIPT.BLANK, JAVASCRIPT.BLANK, index, JAVASCRIPT.BLANK).subscribe(res => {
      this.skelton = false;
      if (res) {
        newArr = res.result;
        this.dataset = res.isNextDataSet;
        newArr.forEach(x => {
          this.reservationDetailsArr.push(x);
        })
        this.skelton = false;
        this.reservationDetailsArr = res.result;
        if (this.reservationDetailsArr.length === 0) {
          this.NolistMessage = true;
        } 
        this.dashboardService.reserveDet = this.reservationDetailsArr;

        this.reservationDetailsArr.forEach(element => {
          element[AWAITING_CONFIMATION_PAGE.reservationDetails].forEach(ele => {
            ele[AWAITING_CONFIMATION_PAGE.reservationcreatedAt] = Math.floor(((new Date().getTime() - 19800000) - (new Date(new Date(ele[AWAITING_CONFIMATION_PAGE.reservationcreatedAt]).toUTCString()).getTime())) / 3600000);
            this.profileService.mobileNo = ele[AWAITING_CONFIMATION_PAGE.memberDetails].mobile
            this.profileService.email = ele[AWAITING_CONFIMATION_PAGE.memberDetails].email;
            if (ele[AWAITING_CONFIMATION_PAGE.memberDetails].Photo != JAVASCRIPT.BLANK) {
              ele[AWAITING_CONFIMATION_PAGE.photo] = ele[AWAITING_CONFIMATION_PAGE.memberDetails].Photo + '?' + new Date().getTime()
            }
          });
        });
      }
      else {
        this.skelton = false;
      }

    }, err => {
      this.common.errorHandler(err);
    });
  }

  backConfirmation() {
    this.enableManageScreen = false
  }
}

