<div *ngIf="!enableManageScreen">
  <ion-list class="mambers-list" *ngIf="!skelton">
    <h1 class="for-web page-title">Awaiting Arrival</h1>
    <ion-item lines="none" *ngIf="NolistMessageArrival" class="available">
      <p class="messageShow">{{'closeChequePage.redemption_list_message'|translate}}</p>
    </ion-item>
    <ion-item-group *ngFor="let time of reservationTimeArr" class="mamber-wrap">
      <ion-label class="date ion-text-wrap">{{time}}</ion-label>
      <div class="mamber-group" *ngFor="let reservationData of awaitData; index as i">
        <div class="" *ngFor="let data of reservationData.reservationDetails; index as j">
          <ion-card class="default-card member-card" *ngIf="time == data?.reservationTime">
            <ion-item lines="full">
              <ion-avatar class="cursor" (click)="profile(data?.memberDetails?.membershipNumber)" slot="start">
                <img [src]="data?.memberDetails?.Photo ||'assets/imgs/user-img.png'">
              </ion-avatar>
              <ion-label class="ion-text-wrap">
                <h2>{{data?.memberDetails?.firstName}}&nbsp;{{data?.memberDetails?.lastName}}</h2>
                <p> {{'queryPage.reservation'|translate}} </p>
              </ion-label>
              <div slot="end" (click)="openModal(data,ionicStorageEmployeeId.userDetails.outletType)"
                class="custom-radio">
                <input type="radio"
                  *ngIf="ionicStorageEmployeeId?.userPageAccess?.userPageAccess?.includes('PUT_ALLOT_REG_TABLE')"
                  name="checkValue" [(ngModel)]="checkValue" id="one{{j}}" [value]="j">
                <label for="one{{j}}"></label>
              </div>
            </ion-item>
            <ion-buttons class="btns-col">
              <ion-button color="primary" *ngIf="data.memberDetails?.mobile!=''"
                href="tel:{{'+'+data.memberDetails?.mobile}}">
                <ion-icon class="ca-call"></ion-icon>
                {{'queryPage.call' | translate}}
              </ion-button>
              <ion-button color="primary" (click)="manageReservation(data.reservationID)">
                <ion-icon class="ca-view"></ion-icon> {{'queryPage.View' | translate}}
              </ion-button>
            </ion-buttons>
          </ion-card>
        </div>
      </div>


    </ion-item-group>
  </ion-list>
</div>

<div *ngIf="enableManageScreen">
  <ion-button class="backButton" fill="outline" (click)="backAwaiting()" mode="md" shape="round">
    <ion-icon mode="ios" class="ca-back"></ion-icon> &nbsp; Back
  </ion-button>
  <app-manage-reservation [sendSfid]="sendingSfid"></app-manage-reservation>
</div>

<!-- Skelton Text Loder -->
<ion-list class="mambers-list" *ngIf="skelton">
  <ion-item-group class="mamber-wrap">
    <ion-label class="date ion-text-wrap">
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
        <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
      </p>
    </ion-label>
    <div class="mamber-group">
      <ion-card class="default-card member-card">
        <ion-item lines="full">
          <ion-avatar slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h2>
              <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </h2>
            <p>
              <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
            </p>
          </ion-label>
          <ion-avatar slot="end" style="height: 36px; width: 36px;">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
        </ion-item>
        <ion-buttons class="btns-col">
          <ion-button color="primary">
            <ion-skeleton-text animated style="width: 35%; height: 18px;"></ion-skeleton-text>
          </ion-button>
          <ion-button color="primary">
            <ion-skeleton-text animated style="width: 35%; height: 18px;"></ion-skeleton-text>
          </ion-button>
        </ion-buttons>
      </ion-card>

      <ion-card class="default-card member-card">
        <ion-item lines="full">
          <ion-avatar slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h2>
              <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </h2>
            <p>
              <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
            </p>
          </ion-label>
          <ion-avatar slot="end" style="height: 36px; width: 36px;">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
        </ion-item>
        <ion-buttons class="btns-col">
          <ion-button color="primary">
            <ion-skeleton-text animated style="width: 35%; height: 18px;"></ion-skeleton-text>
          </ion-button>
          <ion-button color="primary">
            <ion-skeleton-text animated style="width: 35%; height: 18px;"></ion-skeleton-text>
          </ion-button>
        </ion-buttons>
      </ion-card>
    </div>
  </ion-item-group>

  <ion-item-group class="mamber-wrap">
    <ion-label class="date">
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
        <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
      </p>
    </ion-label>
    <div class="mamber-group">
      <ion-card class="default-card member-card">
        <ion-item lines="full">
          <ion-avatar slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h2>
              <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </h2>
            <p>
              <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
            </p>
          </ion-label>
          <ion-avatar slot="end" style="height: 36px; width: 36px;">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
        </ion-item>
        <ion-buttons class="btns-col">
          <ion-button color="primary">
            <ion-skeleton-text animated style="width: 35%; height: 18px;"></ion-skeleton-text>
          </ion-button>
          <ion-button color="primary">
            <ion-skeleton-text animated style="width: 35%; height: 18px;"></ion-skeleton-text>
          </ion-button>
        </ion-buttons>
      </ion-card>
    </div>
  </ion-item-group>
</ion-list>

<!-- Modal -->
<div class="customModal" id="customModal">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <ion-buttons>
        <ion-icon slot="end" (click)="closeModal()" class="ca-modal-close"></ion-icon>
      </ion-buttons>
      <div class="customModal-body ion-text-center">
        <div class="customModal-text">
          <h2 style="margin-bottom: 10px;">
            <ion-text color="primary">
              {{userDetailsModal?.memberDetails?.firstName}}&nbsp;{{userDetailsModal?.memberDetails?.lastName}}&nbsp;{{'awaitArrival.has_arrived'|translate}}
            </ion-text>
          </h2>
          <ion-grid>
            <ion-row>
              <ion-col size="7">
                <ion-label class="ion-text-left m-0">
                  <p>{{'awaitArrival.enter_table_number' | translate}}</p>
                </ion-label>
              </ion-col>
              <ion-col size="5">
                <div class="position-relative">
                  <ion-select  class="tableNumberSelect" [(ngModel)]="tableNo" [placeholder]="'Table Number'" interface="popover"
                    [interfaceOptions]="popoverOptions">
                    <ion-select-option *ngFor="let table of allVaccanTable" [value]="table.tableSfid">{{table.tableNum}}</ion-select-option>
                  </ion-select>
                  <ion-icon slot="end" style="right:0" class="select-arrow ca-down-arrow-yellow-icon"></ion-icon>
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="7">
                <ion-label class="ion-text-left m-0">
                  <p>{{'awaitArrival.enter_check_number' | translate}}</p>
                </ion-label>
              </ion-col>
              <ion-col size="5">
                <ion-input style="max-width: 100%; margin-left: 0;" mode="ios" class="ion-text-center smallInput" maxlength="10"
                  [(ngModel)]="checkNo">
                </ion-input>
              </ion-col>
            </ion-row>
          </ion-grid>

          <!-- <ion-item mode="ios" lines="none" class="form-group height-auto">
            <ion-label class="ion-text-center">
              <p>{{'awaitArrival.enter_table_number' | translate}}</p>
            </ion-label>
            <ion-input slot="end" mode="ios" class="ion-text-center smallInput" type='tel' maxlength="3"
              [(ngModel)]="tableNo">
            </ion-input>
          </ion-item>
          <ion-item mode="ios" lines="none" class="form-group height-auto">
            <ion-label class="ion-text-center">
              <p>{{'awaitArrival.enter_check_number' | translate}}</p>
            </ion-label>
            <ion-input slot="end" mode="ios" class="ion-text-center smallInput" maxlength="10" [(ngModel)]="checkNo">
            </ion-input>
          </ion-item> -->

        </div>
      </div>
      <div class="customModal-footer">
        <ion-button mode="ios" expand="full" class="customModalButton primary-gradient"
          [disabled]="(!tableNo || tableNo ==0) || checkNo?.length < 2"
          (click)="confirmModal(userDetailsModal.reservationID)">
          {{'awaitArrival.confirm'|translate}}
        </ion-button>
      </div>
    </div>
  </div>
</div>

<div class="customModal" id="arrivalArrived">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <ion-buttons>
        <ion-icon slot="end" (click)="ArrivalcloseModal()" class="ca-modal-close"></ion-icon>
      </ion-buttons>
      <div class="customModal-body ion-text-center">
        <div class="customModal-text">
          <ion-item lines="none">
            <ion-label class="ion-text-center" position="stacked">
              <h2>
                {{userDetailsModal?.memberDetails?.firstName}}&nbsp;{{userDetailsModal?.memberDetails?.lastName}}&nbsp;{{'awaitArrival.has_arrived'|translate}}
              </h2>
              <h2 *ngIf="userDetailsModal?.guestCount>1"> {{'awaitArrival.with'|translate}}
                {{userDetailsModal?.guestCount-1}} {{'awaitArrival.guest'|translate}}</h2>
            </ion-label>
          </ion-item>
        </div>
      </div>
      <div class="customModal-footer two-btn">
        <ion-button mode="ios" expand="full" class="customModalButton light-gradient" (click)="skipArrival()">
          {{'awaitArrival.skip'|translate}}
        </ion-button>
        <ion-button mode="ios" expand="full" class="customModalButton primary-gradient" (click)="continueArrival()">
          {{'awaitArrival.continue'|translate}}
        </ion-button>
        <!-- <ion-button mode="ios" expand="full" class="customModalButton primary-gradient" (click)="confirmArrival()" -->
        <ion-button mode="ios" expand="full" class="customModalButton primary-gradient"
          *ngIf="userDetailsModal?.guestCount<1">
          {{'awaitArrival.confirm'|translate}}
        </ion-button>
      </div>
    </div>
  </div>
</div>

<div class="customModal" id="arrivalArrivedGuestName">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <ion-buttons>
        <ion-icon slot="end" (click)="ArrivalGuestNamecloseModal()" class="ca-modal-close"></ion-icon>
      </ion-buttons>
      <div class="customModal-body ion-text-center">
        <div class="customModal-text">
          <ion-item mode="ios" lines="full" class="form-group height-auto"
            *ngIf="(guestNameArry.length + 1) < userDetailsModal?.guestCount && !skipValue">
            <ion-label class="ion-text-center" position="stacked">
              <p>{{'awaitArrival.enter'|translate}} {{guestNameArry.length + 2}} {{'awaitArrival.guest_name'|translate}}
              </p>
            </ion-label>
            <ion-input mode="ios" autocapitalize type="text" class="ion-text-center border-0"
              [(ngModel)]="guestNameObj">
            </ion-input>
          </ion-item>

          <ion-item mode="ios" lines="full" class="form-group height-auto">
            <ion-label class="ion-text-center" position="stacked">
              <p>{{'awaitArrival.enter_check_number' | translate}}</p>
            </ion-label>
            <ion-input mode="ios" class="ion-text-center border-0" maxlength="10" [(ngModel)]="checkNo">
            </ion-input>
          </ion-item>

        </div>
      </div>
      <div class="customModal-footer two-btn more-btns">

        <div *ngIf="!skipValue ">
          <ion-button mode="ios" class="customModalButton light-gradient" (click)="skipArrival()">
            {{'awaitArrival.skip'|translate}}
          </ion-button>
        </div>
        <div *ngIf="(guestNameArry.length + 1) < (userDetailsModal?.guestCount-1) ">
          <ion-button mode="ios" class="customModalButton primary-gradient" *ngIf="!skipValue "
            [disabled]='!guestNameObj' (click)="saveGuestName(userDetailsModal.reservationID)">
            {{'awaitArrival.continue'|translate}}
          </ion-button>
        </div>

        <div *ngIf="(guestNameArry.length+ 1) == (userDetailsModal?.guestCount-1) || userDetailsModal?.guestCount==1">
          <ion-button mode="ios" class="customModalButton primary-gradient" *ngIf="!skipValue "
            [disabled]="checkNo?.length < 2" (click)="saveGuestName(userDetailsModal.reservationID)">
            {{'awaitArrival.confirm'|translate}}
          </ion-button>
        </div>
      </div>

      <ion-button mode="ios" [disabled]="checkNo?.length < 2" (click)="skpiConfirmApi()"
        class="customModalButton primary-gradient" *ngIf="skipValue">
        {{'awaitArrival.confirm'|translate}}
      </ion-button>
    </div>
  </div>
</div>