import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, MenuController } from '@ionic/angular';
import { DashboardhomeService } from 'src/app/services/dashboardhome.service';
import { CommonService } from 'src/app/services/common.service';
import { ProfileService } from 'src/app/services/profile.service';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { TITLE, IN_PROGRESE_PAGE, JAVASCRIPT, COUNT } from 'src/app/app.constants';
import { EventService } from 'src/app/services/event/event.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-inprogress',
  templateUrl: './inprogress.component.html',
  styleUrls: ['./inprogress.component.scss'],
})
export class InprogressComponent implements OnInit {

  startDate: any;
  endDate: any;
  extenDate: any;
  skelton: boolean = true;
  inProgressArray: any;
  inProgressresult: any = [];
  currentDate: number;
  inProgressStorage: any;
  sendingSfid: any;
  enableManageScreen: boolean;
 

  constructor(
    public events: EventService,
    public router: Router,
    public plt: Platform,
    private menu: MenuController,
    public dashboardService: DashboardhomeService,
    public commonService: CommonService,
    private translate: TranslateService,
    public profileService: ProfileService,
    public appState: AppStateService,
  ) { }

  ngAfterContentInit() {
    console.log("ngAfterContentInit call ==>>",)
    this.plt.ready().then((readySource) => {
      this.translate.get(IN_PROGRESE_PAGE.IN_PROGRESE).subscribe((res: string) => {
        this.events.publish(TITLE.TITLE, res);
      });
    });
  
    this.inProgressStorage = this.appState.getAppData();
    this.inProgressAPi();
  }

  oneMonthAdded() {
    this.currentDate = new Date().getTime();
    this.extenDate = this.currentDate + 2629800000;
  }

  ngOnInit() {
    this.oneMonthAdded();
    // this.endDate = (new Date().toISOString()).split(JAVASCRIPT.T)[COUNT.COUNT_0];
    // this.startDate = (new Date().toISOString()).split(JAVASCRIPT.T)[COUNT.COUNT_0];
    this.endDate =  moment().format('YYYY-MM-DD')
    this.startDate =  moment().format('YYYY-MM-DD')
  }

  profile(membershipNumber) {
    this.profileService.membershipNumber = membershipNumber;
    if(this.profileService.membershipNumber) {
      this.router.navigate(['guest-profile']);
    }
  }

  manageReservation(reservationID, reservationTableNumber) {
    this.dashboardService.reservationTable = reservationTableNumber
    this.sendingSfid = reservationID
    this.enableManageScreen = true
  }

  

  inProgressAPi() {
    this.inProgressresult = []
    this.skelton = true;
    this.dashboardService.inProgress(this.startDate, this.endDate).subscribe(res => {
      if (res) {
        this.skelton = false;
        this.inProgressArray = res.result;
        this.inProgressArray.forEach(element1 => {
          element1[IN_PROGRESE_PAGE.reservationDetails].forEach(element2 => {
            this.inProgressresult.push(element2);
          });
        });
        // this.inProgressresult.forEach(memberDetails => {
        //   this.translate.get(IN_PROGRESE_PAGE.localizedType + memberDetails.memberDetails.memberType).subscribe((localizedMember) => {
        //     memberDetails.memberDetails.localizedType = localizedMember;
        //   });
        // });
      }
      else {
        this.skelton = false;
      }
    }, err => {
      this.commonService.dismissLoader();
      this.commonService.errorHandler(err);
    });
  }

  backAwaiting() {
    this.inProgressAPi();
    this.enableManageScreen = false
  }



}
