<div class="modalBg">
  <ion-card class="modalWrap">
    <ion-card-header class="modalHeader">
      <h2 class="modalTitle"> {{'userTypeModelPage.userType' | translate}}</h2>
      <ion-icon class="modalClose ca-close-icon" (click)="closeModal()"></ion-icon>
    </ion-card-header>
    <form>
      <ion-card-content class="modalContent">
        <ion-item lines="full" *ngFor="let item of userTypeoption; index as i">
          <div class="custom-radio">
            <input type="radio" id="option{{item.key}}" (change)="onItemChange(item.value)" [value]="item.key" [(ngModel)]="userDataStore"  name="userDataStore" class="custom-radio">
            <label for="option{{item.key}}">{{item.value}}</label>
          </div>
        </ion-item>
      </ion-card-content>
      <ion-button mode="ios" expand="block" class="modalButton ion-no-margin primary-gradient"
        [disabled]="!userDataStore" (click)="applyFilterRedemption()">{{'userTypeModelPage.applyFilter' | translate}}
      </ion-button>
    </form>
  </ion-card>
</div>