<div class="modalBg">
  <ion-card class="modalWrap">
    <ion-card-header class="modalHeader border-0">
      <!-- <h2 class="modalTitle">Update Email Id</h2>
      <h2 class="modalTitle">Update Mobile Number</h2> -->
      <ion-icon class="modalClose ca-close-icon" (click)="closeModal()"></ion-icon>
    </ion-card-header>
    <form>
      <ion-card-content class="modalContent">
        <div class="formField">
          <ion-item lines="none">
            <ion-label class="input-label ion-text-wrap" position="stacked">
              <p>{{'benefitsModal.restaurantName' | translate}}</p>
            </ion-label>
            <h2>{{'benefitsModal.kitchen' | translate}} </h2>
          </ion-item>
        </div>
        <div class="formField">
          <ion-item lines="none">
            <ion-label class="input-label ion-text-wrap" position="stacked">
              <p>{{'benefitsModal.redemption_date' | translate}} </p>
            </ion-label>
            <h2>Aug 24, 2018</h2>
          </ion-item>
        </div>
        <div class="formField">
          <ion-item lines="none">
            <ion-label class="input-label ion-text-wrap" position="stacked">
              <p>{{'benefitsModal.number_of_guests ' | translate}} </p>
            </ion-label>
            <h2>02</h2>
          </ion-item>
        </div>
        <div class="formField">
          <ion-item lines="none">
            <ion-label class="input-label ion-text-wrap" position="stacked">
              <p>Steward Name</p>
            </ion-label>
            <h2>Scott</h2>
          </ion-item>
        </div>
        <ion-buttons mode="ios" class="ion-justify-content-center w-100">
          <ion-button mode="ios" color="primary" (click)="onClick()">
            {{'benefitsModal.modify'|translate}}
          </ion-button>
        </ion-buttons>
      </ion-card-content>

      <!-- Update Button -->
      <ion-button mode="ios" expand="block" class="modalButton ion-no-margin primary-gradient" (click)="onClick()">
        {{'benefitsModal.confirm_details'|translate}}
      </ion-button>
    </form>
  </ion-card>
</div>