import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EventService } from 'src/app/services/event/event.service';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import country from '../../../data/country-code.json'
import { DashboardhomeService } from 'src/app/services/dashboardhome.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { REGEX, WALK_IN_PAGE, TITLE, JAVASCRIPT } from 'src/app/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { InitService } from 'src/app/services/init/init.service';

@Component({
  selector: 'app-walk-in',
  templateUrl: './walk-in.component.html',
  styleUrls: ['./walk-in.component.scss'],
})
export class WalkInComponent implements OnInit {

  informationType: any;
  walkInForm: FormGroup
  countries = [];
  SelectCode: any;
  messageValidation: boolean = true;

  constructor(
    public events: EventService,
    public modalCtrl: ModalController,
    public router: Router,
    public plt: Platform,
    private translate: TranslateService,
    public initService: InitService,
    public dashboardService: DashboardhomeService,
    public utilities: UtilitiesService,
  ) { this.formValidWalkIn() }

  ngOnInit() {
    this.informationType = WALK_IN_PAGE.phoneNumber
    this.countryCodeFun()
    this.checkCountryCode()
  }

  ionViewWillEnter() {
    this.plt.ready().then((readySource) => {
      this.translate.get(WALK_IN_PAGE.Walk).subscribe((res: string) => {
        this.events.publish(TITLE.TITLE, res);
      });
    });
  }

  checkCountryCode() {

    if (this.informationType == WALK_IN_PAGE.emailaddress) {
    }

    this.walkInForm.patchValue({
      countryCodeSelected: {
        name: this.initService.environment.CountryName,
        iso2: this.initService.environment.CountryCode,
        dialCode: this.initService.environment.CountryDialCode
      },
    });
    this.countryCodeChange()
    if (this.informationType == WALK_IN_PAGE.phoneNumber) {
      // this.walkInForm.get('email').setValidators(null);
      this.walkInForm.setValidators([this.utilities.validatePhoneNumber, Validators.required]);
      ////console.log("hit")
    }
    else {
      ////console.log("kabbbb")
      this.walkInForm.setValidators(null);
    }
  }

  formValidWalkIn() {
    this.walkInForm = new FormGroup({
      countryCodeSelected: new FormControl({
        name: null,
        iso2: null,
        dialCode: null
      },
        []
      ),
      // firstName: new FormControl(JAVASCRIPT.BLANK, [Validators.pattern(/^[a-z ,.'-]+$/i), Validators.required]),
      // numberofGuest: new FormControl(JAVASCRIPT.BLANK, Validators.required),
      // memshipNo: new FormControl(JAVASCRIPT.BLANK, Validators.required),
      // email: new FormControl(null, [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i)]),
      // mobile: new FormControl(null),

      firstName: new FormControl(JAVASCRIPT.BLANK),
      numberofGuest: new FormControl(JAVASCRIPT.BLANK),
      memshipNo: new FormControl(JAVASCRIPT.BLANK),
      email: new FormControl(null, [Validators.pattern(REGEX.email)]),
      mobile: new FormControl(null),

    })
    this.walkInForm.statusChanges.subscribe(res => {
      ////console.log("resss==>>>" + res)
      ////console.log("resss==>>>" + this.walkInForm)
    });
  }
  countryCodeFun() {
    this.countries = country;
  }


  get firstName(): any {
    return this.walkInForm.get(WALK_IN_PAGE.firstName);
  }
  get numberofGuest(): any {
    return this.walkInForm.get(WALK_IN_PAGE.numberofGuest);
  }
  get memshipNo(): any {
    return this.walkInForm.get(WALK_IN_PAGE.memshipNo);
  }


  get email(): any {
    return this.walkInForm.get(WALK_IN_PAGE.email);
  }
  get mobile(): any {
    return this.walkInForm.get(WALK_IN_PAGE.mobile);
  }
  get countryCodeSelected(): any {
    return this.walkInForm.get(WALK_IN_PAGE.countryCodeSelected);
  }

  //Custom Select Icon
  ionViewDidEnter() {
    // const ionSelects = document.querySelectorAll('ion-select');
    // ionSelects.forEach((sel) => {
    //   sel.shadowRoot.querySelectorAll('.select-icon-inner')
    //     .forEach((elem) => {
    //       elem.setAttribute('style', 'display: none;');
    //     });
    // });
  }

  backDashboard() {
    this.router.navigate(['web-dashboard']);
  }
  // API WALK IN 
  fetchGuestProfile() {
    this.dashboardService.walkIn(this.walkInForm.value.countryCodeSelected.dialCode + this.walkInForm.value.mobile, this.walkInForm.value.email, this.walkInForm.value.memshipNo, this.informationType).subscribe((res) => {

    }), err => {
      ////console.log('this is error the add prospect api------>>>>"', err)
    }
  }

  ProceedClick() {
    this.router.navigate(['guest-profile']);
    document.getElementById(WALK_IN_PAGE.customModal).classList.remove(JAVASCRIPT.SHOW);
  }

  countryCodeChange() {
    this.SelectCode = this.walkInForm.value.countryCodeSelected.dialCode

  }
  changeSelectInfo(val) {
    if (val.target.value == WALK_IN_PAGE.phoneNumber) {
      this.walkInForm.patchValue({
        mobile: JAVASCRIPT.BLANK
      })
      this.messageValidation = true;
    }
  }
  closeModal() {
    this.modalCtrl.dismiss({
    });
  }
  onClick(){
    
  }
}
