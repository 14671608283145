import { Component, OnInit, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { EventService } from 'src/app/services/event/event.service';
import { BACK_BUTTON } from 'src/app/app.constants';


@Component({
  selector: 'back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {

  @Input() backPage: string = null;
  @Input(BACK_BUTTON.show) show: string;
  @Input(BACK_BUTTON.select) select: string;
  titleVal: any;
  constructor(
    public events: EventService,
    public navCtrl: NavController
  ) {
    this.events.subscribe(BACK_BUTTON.title, titleData => {
      this.backPage = titleData
    });
  }

  ngOnInit() {
    if (!this.backPage) {
      ////console.log(this.backPage);
    }
  }

  navigationBack() {
  
    this.navCtrl.setDirection('back');
    this.navCtrl.pop();
    // this.navCtrl.back();
  }
}


