<ion-item-group *ngIf="loaded">
  <ion-card class="default-card">
      <ion-card-content>
          <ion-item lines="none" class="ion-no-padding height-auto value-item">
              <label class="ion-text-wrap">
                  <h3 class="user-name">{{userInfo?.firstName}}&nbsp; {{userInfo?.lastName}}</h3>
              </label>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding height-auto value-item">
              <label class="ion-text-wrap">
                  <p class="label-value"> +{{userInfo?.mobile}} </p>
                  <p class="label-value"> {{userInfo?.email}} </p>
              </label>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding height-auto value-item">
              <label class="ion-text-wrap">
                  <p class="label-value">{{userInfo?.programName}}</p>
                  <p class="label-value">{{userInfo?.level}}</p>
              </label>
          </ion-item>
      </ion-card-content>
  </ion-card>

  <ion-card class="default-card order-details">
      <ion-card-header>
          <ion-card-title>{{'userDetailsPage.ViewOrders' | translate}}</ion-card-title>
      </ion-card-header>
      <ion-card-content>
          <ion-item class="ion-no-padding order-item" *ngFor="let item of orderHistory">
              <ion-icon slot="start" color="primary" *ngIf="item.orderType == 'Gifting' " class="ca-gift">
              </ion-icon>
              <ion-icon slot="start" color="primary" *ngIf="item.orderType == 'Takeaway' " class="ca-takeaway">
              </ion-icon>
              <ion-icon slot="start" color="primary" *ngIf="item.orderType == 'Delivery' " class="ca-delivery">
              </ion-icon>
              <label class="ion-text-wrap">
                  <p class="order-item-text">
                      <ion-text>{{'userDetailsPage.order_ID' | translate}} {{item.orderId}}</ion-text>
                  </p>
                  <p class="order-item-text">{{item.orderedDate}}, {{item.orderedTime}}.</p>
                  <p class="order-item-text">{{item.propertyName}}</p>
                  <div class="order-item-text-space">
                      <p class="order-item-text">
                          <ion-text>{{showCurrency.currencySymbol}} {{item.amountPaid}} </ion-text>{{'userDetailsPage.billPaid' | translate}}
                      </p>
                      <p class="order-item-text">{{item.promoCode}}</p>
                  </div>
              </label>
          </ion-item>
      </ion-card-content>
  </ion-card>
</ion-item-group>

<ion-item-group *ngIf="!loaded">
  <ion-card class="default-card">
      <ion-card-content>
          <ion-item lines="none" class="ion-no-padding height-auto value-item">
              <label class="ion-text-wrap" style="width: 100%;">
                  <h3 class="user-name">
                      <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                  </h3>
              </label>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding height-auto value-item">
              <label class="ion-text-wrap" style="width: 100%;">
                  <p class="label-value">
                      <ion-skeleton-text [animated]="true" style="width: 40%; margin-bottom: 10px;">
                      </ion-skeleton-text>
                  </p>
                  <p class="label-value">
                      <ion-skeleton-text [animated]="true" style="width: 60%;"></ion-skeleton-text>
                  </p>
              </label>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding height-auto value-item">
              <label class="ion-text-wrap" style="width: 100%;">
                  <p class="label-value">
                      <ion-skeleton-text [animated]="true" style="width: 30%; margin-bottom: 10px;">
                      </ion-skeleton-text>
                  </p>
                  <p class="label-value">
                      <ion-skeleton-text [animated]="true" style="width: 25%;"></ion-skeleton-text>
                  </p>
              </label>
          </ion-item>
      </ion-card-content>
  </ion-card>

  <ion-card class="default-card order-details">
      <ion-card-header>
          <ion-card-title>
              <ion-skeleton-text [animated]="true" style="width: 40%;"></ion-skeleton-text>
          </ion-card-title>
      </ion-card-header>
      <ion-card-content>
          <ion-item class="ion-no-padding order-item">
              <ion-thumbnail slot="start">
                  <ion-skeleton-text [animated]="true" style="width: 40px; height: 40px;">
                  </ion-skeleton-text>
              </ion-thumbnail>
              <label class="ion-text-wrap" style="width: 100%;">
                  <p class="order-item-text">
                      <ion-skeleton-text [animated]="true" style="width: 60%;"></ion-skeleton-text>
                  </p>
                  <p class="order-item-text">
                      <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                  </p>
                  <p class="order-item-text">
                      <ion-skeleton-text [animated]="true" style="width: 70%;"></ion-skeleton-text>
                  </p>
                  <div class="order-item-text-space">
                      <p class="order-item-text">
                          <ion-skeleton-text [animated]="true" style="width: 45%;"></ion-skeleton-text>
                      </p>
                      <p class="order-item-text">
                          <ion-skeleton-text [animated]="true" style="width: 70%;"></ion-skeleton-text>
                      </p>
                  </div>
              </label>
          </ion-item>
          <ion-item class="ion-no-padding order-item">
              <ion-thumbnail slot="start">
                  <ion-skeleton-text [animated]="true" style="width: 40px; height: 40px;">
                  </ion-skeleton-text>
              </ion-thumbnail>
              <label class="ion-text-wrap" style="width: 100%;">
                  <p class="order-item-text">
                      <ion-skeleton-text [animated]="true" style="width: 60%;"></ion-skeleton-text>
                  </p>
                  <p class="order-item-text">
                      <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                  </p>
                  <p class="order-item-text">
                      <ion-skeleton-text [animated]="true" style="width: 70%;"></ion-skeleton-text>
                  </p>
                  <div class="order-item-text-space">
                      <p class="order-item-text">
                          <ion-skeleton-text [animated]="true" style="width: 45%;"></ion-skeleton-text>
                      </p>
                      <p class="order-item-text">
                          <ion-skeleton-text [animated]="true" style="width: 70%;"></ion-skeleton-text>
                      </p>
                  </div>
              </label>
          </ion-item>
          <ion-item class="ion-no-padding order-item">
              <ion-thumbnail slot="start">
                  <ion-skeleton-text [animated]="true" style="width: 40px; height: 40px;">
                  </ion-skeleton-text>
              </ion-thumbnail>
              <label class="ion-text-wrap" style="width: 100%;">
                  <p class="order-item-text">
                      <ion-skeleton-text [animated]="true" style="width: 60%;"></ion-skeleton-text>
                  </p>
                  <p class="order-item-text">
                      <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                  </p>
                  <p class="order-item-text">
                      <ion-skeleton-text [animated]="true" style="width: 70%;"></ion-skeleton-text>
                  </p>
                  <div class="order-item-text-space">
                      <p class="order-item-text">
                          <ion-skeleton-text [animated]="true" style="width: 45%;"></ion-skeleton-text>
                      </p>
                      <p class="order-item-text">
                          <ion-skeleton-text [animated]="true" style="width: 70%;"></ion-skeleton-text>
                      </p>
                  </div>
              </label>
          </ion-item>
          <ion-item class="ion-no-padding order-item">
              <ion-thumbnail slot="start">
                  <ion-skeleton-text [animated]="true" style="width: 40px; height: 40px;">
                  </ion-skeleton-text>
              </ion-thumbnail>
              <label class="ion-text-wrap" style="width: 100%;">
                  <p class="order-item-text">
                      <ion-skeleton-text [animated]="true" style="width: 60%;"></ion-skeleton-text>
                  </p>
                  <p class="order-item-text">
                      <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                  </p>
                  <p class="order-item-text">
                      <ion-skeleton-text [animated]="true" style="width: 70%;"></ion-skeleton-text>
                  </p>
                  <div class="order-item-text-space">
                      <p class="order-item-text">
                          <ion-skeleton-text [animated]="true" style="width: 45%;"></ion-skeleton-text>
                      </p>
                      <p class="order-item-text">
                          <ion-skeleton-text [animated]="true" style="width: 70%;"></ion-skeleton-text>
                      </p>
                  </div>
              </label>
          </ion-item>
      </ion-card-content>
  </ion-card>
</ion-item-group>