import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { MbowService } from 'src/app/services/mbow.service';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { CommonService } from 'src/app/services/common.service';
import { DashboardhomeService } from 'src/app/services/dashboardhome.service';

@Component({
  selector: 'app-menu-all',
  templateUrl: './menu-all.component.html',
  styleUrls: ['./menu-all.component.scss'],
})
export class MenuAllComponent implements OnInit {
  menuAllData: any;
  ionicStorageData: any;
  param : any = true
  outletCurrency ;
  showCurrency: any = {};

  constructor(
    private modalCtrl: ModalController,
    private mbow: MbowService,
    public app: AppStateService,
    public common: CommonService,
    public dashboardService: DashboardhomeService,

  ) {
    this.outletCurrency = this.dashboardService.outletCurrency
    this.ionicStorageData = this.app.getAppData();
    this.ionicStorageData.appInitData.currency.forEach(element => {
      if (this.outletCurrency == element.currencyName) {
          this.showCurrency = element;
      }
  });
    this.getData()
  }

  ngOnInit() {
  }


  getData() {
    this.common.presentLoader();
    let flag: boolean = true
    this.mbow.itemAPI(this.ionicStorageData.userDetails.outletId, flag).subscribe(res => {
      if (res) {
        this.common.dismissLoader();
        this.menuAllData = res
      }

    }
      , err => {
        this.common.dismissLoader();
        this.common.errorHandler(err);
      });
  }

  closeModal() {
    this.modalCtrl.dismiss({
    });
  }
}
