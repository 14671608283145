import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask'
})
export class MaskPipe implements PipeTransform {

  transform(value: any, type: 'email' | 'mobile'): any {
    switch (type) {
      case 'email':
        var firstChar = value.substring(0, 2);
        var maskedString = '*******';
        var lastString = value.split('@')[1];
        //console.log('LastPartOfEmail ==>', lastString);
        var email = firstChar + maskedString + '@' + lastString;
        //console.log('email ==>', email);
        value = email;
        break;

      case 'mobile':
        var maskedString = '*******';
        var beginStr = value.substring(0, 2)
        var phnNum = maskedString + value.substring(value.length - 3, value.length);
        //console.log('PhoneNum ==>', phnNum);
        value = phnNum;
        break;

      default:
        break;
    }
    return value;
  }

}
