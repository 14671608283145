<ion-item-group class="fixed-width">
  <h1 class="page-title for-web">Walk in</h1>
  <div [formGroup]="walkinForm">

    <!-- Room number Card Start-->
    <ion-card class="default-card">
      <ion-item mode="ios" lines="none">
        <ion-input type="text" mode="ios" (ionChange)="walkinRoomFun()" maxlength="5" formControlName="walkinRoom"
          [placeholder]="'Enter Room number'"></ion-input>
        <!-- <ion-label position="stacked" color="danger" class="validation-text">{{'addClubPage.validation_email_pattern'
                | translate}}</ion-label> -->
      </ion-item>
    </ion-card>
    <!-- Room number Card End-->

    <!-- Number Card -->
    <ion-card class="default-card">
      <ion-card-content>
        <!-- After Verified -->
        <!-- <ion-item lines="none" class="height-auto ion-no-padding">
        <ion-label style="min-width: 75%;" class="ion-no-margin ion-text-wrap">
          <p class="input-label">Mobile Number</p>
          <p class="input-value">*******483</p>
        </ion-label>
        <ion-label class="ion-no-margin text-right verified-text">
          <ion-text color="primary">{{'activatePage.verified' | translate}}</ion-text>
          <ion-icon class="ca-verified "></ion-icon>
        </ion-label>
      </ion-item> -->

        <!-- Before Verified -->
        <ion-item mode="ios" lines="none" class="no-padding">
          <ion-label position="stacked" class="ion-no-margin">
            <p class="input-label">{{'loginPage.enter_with_mob' | translate}}</p>
          </ion-label>
          <div class="phoneWrap">
            <p class="countryValue">{{'+'+SelectCode}} <ion-icon class="ca-down-arrow-yellow-icon"></ion-icon>
            </p>
            <ion-select mode="md" [interfaceOptions]="countryOptions" class="countryCode"
              formControlName="countryCodeSelected" (ionChange)="getcountryCode()"
              okText="{{'selectOutletPage.ok'|translate}}" cancelText="{{'selectOutletPage.cancel'|translate}}">
              <ion-select-option *ngFor="let country of countries" [value]="country">
                {{ '+'+country.dialCode }}
                {{ country.name }}
              </ion-select-option>
            </ion-select>
            <div style="flex-grow: 1;">
              <ion-input mode="ios" clearOnEdit="false" name="userMobileNum"
                [placeholder]="'activatePage.mobile_number' | translate" (ionInput)="onlyNumberValidate($event)"
                formControlName="mobile" class="phone" maxlength="16">
              </ion-input>
              <ion-text class="error-text"
                *ngIf="(walkinForm?.errors && walkinForm?.errors.isInvalidNumber) && walkinForm.controls.mobile.touched"
                color="danger">
                *{{'addClubPage.validation_mob_pattern'|translate}}
              </ion-text>
            </div>
          </div>
        </ion-item>
      </ion-card-content>
    </ion-card>

    <!-- Name Card -->
    <div>
      <ion-item-group class="nameGroup">
        <ion-item mode="ios" lines="none" class="default-card salutation">
          <ion-icon class="ca-down-arrow-yellow-icon"></ion-icon>
          <ion-select mode="md" class="genderCode" [interfaceOptions]="salutationOptions" formControlName="genderCode"
            [disabled]='enableUserDetailsValue' okText="{{'selectOutletPage.ok'|translate}}"
            cancelText="{{'selectOutletPage.cancel'|translate}}">
            <ion-select-option [value]="items.id" *ngFor="let items of salutationarray">{{items.Value}}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item mode="ios" lines="none" class="default-card name">
          <ion-input [disabled]='enableUserDetailsValue' type="text" formControlName="firstName"
            placeholder="First Name *"></ion-input>
        </ion-item>
        <ion-item mode="ios" lines="none" class="default-card name">
          <ion-input [disabled]='enableUserDetailsValue' type="text" formControlName="lastName"
            placeholder="Last Name *">
          </ion-input>
        </ion-item>
      </ion-item-group>
    </div>

    <!-- Email Card -->
    <ion-card class="default-card">
      <!-- After Verified -->
      <!-- <ion-card-content>
      <ion-item lines="none" class="height-auto ion-no-padding">
        <ion-label style="min-width: 75%;" class="ion-no-margin ion-text-wrap">
          <p class="input-label">Email</p>
          <p class="input-value">vi*******@domain.com</p>
        </ion-label>
        <ion-label class="ion-no-margin text-right verified-text">
          <ion-text color="primary">{{'activatePage.verified' | translate}}</ion-text>
          <ion-icon class="ca-verified "></ion-icon>
        </ion-label>
      </ion-item>
    </ion-card-content> -->

      <!-- Before Verified -->
      <ion-item mode="ios" lines="none">
        <ion-input type="email" [disabled]='enableUserDetailsValue' (ionChange)="onlyemailValidate()"
          formControlName="email" placeholder="Email">
        </ion-input>
      </ion-item>
    </ion-card>

    <!-- <ion-button mode="ios" class="btn-default primary-gradient" expand="block">
    Submit
  </ion-button> -->

    <ion-item mode="ios" lines="none" class="default-card">
      <ion-input type="text" [disabled]='true' formControlName="selectedOutlet"
        [placeholder]="'benefitsModal.SelectoutletName'">
      </ion-input>
    </ion-item>

    <!-- <ion-item mode="ios" lines="none" class="default-card">
      
      <ion-select [disabled]='true' [placeholder]="'benefitsModal.SelectoutletName' | translate"
        formControlName="selectedOutlet" mode="md" class="restaurantSelect" okText="{{'selectOutletPage.ok'|translate}}"
        cancelText="{{'selectOutletPage.cancel'|translate}}">
        <ng-container *ngFor="let outlet of ionicStorage?.userDetails?.allOutlet">
          <ion-select-option *ngIf="outlet.orderIn ==false " [value]="outlet?.outletId">
            {{outlet?.outletName}}
          </ion-select-option>
        </ng-container>
      
      </ion-select>
    
    </ion-item> -->

    <div class="promoSection" [hidden]="!walkinForm.value.email || !walkinForm.value.mobile">
      <div class="promoWrap">
        <ion-item lines="none" class="default-card item-input">
          <ion-input type="text" (ionChange)="ChangePromo()" formControlName="promocode" placeholder="Enter Promo Code"
            type="text"></ion-input>
          <ion-icon class="ca-verified" *ngIf="walkinForm.value.promocodeDetails"></ion-icon>
        </ion-item>
        <ion-button fill="outline" *ngIf="!walkinForm.value.promocodeDetails" (click)="applyPromocode()"
          [disabled]="walkinForm?.controls['promocode'].invalid" class="applyBtn"> Apply </ion-button>
        <ion-button fill="outline" *ngIf="walkinForm.value.promocodeDetails"
          [disabled]="walkinForm.value.promocodeDetails" class="applyBtn"> Applied </ion-button>
      </div>

      <ion-text class="error-text mb-1" *ngIf="walkinForm?.controls['promocode'].invalid" color="danger">
        {{'openReservation.enterValidPromocode' | translate}}</ion-text>
      <p class="error-text" *ngIf="walkinForm.value.promocode && walkinForm.value.promocodeDetails">
        {{'openReservation.promocodeValidated' | translate}}</p>
    </div>

    <!-- Available Benefits -->
    <div *ngIf="!enableBenefitName">

      <ion-item-group class="benefitsCards" *ngIf="cardBenefits?.length!= '' || certificateBenefits.length!='' "
        id="memberBenefits">
        <!-- <h2>{{'openReservation.availableBenefits' | translate}}</h2> -->

        <ion-item lines="none" class="default-card" (click)="expandBenefits()">
          <ion-label>{{'openReservation.availableBenefits' | translate}}</ion-label>
          <ion-icon *ngIf="!benefitExpanded" class="ca-down-arrow-yellow-icon"></ion-icon>
          <ion-icon *ngIf="benefitExpanded" class="ca-up-arrow-yellow-icon"></ion-icon>
        </ion-item>
        <div id="avilableBenefitCard" *ngIf="benefitExpanded">

          <ion-segment mode="md" class="androidSegment" formControlName="benefit">
            <ion-segment-button mode="md" value="Certificate">
              <ion-label>{{'openReservation.certificateBenefits' | translate}}</ion-label>
            </ion-segment-button>
            <ion-segment-button mode="md" value="Card">
              <ion-label>{{'openReservation.cardBenefits' | translate}}</ion-label>
            </ion-segment-button>
          </ion-segment>

          <div class="ion-padding-top" [ngSwitch]="walkinForm?.value?.benefit">

            <div *ngSwitchCase="'Certificate'">
              <ion-grid class="ion-no-padding">
                <ion-row>
                  <ion-col size="6" *ngFor="let certificate of otherCertificateArr">
                    <ion-card class="certificate-card no-bg" (click)="selectBenefit(certificate,'certificate')">
                      <span class="count">{{certificate.certNum}}</span>
                      <img [src]="certificate?.certificateImage" />
                    </ion-card>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>

            <div *ngSwitchCase="'Card'">
              <ion-grid class="ion-no-padding">
                <ion-row>
                  <ion-col size="6" *ngFor="let cardBenefit of cardBenefits">
                    <ion-card class="certificate-card no-bg" (click)="selectBenefit(cardBenefit,'cardBenefit')">
                      <img [src]="cardBenefit?.aemData?.cardBenefitImage" />
                    </ion-card>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </div>
        </div>

      </ion-item-group>
    </div>
    <ion-item mode="ios" color="primary" class="default-card" lines="none" *ngIf="enableBenefitName">
      <ion-label>{{showBenefitName}}</ion-label>
      <ion-button (click)="removeBenifit()" fill="clear" slot="end" class="removeBtn">
        <ion-icon class="ca-close-icon"></ion-icon>
      </ion-button>
    </ion-item>

    <!-- Guest Card -->
    <ion-card class="default-card guest-card">
      <ion-card-content>
        <ion-label position="stacked" class="ion-no-margin">
          <p class="input-label">{{'openReservation.selectGuest' | translate}}</p>
        </ion-label>
        <ion-item lines="none" class="no-padding">
          <ion-label class="ion-text-wrap guest_count">
            <!-- {{numberOfGuest + '\t' + ('openReservation.guest' | translate)}} -->
            {{guestCount}}
            <span *ngIf="guestCount>1">{{'reservationDetailsModal.guests'| translate}}</span>
            <span *ngIf="guestCount <= 1">{{'reservationDetailsModal.guest'| translate}}</span>
          </ion-label>
          <ion-buttons slot="end" class="addRemoveBtn">
            <ion-button color="primary" fill="clear" (click)="onDecrement('restaurant','guest')">
              <ion-icon mode="ios" class="ca-minus-icon"></ion-icon>
            </ion-button>
            <ion-button color="primary" fill="clear" (click)="onIncrement('restaurant','guest')">
              <ion-icon mode="ios" class="ca-add-icon"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-item>
        <ion-item lines="none" class="no-padding">
          <ion-label position="stacked" class="ion-text-wrap">{{'openReservation.tellUsMore' |translate}}
          </ion-label>

          <ion-buttons class="celebrationBtns">
            <ion-button color="primary" (click)="selectCelebration(celebrations.appBirthday)"
              [fill]="celebration == celebrations.appBirthday ? 'solid' : 'outline'">
              {{'openReservation.birthday' | translate}}
            </ion-button>
            <ion-button color="primary" (click)="selectCelebration(celebrations.appAnniversary)"
              [fill]="celebration == celebrations.appAnniversary ? 'solid' : 'outline'">
              {{'openReservation.anniversary' | translate}}
            </ion-button>
            <ion-button color="primary" (click)="selectCelebration(celebrations.appOther)"
              [fill]="celebration == celebrations.appOther ? 'solid' : 'outline'">
              {{'openReservation.other' | translate}}
            </ion-button>
          </ion-buttons>
        </ion-item>
        <ion-grid>
          <ion-row>
            <ion-col size="6" class="space-right">
              <ion-item lines="full" class="no-padding height-auto borderColer">
                <!-- <ion-input mode="ios" formControlName="tableNumber" type="text" autocapitalize [disabled]="isDisabled ? '' : null"
                  placeholder="Table Number (Optional)"  (ionInput)="numberOnly($event)" maxlength="3">
                </ion-input> -->
                <div class="position-relative w-100" style="margin-top: 10px;">
                  <ion-select class="tableNumberSelect"  [disabled]="isDisabled "
                    [placeholder]="tableNo?tableNo:'Table Number (Optional)' " interface="popover"
                    [interfaceOptions]="tableNo" formControlName="tableNumber">
                    <ion-select-option *ngFor="let tableVaccant of allVaccanTable" [value]="tableVaccant">{{tableVaccant.tableNum}}</ion-select-option>
                    <!-- <ion-select-option *ngFor="let type of apiData?.tabletypes" [value]="type">{{type}}</ion-select-option> -->
                  </ion-select>
                  <ion-icon slot="end" [hidden]="isDisabled" style="right:0"
                    class="select-arrow ca-down-arrow-yellow-icon"></ion-icon>
                </div>
              </ion-item>
            </ion-col>
            <ion-col size="6" class="space-left">
              <ion-item lines="full" class="no-padding height-auto borderColer">
                <ion-input mode="ios" formControlName="chequeNumber" type="text" autocapitalize
                  placeholder="Cheque Number (Optional)" maxlength="10">
                </ion-input>
              </ion-item>
            </ion-col>
            <ion-col size="12">
              <ion-item lines="full" class="no-padding height-auto borderColer">
                <ion-input mode="ios" formControlName="hotelComment" type="text" autocapitalize
                  placeholder="Hotel comment (Optional)">
                </ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
    <!-- <ion-button mode="ios" (click)="doWalkin()" [disabled]="walkinForm.invaid" class="btn-default primary-gradient"> -->

    <ion-button mode="ios" (click)="doWalkin()"
      [disabled]="!enableButton ||  walkinForm.get('firstName').invalid || walkinForm.get('lastName').invalid " 
      class="btn-default primary-gradient" expand="block">
      Confirm Reservation 
    </ion-button>
  </div>
</ion-item-group>

<!-- Modal -->
<div class="customModal" id="confirmwalkin">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <!-- <ion-buttons>
        <ion-icon slot="end" class="ca-modal-close"></ion-icon>
      </ion-buttons> -->
      <div class="customModal-body ion-text-center">
        <div class="reservation-confirm-customModal-text">
          <h2 style="margin-bottom: 20px;font-weight: bolder;font-size: 14px;"
            [innerHTML]="reservationConfirmationPara"></h2>
          <p>{{walkinapiRes?.statusMessage}}</p>
        </div>
      </div>
      <div class="customModal-footer">
        <ion-button mode="ios" class="customModalButton primary-gradient" (click)="proceed()" expand="full">
          Got it
        </ion-button>
      </div>
    </div>
  </div>
</div>

<div class="customModal" id="confirmReservationWalk">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <!-- <ion-buttons>
        <ion-icon slot="end" class="ca-modal-close"></ion-icon>
      </ion-buttons> -->
      <div class="customModal-body ion-text-center">
        <div class="reservation-confirm-customModal-text">
          <h2 style="margin-bottom: 20px;font-weight: bolder;font-size: 14px;"
            [innerHTML]="reservationConfirmationPara"></h2>
          <p>{{confirmationNumberPara}}</p>
        </div>
      </div>
      <div class="customModal-footer">
        <ion-button mode="ios" class="customModalButton primary-gradient" (click)="proceed()" expand="full">
          {{'openReservation.got_it' | translate}}
        </ion-button>
      </div>
    </div>
  </div>
</div>