import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from 'src/app/services/event/event.service';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { MbowService } from 'src/app/services/mbow.service';
import { CommonService } from 'src/app/services/common.service';
import { JAVASCRIPT } from 'src/app/app.constants';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent implements OnInit {
  @Input() sendTitleObj: any;

  titleShow: any;
  sendTitle: any;
  ionicStorageData: any;
  skeleton: boolean = false;
  orderListToday: any;
  showDateToday: any;
  orderListTomarrow: any;
  showDateTommorow: any;
  currentDate: any
  tobeBookedArray: any;
  tomarrowDate: string;
  bucketNumber: number = 0
  bucketsize: any;
  userDetailsView: boolean = false
  sendUserOrder: any;
  orderDetailsView: boolean = false
  sendOrder: { orderid: any; screenName: any;sendTitle:any };

  constructor(
    public plt: Platform,
    public events: EventService,
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    private router: Router,
    public menu: MenuController,
    private translate: TranslateService,
    private appState: AppStateService,
    private MbowService: MbowService,
    public common: CommonService,

  ) {
   
    // let state = this.router.getCurrentNavigation().extras.state;
    // if (state && state.membershipDetails) {
    //   this.titleShow = JSON.parse(state.membershipDetails);
    //   console.log('titleShow details ====>>>', this.titleShow);
    // }
    this.ionicStorageData = this.appState.getAppData();
    this.bucketsize = this.ionicStorageData.appInitData.bucketSize
    console.log(' this.bucketsize ', this.bucketsize);
    this.dateFormat()
  }

  dateFormat() {
    let dateSend
    dateSend = new Date().toISOString().split(JAVASCRIPT.T)[0];
    this.currentDate = dateSend
    console.log(' this.dateCheck ', this.currentDate);
    this.tomarrowDate = new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1)) + '-' + (new Date().getDate() + 1)
    console.log(" this.tomarrowDate", this.tomarrowDate)

  }

  ngOnInit() {
  
    console.log("ngOnInit",this.sendTitleObj )
    this.titleShow  =  this.sendTitleObj
  
 
  }

  ngAfterContentInit() {
    console.log("ngAfterContentInit" )
    this.menu.enable(false)
    this.plt.ready().then((readySource) => {

    });
    this.orderAPI()
  }

  orderDetails(item) {
    this.orderDetailsView = true
    console.log("item from orderdetals", item.orderId);
    if (this.titleShow == 'Orders to be Booked') {
      this.sendTitle = 'Order ID:'
    } else {
      this.sendTitle = 'Check Number:'
    }
    this.sendOrder = {
      'orderid': item.orderId,
      'screenName': this.titleShow,
      'sendTitle':this.sendTitle
    }
    // let navigationExtras: NavigationExtras = {
    //   state: {
    //     membershipDetails: JSON.stringify(this.sendTitle),
    //     'orderid': item.orderId,
    //     'screenName': this.titleShow
    //   }
    // }
    // this.router.navigate(['order-details'], navigationExtras);
    // this.router.navigateByUrl('order-details')
  }



  navigationBack() {
    this.navCtrl.setDirection('back');
    this.router.navigateByUrl('web-dashboard')
  }

  orderAPI(event?: any) {
    let typeSend
    switch (this.titleShow) {
      case 'Orders to be Booked': {
        typeSend = 'tobebooked'
      } break;

      case 'All Orders': {
        typeSend = 'allOrders'
      }
        break;

      case 'Delivery Order': {
        typeSend = 'Delivery'
      }
        break;

      case 'Takeaway Order': {
        typeSend = 'Takeaway'
      }
        break;

    }
    if(this.ionicStorageData.userDetails) {
      this.MbowService.orderDetailsApi(this.ionicStorageData.userDetails.outletId,typeSend).subscribe(res => {
        console.log("typeoffff ==>>>", typeof (res))
        if (res) {
          if (typeSend == 'tobebooked') {
            this.tobeBookedArray = []
            this.skeleton = true
            if (event) {
              event.target.complete();
            }
            for (const property in res) {
              console.log(`${property}:=> ${res[property]}`);
              if (this.currentDate == property) {
                console.log("currentDate ==>> ", property)
                this.tobeBookedArray.push({ "date":property, "data": res[property] })
              } else
                if (this.tomarrowDate == property) {
                  this.tobeBookedArray.push({ "date": property, "data": res[property] })
                } else {
                  this.tobeBookedArray.push({ "date": property, "data": res[property] })
                }
            }
            console.log("tobeBookedArray ==>> ", JSON.stringify(this.tobeBookedArray))

          } else {
            this.skeleton = true
            if (event) {
              event.target.complete();
            }
            this.orderListToday = res.todayOrders;
            console.log('orderListToday details ====>>>', this.orderListToday);
            this.orderListTomarrow = res.tommorowOrders;
            this.showDateToday = res.today;
            this.showDateTommorow = res.tommorow;
          }

        } else {
          this.skeleton = true
        }

      }, err => {
        console.log("errrorr order API ==>>", err)
        this.skeleton = true
        this.common.errorHandler(err);
      })
    }
  
  }

  userDetails(memberID) {
    this.userDetailsView = true
    this.sendUserOrder = memberID
  }

  doInfinite(event: any) {
    this.bucketNumber += 1;
    if (this.titleShow == 'Orders to be Booked') {
      // this.orderAPI(event)
    } else {
      // this.orderAPI(event)
    }
  }

  bacToList(details:any) {
    console.log("details, ", details)
    if(details =='userDetails') {
      this.userDetailsView = false
    } else {
      this.orderDetailsView = false
    }
  
  }

}
