import { LocalizationService } from '../localize/localization.service';
import { LocationService } from '../location/location.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { InitService } from '../../init/init.service';
import { LoadingController, Platform } from '@ionic/angular';
import { HTTPErrorHandler } from './http-error-handler';
import { AppStateService } from '../../init/app-state.service';
import { LocationCheckService } from '../../locationCheck.service';
import { OpenReservationService } from '../../open-reservation/open-reservation.service';
import { CommonService } from '../../common.service';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { EventService } from '../../event/event.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  checkUUID: number;

  constructor(private initService: InitService,
    private localizationService: LocalizationService,
    private locationService: LocationService,
    private errorHandler: HTTPErrorHandler,
    private appState: AppStateService,
    public platform: Platform,
    private locationChack: LocationCheckService,
    public common: CommonService,
    private cookieService: CookieService,

  ) {

  }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.common.uuid == undefined || this.common.uuid == null) {
      this.checkUUID = Math.floor(1000000000 * Math.random());
      if (!this.cookieService.get('uuid')) {
        console.log("this.common.uuid 111",  this.checkUUID)
        this.common.uuid = 'webUUID' + this.checkUUID;
        this.cookieService.set('uuid', this.common.uuid);
      }
    }

    if (request.url.indexOf("assets/i18n") == -1) {
      
      const headers = {
        'Content-Type': 'application/json', //Appears to be set by default
        'appid': this.initService.environment.headers.appid,
        'client_id': this.initService.environment.headers.client_id,
        'client_secret': this.initService.environment.headers.client_secret,
        'Country': this.locationService.getCurrentCountry() ? this.locationService.getCurrentCountry() : this.initService.environment.headers.country,
        'program_id': this.initService.environment.headers.program_id,
        'subProgramID': this.initService.environment.headers.subprogramid,
        'uuid': this.cookieService.get('uuid'),
        'Language': this.localizationService.getDeviceLanguage(),
        'timezone': String(new Date().getTimezoneOffset()),
        'source':'Web'
      };
      if (request.url.indexOf('itemImage') != -1) {
        headers['Content-Type'] = "multipart/form-data; boundary=+++++";
      }
     
      if (request.url.indexOf('activation') != -1) {
        headers['Ipaddress'] = localStorage.getItem('ip');
    
      }
      const appData = this.appState.getAppData();
      if (this.common.outletIDD) {
        headers['OutletID'] = this.common.outletIDD;
      } else {
        if (appData && appData.userDetails) {
          if (appData.userDetails.outletId) {
            headers['OutletID'] = appData.userDetails.outletId;
          }
        }
      }


      if (appData && appData.employeeidHeader) {
        if (appData.employeeidHeader) {
          headers['employeeId'] = appData.employeeidHeader;
        }

      }


      if (appData && appData.sessionToken) {
        if (appData.sessionToken) {
          headers['SessionIDToken'] = appData.sessionToken;
        }
      }

      if (appData && appData.csrftoken) {
        if (appData.csrftoken) {
          headers['csrftoken'] = appData.csrftoken;
        }
      }


      let reqHeaders = { 'Content-Type': 'application/json' };
      if (request.url.indexOf('stage.clubmarriott.in') === -1) {
        if (appData.userPageAccess != undefined && request.url.indexOf("logout") == -1) {
        
          this.locationChack.getUserLocation(appData.userPageAccess);
        }
       
        reqHeaders = headers;
   
      }
      // let imagereqHeaders = { 'Content-Type': 'multipart/form-data' };
      // if (request.url.indexOf('itemImage') === -1) {
      //     console.log("insdeeeeeeeee")
      //   imagereqHeaders = headers;
      // }

      const updatedRequest = request.clone({
        headers: new HttpHeaders(reqHeaders)

      });

      return next.handle(updatedRequest).do((event: HttpEvent<any>) => { }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.errorHandler.handleError(err);
        }
      });
    } else {
      return next.handle(request);
    }
  }
}

