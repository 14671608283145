import { Injectable } from '@angular/core';
import { EncryptionService } from './core/encryption/encryption.service';
import { AppStateService } from './init/app-state.service';
import { appApiResources } from 'src/app/app.constants';
import { from, Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { HttpClient, HttpClient as angularHttp, HttpHeaders, HttpParams } from "@angular/common/http";
import { checkAvailability } from '@ionic-native/core';
import { environment } from "../../environments/environment";
import { LocationService } from './core/location/location.service';
import { LocalizationService } from './core/localize/localization.service';
import { TranslateService } from '@ngx-translate/core';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { catchError, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class TokenCheck {

  protected nativeIsAvailable: boolean | null = null;
  private nativeHttp: HTTP;
  private angularHttp: HttpClient;
  uuid: any;
  deviceToken: string;


  constructor(

    public encryptService: EncryptionService,
    private angular: angularHttp,
    private native: HTTP,
    private appState: AppStateService,
    private cookieService: CookieService,
    private localizationService: LocalizationService,
    private translate: TranslateService, private locationService: LocationService,

    public platform: Platform,
  ) {
    this.nativeHttp = native;
    this.angularHttp = angular;
  }
  private httpHeaders = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',

    })
  };

  checkCSRFToken() {
    // //console.log('checkCSRFToken=======>>>>>>>>>>>')
    const appData = this.appState.getAppData();
    console.log("appData",appData)
    if( appData.devicCheckBase64) {
      this.deviceToken = appData.devicCheckBase64.saveDevicecheckBase64
    }

    let options = new HttpInputData();
    if (this.platform.is("cordova")) { 
      var datapass = {
        "device_token":this.deviceToken
      }
    } else {
      var datapass = {
        "device_token":"asbhdjkhdkjshjdkhskjdhskjd335"
      }
    }

    return new Promise((resolve, reject) => {
      let options = new HttpInputData();
      this.postHeader('program/anonymouskey',datapass, options, true).subscribe(
        (res) => {
          // //console.log("res check",res)
          this.encryptService.beforesecureKeyValue = res['body'].secureKey;
          this.encryptService.beforesecureIVKeyValue = res['body'].secureIV;
          this.appState.saveCsrfToken(res.headers.get("csrftoken"));
          resolve(res)
        },
        error => {

          reject(error)
        });
    })

  }

  /**
   * Make a get request.
   * @param url - URL for network operation.
   * @param addAuth - whether needs to add the session token.
   * @param options - The headers and params to set for this request
   */
  // Header  Get method 
  public headerget(url: string, options?: HttpInputData, isHeaderRequired?: boolean): Observable<any> {
    // //console.log("url==>>" + url)
    ////console.log("options==>>" + JSON.stringify(options))


    url = this.createURL(url, options);
    // //console.log("url createURL ==>>" + url)
    // //console.log("Data NATIVE ==>> options GET " + JSON.stringify(options))
    if (this.isNativeHttpAvailable()) {
      ////console.log("1st STEP GET METHOD")
      return from(this.nativeHttp.get(url, this.parseParamsForNativeHttp(options), this.parseHeadersForNativeHttp(url, options))).pipe(map((res: any) => {
        // //console.log("Data NATIVE ==>> all res GET " + JSON.stringify(res))


        return {
          json() {
            ////console.log("Data NATIVE ==>> GET " + JSON.stringify(res.data))
            return JSON.parse(res.data);
          },
          text(ignoredEncodingHint) {
            return res.data.toString();
          },
          body: this.parseBodyFromNativeHttpResponse(res, options),
          headers: new Headers(res.headers)
        }
      })
      )};
    if (isHeaderRequired) {
      return this.angularHttp.get(url, { headers: options.headers, params: options.params, observe: "response" });
    } else if (options) {
      return this.angularHttp.get(url, { headers: options.headers, params: options.params });
    } else {
      return this.angularHttp.get(url);
    }

  }




  private createURL(url: string, options?: HttpInputData) {
    url = appApiResources.baseUrl + url;
    if (options && options.uriParams) {
      url += "/";
      var index = 0;
      var length = Object.keys(options.uriParams).length
      Object.keys(options.uriParams).forEach(key => {
        url.replace("{" + key + "}", options.uriParams[key]);
        if (++index != length) {
          url += "/";
        }
      });
    }
    return url;
  }

  private parseParamsForNativeHttp(options: any) {
    //return options && options.params ? options.params : {};
    let params: HttpParams = options !== undefined && options.params !== undefined ? options.params : {};
    if (params instanceof HttpParams) {
      let newParams: any = {};
      params.keys().forEach(function (key) {
        newParams[key] = params.get(key).toString();
      });
      params = newParams;
    }
    return params;
  }

  private isNativeHttpAvailable() {
    if (this.nativeIsAvailable === null) {
      this.nativeIsAvailable = checkAvailability('cordova.plugin.http') === true || checkAvailability('cordovaHTTP') === true;
    }
    // return false;
    return this.nativeIsAvailable;
  }



  public postHeader(url: string, body: any, options?: HttpInputData, isHeaderRequired?: boolean): Observable<any> {

      url = appApiResources.baseUrl + url;
      console.log(" postHeader METHOD URL ", url)
      console.log("Data NATIVE ==>>  headero bodyPost " + JSON.stringify(body))
      if (this.isNativeHttpAvailable()) {
        this.nativeHttp.setDataSerializer('json');

        return from(this.nativeHttp.post(url, body, this.parseHeadersForNativeHttp(url, options))).pipe(map((res: any) => {
          //console.log("Data NATIVE ==>> header all res Post " + JSON.stringify(res))
          // //console.log("Data NATIVE ==>>header  Post " + JSON.stringify(res.data))
          // //console.log("Data NATIVE ==>>  headeroptions Post " + JSON.stringify(options))
          // //console.log("Data NATIVE ==>> postHeader headers headers Post " + JSON.stringify(res.headers))
          return {
            json() {

              return JSON.parse(res.data);
            },
            text(ignoredEncodingHint) {
              return res.data.toString();
            },
            body: this.parseBodyFromNativeHttpResponse(res, options),
            headers: new Headers(res.headers)
          }

        })
        )};

      if (isHeaderRequired) {
        return this.angularHttp.post(url, body, { headers: options.headers, params: options.params, observe: "response" });
      } else {
        return this.angularHttp.post(url, body, { headers: options.headers, params: options.params });
      }
  }



  private parseHeadersForNativeHttp(url: string, options: any) {
    ////console.log('parseHeadersForNativeHttp_URL ==>', url, options);
    let headers: Headers | {} | null = options !== undefined && options.headers !== undefined ? options.headers : {};
    headers = this.gethttpHeader(url);
    ////console.log("wraper HEader=>>" + JSON.stringify(headers))
    if (headers instanceof Headers) {
      let newHeaders: any = {};
      headers.forEach(function (value, name) {
        newHeaders[name.toString()] = value.toString();

      });
      headers = newHeaders;
    }
    //console.log("Native HEADER==>>" + JSON.stringify(headers))
    return headers;
  }


  private parseBodyFromNativeHttpResponse(res: any, options: any) {
    let response = null;
    if (res.data) {
      // if (options === undefined || options.responseType === undefined || options.responseType === 'json') {
      //   response = JSON.parse(res.data);
      // }
      response = JSON.parse(res.data);
    }
    return response;
  }


  private gethttpHeader(url: string) {
    ////console.log('getHttpHeaderOptions_URL ==>', url);
    let headers = {};

    if (url.indexOf('assets/i18n') === -1 && url.indexOf(appApiResources.aemUrl) === -1) {
      ////console.log("aemmmSetp1")
      headers = this.headerSendHttp();
      const appData = this.appState.getAppData();

      if (appData && appData.csrftoken) {
        headers['csrftoken'] = appData.csrftoken;
      }


      // if (url.indexOf('anonymouskey') !== -1) {
      //   headers["device_token"] = appData.devicCheckBase64.saveDevicecheckBase64;
      // }
      if (url.indexOf("anonymouskey") != -1 && this.platform.is('cordova')) {
        if (appData.devicCheckBase64) {
          headers['device_token'] = appData.devicCheckBase64.saveDevicecheckBase64;
        }
        if (this.platform.is('ios')) {
          headers['device_type'] = 'IOS';
        } else {
          headers['X-Requested-With'] = 'com.tlcgroup.hotel.cmhotel';
          headers['device_type'] = 'ANDROID';
        }
      }

      if (appData && appData.userDetails) {
        if (appData.userDetails.outletId) {
          headers['OutletID'] = appData.userDetails.outletId;
        }
      }

      if (appData && appData.userDetails) {
        if (appData.userDetails.employeeId) {
          headers['employeeId'] = appData.userDetails.employeeId;
        }
      }

      if (appData && appData.employeeidHeader) {
        if (appData.employeeidHeader) {
          headers['employeeId'] = appData.employeeidHeader;
        }

      }




      if (appData && appData.sessionToken) {
        if (appData.sessionToken) {
          headers['SessionIDToken'] = appData.sessionToken;
        }
      }


      if (this.platform.is('ios')) {
        headers['device_type'] = 'IOS';
      } else {
        headers['X-Requested-With'] = 'com.tlcgroup.hotel.cmhotel';
        headers['device_type'] = 'ANDROID';
      }

      //console.log('getHttpHeaderOptions ==>', headers);
      ////console.log("aemmmSetp2")
      return headers;
    }
  }

  headerSendHttp() {

    var headers = {
      'Content-Type': appApiResources.applicationORjson,
      'appid': environment.headers.appid,
      'client_id': environment.headers.client_id,
      'client_secret': environment.headers.client_secret,
      'Country': this.locationService.getCurrentCountry() ? this.locationService.getCurrentCountry() : environment.headers.country,
      'program_id': environment.headers.program_id,
      'subProgramID': environment.headers.subprogramid,
      'uuid':this.cookieService.get('uuid'),
      'Language': this.localizationService.getDeviceLanguage(),
      'timezone': String(new Date().getTimezoneOffset())
    }
    return headers
  }
}
export class HttpInputData {
  headers: HttpHeaders = null;
  uriParams: Object = null;
  params: HttpParams = null;
  authentication: boolean = false;
}





