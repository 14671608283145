
<div class="customModal" id="delete-alert">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <ion-buttons>
        <ion-button mode="md" class="closeModal" (click)="closeModal()">
          <ion-icon slot="icon-only" class="ca-close-icon"></ion-icon>
        </ion-button>
      </ion-buttons>
      <div class="customModal-body ion-text-center">
        <div class="customModal-text">
          <p>Are you sure you want to <br> delete the outlet section?</p>
        </div>
      </div>
      <div class="customModal-footer two-btn">
        <ion-button fill="outline" mode="md" (click)="closeModal()"> Cancel </ion-button>
        <ion-button fill="solid" mode="md" class="primary-gradient"  (click)="delete()"> Yes </ion-button>
      </div>
    </div>
  </div>
</div>
