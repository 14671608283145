<div class="modalBg">
  <ion-card class="modalWrap">
    <ion-card-header class="modalHeader" (click)="closeModal()">
      <!-- <h2 class="modalTitle">Update Email Id</h2>
      <h2 class="modalTitle">Update Mobile Number</h2> -->
      <ion-icon class="modalClose ca-close-icon" (click)="closeModal()"></ion-icon>
    </ion-card-header>
    <form>
      <ion-card-content class="modalContent">

        <div class="formField">
          <ion-item lines="full">
            <ion-label class="input-label ion-text-wrap" position="stacked">
              {{'reservationDetailsModal.editDate'| translate}}</ion-label>
            <ion-item class="value-item" lines="none">
              <ion-label class="input-label">
                <h2> {{showDate | date:'dd MMMM yyyy' }}</h2>
                <input class="datetimeValue" mbsc-datepicker #picker="mobiscroll" [controls]="['date']"
                  dateFormat="DDDD MMM D, YYYY" [touchUi]="true" [min]="minDateDisabled" [(ngModel)]="dateObj.startDate"  [ngModelOptions]="{standalone: true} " [showOnClick]="false" [showOnFocus]="false" disabled='true' />
              </ion-label>
              <!-- <ion-datetime mode="ios" presentation="date" [(ngModel)]="dateObj.startDate" id="reservationDetailsDateTime"
                  name="startDate" (ionChange)="dateFun()" [min]="minDateDisabled" [max]="maxDateEnable"></ion-datetime> -->
              <ion-icon (click)="openCalendar()" slot="end" color="primary" class="ca-calendar-yellow-icon"></ion-icon>
            </ion-item>
          </ion-item>
        </div>

        <div class="formField">
          <ion-item lines="full">
            <ion-label class="input-label ion-text-wrap" position="stacked">
              {{'reservationDetailsModal.editTime'| translate}}</ion-label>
            <ion-item class="value-item" lines="none">
              <div class="timeInputRow">
                <div class="timeInputWrap">
                  <!-- <ion-input type="text" value="05"></ion-input> -->
                  <ion-select mode="md" [interfaceOptions]="hoursSelect"
                    placeholder="{{'reservationDetailsModal.00'| translate}}" [(ngModel)]="dateObj.hoursSelect"
                    name="hoursSelect" (ionChange)="gethour($event)"  >
                    <ion-select-option *ngFor="let hr of hours" [value]="hr">{{hr}}</ion-select-option>
                  </ion-select>
                  <span>:</span>
                  <!-- <ion-input type="text" value="00"></ion-input> -->
                  <ion-select mode="md" placeholder="{{'reservationDetailsModal.00'| translate}}"
                    [interfaceOptions]="minuteSelect" [(ngModel)]="dateObj.minuteSelect" name="minuteSelect"
                    (ionChange)="getMin($event)"  >
                    <ion-select-option *ngFor="let min of minutes" [value]="min">{{min}}</ion-select-option>
                  </ion-select>
                </div>
                <div class="timeWrap">
                  <span [ngClass]="{'active':dateOptionChoosed == 'AM'}"
                    (click)="getDate('AM')">{{'reservationDetailsModal.AM'| translate}}</span>
                  <span [ngClass]="{'active':dateOptionChoosed == 'PM'}"
                    (click)="getDate('PM')">{{'reservationDetailsModal.PM'| translate}}</span>
                </div>
              </div>
            </ion-item>
          </ion-item>
        </div>

        <!-- Guest Div  For Dine-->
        <div class="formField"
          *ngIf="modifiyStoreData.userDetails.outletType=='Restaurant' || modifiyStoreData.userDetails.outletType=='Spa'  ">
          <ion-item lines="full">
            <ion-label class="input-label ion-text-wrap" position="stacked">
              {{'reservationDetailsModal.editGuests'| translate}}</ion-label>
            <ion-item class="value-item" lines="none">
              <ion-label class="input-label ion-text-wrap">
                <h2>{{guestCount}}
                  <span *ngIf="guestCount>1">{{'reservationDetailsModal.guests'| translate}}</span>
                  <span *ngIf="guestCount <= 1">{{'reservationDetailsModal.guest'| translate}}</span>
                </h2>
              </ion-label>
              <ion-buttons slot="end">
                <ion-button class="btnHeightAuto" (click)="onDecrement('restaurant','guest')">
                  <ion-icon class="ca-minus-icon"></ion-icon>
                </ion-button>
                <ion-button class="btnHeightAuto" (click)="onClick('restaurant','guest')">
                  <ion-icon class="ca-add-icon"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>
          </ion-item>
        </div>

        <!-- Guest Div for pool -->

        <!-- Spa Outlet -->
        <div class="selectTreatment" *ngIf="modifiyStoreData.userDetails.outletType=='Spa'">
          {{'reservationDetailsModal.selectTreatment'| translate}}</div>
        <div class="formField" *ngIf="modifiyStoreData.userDetails.outletType=='Spa'">
          <ion-item lines="none">
            <ion-segment mode="ios" class="iosSegment" (ionChange)="spaSlectTretment($event)"
              [(ngModel)]="dateObj.therapyValue" name="therapyValue" scrollable>
              <ion-segment-button mode="ios" value="Therapy">
                <ion-label>{{'reservationDetailsModal.therapy'|translate}}</ion-label>
              </ion-segment-button>
              <ion-segment-button mode="ios" value="Beauty">
                <ion-label>{{'reservationDetailsModal.beauty_treatment'|translate}}</ion-label>
              </ion-segment-button>
              <ion-segment-button mode="ios" value="Other">
                <ion-label>{{'reservationDetailsModal.other'|translate}}</ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-item>
        </div>

        <!-- Duration Card -->
        <div class="formField" *ngIf="modifiyStoreData.userDetails.outletType=='Spa'">
          <ion-item lines="full">
            <ion-label class="input-label ion-text-wrap" position="stacked">
              {{'reservationDetailsModal.selectDuration'| translate}}</ion-label>
            <ion-item class="value-item" lines="none">
              <div class="timeInputRow">
                <!-- <div class="timeInputWrap"> -->
                <ion-select mode="md" class="duration" placeholder="{{ dateObj.spaDurationValue == '0' ? dateObj.spaDurationValue:''}}"
                  [interfaceOptions]="minuteSelect" 
                  [(ngModel)]="dateObj.spaDurationValue" name="spaDurationValue"  >
                  <ion-select-option *ngFor="let duration of modifiyStoreData?.appInitData?.spaDurations" value="{{duration}}">{{duration}}</ion-select-option>
                  <!-- <ion-select-option value="{{'reservationDetailsModal.60'| translate}}">{{'reservationDetailsModal.60'|
                    translate}}</ion-select-option> -->
                </ion-select>
                <!-- </div> -->
              </div>
            </ion-item>
          </ion-item>
        </div>

        <!-- Adults Card -->
        <div class="formField" *ngIf="modifiyStoreData.userDetails.outletType=='Pool'">
          <ion-item lines="none">
            <ion-label class="input-label ion-text-wrap" position="stacked">
              {{'reservationDetailsModal.editadult'| translate}}</ion-label>
            <ion-item class="value-item" lines="none">
              <ion-label class="input-label ion-text-wrap">
                <h2>{{adultCount}}
                  <span *ngIf="adultCount>1">{{'reservationDetailsModal.adults'| translate}}</span>
                  <!-- <span *ngIf="guestCount < 1">{{'reservationDetailsModal.adult'| translate}}</span> -->
                  <span *ngIf="adultCount <= 1">{{'reservationDetailsModal.adult'| translate}}</span>
                </h2>
              </ion-label>
              <ion-buttons slot="end">
                <ion-button class="btnHeightAuto" (click)="onDecrement('pool','adult')">
                  <ion-icon class="ca-minus-icon"></ion-icon>
                </ion-button>

                <ion-button class="btnHeightAuto" (click)="onClick ('pool','adult')">
                  <ion-icon class="ca-add-icon"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>
          </ion-item>
        </div>

        <!-- Kids Card -->
        <!-- TO DO WHEN IT'S WANT -->
        <!-- <div class="formField" *ngIf="modifiyStoreData.userDetails.outletType=='Pool'">
          <ion-item lines="none">
            <ion-label class="input-label ion-text-wrap" position="stacked">
              {{'reservationDetailsModal.editKids'| translate}}</ion-label>
            <ion-item class="value-item" lines="none">
              <ion-label class="input-label ion-text-wrap">
                <h2>{{childCount}}
                  <span *ngIf="childCount>1">{{'reservationDetailsModal.kids'| translate}} </span>
                  <span *ngIf="childCount == '0' || childCount == '1' ">{{'reservationDetailsModal.kid'| translate}}
                  </span>
                </h2>
              </ion-label>
              <ion-buttons slot="end">
                <ion-button (click)="onDecrement('pool','child' )">
                  <ion-icon class="btnHeightAuto" class="ca-minus-icon"></ion-icon>
                </ion-button>
                <ion-button class="btnHeightAuto" (click)="onClick ('pool','child')">
                  <ion-icon class="ca-add-icon"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>
          </ion-item>
        </div> -->

        <!-- Additional Comments -->
        <ion-item mode="ios" lines="none">
          <!-- <ion-item mode="ios" class="comments-item" lines="none">
            <ion-label mode="ios" class="input-label ion-text-wrap" position="stacked">
              {{'reservationDetailsModal.additionalComments'| translate}}</ion-label>
            <ion-input autocapitalize mode="ios" class="comments" required type="text" [(ngModel)]="dateObj.comment"
              name="comment">
            </ion-input>
          </ion-item> -->

          <ion-label mode="ios" class="input-label ion-text-wrap" position="stacked">
            {{'reservationDetailsModal.additionalComments'| translate}}</ion-label>
            <ion-label mode="ios" color="medium" *ngIf="!dateObj.comment" position="stacked">{{'reason_exception_page.100_words'|translate}}</ion-label>
            <ion-label mode="ios" color="medium" *ngIf="dateObj.comment" position="stacked">{{100 - dateObj.comment.length}} &nbsp;{{'reason_exception_page.word'|translate}}</ion-label>
            <ion-textarea autocapitalize mode="ios" maxlength="100"  class="comments" type="url" [ngModelOptions]="{standalone: true}" (ionInput)="restrictText($event)" rows="3" [(ngModel)]="dateObj.comment"
              ></ion-textarea> 
       
        </ion-item>

      </ion-card-content>

      <!-- Update Button -->
      <ion-button mode="ios" expand="block" class="modalButton ion-no-margin primary-gradient"
        *ngIf="modifiyStoreData.userDetails.outletType!='Pool'" [disabled]="guestCount== 0"
        (click)="modifyNavigation()"> {{'reservationDetailsModal.modify'| translate}}
      </ion-button>

      <ion-button mode="ios" expand="block" class="modalButton ion-no-margin primary-gradient"
        *ngIf="modifiyStoreData.userDetails.outletType=='Pool'" [disabled]="adultCount== 0"
        (click)="modifyNavigation()"> {{'reservationDetailsModal.modify'| translate}}
      </ion-button>
    </form>
  </ion-card>
</div>