import { Injectable } from '@angular/core';
import { LOGIN_PAGE } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthServService {

  constructor() { }

  IsloggedIn() {
   
    if(localStorage.getItem(LOGIN_PAGE.loggedUser)) {
      console.log("inside logged")
      return localStorage.getItem(LOGIN_PAGE.loggedUser);
    } else {
      console.log(" not inside logged")
      return false
    }
   
  }


}
