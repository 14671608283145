<div class="modalBg">
  <ion-card class="modalWrap">
    <ion-card-header class="modalHeader">
      <h2 class="modalTitle">{{'handle_with_care.handle_with_care'|translate}}</h2>
      <ion-icon class="modalClose ca-close-icon" (click)="closeModal()"></ion-icon>
    </ion-card-header>
    <ion-card-content class="modalContent">
      <div class="formField">
        <ion-item mode="ios" lines="none">
          <ion-textarea mode="ios" rows="4" [(ngModel)]="instanceData" placeholder="{{'handle_with_care.instance_place'|translate}}" maxlength='100'>
          </ion-textarea>
        </ion-item>
        <!-- <ion-label position="stacked" class="no-margin validation-text">Enter OTP in the section below</ion-label> -->
      </div>
    </ion-card-content>
    <!-- Update Button -->
    <ion-button mode="ios" expand="block" class="modalButton ion-no-margin primary-gradient" (click)="onClick()" [disabled]="!instanceData">
      {{'handle_with_care.confirm'|translate}}
    </ion-button>
  </ion-card>
</div>