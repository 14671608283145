import { MbscModule } from '@mobiscroll/angular';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AES256 } from '@awesome-cordova-plugins/aes-256/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { UniqueDeviceID } from '@ionic-native/unique-device-id';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpInterceptorService } from './services/core/http/http-interceptor.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Globalization } from '@awesome-cordova-plugins/globalization/ngx';
import { ComponentsModule } from './components/components.module';
import { FeedbackStarComponent } from '../app/components/feedback-star/feedback-star.component';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
// import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { DatePipe } from '@angular/common';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';
import { InAppReview } from '@awesome-cordova-plugins/in-app-review/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Drivers, Storage } from '@ionic/storage';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { MainPipe } from 'src/app/pipes/main-pipe.module';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { Crop } from '@ionic-native/crop/ngx';
import { Media } from '@awesome-cordova-plugins/media/ngx';
import { publishLast } from 'rxjs-compat/operator/publishLast';
import { Push } from "@awesome-cordova-plugins/push/ngx"
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { AuthguardGuard } from './auth/authguard.guard';
import { DeactivateGuard } from './auth/deactivate-guard';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');

}

declare global {
  interface CordovaPlugins {
    AntiTampering: any;
  }
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [FeedbackStarComponent],
  imports: [
    MbscModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    ComponentsModule,
    IonicStorageModule.forRoot({
      name: 'TLC_Database',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]

    }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MainPipe,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [

    StatusBar,
    SplashScreen,
    Network,
    Globalization,
    Geolocation,
    NativeGeocoder,
    Diagnostic,
    DatePipe,
    Device,
    // AppVersion,
    HTTP,
    AES256,
    BarcodeScanner,
    AppVersion,
    Market,
    InAppReview,
    InAppBrowser,
    OpenNativeSettings,
    Camera,
    File,
    FileTransfer,
    Crop,
    Media,
    Push,
    Keyboard,
    CookieService,
    DeactivateGuard,
    AuthguardGuard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // UniqueDeviceID,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy }

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }