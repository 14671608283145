
<div class="customModal" id="QRCodeModal">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <!-- <ion-buttons>
        <ion-icon slot="end"></ion-icon>
      </ion-buttons> -->
      <div class="customModal-body ion-text-center">
        <div class="customModal-text">
          <p>{{'qrCodeModal.qrMsg'|translate}}</p>
        </div>
      </div>
      <div class="customModal-footer two-btn">
        <ion-button mode="ios" class="customModalButton light-gradient" (click)="closeModal()">
          {{'qrCodeModal.cancel'|translate}}
        </ion-button>
        <ion-button mode="ios" class="customModalButton primary-gradient" (click)="barCodeScanner()">
          {{'qrCodeModal.ok'|translate}}
        </ion-button>
      </div>
    </div>
  </div>
</div>