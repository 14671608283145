<form class="page-content fixed-width" [formGroup]="addClubForm">
  <ion-item-group class="pageWrap">
    <h1 class="for-web page-title">Club Marriott Prospect</h1>
    <ion-card class="default-card">
      <div class="field-group">
        <ion-item lines="none">
          <div class="genderWrap">
            <div class="salutationWrap">
              <p class="salutationValue">{{selectGender}} <ion-icon class="ca-down-arrow-yellow-icon"></ion-icon>
              </p>
              <ion-select mode="md" class="genderCode" formControlName="genderCode"
                (ionChange)="getgenderCode(genderCode)" [interfaceOptions]="salutationInterface" okText="{{'selectOutletPage.ok'|translate}}"
                cancelText="{{'selectOutletPage.cancel'|translate}}">
                <ion-select-option [value]="items" *ngFor="let items of salutationarray">{{items.Value}}
                </ion-select-option>
              </ion-select>
            </div>
            <div>
              <ion-input mode="ios" autocapitalize formControlName="firstName"
                [placeholder]=" 'addClubPage.first_name' | translate ">
              </ion-input>
              <span class="error_msg" *ngIf="firstName.invalid && (firstName.touched)">
                <ion-text *ngIf="firstName.errors.required" color="danger">
                  *{{'addClubPage.validation_first_name_requerd' | translate}}</ion-text>
                <ion-text *ngIf="firstName.errors.pattern" color="danger">
                  *{{'addClubPage.validation_first_name_pattern'| translate}}</ion-text>
              </span>
            </div>
            <div>
              <ion-input mode="ios" autocapitalize formControlName="lastName"
                [placeholder]=" 'addClubPage.last_name' | translate">
              </ion-input>
              <span class="error_msg" *ngIf="lastName.invalid && (lastName.touched)">
                <ion-text *ngIf="lastName.errors.required" color="danger">
                  *{{'addClubPage.validation_last_name_requerd' | translate}}</ion-text>
                <ion-text *ngIf="lastName.errors.pattern" color="danger">
                  *{{'addClubPage.validation_last_name_pattern'| translate}}</ion-text>
              </span>
            </div>
          </div>
        </ion-item>
      </div>

      <div class="field-group">
        <ion-item lines="none">
          <ion-label mode="ios" position="floating">{{'addClubPage.enter_mob_number'| translate}}</ion-label>
          <div class="phoneWrap">
            <p  class="countryValue"  >{{'+'+SelectCode}} <ion-icon class="ca-down-arrow-yellow-icon"></ion-icon>
            </p>
            <ion-select mode="md"  [interfaceOptions]="countryCodeInterface" class="countryCode" formControlName="countryCodeSelected"
              (ionChange)="getcountryCode()" okText="{{'selectOutletPage.ok'|translate}}"
              cancelText="{{'selectOutletPage.cancel'|translate}}">
              <ion-select-option *ngFor="let country of countries" [value]="country">
                {{ '+'+country.dialCode }}
                {{ country.name }}
              </ion-select-option>
            </ion-select>
            <div style="flex-grow: 1;">
              <ion-input mode="ios" type="tel" maxlength="15" (input)="onlyNumberValidate($event)"
                formControlName="mobile"></ion-input>
              <span class="error_msg"
                *ngIf="(addClubForm?.errors && addClubForm?.errors.isInvalidNumber) && addClubForm.controls.mobile.touched">
                <ion-text color="danger">
                  *{{'addClubPage.validation_mob_pattern'|translate}}
                </ion-text>
              </span>
            </div>
          </div>
        </ion-item>
      </div>

      <div class="field-group mb-0">
        <ion-item lines="none">
          <ion-label mode="ios" position="floating">{{'addClubPage.enter_email_address'| translate}}</ion-label>
          <ion-input mode="ios" type="email" formControlName="email"></ion-input>
        </ion-item>
        <ion-item lines="none" class="height-auto" *ngIf="email.invalid && (email.touched)">
          <ion-text *ngIf="email.errors.required" color="danger errors">
            *{{'addClubPage.validation_email_req'| translate}}
          </ion-text>
          <ion-text *ngIf="email.errors.pattern" color="danger errors">
            *{{'addClubPage.validation_email_pattern'| translate}}
          </ion-text>
        </ion-item>
      </div>

      <div class="field-group">
        <ion-item lines="none">
          <ion-label class="ion-text-wrap">Select Source</ion-label>
        </ion-item>
        <ion-radio-group class="radioStyle" mode="md" formControlName="sourceLead">
          <ion-item class="height-auto" lines="none"
            *ngFor="let source of ionicStorageEmployeeId?.appInitData?.addProspectLead ">
            <ion-radio [value]="source.value" mode="md" slot="start"></ion-radio>
            <ion-label class="ion-text-wrap">{{source.value}}</ion-label>
          </ion-item>
        </ion-radio-group>
      </div>

    </ion-card>
  </ion-item-group>
</form>

<ion-footer>
  <ion-button mode="ios" expand="block" class="btn-default primary-gradient"
    [disabled]="addClubForm.invalid || !ionicStorageEmployeeId.userPageAccess.userPageAccess.includes('POST_ADDPROSPECT')"
    (click)="submitClick()">
    {{'addClubPage.submit'|translate}}
  </ion-button>
</ion-footer>

<!-- Modal -->
<div class="customModal" id="AddModal">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <ion-buttons>
        <!-- <ion-icon slot="end" (click)="closeModal()" class="ca-modal-close"></ion-icon> -->
      </ion-buttons>
      <div class="customModal-body ion-text-center">
        <img class="imagePopup" src="assets/imgs/Icon.png" />
        <div class="customModal-text">
          <p>{{addClubForm.value.firstName}} {{addClubForm.value.lastName}} {{'addClubPage.has_been'| translate}} <br>
            {{'addClubPage.successfully_added_as_a_club'| translate}} <br>
            {{'addClubPage.prospect_and_will_be'| translate : brandName}}<br>
            {{'addClubPage.contacted_within_24_business'|translate}}<br>{{'addClubPage.hours!'|translate}}</p>
        </div>
      </div>
      <div class="customModal-footer">
        <ion-button mode="ios" class="customModalButton primary-gradient" expand="full" (click)="gotClick()">
          {{'addClubPage.got_it'| translate}}
        </ion-button>
      </div>
    </div>
  </div>
</div>