
import { Component, OnInit } from '@angular/core';
import { DashboardhomeService } from 'src/app/services/dashboardhome.service';
import { Platform } from '@ionic/angular';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Router } from '@angular/router';
import { COUNT, TITLE, REDEMPTION_LIST_PAGE, JAVASCRIPT, RESERVATION_DETAILS_MODAL, REGEX } from 'src/app/app.constants';
import { CommonService } from 'src/app/services/common.service';
import { EventService } from 'src/app/services/event/event.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
    selector: 'app-redemption-close',
    templateUrl: './redemption-close.component.html',
    styleUrls: ['./redemption-close.component.scss'],
})
export class RedemptionCloseComponent implements OnInit {
    endDate: any;
    startDate: any;
    reservationType: any;
    redeemList: any;
    reservationConfirmationPara : any;
    redeemListData: any = [];
    reservIdInModal: any;
    userDetailsModal: any;
    ionicStorageEmployeeId: any;
    checkValue: string;
    tableNo: string;
    checkNo: string;
    guestNameArry: any[];
    guestNameObj: string;
    indexValue: number = Number(COUNT.COUNT_1);
    tabledetails: string;
    sendreservationSfid: any;
    sendreservationMembership: any;
    inProgressresult: any[];
    inProgressArray: any;
    mebrshipNumber: any;
    skeleton: boolean = true;
    cancelCloseData: any;
    listMessage: boolean = false;
    enableModal: boolean = false;
    guestCount: number = 1;
    childCount: number = 0;
    adultCount: number = 1;
    outletCurrency: any;
    showCurrency: any = {};
    netAmount: string;
    amountEnable: boolean = false
    bucketNo: number = parseInt(COUNT.COUNT_1);
    checkPagination: boolean = false;
    showScrollMsg: boolean = false;
    hotelContent: boolean = true;
    lastresultID: number;
    selectedRedemptionIndex: any;
    reschequesettle: any;
    popoverOptions = {
        showBackdrop: false,
        dismissOnSelect: true,
        backdropDismiss: true,
        keepContentsMounted: true,
        side: "bottom",
        alignment: "start",
        mode: "md",
        size: "cover",
    };
    allVaccanTable: any[];

    constructor(
        public dashboardService: DashboardhomeService,
        public plt: Platform,
        public events: EventService,
        public app: AppStateService,
        public profileService: ProfileService,
        public router: Router,
        private translate: TranslateService,
        public common: CommonService,
    ) {
        this.outletCurrency =  this.dashboardService.outletCurrency ;
     }

    ngOnInit() {
        this.ionicStorageEmployeeId = this.app.getAppData();
        this.reservationType = this.dashboardService.reservationsTypeData;
        // this.endDate = (new Date().toISOString()).split(JAVASCRIPT.T)[COUNT.COUNT_0];
        // this.startDate = (new Date().toISOString()).split(JAVASCRIPT.T)[COUNT.COUNT_0];
        this.endDate =  moment().format('YYYY-MM-DD')
        this.startDate =  moment().format('YYYY-MM-DD')
    }

    ngAfterViewInit() {
        console.log("ngAfterViewInit", )
        this.plt.ready().then((readySource) => {
            this.translate.get(REDEMPTION_LIST_PAGE.REDEMPTION_LIST).subscribe((res: string) => {
                this.events.publish(TITLE.TITLE, res);
            });
        });
        this.redeptionListing();
        this.allTableList();
    }

    closeModal() {
        document.getElementById(REDEMPTION_LIST_PAGE.customModal).classList.remove(JAVASCRIPT.SHOW);
        this.checkNo = JAVASCRIPT.BLANK
        this.tableNo = JAVASCRIPT.BLANK
        this.netAmount = JAVASCRIPT.BLANK
        this.amountEnable = false

    }

    profile(memberShipNo) {
        this.profileService.membershipNumber = memberShipNo
        if (this.profileService.membershipNumber) {
            this.router.navigate(['guest-profile']);
        }
    }

    openModal(data, userRoleType, membershipNumber,i) {
            this.selectedRedemptionIndex = i 
        console.log("i value==>", i)
        this.ionicStorageEmployeeId.appInitData.currency.forEach(element => {
            if (this.outletCurrency == element.currencyName) {
                this.showCurrency = element;
            }

        });
        console.log("whatdatahere==>", data)
       
        this.guestCount = data.guestCount
        this.adultCount = data.adultCount
        this.childCount = data.kidsCount
        this.mebrshipNumber = membershipNumber
        this.tableNo = data.reservationTableNo
        this.checkNo = data.checkNo
        if (userRoleType != REDEMPTION_LIST_PAGE.Restaurant && userRoleType != REDEMPTION_LIST_PAGE.spa && userRoleType != REDEMPTION_LIST_PAGE.pool && userRoleType != 'Accommodation') {
            console.log("inside1")
            this.reservIdInModal = data.reservationID
            this.userDetailsModal = data
            document.getElementById(REDEMPTION_LIST_PAGE.customModal).classList.add(JAVASCRIPT.SHOW);
        }
        else {
            if (data.reservationTableNo) {
                console.log("inside2")

                this.userDetailsModal = data
                this.reservIdInModal = data.reservationID
                // document.getElementById(REDEMPTION_LIST_PAGE.closeChequeId).classList.add(JAVASCRIPT.SHOW);
                document.getElementById('alreadyTable').classList.add(JAVASCRIPT.SHOW);

            }
            else {
                console.log("inside3")

                this.userDetailsModal = data
                document.getElementById(REDEMPTION_LIST_PAGE.customModal).classList.add(JAVASCRIPT.SHOW);
                this.userDetailsModal = data
            }

        }
    }


    redeptionListing() {
        console.log("redeptionListing ===>");
        this.skeleton = true;
        this.redeemListData = []
        this.bucketNo = parseInt(COUNT.COUNT_1);
        this.dashboardService.redeptionListingApi(this.reservationType, this.startDate, this.endDate, this.bucketNo).subscribe(res => {
            if (res) {
                console.log("response from redemption listing", res);
                this.checkPagination = res.isNextDataSet;
                this.skeleton = false;
                this.redeemList = res.result;
                if (this.redeemList.length === 0) {
                    this.listMessage = true;
                } else {
                    this.listMessage = false;
                    this.redeemList.forEach(element => {
                        element.reservationDetails.forEach(ele => {

                            this.redeemListData.push(ele)
                        })
                    });

                }

            }
            else { }
        }, err => {
            this.common.dismissLoader();
            this.common.errorHandler(err);
        });
    }


    confirmModal(reservIdInModal) {
        this.reservIdInModal = reservIdInModal
        document.getElementById(REDEMPTION_LIST_PAGE.customModal).classList.remove(JAVASCRIPT.SHOW);
        this.alotReservationTableApi();
    }

    confirmModalAreadyAssign(reservIdInModal) {
        this.reservIdInModal = reservIdInModal

        this.closeChequeApi(this.reservIdInModal);

    }

    //..Api of the confirmation of the table in the AwaitArrival Screen..//
    alotReservationTableApi() {
        // if (this.tableNo < (COUNT.COUNT_1)) {
        //     var table: string
        //     table = this.tableNo.slice(length - Number(COUNT.COUNT_1));
        //     this.tabledetails = + COUNT.COUNT_0 + table
        // }
        // else {
        //     this.tableNo
        // }


        this.common.presentLoader();
        if(this.ionicStorageEmployeeId.userDetails.outletType!='Restaurant') { 
            this.dashboardService.allotReservartionApi(this.reservIdInModal, this.tabledetails || this.tableNo, this.ionicStorageEmployeeId.userDetails.employeeId, this.guestNameArry, this.ionicStorageEmployeeId.userDetails.outletType, this.checkNo).subscribe(res => {
                if (res) {
                    this.common.dismissLoader();
                    this.guestNameObj = JAVASCRIPT.BLANK
                    this.guestNameArry = [];
                    this.enableModal = true
                    this.closeChequeApi(this.reservIdInModal);
    
                } else {
                    console.log("hit ")
                    this.amountEnable = false;
                    this.tableNo = JAVASCRIPT.BLANK
                    this.tabledetails = JAVASCRIPT.BLANK
                    this.checkNo = JAVASCRIPT.BLANK
                    this.guestNameObj = JAVASCRIPT.BLANK
                    this.guestNameArry = []
                    this.checkValue = JAVASCRIPT.BLANK
                    this.common.dismissLoader();
                }
            }, err => {
                console.log("hit ")
                this.amountEnable = false;
                this.tableNo = JAVASCRIPT.BLANK
                this.tabledetails = JAVASCRIPT.BLANK
                this.checkNo = JAVASCRIPT.BLANK
                this.guestNameObj = JAVASCRIPT.BLANK
                this.netAmount = ''
                this.guestNameArry = []
                this.checkValue = JAVASCRIPT.BLANK
                this.common.dismissLoader();
                this.common.dismissLoader();
                this.common.errorHandler(err);
            })

        } else {
            this.dashboardService.assignTable(this.tableNo,this.userDetailsModal,this.ionicStorageEmployeeId.userDetails.sfid,this.checkNo,this.guestNameArry).subscribe(res => {
                if (res) {
                    this.common.dismissLoader();
                    this.guestNameObj = JAVASCRIPT.BLANK
                    this.guestNameArry = [];
                    this.enableModal = true
                    this.closeChequeApi(this.reservIdInModal);
    
                } else {
                    console.log("hit ")
                    this.amountEnable = false;
                    this.tableNo = JAVASCRIPT.BLANK
                    this.tabledetails = JAVASCRIPT.BLANK
                    this.checkNo = JAVASCRIPT.BLANK
                    this.guestNameObj = JAVASCRIPT.BLANK
                    this.guestNameArry = []
                    this.checkValue = JAVASCRIPT.BLANK
                    this.common.dismissLoader();
                }
            }, err => {
                console.log("hit ")
                this.amountEnable = false;
                this.tableNo = JAVASCRIPT.BLANK
                this.tabledetails = JAVASCRIPT.BLANK
                this.checkNo = JAVASCRIPT.BLANK
                this.guestNameObj = JAVASCRIPT.BLANK
                this.netAmount = ''
                this.guestNameArry = []
                this.checkValue = JAVASCRIPT.BLANK
                this.common.dismissLoader();
                this.common.dismissLoader();
                this.common.errorHandler(err);
            })
        }
       
    }

    openModalCheque(sfid, mebrshipNumber) {
        this.sendreservationSfid = sfid
        this.mebrshipNumber = mebrshipNumber
        this.closeChequeApi(this.sendreservationSfid)
    }


    onChange(event) : boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
        }
        console.log('valid',);
        this.amountEnable = true;
        return true;
    
      }

      check() {
        if(this.netAmount=='') {
            this.amountEnable = false;
        }
      }

    closeChequeApi(id) {
        this.common.presentLoader();
        this.dashboardService.closeChequeApiFuntion(this.ionicStorageEmployeeId.userDetails.employeeId, id, this.mebrshipNumber,
            this.guestCount, this.adultCount, this.childCount, this.ionicStorageEmployeeId, this.netAmount, this.showCurrency).subscribe(res => {
                this.common.dismissLoader();
                if (res) {
                    this.reschequesettle = res 
                    console.log("selectedRedemptionIndex",this.selectedRedemptionIndex)
                    console.log("redeemListData",this.redeemListData)
                    this.redeemListData.splice(this.selectedRedemptionIndex,1)
                    document.getElementById('alreadyTable').classList.remove(JAVASCRIPT.SHOW);
                    document.getElementById('confirSettleCheque').classList.add(JAVASCRIPT.SHOW);
                    // this.router.navigate(['web-dashboard']);
                }
                else {
                    this.netAmount = ''
                    this.amountEnable = false;
                    this.common.dismissLoader();
                }
            }, err => {
                this.netAmount = ''
                this.amountEnable = false;
                this.common.dismissLoader();
                this.common.errorHandler(err);
            })
    }

    cancelCloseChequeModalRedemption(cancelCloseData,i) {
        this.selectedRedemptionIndex = i
        console.log("cancelCloseData", cancelCloseData)
        this.cancelCloseData = cancelCloseData
        document.getElementById(REDEMPTION_LIST_PAGE.cancelCloseCheque).classList.add(JAVASCRIPT.SHOW);
    }

    removeCloseChequeModal() {
        document.getElementById(REDEMPTION_LIST_PAGE.cancelCloseCheque).classList.remove(JAVASCRIPT.SHOW);
    }

    cancelCloseChequqGotIt() {
        this.common.presentLoader();
        this.dashboardService.cancelCloseChequqApi(this.ionicStorageEmployeeId.userDetails.outletId, this.cancelCloseData.reservationID,
            this.cancelCloseData.benefitCode, this.cancelCloseData.memberDetails.membershipNumber).subscribe((res) => {
                this.common.dismissLoader();
                if (res) {
                    this.redeemListData.splice(this.selectedRedemptionIndex,1)
                    document.getElementById(REDEMPTION_LIST_PAGE.cancelCloseCheque).classList.remove(JAVASCRIPT.SHOW);
                    

                }
                else {
                    this.common.dismissLoader();
                }
            }, err => {
                this.common.dismissLoader();
                this.common.errorHandler(err);
            })
    }

    closeChequeModal() {
        document.getElementById(REDEMPTION_LIST_PAGE.closeChequeId).classList.remove(JAVASCRIPT.SHOW);

    }


    addCounter(outletType, member) {
        console.log("addCounter")

        if (outletType == RESERVATION_DETAILS_MODAL.RESTAURANT) {

            if (member == RESERVATION_DETAILS_MODAL.GUEST) {
                this.guestCount += parseInt(COUNT.COUNT_1);
            }
        }
        else if (outletType == RESERVATION_DETAILS_MODAL.POOL) {
            if (member == RESERVATION_DETAILS_MODAL.CHILD) {
                this.childCount += parseInt(COUNT.COUNT_1);
            }
            else if (member == RESERVATION_DETAILS_MODAL.ADULT) {
                this.adultCount += parseInt(COUNT.COUNT_1);
            }
        }
    }
    onDecrement(outletType, member) {
        console.log("onDecrementhiyyx1")

        if (outletType == RESERVATION_DETAILS_MODAL.RESTAURANT) {
            if (member == RESERVATION_DETAILS_MODAL.GUEST) {
                if (this.guestCount != parseInt(COUNT.COUNT_0)) {
                    this.guestCount -= parseInt(COUNT.COUNT_1);
                }
            }
        }
        else if (outletType == RESERVATION_DETAILS_MODAL.POOL) {
            if (member == RESERVATION_DETAILS_MODAL.CHILD) {
                if (this.childCount != parseInt(COUNT.COUNT_0)) {
                    this.childCount -= parseInt(COUNT.COUNT_1);
                }
            }
            else if (member == RESERVATION_DETAILS_MODAL.ADULT) {
                if (this.adultCount != parseInt(COUNT.COUNT_0)) {
                    this.adultCount -= parseInt(COUNT.COUNT_1);

                }
            }
        }
    }

    alreadyTablecloseModal() {
        this.netAmount = '';
        this.amountEnable = false;
        document.getElementById('alreadyTable').classList.remove(JAVASCRIPT.SHOW);

    }

    doInfiniteCheckPagination(infinteScroll) {
        console.log("doInfiniteCheckPagination", infinteScroll);
        this.showScrollMsg = false;
        this.lastresultID = ++this.bucketNo;
        console.log("this.lastresultID ", this.lastresultID , );
        this.dashboardService.redeptionListingApi(this.reservationType, this.startDate, this.endDate, this.lastresultID)
        .finally(() => {
            infinteScroll.target.complete();
          }).subscribe(res => {
            if (res) {
                console.log("response from redemption listing", res);
                this.checkPagination = res.isNextDataSet;
                this.skeleton = false;
                this.redeemList = res.result;
                if (this.redeemList.length === 0) {
                    this.listMessage = true;
                } else {
                    this.listMessage = false;
                    this.redeemList.forEach(element => {
                        element.reservationDetails.forEach(ele => {

                            this.redeemListData.push(ele)
                        })
                    });

                }

            }
            else {
                console.log("i am called for else part")
             }
        }, err => {
            infinteScroll.target.complete();
            this.common.dismissLoader();
            this.common.errorHandler(err);
        });
    }

    doInfinite(infinteScroll) {
        console.log("doInfinite");
        setTimeout(() => {
          if (this.checkPagination == true) {
              console.log("this.checkPagination", this.checkPagination);
            this.doInfiniteCheckPagination(infinteScroll)
          } else {
            console.log("this.checkPagination", this.checkPagination);
            infinteScroll.target.complete();
    
          }
        }, 1000);
      }

      settleChequePopup() {
        this.netAmount = ''
        this.amountEnable = false;
        this.selectedRedemptionIndex = ''
        document.getElementById('confirSettleCheque').classList.remove(JAVASCRIPT.SHOW);
      }

      allTableList() {
        this.dashboardService.tableAllotmentTypeApi(this.ionicStorageEmployeeId.userDetails.sfid, this.ionicStorageEmployeeId.userDetails.outletId, 'All').subscribe(res => {
          this.allVaccanTable = []
          if (res) {
            res.data.forEach(element => {
              if (element.available == true) {
                this.allVaccanTable.push(element)
    
              }
            });
           
          }
        })
    
      }

}
