
import { Component, OnInit } from '@angular/core';
import { NavController, MenuController, IonInfiniteScroll, Platform, ModalController } from '@ionic/angular';
import { FeedbackStarComponent } from '../../components/feedback-star/feedback-star.component';
import { SetDateComponent } from 'src/app/modals/set-date/set-date.component';
import { RespondmodelComponent } from 'src/app/modals/respondmodel/respondmodel/respondmodel.component';
import { Router } from '@angular/router';
import { DashboardhomeService } from 'src/app/services/dashboardhome.service';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { CommonService } from 'src/app/services/common.service';
import { ProfileService } from 'src/app/services/profile.service';
import { constDateFeedbackPage, JAVASCRIPT, TITLE, COUNT, FEEDBACK_HISTORY_PAGE, MODAL_INFO, COUNT_NUMBER, MONTHS, REVIEW_HISTORY_PAGE } from 'src/app/app.constants';
import { EventService } from 'src/app/services/event/event.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-feedbackandresponse',
  templateUrl: './feedbackandresponse.component.html',
  styleUrls: ['./feedbackandresponse.component.scss'],
})

export class FeedbackandresponseComponent implements OnInit {
  bucketNo: number = parseInt(COUNT.COUNT_1);
  hideContent = false;
  ionicStorage: any;
  outletResponse: any;
  filter: string;
  feedBackdata: any;
  sevenDaysAfter: string;
  todayDate: number;
  sevenDaysBefore: string;
  monthahead: number;
  oneMonthAdd: string;
  oneMonthSub: string;
  currentDate: string;
  lastresultID: number;
  feedBackDetails: any = [];
  Details: any;
  setModalData: any;
  extenDate: string | number | Date;
  setdate: boolean = true;
  setrate: boolean = true;
  infinteScroll: IonInfiniteScroll;
  starData: any = JAVASCRIPT.BLANK;
  skelton: boolean = true;
  newDate = new Date().toISOString().split(JAVASCRIPT.T)[COUNT.COUNT_0];
  disableSetDate: boolean = true;
  enableDateFormet: boolean = false;
  enableCalenderChange: boolean = false;
  substract: string;
  respondModalMessage: string;
  respondMemberId: any;
  IdFeedBack: any;
  showsetModalData: any;
  startDateShow: string;
  endDateShow: string;

  constructor(
    public plt: Platform,
    public events: EventService,
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    private router: Router,
    public app: AppStateService,
    public menu: MenuController,
    public dashboardService: DashboardhomeService,
    public common: CommonService,
    private translate: TranslateService,
    public profileService: ProfileService

  ) { }

  ngOnInit() {
    this.menu.enable(false)
    this.plt.ready().then((readySource) => {
      this.translate.get(FEEDBACK_HISTORY_PAGE.FEEDBACK_HISTORY).subscribe((res: string) => {
        this.events.publish(TITLE.TITLE, res);
      });
    });
    this.dateFormat();
    this.ionicStorage = this.app.getAppData();
    this.searchApi();
    if (this.ionicStorage.userPageAccess.userPageAccess.includes(FEEDBACK_HISTORY_PAGE.GET_FEEDBACK_COUNT)) {
      this.outletFeedbackCountApi();
    }
  }

  dateFormat() {
    this.todayDate = new Date().getTime();
    this.monthahead = Number(constDateFeedbackPage.oneMonthAhead);
    this.oneMonthSub = new Date(this.todayDate - this.monthahead).toISOString().split(JAVASCRIPT.T)[COUNT.COUNT_0];
    this.substract = new Date(this.todayDate - this.monthahead).toISOString().split(JAVASCRIPT.T)[COUNT.COUNT_0];
    this.currentDate = new Date().toISOString().split(JAVASCRIPT.T)[COUNT.COUNT_0];
  }

  //* navigate to the profile page */
  profile(membershipNumber) {
    this.profileService.membershipNumber = membershipNumber;
    this.router.navigate(['guest-profile']);
  }

  

  hideContentView() {
    this.hideContent = !this.hideContent;
  }

  // async openModal(type: string) {
  //   let modal;
  //   if (type === "addInstance") {
  //     modal = await this.modalCtrl.create({
  //       component: HandleWithCareComponent
  //     });
  //   }
  //   modal.present();
  //   const sendDataInstance = await modal.onWillDismiss();
  //   ////console.log("sendDataInstance==>" + JSON.stringify(sendDataInstance))

  //   if (sendDataInstance.data.sendDataInstance) {
  //     this.instanceData = sendDataInstance.data.sendDataInstance
  //     ////console.log("sendDataInstance==>" + JSON.stringify(this.instanceData))
  //     this.handleWithCare()
  //   }
  // }


  async openModal(type: string, feedbackId) {
    this.IdFeedBack = feedbackId;
    let modal;
    this.feedBackdata.forEach(element => {
      element[FEEDBACK_HISTORY_PAGE.feedbackDetails].forEach(obj => {
        this.respondMemberId = obj.memberDetails.membershipNumber;
      });
    })
    modal = await this.modalCtrl.create({
      component: RespondmodelComponent,
      mode : 'ios',
    });
    modal.present()
    const sendRespondDataInstance = await modal.onWillDismiss();
    this.respondModalMessage = (sendRespondDataInstance.data.messageModal)
    this.respondModalFeed();
  }

  async openModalForRating() {
    let modal;
    modal = await this.modalCtrl.create({
      component: FeedbackStarComponent, componentProps: {
        'setRating': this.setrate
      }
    });
    modal.present()
    const modifyStarModalData = await modal.onWillDismiss();
    this.starData = modifyStarModalData.data.setstarData.starRating;
    this.searchApi();

  }


  //* this is the respondModal */
  respondModalFeed() {
    this.dashboardService.feedBackRespondModal(this.respondModalMessage, this.IdFeedBack, this.respondMemberId).subscribe(res => {
      if (res) {
        this.searchApi();
        this.outletFeedbackCountApi();
      } ((err) => { })
    })
  }


  async dateClick() {
    let modal = await this.modalCtrl.create({
      component: SetDateComponent,
      componentProps: {
        "dataArr": {
          selectedCalender: this.setModalData,
          Type: FEEDBACK_HISTORY_PAGE.FEEDBACK_TYPE,
          calenderChange: FEEDBACK_HISTORY_PAGE.MONTHLY
        }
      }
    });
    modal.present();
    const modifyModalData = await modal.onWillDismiss();
    if (modifyModalData.data == undefined) {
      return;
    }
    this.setModalData = modifyModalData.data.setDateData;
    let setModalOption: string = MODAL_INFO.setModalData + this.setModalData.calenderChange
    this.translate.get(setModalOption).subscribe((res: string) => {
      this.showsetModalData = res;
    });
    switch (this.setModalData.calenderChange) {
      case FEEDBACK_HISTORY_PAGE.TODAY:
        this.oneMonthSub = this.setModalData.todayDate;
        this.currentDate = this.setModalData.todayDate;
        this.enableDateFormet = false;
        this.disableSetDate = false;
        this.enableCalenderChange = true;
        break;
      case FEEDBACK_HISTORY_PAGE.WEEKLY:
        this.oneMonthSub = this.setModalData.endWeekDate;
        this.currentDate = this.setModalData.todayDate;
        this.enableDateFormet = false;
        this.disableSetDate = false;
        this.enableCalenderChange = true;
        break;
      case FEEDBACK_HISTORY_PAGE.MONTHLY:
        this.oneMonthSub = this.substract
        this.currentDate = this.setModalData.todayDate;
        this.enableDateFormet = false;
        this.disableSetDate = false;
        this.enableCalenderChange = true;
        break;
      case FEEDBACK_HISTORY_PAGE.CUSTOM:
        this.checkLanguageForMonths(this.setModalData.startCustomDate, MONTHS.startDate)
        this.checkLanguageForMonths(this.setModalData.endCustomDate, MONTHS.endDate)
        this.enableCalenderChange = false;
        this.disableSetDate = false;
        this.enableDateFormet = true;
        break;
    }
    this.searchApi();
  }

  //* function to infinite scroll */

  doInfinite(event) {
    setTimeout(() => {
      event.target.complete();
      if (this.feedBackdata.isNextDataSet == true) {
        this.doInfiniteSearchApi();
        event.target.disabled = true;
      } else {
        event.target.disabled = true;
      }
    }, 1000);
  }

  //* Search event on feedback api */

  searchApi() {
    this.Details = [];
    if (this.filter == undefined || this.filter == JAVASCRIPT.BLANK) {
      this.filter = JAVASCRIPT.BLANK
    }
    this.bucketNo = parseInt(COUNT.COUNT_1);
    this.skelton = true;
    this.dashboardService.feedBackApi(this.ionicStorage.userDetails.outletId, this.filter, this.oneMonthSub, this.currentDate, this.bucketNo, this.starData).subscribe(response => {
      if (response) {
        this.skelton = false;
        this.feedBackdata = response.result;
      } else {
        this.dateFormat();
        this.skelton = false;
      }
    },
      err => {
        this.skelton = false;
      })
  }

  doInfiniteSearchApi() {
    this.Details = [];
    if (this.filter == undefined || this.filter == JAVASCRIPT.BLANK) {
      this.filter = JAVASCRIPT.BLANK
    }
    this.lastresultID = ++this.bucketNo;
    this.skelton = true;
    this.dashboardService.feedBackApi(this.ionicStorage.userDetails.outletId, this.filter, this.oneMonthSub, this.currentDate, this.lastresultID, this.starData).subscribe(response => {
      if (response) {
        this.skelton = false;
        this.feedBackdata = this.feedBackdata.concat(response.result);
      } else {
        this.dateFormat();
        this.skelton = false;
      }
    },
      err => {
        this.skelton = false;
      })
  }

  //* function to navigate to dashboard */
  dashboardBack() {
    this.router.navigateByUrl('web-dashboard')
  }

  //* Outlet Feedback Count API starts here */
  outletFeedbackCountApi() {
    this.dashboardService.feedBackCountOutletApi(this.ionicStorage.userDetails.outletId,this.ionicStorage.userDetails.orderIn).subscribe((res) => {
      if (res) {
        this.outletResponse = res;
      } ((err) => { })
    });
  }

  checkLanguageForMonths(val, TYPE) {
    var month = new Array();
    month[COUNT_NUMBER.COUNT_0] = MONTHS.january;
    month[COUNT_NUMBER.COUNT_1] = MONTHS.february;
    month[COUNT_NUMBER.COUNT_2] = MONTHS.march;
    month[COUNT_NUMBER.COUNT_3] = MONTHS.april;
    month[COUNT_NUMBER.COUNT_4] = MONTHS.may;
    month[COUNT_NUMBER.COUNT_5] = MONTHS.june;
    month[COUNT_NUMBER.COUNT_6] = MONTHS.july;
    month[COUNT_NUMBER.COUNT_7] = MONTHS.august;
    month[COUNT_NUMBER.COUNT_8] = MONTHS.september;
    month[COUNT_NUMBER.COUNT_9] = MONTHS.october;
    month[COUNT_NUMBER.COUNT_10] = MONTHS.november;
    month[COUNT_NUMBER.COUNT_11] = MONTHS.december;
    var day = new Date(val);
    var currentMonth = month[day.getMonth()];
    var currentArr = val.split('-')
    this.translate.get(REVIEW_HISTORY_PAGE.months + currentMonth).subscribe((res: string) => {
      let dataOfToday = currentArr[COUNT_NUMBER.COUNT_2] + JAVASCRIPT.BLANKSPACE + res + JAVASCRIPT.BLANKSPACE + currentArr[COUNT_NUMBER.COUNT_0];
      if (TYPE === MONTHS.startDate) {
        this.oneMonthSub = val;
        this.startDateShow = dataOfToday;
      } else if (TYPE === MONTHS.endDate) {
        this.currentDate = val
        this.endDateShow = dataOfToday;
      }
    })
  }

}