<div class="modalBg">
  <ion-card class="modalWrap">
    <ion-card-header class="modalHeader">
      <h2 class="modalTitle">{{'reservation_regret.reason_of_regret'|translate}}</h2>
      <!-- <h2 class="modalTitle">Update Mobile Number</h2> -->
      <ion-icon class="modalClose ca-close-icon" (click)="closeModal()"></ion-icon>
    </ion-card-header>
    <form>
      <ion-card-content class="modalContent">
        <!-- Email Field -->
        <ion-item lines="full" *ngFor="let item of optionregretAll; index as i">
          <div class="custom-radio">
            <input type="radio" id="option{{item.id}}" [value]="item.id" [(ngModel)]="resvationData" (change)="chekcedFun()"
              name="resvationData" class="custom-radio">
            <label for="option{{item.id}}">{{item.Value}}</label>
          </div>
        </ion-item>
      </ion-card-content>
      <!-- Update Button -->
      <ion-button mode="ios" expand="block" class="modalButton ion-no-margin primary-gradient"
        [disabled]="!buttonEnable" (click)="regrettedApi(constRegret.regret)">
        {{'reservation_regret.regret_reservation'|translate}}
      </ion-button>
    </form>
  </ion-card>
</div>


<!-- <div class="customModal" id="regrettedModal">
    <div class="customModal-dialog customModal-dialog-centered">
      <div class="customModal-content">
        <ion-buttons>
          <ion-icon slot="end" (click)="regrettedClose()" class="ca-modal-close"></ion-icon>
        </ion-buttons>
        <div class="customModal-body ion-text-center">
          <div class="customModal-text">
            <p>You have successfully regretted the reservation request.</p>
          </div>
        </div>
        <div class="customModal-footer">
          <ion-button mode="ios" expand="full" class="customModalButton primary-gradient" (click)="gotIt()"> Got It </ion-button>
        </div>
      </div>
    </div>
  </div> -->