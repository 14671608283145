<div class="celebBackground">
  <ion-card class="modalWrap">
    <ion-card-header class="modalHeader">
      <h2 class="modalTitle"> {{'AllowOtpPage.title'| translate }} </h2>
      <ion-icon class="modalClose ca-close-icon" (click)="closeModal(1)"></ion-icon>
    </ion-card-header>

    <ion-card-content>
      <ion-item lines="none">
        <ion-label class="ion-text-wrap allowText">
          {{'AllowOtpPage.allow'| translate : brandName}}
        </ion-label>
      </ion-item>
    </ion-card-content>

    <ion-button color="primary" expand="block" class="modalButton alowBut ion-no-margin" (click)="closeModal(2)"> {{'AllowOtpPage.btn_Allow'| translate }}
    </ion-button>
  </ion-card>
</div>