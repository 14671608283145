import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DashboardhomeService } from 'src/app/services/dashboardhome.service';
import { NavParams } from '@ionic/angular';
import { COUNT, SET_DATE_MODAL, JAVASCRIPT } from 'src/app/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { LocalizationService } from 'src/app/services/core/localize/localization.service';
import { setOptions } from '@mobiscroll/angular';
import * as moment from 'moment';

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});
@Component({
  selector: 'app-set-date',
  templateUrl: './set-date.component.html',
  styleUrls: ['./set-date.component.scss'],
})
export class SetDateComponent implements OnInit {
  @Input() dataArr: string;
  date: any;
  sevenDaysBefore: string;
  todayDate: number;
  sevenDaysAfter: string;
  change: any;
  date1: string;
  date2: string;
  dateFrom: any;
  dateTO: any;
  demoDummy: boolean = false
  week: number;
  currentDate: string;
  feedbackDate: any;
  onemonth: string;
  minDate: string = new Date().toISOString();
  maxData: any = (new Date()).getFullYear() + parseInt(COUNT.COUNT_10);
  buttonEnable: boolean;
  minDateDisabled: string = new Date().toISOString();
  maxDateEnable: any = (new Date()).getFullYear() + parseInt(COUNT.COUNT_20);
  viewReservationDate: any;
  typeCalender: any;
  maxDateEnableRedemptionLog: any;
  minDateEnableRedemptionLog: number;
  monthShortNames: string;
  validMinDate: string;


  constructor(
    private modalCtrl: ModalController,
    public navParams: NavParams,
    private translate: TranslateService,
    public dashboardService: DashboardhomeService,
    private localizationService: LocalizationService,

  ) {
    this.translate.get(SET_DATE_MODAL.customMonth).subscribe((res: string) => {
      //console.log("date",res)
      this.monthShortNames = res
    });
    var getArr = navParams.get(SET_DATE_MODAL.GET_TYPE_PARAMS);
    if (getArr) {
      if (getArr.selectedCalender) {
        this.date = getArr.selectedCalender.calenderChange;
        if (getArr.selectedCalender.calenderChange == SET_DATE_MODAL.CUSTOM && getArr.Type === SET_DATE_MODAL.REDEMPTION_TYPE
          || getArr.Type === SET_DATE_MODAL.VIEW_RESERVATIONS_TYPE
          || getArr.Type === SET_DATE_MODAL.FEEDBACK_TYPE) {
          this.dateFrom = getArr.selectedCalender.startCustomDate
          this.dateTO = getArr.selectedCalender.endCustomDate
        }
        this.change = this.date;
      }
      if (getArr.selectedCalender == undefined && getArr.Type === SET_DATE_MODAL.REDEMPTION_TYPE
        || getArr.selectedCalender == undefined && getArr.Type === SET_DATE_MODAL.VIEW_RESERVATIONS_TYPE
        || getArr.selectedCalender == undefined && getArr.Type === SET_DATE_MODAL.FEEDBACK_TYPE) {
        this.date = getArr.calenderChange;
        this.change = this.date;
      }
      this.typeCalender = getArr.Type;
    }
  }

  ngOnInit() {
    var date = new Date();
    this.validMinDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();

  }

  //* getting of this week */
  ionViewWillEnter() {
    this.dateFormat();

  }
  closeModal() {
    this.modalCtrl.dismiss();
  }


  dateFormat() {
    this.currentDate = new Date().toISOString().split(JAVASCRIPT.T)[COUNT.COUNT_0];
    this.todayDate = new Date().getTime();
    this.week = parseInt(SET_DATE_MODAL.WEEKLY_NUMBER);
    this.sevenDaysAfter = new Date(this.todayDate + this.week).toISOString().split(JAVASCRIPT.T)[COUNT.COUNT_0];
    this.sevenDaysBefore = (new Date(this.todayDate - this.week).toISOString()).split(JAVASCRIPT.T)[COUNT.COUNT_0];
    this.maxDateEnableRedemptionLog = this.currentDate;
    this.minDateEnableRedemptionLog = (new Date()).getFullYear() - parseInt(COUNT.COUNT_5);
  }


  onItemChange(value) {
    this.dateFrom = JAVASCRIPT.BLANK;
    this.dateTO = JAVASCRIPT.BLANK;
    this.change = value.target.value
    this.dateFrom = JAVASCRIPT.BLANK;
    this.dateTO = JAVASCRIPT.BLANK;
  }

  //* Date filter api */
  applyFilter() {
    let dataForClosecheque = {
      "endWeekDate": this.sevenDaysBefore,
      "endnextWeekDate": this.sevenDaysAfter,
      "todayDate": this.currentDate,
      "startCustomDate": this.dateFrom,
      "endCustomDate": this.dateTO,
      "calenderChange": this.change,
    }
    this.modalCtrl.dismiss({
      "setDateData": dataForClosecheque,
    });
  }


  //* Custom Date fetch from here */

  setStartDate(startDateValue) {
    console.log("startDateValue",startDateValue)


    // let selectedDate = JAVASCRIPT.BLANK;
    // console.log("val date start date",val)
    // this.checkDatevali = val.value
    var checkDate = new Date(startDateValue.value)
    var checkDatevali = new Date(checkDate.getTime() - (checkDate.getTimezoneOffset() * 60000)).toISOString();
    console.log("vcheckDatevali", checkDatevali)



    this.dateFrom = (new Date(checkDatevali).toISOString()).split(JAVASCRIPT.T)[COUNT.COUNT_0];
    console.log("dateFrom", this.dateFrom)

  }
  setEndDate(endDateValue) {
    console.log("endDateValue", endDateValue)
    // this.dateTO = (new Date(endDateValue.value).toISOString()).split(JAVASCRIPT.T)[COUNT.COUNT_0];


    // var new_date = new Date(endDateValue.value);
    // let new_date2 = (new_date.getFullYear() + "-" + ( new_date.getMonth() > 9 ? (new_date.getMonth() + 1): '0'+ (new_date.getMonth() + 1))+ "-" + (new_date.getDate() > 9 ? new_date.getDate() : '0'+new_date.getDate() ));
     
      this.dateTO = moment(endDateValue.value).format("YYYY-MM-DD");
   
    
      if(this.typeCalender =='ViewReservations' ) {
        // https://tlcgroup.atlassian.net/browse/THA-847
      // if (this.dateTO > this.dateFrom) {
      //   console.log("feedback")
      //   this.dateFrom = JAVASCRIPT.BLANK;
      //   this.dateTO = JAVASCRIPT.BLANK;
      //   return
      // } 
  }
  else if(this.typeCalender =='redemptionLog' || this.typeCalender =='feedbackHistory' ) {
    if (this.dateTO < this.dateFrom) {
      console.log("feedback")
      this.dateFrom = JAVASCRIPT.BLANK;
      this.dateTO = JAVASCRIPT.BLANK;
      return
    } 
}
    // else {
    //   this.dateFrom;
    //   }
    
  }



}
