<div class="tablemodal-wrap">
  <h1 class="table-number">Table Number {{tableNumber}}</h1>
  <ion-icon class="modalClose ca-close-icon" (click)="closeModal()"></ion-icon>
  <ion-item lines="none" class="ion-no-padding informationItem">
    <ion-label position="stacked" class="ion-text-wrap">
      <p>Tags</p>
      <h4> 
          <ion-chip  *ngFor="let detail of tags | keyvalue ; let j = index" mode="md" style="--background: #D4DDFA;">
            <!-- <ion-img *ngIf="detail.value =='WindowSide'" src="../../../assets/icon/tag1.svg"></ion-img>
            <ion-img *ngIf="detail.value =='highlyRequested'" src="../../../assets/icon/tag2.svg"></ion-img> -->
            <ion-label>{{detail.value}}</ion-label>
          </ion-chip>
          <!-- <ion-chip mode="md" style="--background: #FAE2D4;">
            <ion-img src="../../../assets/icon/tag2.svg"></ion-img>
            <ion-label>{{detail.value}}</ion-label>
          </ion-chip> -->
      </h4>
    </ion-label>
  </ion-item>


  <hr>
  <div style="min-height: 350px;">
   
      <ion-radio-group value="">
        <ion-item-group *ngFor="let time of reservationTimeArr" class="mambers-list" mode="ios">
        <ion-list mode="ios" class="mamber-wrap">
          <ion-label class="date ion-text-wrap">{{time}}</ion-label>
          <div class="mamber-group" *ngFor="let reservationData of awaitData; index as i">
            <div class="" *ngFor="let data of reservationData.reservationDetails; index as j">
              <ion-card class="default-card member-card" *ngIf="time == data?.reservationTime">
                <ion-item lines="full">
                  <ion-avatar class="cursor" (click)="profile(data?.memberDetails?.membershipNumber)" slot="start">
                    <img [src]="data?.memberDetails?.Photo ||'assets/imgs/user-img.png'">
                  </ion-avatar>
                  <ion-label class="ion-text-wrap">
                    <h2>{{data?.memberDetails?.firstName}}&nbsp;{{data?.memberDetails?.lastName}}</h2>
                    <p> {{'queryPage.reservation'|translate}} </p>
                  </ion-label>
                  <ion-radio mode="md" slot="end" 
                    (click)="selectedUser(data,ionicStorageEmployeeId.userDetails.outletType)"></ion-radio>
                </ion-item>
                <ion-buttons class="btns-col">
                  <ion-button mode="md" color="primary" *ngIf="data.memberDetails?.mobile!='' " href="tel:{{'+'+data.memberDetails?.mobile}}">
                    <ion-icon class="ca-call"></ion-icon> {{'queryPage.call' | translate}} 
                  </ion-button>
                  <ion-button mode="md" color="primary" (click)="viewReservation(data)" >
                    <ion-icon class="ca-view"></ion-icon> {{'queryPage.View' | translate}}
                  </ion-button>
                </ion-buttons>
              </ion-card>
            </div>
          </div>
        </ion-list>
      </ion-item-group>
      </ion-radio-group>
   
    <div class="ion-text-center">
      <ion-button mode="md" fill="clear" (click)="walkin()">
        <ion-icon class="ca-plus-icon"></ion-icon> Walk in
      </ion-button>
    </div>
  </div>
  <ion-button mode="md" [disabled]="!flag" (click)="assignTable()" class="btnAssignTable" expand="block">Assign
    Table</ion-button>
</div>