<div *ngIf="!enableManageScreen">
  <h1 class="for-web page-title">In Progress</h1>
  <ion-list class="mambers-list" *ngIf="!skelton">
    <div *ngFor="let items of inProgressresult">
      <ion-item-group class="mamber-wrap">
        <ion-label class="date ion-text-wrap" *ngIf="inProgressStorage.userDetails.outletType =='Restaurant'">
          {{items?.reservationTableNo}}</ion-label>
        <ion-item lines="none" class="default-card member-card">
          <ion-avatar class="cursor" (click)="profile(items?.memberDetails?. membershipNumber)" slot="start">
            <img
              [src]="items?.memberDetails?.Photo ? items?.memberDetails?.Photo + '?' +currentDate:'assets/imgs/user-img.png'">
          </ion-avatar>
          <ion-label class="ion-text-wrap cursor" (click)="manageReservation(items.reservationID,items?.reservationTableNo)">
            <h2>{{items?.memberDetails?.firstName}}&nbsp;{{items?.memberDetails?.lastName}}
            </h2>
            <p>{{items?.memberDetails?.memberType ? items?.memberDetails?.memberType:'Visitor' }}</p>
          </ion-label>
          <ion-icon (click)="manageReservation(items.reservationID,items?.reservationTableNo)" slot="end"
            class="ca-right-arrow-icon cursor"></ion-icon>
        </ion-item>
      </ion-item-group>
    </div>
  </ion-list>
</div>
<!-- selector call -->
<div *ngIf="enableManageScreen">
  <ion-button class="backButton" fill="outline" (click)="backAwaiting()" mode="md" shape="round">
    <ion-icon mode="ios" class="ca-back"></ion-icon> &nbsp; Back
  </ion-button>
  <app-manage-reservation [sendSfid]="sendingSfid"></app-manage-reservation>
</div>

<!-- Skelton Text Loder -->
<ion-list class="mambers-list" *ngIf="skelton">
  <ion-item-group class="mamber-wrap">
    <ion-label class="date">
      <ion-skeleton-text animated style="width: 50%; margin: auto; height: 20px;"></ion-skeleton-text>
    </ion-label>
    <ion-item lines="none" class="default-card member-card">
      <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label class="ion-text-wrap">
        <h2>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </h2>
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-item-group>

  <ion-item-group class="mamber-wrap">
    <ion-label class="date">
      <ion-skeleton-text animated style="width: 50%; margin: auto; height: 20px;"></ion-skeleton-text>
    </ion-label>
    <ion-item lines="none" class="default-card member-card">
      <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label class="ion-text-wrap">
        <h2>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </h2>
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-item-group>

  <ion-item-group class="mamber-wrap">
    <ion-label class="date">
      <ion-skeleton-text animated style="width: 50%; margin: auto; height: 20px;"></ion-skeleton-text>
    </ion-label>
    <ion-item lines="none" class="default-card member-card">
      <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label>
        <h2>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </h2>
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-item-group>

  <ion-item-group class="mamber-wrap">
    <ion-label class="date">
      <ion-skeleton-text animated style="width: 50%; margin: auto; height: 20px;"></ion-skeleton-text>
    </ion-label>
    <ion-item lines="none" class="default-card member-card">
      <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label>
        <h2>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </h2>
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-item-group>
</ion-list>