import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpWrapperService, HttpInputData } from './core/http/http-wrapper.service';
import { CommonService } from './common.service';
import { constantDashboard, constActivtePage, JAVASCRIPT, COUNT } from '../app.constants'
import { appApiResources } from './../app.constants';
import { InitService } from './init/init.service';
import { AppStateService } from './init/app-state.service';
import { $ } from 'protractor';

@Injectable({
  providedIn: 'root'
})

export class DashboardhomeService {
  reservationsTypeData: any;
  resevationStatusType: any;
  statusCode = constActivtePage.statusCode;
  inProgressTransaction: any;
  reservationTable: any
  closeResponse: any = [];
  memberProfile: any = [];
  reserveDet: any = [];
  guestTopreferenceData: any;
  propertyId: any = [];
  barCoderesponse: any;
  poolGuest: any;
  resultLastId: number;
  propID: any;
  dashboardskelton: boolean = false
  outletCurrency: any;
  appstateAlldata: any;
  bucketSize: number;
    vaccanTableNumber: any[];


  constructor(
    public http: HttpClient,
    public httpWrapper: HttpWrapperService,
    public commonService: CommonService,
    public initService: InitService,
    public appState: AppStateService,
  ) {


    this.appstateAlldata = this.appState.getAppData();



  }



  // Table allotment table type api
  tableAllotmentTypeApi(sfid, outletId, type) {
    console.log("orderInTypeCheck,",type)
    if (type ) {

      // Request URL: https://hotelapp-uat.tlcgroup-test-platform.com/api/tableReservationData?outletSfid=a0Q1y000003ZxrhEAC &type=”Main Dining” 
      let options = new HttpInputData();
      let httpParams = new HttpParams();
      options.params = httpParams;
      return this.httpWrapper.get('getReservationTableData?outletSfid=' + outletId + '&tableType=' + `${type}` , options)
        .map((response: any) => {
          return response;
        })
    } else {
      let options = new HttpInputData();
      let httpParams = new HttpParams();
      options.params = httpParams;
      return this.httpWrapper.get(`outlet/${outletId}/deliverydashboard`, options)
        .map((response: any) => {
          return response;
        })
    }
    // let options = new HttpInputData();
    // let httpParams = new HttpParams();
    // options.params = httpParams;
    // return this.httpWrapper.get('account/' + sfid + '/dashBoard?outletSfid=' + outletId, options)
    //   .map((response: any) => {
    //     return response;
    //   })
    // .catch((error: Response) => this.commonService.errorHandler(error))
  }


  // .............. dashboard home api ...... //

  dashboardHomeApi(sfid, outletId, orderInTypeCheck) {
    console.log("orderInTypeCheck,",orderInTypeCheck)
    if (orderInTypeCheck == false) {
      let options = new HttpInputData();
      let httpParams = new HttpParams();
      options.params = httpParams;
      return this.httpWrapper.get('account/' + sfid + '/dashBoard?outletSfid=' + outletId, options)
        .map((response: any) => {
          console.log("response from service", response);
          return response;
        })
    } else {
      let options = new HttpInputData();
      let httpParams = new HttpParams();
      options.params = httpParams;
      return this.httpWrapper.get(`outlet/${outletId}/deliverydashboard`, options)
        .map((response: any) => {
          return response;
        })
    }
    // let options = new HttpInputData();
    // let httpParams = new HttpParams();
    // options.params = httpParams;
    // return this.httpWrapper.get('account/' + sfid + '/dashBoard?outletSfid=' + outletId, options)
    //   .map((response: any) => {
    //     return response;
    //   })
    // .catch((error: Response) => this.commonService.errorHandler(error))
  }

  //..... Reservation type api function......//

  allReservationApi(reservationsType, startDate, endDate, reservationSfid, managePageType, bucketNum, search) {
    console.log("reservationSfid", reservationsType, startDate, endDate, reservationSfid, managePageType, bucketNum, search)
    let bucketSize
    if (this.appstateAlldata.appInitData.bucketSize) {
      bucketSize = this.appstateAlldata.appInitData.bucketSize
    } else {
      bucketSize = 10
    }


    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    let url = managePageType === 'manageReservation' ?
      appApiResources.reservationsUrl + reservationSfid :
      `reservations?status=${reservationsType}&startDate=${startDate}&endDate=${reservationsType == constantDashboard.awaitingArrival ? startDate : endDate}&searchText=${search}&bucketNo=${bucketNum}&bucketSize=${bucketSize}`

    return this.httpWrapper.get(url, options)
      .map((response: any) => {
        if (response) {
          this.statusCode = response.statusCode;
        }
        return response;
      })
  }

  //........ reservation table API .......// 

  allotReservartionApi(reservationId, tableNo, employeeID, guestName, outletType, checkno) {
    // console.log("reservationId=>>", reservationId)
    if (guestName == undefined) {
      guestName = ''
    }
    var outletPass
    outletPass = outletType === 'Restaurant' ? true : false
    let data = {
      "reservationId": reservationId,
      "employeeID": Number(employeeID),
      "tableNo": tableNo ? tableNo : (undefined || ''),
      "checkTable": outletPass,
      "guestNames": String(guestName),
      "checkNumber": checkno
    }
    let options = new HttpInputData();
    return this.httpWrapper.put('reservations' + '/' + reservationId + '/allotReservationTable', data, options)
      .map((response: any) => {
        return response;
      })
    // .catch((error: Response) => this.commonService.errorHandler(error))
  }

  //......... logout api start here ........//

  emplogout(empstatus) {
    let data = {
      "memberId": empstatus,
    }
    let options = new HttpInputData();
    return this.httpWrapper.put('account' + '/logout', data, options)
      .map((response: any) => {
        return response;
      })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }



 outletTagPutApi(outletId,tagId,tagStatus) {
  
    let data = [{
      "tagId": tagId,
      "tagStatus":tagStatus,
    
    }]
    console.log("data ==>" , data )
    let options = new HttpInputData();
    return this.httpWrapper.put('outlet/' + outletId+ '/outletTags', data, options)
      .map((response: any) => {
        return response;
      })
  }


  //....... inprogress api start here ......../

  inProgress(instartDate, inendDate) {
    return this.httpWrapper.get(`${appApiResources.IPReservationsUrl}?&startDate=${instartDate}&endDate=${inendDate}`)
      .map((response: any) => {
        return response;
      })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }

  //........Function change  reservation details api .......//

  reservationChangeStatus(reservationStatusType, sfid, employeeId, reason) {
    let data = {
      "reservationSfid": sfid,
      "employeeID": Number(employeeId),
      "status": this.resevationStatusType || reservationStatusType,
      "reason": reason
    }
    let options = new HttpInputData();
    return this.httpWrapper.post(appApiResources.reservationStatusUpdateUrl, data, options)
      .map((response: any) => {
        return response;
      })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }

  //........select-outlet country details api .......//


putOutletSectionApi(outletid, data) {
    console.log("putOutletSectionApi", outletid, "data", data);
    let options = new HttpInputData();
    // propertySections
    return this.httpWrapper.put('outlet/' + outletid + '/' +   appApiResources.outletSection, data, options)
      .map((response: any) => {
        return response;
      })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }


  postOutletSectionApi(outletid, data) {
    console.log("postOutletSectionApi", outletid, "data", data);
    let options = new HttpInputData();
    // propertySections
    return this.httpWrapper.post('outlet/' + outletid + '/' +   appApiResources.outletSection, data, options)
      .map((response: any) => {
        return response;
      })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }


  outletCountriesApi(sfid) {

    return this.httpWrapper.get(`countries?&employeeID=${sfid}`).map((response: any) => {

      return response;
    })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }

  outletSelectApi() {

    return this.httpWrapper.get(this.initService.environment.headers.program_id + `/outlets?status=Live`).map((response: any) => {
      // return this.httpWrapper.get(`TLC_MAR_CLMA/outlets?status=Live`).map((response: any) => {

      return response;
    })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }

  //........select-outlet cities details api .......//

  outletCitiesApi(sfid, countryId) {
    return this.httpWrapper.get(`countries/${countryId}/cities?employeeID=${sfid}`).map((response: any) => {
      return response;
    })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }

  //........select-outlet hotel details api .......//

  outletHotelApi(sfid, countryId, cityId) {
    return this.httpWrapper.get(`countries/${countryId}/cities/${cityId}/properties?employeeID=${sfid}`).map((response: any) => {
      return response;
    })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }

  //........select-outlet outlet details api .......//

  outletApi(sfid, countryId, cityId, propertyId) {
    return this.httpWrapper.get(`countries/${countryId}/cities/${cityId}/properties/${propertyId}/outlets?employeeID=${sfid}`).map((response: any) => {
      return response;
    })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }

  //........close check page navigation api .......//

  closeCheck(instartDate, inendDate) {

    return this.httpWrapper.get(`${appApiResources.IPReservationsUrl}?&startDate=${instartDate}&endDate=${inendDate}`)
      .map((response: any) => {
        return response;
      })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }

  modifyApifunction(reservationId, outletId, modalData, outLetType) {
    if (modalData.modifyModalData.time == '24') {
      modalData.modifyModalData.time = '12'
    }
    if (outLetType == 'Restaurant' || outLetType == 'Spa') {
      modalData.modifyModalData.guest

    } else if (outLetType == 'Pool') {
      this.poolGuest = modalData.modifyModalData.adultCount + modalData.modifyModalData.chilCount
    }
    let data = {
      reservationDetails: {
        reservationDate: modalData.modifyModalData.date,
        reservationTime: String(modalData.modifyModalData.time + ':' + modalData.modifyModalData.minute)
      },
      guestCount: String(this.poolGuest || modalData.modifyModalData.guest),
      adultCount: String(modalData.modifyModalData.adultCount),
      kidsCount: String(modalData.modifyModalData.chilCount),
      specialRequest: modalData.modifyModalData.specialRequest,
      spaTreatment: modalData.modifyModalData.spaTreatment,
      spaDuration: modalData.modifyModalData.spaDuration
    }

    let options = new HttpInputData();
    return this.httpWrapper.put(appApiResources.reservationsmodifyReservationUrl + reservationId + '?outletID=' + outletId, data, options)
      .map((response: any) => {
        return response;
      })
    // .catch((error: Response) => this.commonService.errorHandler(error))
  }

  // close cheque api 
  closeChequeApiFuntion(employeeid, reservationId, membershipNumber, guestCount, adultCount, childCount, outletDetails, netAmount, currency) {
    // console.log("outletDetails", outletDetails)
    if (outletDetails.userDetails.outletType == 'Restaurant' || outletDetails.userDetails.outletType == 'Spa'
      || outletDetails.userDetails.outletType == 'Accommodation') {
      // console.log("Guest")
      guestCount

    } else if (outletDetails.userDetails.outletType == 'Pool') {
      // console.log("pool")
      var poolGuest = adultCount
    }



    let data = {
      "employeeId": employeeid,
      "reservationID": reservationId,
      "membershipNumber": membershipNumber,
      "guestCount": String(poolGuest || guestCount),
      "kidsCount": String(childCount),
      "adultCount": String(adultCount),
      "addNetAmount": String(netAmount),
      "currency": currency.currencyName
    }
    let options = new HttpInputData();
    return this.httpWrapper.post(appApiResources.settleChequeUrl, data, options)
      .map((response: any) => {
        return response;
      })
    // .catch((error: Response) => this.commonService.errorHandler(error))
  }

  //*....add prospect api....*/

  addprospect(sfid, data) {
    let options = new HttpInputData();
    return this.httpWrapper.post(appApiResources.addProspect + sfid, data, options)
      .map((response: any) => {
        return response;
      })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }

  /* member search api */
  searchByKeyword(searchfilter, page) {
    let bucketSize
    if (this.appstateAlldata.appInitData.bucketSize) {
      bucketSize = this.appstateAlldata.appInitData.bucketSize
    } else {
      bucketSize = 10
    }
    let options = new HttpInputData();
    return this.httpWrapper.get('memberSearch?' + "query=" + searchfilter + '&limit=' + bucketSize + '&lastResultID=' + (page), options)
      .map((response: any) => {
        this.resultLastId = page;
        return response;
      })
  }

  callbarCodeCheck(ionicStorageData, barcodeData) {
    //console.log("barcodeData",barcodeData)
    var scenData = barcodeData.split('|');
    //console.log("scenData",scenData)
    var data = {
      "membershipNumber": parseInt(scenData[2], 10),
      "benefitId": scenData[3]
    }
    if (scenData[2]) {
      data['reservationId'] = scenData[5];
    }
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    return this.httpWrapper.post('redeem/redeemWithQR?outetsfid=' + ionicStorageData.userDetails.outletId, data, options)
      .map((response: any) => {
        this.barCoderesponse = response;
        return response;
      })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }

  reservationInfoGetApi(ionicStorageData) {
    let options = new HttpInputData();
    return this.httpWrapper.get('reservationMaster/' + ionicStorageData.userDetails.outletId, options)
      .map((response: any) => {
        return response;
      })
  }

  newreservationInfoGetApi(ionicStorageData, startdate) {
    let options = new HttpInputData();
    return this.httpWrapper.get('reservationMaster/' + ionicStorageData.userDetails.outletId + '?' + 'reservationDate=' + startdate, options)
      .map((response: any) => {
        return response;
      })
  }

  reservationUpdateCountInfoGetApi(ionicStorageData, selectDate) {
    let options = new HttpInputData();
    return this.httpWrapper.get(`outlets/${ionicStorageData.userDetails.outletId}/reservations/paxcount?reservationdate=${selectDate}&aggregatepax=true`, options)
      .map((response: any) => {
        return response;
      })
  }

  setReservationMasterAPI(reservationData, getStorage) {
    let options = new HttpInputData();
    return this.httpWrapper.post('outlets' + '/' + getStorage.userDetails.outletId + '/restrictions', reservationData, options)
      .map((response: any) => {
        return response;
      })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }

  onUnblockClickAPI(unblock, getStorage) {
    let options = new HttpInputData();
    return this.httpWrapper.delete('outlets' + '/' + getStorage.userDetails.outletId + '/restrictions/' + unblock, options)
      .map((response: any) => {
        return response;
      })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }

  


  //* outlet FeedbackCountApi starts here  */
  feedBackCountOutletApi(sfid, whichOutlet) {
    let options = new HttpInputData();
    if (whichOutlet == true) {
      let mbowSend = true
      return this.httpWrapper.get(`outletFeedbackCount/${sfid}?getDeliveryReviews=${mbowSend}`, options)
        .map((response: any) => {
          return response;
        })
        .catch((error: Response) => this.commonService.errorHandler(error))
    } else {
      return this.httpWrapper.get(appApiResources.FeedbackCount + sfid, options)
        .map((response: any) => {
          return response;
        })
        .catch((error: Response) => this.commonService.errorHandler(error))
    }

  }

  //* outlet Feedbackapi starts here */

  feedBackApi(outletId, filter, monthAdd, monthSub, bucketNum, rating) {
    let bucketSize
    if (this.appstateAlldata.appInitData.bucketSize) {
      bucketSize = this.appstateAlldata.appInitData.bucketSize
    } else {
      bucketSize = 10
    }

    let options = new HttpInputData();
    return this.httpWrapper.get(`outlet/${outletId}/feedback?&bucketSize=${bucketSize}&bucketNo=${bucketNum}&startDate=${monthAdd}&endDate=${monthSub}&rating=${rating}&query=${filter}`, options)
      .map((response: any) => {
        return response;
      })
  }

  getSettingInFoAPI(ionicStorageData) {
    let options = new HttpInputData();
    return this.httpWrapper.get('account/settings?accountId=' + ionicStorageData.userDetails.employeeId, options)
      .map((response: any) => {
        return response;
      })
  }

  updateSettingInFoAPI(update, ionicStorageData) {
    let options = new HttpInputData();
    return this.httpWrapper.put('account/settings?accountId=' + ionicStorageData.userDetails.employeeId, update, options)
      .map((response: any) => {
        return response;
      })
  }

  getOfferInFoAPI(ionicStorageData) {
    let options = new HttpInputData();
    return this.httpWrapper.get('outlet/' + ionicStorageData.userDetails.outletId + '/offer', options)
      .map((response: any) => {
        return response;
      })
      .catch((error: Response) => {
        return this.commonService.dismissLoader();
      })
  }

  // walk in api 

  //*....add prospect api....*/

  walkIn(userMobileNo, userEmail, usermemberShip, userType) {
    switch (userType) {
      case 'emailaddress':
        var url = `membershipDetails?email=${userEmail}`
        break;
      case 'phoneNumber':
        var url = `membershipDetails?mobile=${userMobileNo}`
        break;
      case 'membershipNumber':
        var url = `members?mermerShip=${usermemberShip}`
        break;
    }
    let options = new HttpInputData();
    return this.httpWrapper.get(url)
      .map((response: any) => {
        return response;
      })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }

  //* redeemTransaction get api*/

  redeemTransaction(reserveSfid, reserveId, benefitCode) {
    let options = new HttpInputData();
    return this.httpWrapper.get(appApiResources.redeemTransaction + `${reserveSfid}?reservationID=${reserveId}&benefitId=${benefitCode}`)
      .map((response: any) => {
        return response;
      })
  }

  redeptionListingApi(reserverionType, startDate, endDate, bucketNo) {
    let bucketSize
    if (this.appstateAlldata.appInitData.bucketSize) {
      bucketSize = this.appstateAlldata.appInitData.bucketSize
    } else {
      bucketSize = 10
    }

    let options = new HttpInputData();
    return this.httpWrapper.get(`reservations?bucketSize=${bucketSize}&bucketNo=${bucketNo}&status=${reserverionType}&startDate=${startDate}&endDate=${endDate}`)
      .map((response: any) => {
        return response;
      })
  }

  //*feedBack respond Modal */

  feedBackRespondModal(resMessage, feedbackId, memberShipNumber) {
    let data = {
      "responseMessage": resMessage,
      "membershipNumber": memberShipNumber,
      "feedbackID": feedbackId,
    }
    let options = new HttpInputData();
    return this.httpWrapper.post(appApiResources.feedbackRespond, data, options)
      .map((response: any) => {
        return response;
      })
  }

  cancelCloseChequqApi(outletSfId, reservationId, membershipOfferId, membershipNumber) {
    let data = {
      "membershipNumber": membershipNumber,
      "membershipOfferId": membershipOfferId,
      "outletSfId": outletSfId,
      "reservationId": reservationId
    }
    // console.log("data",data)
    let options = new HttpInputData();
    return this.httpWrapper.post(appApiResources.redeemcancelUrl, data, options)
      .map((response: any) => {
        return response;
      })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }

  outletOpen(outletID, startDate, endDate, startTime, endTime, apiStatusUrl) {

    if (apiStatusUrl == 'outletOpen') {
      var outletUrlSend = '/createOutletOpenRestriction'
    } else {
      var outletUrlSend = '/createOutletCloseRestriction'
    }
    // console.log(startTime)
    // console.log(endTime)

    let data = {
      "startDate": startDate,
      "startTime": startTime.hour + ':' + startTime.min + ':00',
      "endDate": endDate,
      "endTime": endTime.hour + ':' + endTime.min + ':00',
    }
    let options = new HttpInputData();
    return this.httpWrapper.post(outletID + outletUrlSend, data, options)
      .map((response: any) => {
        return response;
      })
    // .catch((error: Response) => this.commonService.errorHandler(error))
  }

  // outletClose(outletID, startDate, endDate, startTime, endTime) {

  //   console.log()
  //   let data = {
  //     "startDate": startDate,
  //     "startTime": startTime + ':00',
  //     "endDate": endDate,
  //     "endTime": endTime + ':00',

  //   }
  //   let options = new HttpInputData();
  //   return this.httpWrapper.post(outletID + '/createOutletCloseRestriction ', data, options)
  //     .map((response: any) => {
  //       return response;
  //     })
  //     .catch((error: Response) => this.commonService.errorHandler(error))
  // }

  // resCancellationPeriod

  getCancellationPeriodApi(outletID){
    
    var urlSend
    urlSend = `outlet/${outletID}/resCancellationPeriod`
    let options = new HttpInputData();
    return this.httpWrapper.get(urlSend, options)
      .map((response: any) => {
        return response;
      })
      .catch((error: Response) => this.commonService.errorHandler(error));
  }

  reservationChargesapiFunc(outletID){
    
    var urlSend
    urlSend = `outlet/${outletID}/reservation/chargesWindow`
    let options = new HttpInputData();
    return this.httpWrapper.get(urlSend, options)
      .map((response: any) => {
        return response;
      })
      // .catch((error: Response) => this.commonService.errorHandler(error));
  }
  outletOpenRestrictionsApiFun(outletID) {
    var urlSend
    urlSend = `getOutletOpenRestriction/${outletID}?newOutletTimings=true`
    let options = new HttpInputData();
    return this.httpWrapper.get(urlSend, options)
      .map((response: any) => {
        return response;
      })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }


  outletSectionGetApi(outletID) {
    var urlSend
    urlSend = `outlet/${outletID}/propertySections`
    let options = new HttpInputData();
    return this.httpWrapper.get(urlSend, options)
      .map((response: any) => {
        return response;
      })
      // .catch((error: Response) => this.commonService.errorHandler(error))
  }

outletTagGetApi(outletID) {
    var urlSend
    urlSend = `outlet/${outletID}/outletTags`
    let options = new HttpInputData();
    return this.httpWrapper.get(urlSend, options)
      .map((response: any) => {
        return response;
      })
      // .catch((error: Response) => this.commonService.errorHandler(error))
  }

  deleteOutletSection(outletId,sectionid ) {
    let options = new HttpInputData();
    let data = {
      "sectionId": sectionid, 
    }
    return this.httpWrapper.put('outlet' + '/' + outletId + '/deletePropertySections', data, options)
      .map((response: any) => {
        return response;
      })
     
  }


  outletCloseRestrictionsApiFun(outletID) {
    var urlSend
    urlSend = `getOutletCloseRestriction/${outletID}?newOutletTimings=true`
    let options = new HttpInputData();
    return this.httpWrapper.get(urlSend, options)
      .map((response: any) => {
        return response;
      })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }


  // outletOpenRestrictionsApiFun(outletID,selectdate) {


  //   let options = new HttpInputData();
  //   return this.httpWrapper.get(`getOutletOpenRestriction/${outletID}?selectedDate=${selectdate}`, options)

  //     .map((response: any) => {
  //       return response;
  //     })
  //     .catch((error: Response) => this.commonService.errorHandler(error))
  // } 


  mbowAPI() {


    let options = new HttpInputData();
    return this.httpWrapper.get(`outlet/a0Q9I000000zQK9UAM/deliverydashboard`, options)

      .map((response: any) => {
        return response;
      })
      .catch((error: Response) => this.commonService.errorHandler(error))
  }



  PostOutletCloseRestriction(outletSfId, closeData, delId) {
    // console.log("closeData",closeData)
    let sendClose = []
    closeData.forEach(element => {
      if (element.uniqueId == '') {
        sendClose.push({
          "endDate": element.endDate,
          "endTime": element.endTime,
          "startDate": element.startDate,
          "startTime": element.startTime,
          "weekday": element.weekday
        })
      }

    });
    // console.log("sendClose",sendClose)
    if (sendClose.length == 0) {
      sendClose = []
    }

    let data = {
      "deleted": delId,
      "new": sendClose
    }
    // console.log("data",data)
    let options = new HttpInputData();
    return this.httpWrapper.post(`${outletSfId}/createOutletCloseRestriction?newOutletTimings=true`, data, options)
      .map((response: any) => {
        return response;
      })
    // .catch((error: Response) => this.commonService.errorHandler(error))
  }

  PostReservationChargeFunc(outletSfId, openData, delId, reservationFee, isReservationCharges) {
    console.log("openData",outletSfId, openData, delId, reservationFee, isReservationCharges)
    let sendOpen = []
    openData.forEach(element => {
      if (element.uniqueId == '') {
        sendOpen.push({
          "endDate": element.endDate,
          "endTime": element.endTime,
          "startDate": element.startDate,
          "startTime": element.startTime,
          "weekday": element.weekday
        })
      }

    });
    // console.log("sendClose",sendOpen)
    if (sendOpen.length == 0) {
      sendOpen = []
    }
    if(reservationFee == undefined) {
      reservationFee = 0
    }
    let data = {
      "reservationFee": String(reservationFee) || '',
      "isReservationCharges":isReservationCharges,
      "deleted": delId,
      "new": sendOpen
    }
    // console.log("data",data)
    let options = new HttpInputData();
    return this.httpWrapper.post(`outlet/${outletSfId}/reservation/chargesWindow`, data, options)
      .map((response: any) => {
        return response;
      })
    // .catch((error: Response) => this.commonService.errorHandler(error))
  }
  

  PostOutletOpenRestriction(outletSfId, openData, delId) {
    // console.log("openData",openData)
    let sendOpen = []
    openData.forEach(element => {
      if (element.uniqueId == '') {
        sendOpen.push({
          "endDate": element.endDate,
          "endTime": element.endTime,
          "startDate": element.startDate,
          "startTime": element.startTime,
          "isFullDay": element.isFullDay
        })
      }

    });
    // console.log("sendClose",sendOpen)
    if (sendOpen.length == 0) {
      sendOpen = []
    }

    let data = {
      "deleted": delId,
      "new": sendOpen
    }
    // console.log("data",data)
    let options = new HttpInputData();
    return this.httpWrapper.post(`${outletSfId}/createOutletOpenRestriction?newOutletTimings=true`, data, options)
      .map((response: any) => {
        return response;
      })
    // .catch((error: Response) => this.commonService.errorHandler(error))
  }

  getTableUser(joinApiDetails) {
    let options = new HttpInputData();
    return this.httpWrapper.get(`getReservationTableData?tableNum=${joinApiDetails.tableSelectData.tableSfid}`, options)
      .map((response: any) => {
        return response;
      })
  }

  postReaasign(dataReaasign,tableDetails,memberId) {
    console.log("dataa", dataReaasign)
    console.log("tableDetails", tableDetails)
  
    let data = {
      "memberId": memberId,
      "assignedTableNum": tableDetails.tableSelectData.tableSfid,
      "newTableNum":dataReaasign.newTableSelect.tableSfid,
      "pax": dataReaasign.reaasignPax,
      "Reason":dataReaasign.reaasgignReason,
      "reservationId": tableDetails.tableSelectData.reservationId,
    }
    let options = new HttpInputData();
    return this.httpWrapper.put(`${appApiResources.reassign}?outletSfid=${tableDetails.outletSfid}&type=${tableDetails.tableType}`, data, options)
      .map((response: any) => {
        return response;
      })
  }

  resCancellationPeriod(outletid, data) {
    console.log("dataa", outletid, "data", data);
    let options = new HttpInputData();
    return this.httpWrapper.post('outlet/' + outletid + '/' +   appApiResources.resCancellationPeriod, data, options)
    .map((response: any) => {
      return response;
    })
    // .catch((error: Response) => this.commonService.errorHandler(error))
  }


  postTagApi(outletid, data) {
    console.log("dataa", outletid, "data", data);
    let options = new HttpInputData();
    return this.httpWrapper.post('outlet/' + outletid + '/' +   appApiResources.outletTags, data, options)
    .map((response: any) => {
      return response;
    })
    .catch((error: Response) => this.commonService.errorHandler(error))
  }

  postJoinMore(assignedTableNumSfid,joinTable,tableData) {
    console.log("joinTable", assignedTableNumSfid, "joinTable", joinTable)
    let joinArrySend = []
      joinTable.forEach(element => {
        joinArrySend.push({
          'tableSfid':element.moreTable.tableSfid,
          'pax':String(element.moreTablePax),
        })
      });

    let data = {
      "memberId":tableData.memberId,
      // "assignedTableNum": assignedTableNumSfid.tableSelectData.tableSfid,
      "reservationId": assignedTableNumSfid.tableSelectData.reservationId,
      "joinTablesData": joinArrySend,
     
      
    }
    let options = new HttpInputData();
    return this.httpWrapper.post(`joinTable?outletSfid=${assignedTableNumSfid.outletSfid}&type=${assignedTableNumSfid.tableType}`, data, options)
      .map((response: any) => {
        return response;
      })
  }

  assignTable(assignedTableNum,tableData,outletSfid,checkNo?:any,guestNameArry?:any) {
    
    if (guestNameArry == undefined) {
      guestNameArry = ''
    }
    console.log("assignedTableNum",assignedTableNum),
    console.log("tableData", tableData)
    let data = {
      "memberId":tableData.memberDetails.memberId,
      "assignedTableNum": assignedTableNum,
      "reservationId": tableData.reservationID || tableData.reservationId,
      "checkNumber":checkNo,
      "guestNames": String(guestNameArry),
    }
    let options = new HttpInputData();
    return this.httpWrapper.post(`${appApiResources.assignTable}?outletSfid=${outletSfid}`, data, options)
      .map((response: any) => {
        return response;
      })
  }

  postReaasignmanage(tableSfid,manageData,tableDetailsoutletSfid) {
   
  
    let data = {
      "memberId": manageData.memberId,
      "assignedTableNum": manageData.resTableSfid,
      "newTableNum":tableSfid,
      "pax":manageData.guestCount ,
      "Reason":'',
      "reservationId":manageData.reservationId,
    }
    let options = new HttpInputData();
    return this.httpWrapper.put(`${appApiResources.reassign}?outletSfid=${tableDetailsoutletSfid}`, data, options)
      .map((response: any) => {
        return response;
      })
  }



}

