<ion-header mode="ios" class="inner-header">
  <ion-toolbar mode="ios">
    <!-- <ion-buttons mode="ios" (click)="navigationBack()" slot="start">
      <ion-icon mode="ios" class="ca-back"></ion-icon>
    </ion-buttons> -->

    <!-- for order to be booked title -->
    <ion-title mode="ios" class="ion-text-wrap" *ngIf="orderType=='Orders to be Booked'"> {{titleOrderShow}} {{orderID}}
    </ion-title>

    <!-- for all, delivery and takeaway order title -->
    <!-- <ion-title mode="ios" class="ion-text-wrap" *ngIf="orderType!='Orders to be Booked' ">
      Cheque Number: {{allOrderInfo?.chequeNumber}}
      <p class="subtext">Order ID : {{orderID}}</p>
    </ion-title> -->
  </ion-toolbar>
  
</ion-header>




<!-- <ion-content [scrollEvents]="true" (ionScrollStart)="onScroll()" (click)="contentclicked()"> -->
  <ion-item-group *ngIf="loaded">
    <div *ngIf="orderType!='Orders to be Booked' " style="margin-top: 10px;">
      Cheque Number: {{allOrderInfo?.chequeNumber}}
      <p class="subtext">Order ID : {{orderID}}</p>
    </div>
    <ion-item lines="none" class="topTitle">
      <ion-label>
        <p class="label">{{'orderDetailsPage.DeliverAt' | translate}}</p>
        <h3 class="name">{{allOrderInfo?.deliveryDate |date:'dd,MMMM'}}, {{allOrderInfo?.deliveryTime}}</h3>
        <h3 class="placedTime">{{'orderDetailsPage.OrderPlaced' | translate}} {{allOrderInfo?.orderPlaced}}</h3>
      </ion-label>
    </ion-item>

    <ion-card class="default-card">
      <ion-card-content>
        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col size="6">
              <ion-item lines="none" class="ion-no-padding">
                <label class="ion-text-wrap">
                  <p class="label">{{'orderDetailsPage.Name' | translate}}</p>
                  <h3 class="name">{{allOrderInfo?.memberDetails?.salutation }}
                    {{allOrderInfo?.memberDetails?.FirstName}} {{allOrderInfo?.memberDetails?.lastName}}</h3>
                </label>
              </ion-item>
            </ion-col>
            <ion-col size="6">
              <ion-item lines="none" class="ion-no-padding">
                <label class="ion-text-wrap">
                  <p class="label">{{'orderDetailsPage.PhoneNumber' | translate}}</p>
                  <p class="label callText">
                    <ion-icon slot="start" color="primary" class="ca-call-icon"></ion-icon>
                    <span style="vertical-align: text-bottom;">+{{allOrderInfo?.memberDetails?.mobile}}</span>
                  </p>
                </label>
              </ion-item>
            </ion-col>
            <ion-col size="12" *ngIf="allOrderInfo?.orderType !='Takeaway' ">
              <ion-item lines="none" class="ion-no-padding address">
                <label class="ion-text-wrap">
                  <p class="label addressTitle">{{'orderDetailsPage.DeliveryAddress' | translate}}</p>
                  <p class="label">
                    {{allOrderInfo?.deliveryAddress?.addressLine1}}&nbsp;{{allOrderInfo?.deliveryAddress?.addressLine2}}&nbsp;{{allOrderInfo?.deliveryAddress?.landMark}}
                    <br>
                    {{allOrderInfo?.deliveryAddress?.city}},
                    {{allOrderInfo?.deliveryAddress?.state}}{{allOrderInfo?.deliveryAddress?.country}}
                    {{allOrderInfo?.deliveryAddress?.pincode}}
                  </p>
                </label>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>

    <ion-card class="default-card">
      <ion-card-content>
        <ion-grid class="ion-no-padding" *ngFor="let itemsofOrder of allOrderInfo?.deliveryAddress?.cartDetails ">
          <ion-row class="menu-item">
            <ion-col size="7">
              <div class="menu">
                <ion-img *ngIf="itemsofOrder?.itemType=='Non Veg'" src="assets/icon/non-veg-icon.svg"></ion-img>
                <ion-img *ngIf="itemsofOrder?.itemType=='Veg'|| itemsofOrder?.itemType== 'Vegan and Low Calorie' "
                  src="assets/icon/veg-icon.svg"></ion-img>
                <div class="menu-info">
                  <h4 class="item-name">{{itemsofOrder?.item_name}}</h4>
                  <h5 class="item-price">{{showCurrency.currencySymbol}} {{itemsofOrder?.unitPrice}}</h5>
                  <p *ngIf="itemsofOrder?.posDetails != '' " class="item-pos">POS: {{itemsofOrder?.posDetails}}</p>
                </div>
              </div>
            </ion-col>
            <ion-col size="3" class="ion-text-center">
              <span class="qnt">{{itemsofOrder?.quantity}}</span>
            </ion-col>
            <ion-col size="2" class="ion-text-right">
              <label class="item-toprice"> {{showCurrency.currencySymbol}}{{itemsofOrder?.totalPrice}}</label>
            </ion-col>
          </ion-row>

        </ion-grid>
      </ion-card-content>
    </ion-card>

    <ion-card class="default-card promoCard" *ngIf="allOrderInfo?.deliveryAddress?.specialInstructions?.cutlery == true || 
    allOrderInfo?.deliveryAddress?.occasion !='' || allOrderInfo?.deliveryAddress?.specialInstructions?.notes !=''|| 
    allOrderInfo?.deliveryAddress?.specialInstructions?.pickupInstructions !='' || allOrderInfo?.deliveryAddress?.specialInstructions?.audio !='' ">
      <ion-card-content>
        <ion-item *ngIf="allOrderInfo?.deliveryAddress?.specialInstructions?.cutlery == true" lines="full"
          class="ion-no-padding" style="--inner-padding-end: 0px">
          <ion-icon slot="start" color="primary" class="ca-dine"></ion-icon>
          <ion-label> {{'orderDetailsPage.AddCutlery' | translate}} </ion-label>
          <ion-icon slot="end" color="primary" class="ca-round-check"></ion-icon>
        </ion-item>
        <ion-item lines="none" class="ion-no-padding" *ngIf="allOrderInfo?.deliveryAddress?.occasion !=''">
          <ion-label>
            <p class="label">{{'orderDetailsPage.Occassion' | translate}} </p>
            <h4 class="name">{{allOrderInfo?.deliveryAddress?.occasion}}</h4>
          </ion-label>
        </ion-item>
        <ion-item lines="none" class="noPadding ion-no-padding"
          *ngIf="allOrderInfo?.deliveryAddress?.specialInstructions?.notes !='' ">
          <ion-label class="ion-text-wrap mr-0">
            <p class="label">{{'orderDetailsPage.SpecialInstructions' | translate}}
              <ion-icon id="click-trigger1" (click)="popover($event)" color="primary" class="ca-info"></ion-icon>
            </p>
            <span class="instructionText">
              {{allOrderInfo?.deliveryAddress?.specialInstructions?.notes}}
            </span>
          
          </ion-label>
          <ion-popover trigger="click-trigger1" showBackdrop="false" side="bottom" mode="ios" alignment="start"
            triggerAction="click">
            <ng-template>
              <ion-content>Instructions by the Guest.</ion-content>
            </ng-template>
          </ion-popover>
        </ion-item>
        <div class="audioPlayer ion-text-center"   *ngIf="allOrderInfo?.deliveryAddress?.specialInstructions?.audio !='' ">
          <ion-img class="audio-graph" src="../../../../assets/imgs/audio-graph-mbow-app.svg"></ion-img>
          <ion-button class="playButton" *ngIf="!isPlaying" (click)="playAudio('play')" fill="clear">
            <ion-icon color="primary" class="ca-play-icon"> </ion-icon>
          </ion-button>
             <ion-button class="playButton" *ngIf="isPlaying" (click)="playAudio('pause')" fill="clear">
            <ion-icon color="primary" class="ca-pause-icon"> </ion-icon>
          </ion-button>

        </div>
        <!-- Only for Takeaway case -->
        <ion-item lines="none" class="ion-no-padding"
          *ngIf="allOrderInfo?.deliveryAddress?.specialInstructions?.pickupInstructions !=''">
          <ion-label class="ion-text-wrap mr-0">
            <p class="label">{{'orderDetailsPage.PickupInstructions' | translate}}</p>
            <span class="instructionText">
              {{allOrderInfo?.deliveryAddress?.specialInstructions?.pickupInstructions}}
            </span>
          </ion-label>
        </ion-item>
      </ion-card-content>
    </ion-card>

    <ion-card class="default-card">
      <ion-card-content>
        <div
          *ngIf="allOrderInfo?.deliveryAddress?.promoCode != '' && allOrderInfo?.deliveryAddress?.promoCode != 'undefined' || allOrderInfo?.deliveryAddress?.transactionCode">
          <ion-label class="label" *ngIf="allOrderInfo?.deliveryAddress?.promoCode">
            {{'orderDetailsPage.PromoCodeApplied' | translate}}</ion-label>
          <ion-item lines="none" class="ion-no-padding">
            <ion-icon slot="start" color="primary" class="ca-promo-code"></ion-icon>
            <ion-label>
              <h4 *ngIf="allOrderInfo?.deliveryAddress?.promoCode">{{allOrderInfo?.deliveryAddress?.promoCode}} </h4>
              <!-- Showing when comes from Delivery, gift and Takeaway -->
              <p *ngIf="allOrderInfo?.deliveryAddress?.promoCode">
                {{allOrderInfo?.deliveryAddress?.promocodeDiscount}} {{'orderDetailsPage.DiscountApplied' | translate}}
              </p>
              <p>{{'orderDetailsPage.TransactionCode' | translate}} {{allOrderInfo?.deliveryAddress?.transactionCode}}
              </p>
            </ion-label>
          </ion-item>
        </div>
        <div>
          <ul class="price-list">
            <li class="price-row">
              <span>{{'orderDetailsPage.NetItemTotal' | translate}}</span>
              <span>{{showCurrency.currencySymbol}} {{allOrderInfo?.deliveryAddress?.netPrice}}</span>
            </li>
            <li class="price-row">
              <span>{{'orderDetailsPage.Tip' | translate}}</span>
              <span>{{showCurrency.currencySymbol}} {{allOrderInfo?.deliveryAddress?.tipAmount}}</span>
            </li>
            <li class="price-row">
              <span>{{'orderDetailsPage.DeliveryFee' | translate}}</span>
              <span>{{showCurrency.currencySymbol}} {{allOrderInfo?.deliveryAddress?.deliveryCharges}}</span>
            </li>
            <li class="price-row">
              <span>{{'orderDetailsPage.ConvenienceFee' | translate}}</span>
              <span>{{showCurrency.currencySymbol}} {{allOrderInfo?.deliveryAddress?.convenienceCharges}}</span>
            </li>
            <li class="price-row">
              <span>{{'orderDetailsPage.Discount' | translate}} <ng-container
                  *ngIf="allOrderInfo?.deliveryAddress?.promoCode"> ({{allOrderInfo?.deliveryAddress?.promoCode}})
                </ng-container></span>
              <span>({{showCurrency.currencySymbol}} {{allOrderInfo?.deliveryAddress?.discount}} )</span>
            </li>
          </ul>
          <ul class="price-list">
            <li class="price-row">
              <span>{{'orderDetailsPage.TotalAmount' | translate}}</span>
              <span>{{showCurrency.currencySymbol}} {{allOrderInfo?.deliveryAddress?.totalPrice}}</span>
            </li>
            <li class="price-row">
              <span>{{'orderDetailsPage.Taxes' | translate}}</span>
              <span>{{showCurrency.currencySymbol}} {{allOrderInfo?.deliveryAddress?.tax}}</span>
            </li>
          </ul>
          <ul class="price-list solid">
            <li class="price-row mb-0">
              <span>{{'orderDetailsPage.GrandTotal' | translate}}</span>
              <ion-text class="grantTotal" color="primary">{{showCurrency.currencySymbol}}
                {{allOrderInfo?.deliveryAddress?.finalPrice}}</ion-text>
            </li>
          </ul>
        </div>
      </ion-card-content>
    </ion-card>

    <!-- For All, Takeaway and Delivery Orders -->
    <!-- <p class="ion-text-center"><i>Send notification on order completion</i></p> -->
  </ion-item-group>

  <ion-item-group *ngIf="!loaded">
    <ion-item lines="none" class="topTitle">
      <ion-label>
        <ion-skeleton-text animated style="width: 40%; margin-bottom: 10px;"></ion-skeleton-text>
        <ion-skeleton-text animated style="width: 50%; margin-bottom: 10px;"></ion-skeleton-text>
        <ion-skeleton-text animated style="width: 50%;"></ion-skeleton-text>
      </ion-label>
    </ion-item>

    <ion-card class="default-card">
      <ion-card-content>
        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col size="6">
              <ion-skeleton-text animated style="width: 30%; margin-bottom: 10px;"></ion-skeleton-text>
              <ion-skeleton-text animated style="width: 80%; margin-bottom: 10px;"></ion-skeleton-text>
            </ion-col>
            <ion-col size="6">
              <ion-skeleton-text animated style="width: 50%; margin-bottom: 10px;"></ion-skeleton-text>
              <ion-skeleton-text animated style="width: 80%; margin-bottom: 10px;"></ion-skeleton-text>
            </ion-col>
            <ion-col size="12" style="margin-top: 20px;">
              <label class="ion-text-wrap">
                <ion-skeleton-text animated style="width: 40%; margin-bottom: 15px;"></ion-skeleton-text>
                <p class="label">
                  <ion-skeleton-text animated style="width: 80%; margin-bottom: 10px;"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 60%; margin-bottom: 10px;"></ion-skeleton-text>
                </p>
              </label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>

    <ion-card class="default-card">
      <ion-card-content>
        <ion-grid class="ion-no-padding">
          <ion-row class="menu-item">
            <ion-col size="7">
              <div class="menu" style="align-items: baseline;">
                <ion-thumbnail slot="start" style="width: 30px;">
                  <ion-skeleton-text [animated]="true" style="width: 15px; height: 15px;"></ion-skeleton-text>
                </ion-thumbnail>
                <div class="menu-info" style="width: 100%;">
                  <h4 class="item-name">
                    <ion-skeleton-text [animated]="true" style="width: 80%; margin-bottom: 10px;"></ion-skeleton-text>
                  </h4>
                  <h5 class="item-price">
                    <ion-skeleton-text [animated]="true" style="width: 30%; margin-bottom: 10px;"></ion-skeleton-text>
                  </h5>
                  <p class="item-pos">
                    <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                  </p>
                </div>
              </div>
            </ion-col>
            <ion-col size="3" class="ion-text-center">
              <span class="qnt">
                <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
              </span>
            </ion-col>
            <ion-col size="2" class="ion-text-right">
              <label class="item-toprice">
                <ion-skeleton-text [animated]="true" style="width: 80%; margin-left: auto; margin-bottom: 10px;">
                </ion-skeleton-text>
              </label>
            </ion-col>
          </ion-row>
          <ion-row class="menu-item">
            <ion-col size="7">
              <div class="menu" style="align-items: baseline;">
                <ion-thumbnail slot="start" style="width: 30px;">
                  <ion-skeleton-text [animated]="true" style="width: 15px; height: 15px;"></ion-skeleton-text>
                </ion-thumbnail>
                <div class="menu-info" style="width: 100%;">
                  <h4 class="item-name">
                    <ion-skeleton-text [animated]="true" style="width: 80%; margin-bottom: 10px;"></ion-skeleton-text>
                  </h4>
                  <h5 class="item-price">
                    <ion-skeleton-text [animated]="true" style="width: 30%; margin-bottom: 10px;"></ion-skeleton-text>
                  </h5>
                  <p class="item-pos">
                    <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                  </p>
                </div>
              </div>
            </ion-col>
            <ion-col size="3" class="ion-text-center">
              <span class="qnt">
                <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
              </span>
            </ion-col>
            <ion-col size="2" class="ion-text-right">
              <label class="item-toprice">
                <ion-skeleton-text [animated]="true" style="width: 80%; margin-left: auto; margin-bottom: 10px;">
                </ion-skeleton-text>
              </label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>

    <ion-card class="default-card">
      <ion-card-content>
        <div>
          <ion-label class="label">
            <ion-skeleton-text [animated]="true" style="width: 40%;"></ion-skeleton-text>
          </ion-label>
          <ion-item lines="none" class="ion-no-padding" style="align-items: baseline;">
            <ion-thumbnail style="width: 40px;">
              <ion-skeleton-text [animated]="true" style="width: 30px; height: 30px;"></ion-skeleton-text>
            </ion-thumbnail>
            <ion-label>
              <h4>
                <ion-skeleton-text [animated]="true" style="width: 20%;"></ion-skeleton-text>
              </h4>
              <p>
                <ion-skeleton-text [animated]="true" style="width: 40%;"></ion-skeleton-text>
              </p>
              <p>
                <ion-skeleton-text [animated]="true" style="width: 60%;"></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>
        </div>
        <div>
          <ul class="price-list">
            <li class="price-row">
              <span style="width: 50%;">
                <ion-skeleton-text [animated]="true" style="width: 40%;"></ion-skeleton-text>
              </span>
              <span style="width: 50%;">
                <ion-skeleton-text [animated]="true" style="width: 40%; margin-left: auto;"></ion-skeleton-text>
              </span>
            </li>
            <li class="price-row">
              <span style="width: 50%;">
                <ion-skeleton-text [animated]="true" style="width: 30%;"></ion-skeleton-text>
              </span>
              <span style="width: 50%;">
                <ion-skeleton-text [animated]="true" style="width: 30%; margin-left: auto;"></ion-skeleton-text>
              </span>
            </li>
            <li class="price-row">
              <span style="width: 50%;">
                <ion-skeleton-text [animated]="true" style="width: 35%;"></ion-skeleton-text>
              </span>
              <span style="width: 50%;">
                <ion-skeleton-text [animated]="true" style="width: 35%; margin-left: auto;"></ion-skeleton-text>
              </span>
            </li>
            <li class="price-row">
              <span style="width: 50%;">
                <ion-skeleton-text [animated]="true" style="width: 40%;"></ion-skeleton-text>
              </span>
              <span style="width: 50%;">
                <ion-skeleton-text [animated]="true" style="width: 40%; margin-left: auto;"></ion-skeleton-text>
              </span>
            </li>
            <li class="price-row">
              <span style="width: 50%;">
                <ion-skeleton-text [animated]="true" style="width: 60%;"></ion-skeleton-text>
              </span>
              <span style="width: 50%;">
                <ion-skeleton-text [animated]="true" style="width: 40%; margin-left: auto;"></ion-skeleton-text>
              </span>
            </li>
          </ul>
          <ul class="price-list">
            <li class="price-row">
              <span style="width: 50%;">
                <ion-skeleton-text [animated]="true" style="width: 40%;"></ion-skeleton-text>
              </span>
              <span style="width: 50%;">
                <ion-skeleton-text [animated]="true" style="width: 40%; margin-left: auto;"></ion-skeleton-text>
              </span>
            </li>
            <li class="price-row">
              <span style="width: 50%;">
                <ion-skeleton-text [animated]="true" style="width: 30%;"></ion-skeleton-text>
              </span>
              <span style="width: 50%;">
                <ion-skeleton-text [animated]="true" style="width: 30%; margin-left: auto;"></ion-skeleton-text>
              </span>
            </li>
          </ul>
          <ul class="price-list solid">
            <li class="price-row mb-0">
              <span style="width: 50%;">
                <ion-skeleton-text [animated]="true" style="width: 40%;"></ion-skeleton-text>
              </span>
              <span style="width: 50%;">
                <ion-skeleton-text [animated]="true" style="width: 50%; margin-left: auto;"></ion-skeleton-text>
              </span>
            </li>
          </ul>
        </div>
      </ion-card-content>
    </ion-card>
  </ion-item-group>
<!-- </ion-content> -->


<ion-footer [hidden]="!showPostedToPos">
  <ion-button mode="ios" id="trigger-button" *ngIf="orderStatusPass =='Posted to POS' "
    class="btn-default primary-gradient" expand="block" [disabled]="!enablePos"> {{'orderDetailsPage.PostedtoPOS' | translate}}
  </ion-button>
  <!-- For All, Takeaway and Delivery Orders -->
  <ion-button mode="ios" (click)="confirmCheckNo()" class="btn-default primary-gradient"
    *ngIf="orderStatusPass !='Posted to POS' " expand="block"> {{'orderDetailsPage.SendNotification' | translate}}
  </ion-button>
</ion-footer>

<ion-modal mode="ios" trigger="trigger-button" handle="false" [breakpoints]="[0.23, 0.23, 0.23]"
  [initialBreakpoint]="0.23">
  <ng-template>
    <ion-card class="modalWrap">
      <ion-card-content class="modalContent">
        <form>
          <ion-item mode="ios" class="inputWrap">
            <ion-input mode="ios" placeholder="Cheque number" [(ngModel)]="chequeNoOrder"
              [ngModelOptions]="{standalone: true}"></ion-input>
          </ion-item>
          <ion-item mode="ios" class="inputWrap">
            <ion-button mode="ios" expand="block" [disabled]="!chequeNoOrder" class="ion-no-margin primary-gradient"
              (click)="confirmCheckNo()">
              {{'orderDetailsPage.Confirm' | translate}}
            </ion-button>
          </ion-item>
        </form>
      </ion-card-content>
    </ion-card>
  </ng-template>
</ion-modal>