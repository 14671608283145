<ion-card [ngSwitch]="queryKind" [ngClass]="{'contentWidth2': show == 'true'}" class="default-card query_card">
  <ion-item class="height-auto query_title" lines="full">
    <ion-label *ngSwitchCase="'activationQuery'" class="ion-text-wrap" color="medium">
      {{'queryPage.for_activation_related_queries' | translate}}
    </ion-label>
    <ion-label *ngSwitchCase="'loginQuery'" class="ion-text-wrap" color="medium">
      {{'queryPage.for_login_related_queries' | translate}} </ion-label>
    <ion-label *ngSwitchCase="'resetQuery'" class="ion-text-wrap" color="medium">
      {{'queryPage.for_login_related_queries' | translate}} </ion-label>
  </ion-item>
  <ion-buttons class="ion-justify-content-center queryContent">
    <ion-button color="medium" expand="block"
      href="tel:{{appStateData?.appInitData?.contactUs?.employeeActivation?.call}}">
      <ion-icon color="medium" slot="icon-only" class="ca-call"></ion-icon>
      {{'queryPage.call' | translate}}
    </ion-button>
    <ion-button color="medium" expand="block" (click)="navigateToChat()">
      <ion-icon color="medium" slot="icon-only" class="ca-chat"></ion-icon>
      {{'queryPage.chat' | translate}}
    </ion-button>
  </ion-buttons>
</ion-card>