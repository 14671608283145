

  <div class="topTitle" class="buffet-button" *ngIf="membershipDetails?.benefitType == 'memberBenefits' && membershipDetails?.memberBenefit
  || membershipDetails?.benefit == 'Promocode' ">
    {{membershipDetails?.memberBenefit?.aemData?.certificateName || membershipDetails?.memberBenefit?.aemData?.cardBenefitName ||benefitDetails?.certificateName}}
  </div>

  <!-- <h1 class="dine-title" *ngIf="serviceType === staticData.appDine">
    <ion-icon class="ca-dine-icon"></ion-icon>{{showService}} {{'dinningReservation.at' | translate}}
    {{selectedOutlet?.sfdcData?.outletName}}
  </h1>
  <h1 class="dine-title" *ngIf="serviceType === staticData.appSpa">
    <ion-icon class="ca-spa"></ion-icon>{{showService}} {{'dinningReservation.at' | translate}}
    {{selectedOutlet?.sfdcData?.outletName}}
  </h1>
  <h1 class="dine-title" *ngIf="serviceType === staticData.pool">
    <ion-icon class="ca-swimming"></ion-icon>{{showService}} {{'dinningReservation.at' | translate}}
    {{selectedOutlet?.sfdcData?.outletName}}
  </h1> -->

  <!-- Treatment Card For Spa -->
  <!-- <ion-card class="default-card treatment-card" *ngIf="serviceType == staticData.appSpa">
    <ion-card-header>
      <ion-card-subtitle>{{'dinningReservation.treatment' | translate}}</ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <ion-item class="date-item" lines="none">
        <ion-buttons slot="start">
          <ion-button color="primary" fill="outline"
            [ngClass]="{'selected-option':spaOptionChoosed == staticData.therapy}"
            (click)="getSpaTreatment(staticData.therapy)">{{'dinningReservation.therapy' | translate}}
          </ion-button>
          <ion-button color="primary" fill="outline"
            [ngClass]="{'selected-option':spaOptionChoosed == staticData.beauty}"
            (click)="getSpaTreatment(staticData.beauty)">{{'dinningReservation.beauty' | translate}}
          </ion-button>
          <ion-button color="primary" fill="outline"
            [ngClass]="{'selected-option':spaOptionChoosed == staticData.other}"
            (click)="getSpaTreatment(staticData.other)">{{'dinningReservation.other' | translate}}
          </ion-button>
        </ion-buttons>
      </ion-item>
    </ion-card-content>
  </ion-card> -->

  <!-- Date Card -->
  <ion-card class="default-card date-card">
    <ion-card-header>
      <ion-card-subtitle>{{'openReservation.choosePreferredDate' | translate}}</ion-card-subtitle>
      <ion-card-title>{{reservationDateTime | date: 'EEEE, MMMM dd, yyyy'}}</ion-card-title>
      <input class="datetimeValue" mbsc-datepicker #picker="mobiscroll" [controls]="['date']"
        dateFormat="DDD, DD MMM YYYY" [touchUi]="true" [showOnClick]="false" hidden [showOnFocus]="false" disabled 
        (onChange)="getDate('other', $event)" />
    </ion-card-header>
    <ion-card-content>
      <ion-item class="date-item" lines="none">
        <ion-buttons slot="start">
          <ion-button color="primary" [fill]="selectedDate == todayDate ? 'solid' : 'outline'"
            (click)="getDate('today')">{{'openReservation.today' | translate}}</ion-button>
          <ion-button color="primary" [fill]="selectedDate == tomorrowDate ? 'solid' : 'outline'"
            (click)="getDate('tomorrow')">{{'openReservation.tomorrow' | translate}}</ion-button>
        </ion-buttons>
        <!-- <ion-datetime mode="ios" slot="end" class="calenderDateTime" (ionChange)="getDate('other', $event.detail.value)"
          monthNames="{{ monthShortNames }}" displayFormat="DD MMMM YYYY" [min]="" max="2050-08-23">
        </ion-datetime> -->
        <ion-icon slot="end" (click)="openPicker()" color="primary" class="ca-calendar-yellow-icon"></ion-icon>
      </ion-item>
    </ion-card-content>
  </ion-card>

  <!-- Time Card -->
  <ion-card class="default-card time-card">
    <ion-card-header>
      <ion-card-subtitle>{{'openReservation.preferredTime' | translate}}</ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <ion-item lines="none">
        <div class="selectWrapBox">
          <ion-select mode="md" (ionChange)="getTime('hour')" [interfaceOptions]="hoursInterfaceOptions"
            [(ngModel)]="hour">
            <ion-select-option *ngFor="let hour of hours" [value]="hour">{{hour}}</ion-select-option>
          </ion-select>
          <ion-icon color="primary" class="ca-down-arrow-yellow-icon select-arrow"></ion-icon>
        </div>
        <span class="colon">:</span>
        <div class="selectWrapBox">
          <!-- <ion-label style="display: none;">Minutes</ion-label> -->
          <ion-select mode="md" (ionChange)="getTime('minute')" [interfaceOptions]="minutesInterfaceOptions"
            [(ngModel)]="minute">
            <ion-select-option *ngFor="let minute of minutes" [value]="minute">{{minute}}</ion-select-option>
          </ion-select>
          <ion-icon color="primary" class="ca-down-arrow-yellow-icon select-arrow"></ion-icon>
        </div>
        <ion-radio-group mode="ios" (ionChange)="getTime('am-pm')" class="radioTime" [(ngModel)]="amOrPm">
          <ion-item mode="ios" lines="none">
            <ion-radio class="postionPm" mode="md" value="PM" slot="start">
            </ion-radio>
            <span>{{'openReservation.PM' | translate}}</span>
          </ion-item>
          <ion-item mode="ios" lines="none">
            <ion-radio class="radioPostion" mode="md" value="AM" slot="start">
            </ion-radio>
            <span>{{'openReservation.AM' | translate}}</span>
          </ion-item>
        </ion-radio-group>
      </ion-item>
    </ion-card-content>
  </ion-card>

  <!-- Duration Card For Spa -->
  <!-- <ion-card class="default-card time-card" *ngIf="serviceType == 'Spa'">
    <ion-card-header>
      <ion-card-subtitle>{{'dinningReservation.duration' | translate}}</ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <ion-item class="date-item" lines="none">
        <div class="selectWrapBox">
          <ion-label style="display: none;">{{'dinningReservation.selectDuration' | translate}}</ion-label>
          <ion-select mode="md" value="30" [(ngModel)]="spaDuration">
            <ion-select-option value="30">30</ion-select-option>
            <ion-select-option value="60">60</ion-select-option>
            <ion-select-option value="90">90</ion-select-option>
            <ion-select-option value="120">120</ion-select-option>
          </ion-select>
          <ion-icon color="primary" class="ca-down-arrow-yellow-icon select-arrow"></ion-icon>
        </div>
        <span class="minuts">{{'planWith.minutes' | translate}}</span>
      </ion-item>
    </ion-card-content>
  </ion-card> -->

  <!-- Guest Card -->
  <ion-card class="default-card guest-card">
    <!-- No of Guest -->
    <ion-card-header>
      <ion-card-subtitle>{{'openReservation.selectGuest' | translate}}</ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <ion-item lines="none">
        <ion-label slot="start" class="ion-text-wrap guest_count">
          {{numberOfGuest + '\t' + ('openReservation.guest' | translate)}}
        </ion-label>
        <ion-buttons slot="end">
          <ion-button color="primary" fill="outline" (click)="countGuest(numberOfGuest - 1)">
            <ion-icon mode="ios" name="remove"></ion-icon>
          </ion-button>
          <ion-button color="primary" fill="outline" (click)="countGuest(numberOfGuest + 1)">
            <ion-icon mode="ios" name="add"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>
    </ion-card-content>

    <!-- No of Adults -->
    <!-- <ion-card-header *ngIf="serviceType == staticData.pool">
      <ion-card-subtitle>{{'dinningReservation.adultNumber' | translate}}</ion-card-subtitle>
    </ion-card-header>
    <ion-card-content *ngIf="serviceType == staticData.pool">
      <ion-item lines="none">
        <ion-label slot="start" class="ion-text-wrap guest_count">{{numberOfAdult}} {{adult}}
        </ion-label>
        <ion-buttons slot="end">
          <ion-button color="primary" fill="outline"
            (click)="countGuestNumber(staticData.pool,staticData.sub,staticData.adult)">
            <ion-icon mode="ios" name="remove"></ion-icon>
          </ion-button>
          <ion-button color="primary" fill="outline"
            (click)="countGuestNumber(staticData.pool,staticData.add,staticData.adult)">
            <ion-icon mode="ios" name="add"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>
    </ion-card-content> -->

    <!-- No of Child -->
    <!-- <ion-card-header *ngIf="serviceType == staticData.pool">
            <ion-card-subtitle>{{'dinningReservation.kidNumber' | translate}}</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content *ngIf="serviceType == staticData.pool">
            <ion-item lines="none">
                <ion-label slot="start" class="ion-text-wrap guest_count">{{numberOfKids}} {{kid}}
                </ion-label>
                <ion-buttons slot="end">
                    <ion-button color="primary" fill="outline"
                        (click)="countGuestNumber(staticData.pool,staticData.sub,staticData.kid)">
                        <ion-icon mode="ios" name="remove"></ion-icon>
                    </ion-button>
                    <ion-button color="primary" fill="outline"
                        (click)="countGuestNumber(staticData.pool,staticData.add,staticData.kid)">
                        <ion-icon mode="ios" name="add"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-item>
        </ion-card-content> -->

    <ion-card-content class="guestInfo">
      <ion-item lines="none">
        <ion-label position="stacked" class="ion-text-wrap">{{'openReservation.tellUsMore' |translate}}
        </ion-label>
        <ion-buttons class="celebrationBtns">
          <ion-button color="primary" (click)="selectCelebration(celebrations.appBirthday)"
            [fill]="celebration == celebrations.appBirthday ? 'solid' : 'outline'">
            {{'openReservation.birthday' | translate}}
          </ion-button>
          <ion-button color="primary" (click)="selectCelebration(celebrations.appAnniversary)"
            [fill]="celebration == celebrations.appAnniversary ? 'solid' : 'outline'">
            {{'openReservation.anniversary' | translate}}
          </ion-button>
          <ion-button color="primary" (click)="selectCelebration(celebrations.appOther)"
            [fill]="celebration == celebrations.appOther ? 'solid' : 'outline'">
            {{'openReservation.other' | translate}}
          </ion-button>
        </ion-buttons>
      </ion-item>
      <ion-item lines="full" class="borderColer">
        <ion-input mode="ios" type="text" [(ngModel)]="selectedCelebDescription" autocapitalize
          [placeholder]="'openReservation.wouldYouLikeToTellMore' | translate">
        </ion-input>
      </ion-item>
    </ion-card-content>
  </ion-card>

  <!-- Preference Card -->
  <ion-card class="default-card preference-card">
    <ion-item-group>
      <ion-item lines="full" class="" (click)="openPreference('foodPreference', 'food-arrow')">
        <ion-icon slot="start" class="ca-food-pref-icon"></ion-icon>
        <ion-label class="ion-text-wrap">{{'openReservation.addFood' | translate}}</ion-label>
        <ion-icon slot="end" id="food-arrow" class="ca-down-arrow-yellow-icon">
        </ion-icon>
      </ion-item>
      <ion-list lines="full" id="foodPreference" style="display: none;">
        <ion-item *ngFor="let foodPreference of foodPreferences; let i = index">
          <div slot="start" class="custom-check">
            <input type="checkbox" [id]="'food-'+i" (change)="addPreference(foodPreference, 'food')"
              class="custom-check">
            <label for="{{'food-'+i}}"></label>
          </div>
          <ion-label>{{foodPreference?.value}} </ion-label>
        </ion-item>
      </ion-list>
    </ion-item-group>
    <ion-item-group>
      <ion-item lines="full" class="" (click)="openPreference('beveragePreference', 'beverage-arrow')">
        <ion-icon slot="start" class="ca-beverage-icon"></ion-icon>
        <ion-label class="ion-text-wrap">{{'openReservation.addBeverage' | translate}}</ion-label>
        <ion-icon slot="end" id="beverage-arrow" class="ca-down-arrow-yellow-icon">
        </ion-icon>
      </ion-item>
      <ion-list lines="full" id="beveragePreference" style="display: none;">
        <ion-item *ngFor="let beveragePreference of beveragePreferences; let j = index">
          <div slot="start" class="custom-check">
            <input type="checkbox" [id]="'beverage-'+j" (change)="addPreference(beveragePreference, 'beverage')"
              class="custom-check">
            <label for="{{'beverage-'+j}}"></label>
          </div>
          <ion-label>{{beveragePreference?.value}} </ion-label>
        </ion-item>
      </ion-list>
    </ion-item-group>
 
   <!-- Table prefrence  -->
   <ion-item-group *ngIf="outletDetails?.tablePreferences?.length ">
    <ion-item lines="full" class="" (click)="openPreference('tablePreference', 'table-arrow')">
      <ion-icon slot="start" class="ca-table-preference"></ion-icon>
      <ion-label class="ion-text-wrap">{{'openReservation.TablePre' | translate}}</ion-label>
      <ion-icon slot="end" id="table-arrow" class="ca-down-arrow-yellow-icon">
      </ion-icon>
    </ion-item>
    <ion-list lines="full" id="tablePreference" style="display: none;">
      <ion-item *ngFor="let tablePreData of outletDetails?.tablePreferences; let k = index">
        <div slot="start" class="custom-check">
            <input type="radio" [id]="'table-'+k" name="tablePreferences" [(ngModel)]="selectTablePreferences"
            [value]="tablePreData.preferenceid" (change)="addPreference(tablePreData.preferenceid,'tablePre')" />

          <label for="{{'table-'+k}}"></label>
        </div>
        <ion-label>{{tablePreData?.preferencename}} </ion-label>
      </ion-item>
    </ion-list>
  </ion-item-group>
</ion-card>

  <!-- Special Requestd -->
  <ion-card class="default-card guest-card">
    <ion-card-content>
      <ion-card-subtitle>{{'openReservation.specialRequest' | translate}}</ion-card-subtitle>
      <ion-item lines="full" class="borderColer">
        <ion-input type="text" autocapitalize mode="ios" [(ngModel)]="specialRequest" maxlength="250" placeholder="">
        </ion-input>
      </ion-item>
    </ion-card-content>
  </ion-card>

  <ion-button expand="block" mode="ios" [disabled]="reservationNotPossible" class="default-btn primary-gradient"
    (click)="checkRestrictionForBenefits()">{{'openReservation.confirm' | translate}}
  </ion-button>



<div id="guestCount" class="customModal">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <ion-buttons>
        <ion-icon slot="end" (click)="closeGuestCountModal(false)" class="ca-modal-close"></ion-icon>
      </ion-buttons>
      <div class="customModal-body ion-text-center">
        <div class="modalIcons">
          <!-- <ion-icon color="primary" class="ca-call-service-icon" *ngIf="!showGuestVali"></ion-icon> -->
          <ion-icon color="primary" class="ca-stroke-dine"></ion-icon>
        </div>
        <p class="membership-disc">{{guestCountPara}}</p>
      </div>
      <div class="customModal-footer">
        <ion-button mode="ios" class="customModalButton primary-gradient" expand="full"
          (click)="closeGuestCountModal(true)">
          {{'openReservation.cont' | translate}}
        </ion-button>
      </div>
    </div>
  </div>
</div>

<div id="outletNotOperationModal" class="customModal">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <ion-buttons>
        <ion-icon slot="end" (click)="closeOutletNotOperationModal()" class="ca-modal-close"></ion-icon>
      </ion-buttons>
      <div class="customModal-body ion-text-center">
        <div class="modalIcons">
          <ion-icon color="primary" class="ca-activity-off-icon"></ion-icon>
        </div>
        <p class="membership-disc">{{outletNotOperationPara}}</p>
      </div>
      <div class="customModal-footer">
        <ion-button mode="ios" class="customModalButton primary-gradient" expand="full"
          (click)="closeOutletNotOperationModal()">
          {{'openReservation.got_it' | translate}}
        </ion-button>
      </div>
    </div>
  </div>
</div>

<div id="currentDateTimeReservationModal" class="customModal">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <ion-buttons>
        <ion-icon slot="end" (click)="closeCurrentDateTimeReservationModal()" class="ca-modal-close"></ion-icon>
      </ion-buttons>
      <div class="customModal-body ion-text-center">
        <div class="modalIcons">
          <ion-icon color="primary" class="ca-call-service-icon"></ion-icon>
        </div>
        <p class="membership-disc">{{currentDateTimeReservationPara}}</p>
      </div>
      <div class="customModal-footer">
        <ion-button mode="ios" class="customModalButton primary-gradient" expand="full"
          href="tel:{{outletDetails?.outletHelpdeskNumber}}" (click)="closeCurrentDateTimeReservationModal()">
          {{'openReservation.call_Now' | translate}}
        </ion-button>
      </div>
    </div>
  </div>
</div>

<div id="guestCountForOutlet" class="customModal">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <ion-buttons>
        <ion-icon slot="end" (click)="closeGuestCountModalForOutlet(false)" class="ca-modal-close"></ion-icon>
      </ion-buttons>
      <div class="customModal-body ion-text-center">

        <div class="customModal-text">
          <div class="modalIcons">
            <ion-icon color="primary" class="ca-stroke-dine"></ion-icon>
          </div>
          <p>{{guestCountParaForOutlet}}</p>
        </div>
      </div>
      <div class="customModal-footer two-btn">
        <ion-button mode="ios" class="customModalButton primary-gradient" expand="full"
          href="tel:{{outletDetails?.outletHelpdeskNumber}}">
          {{'openReservation.call_Now' | translate}}
        </ion-button>
        <ion-button mode="ios" class="customModalButton light-gradient" expand="full"
          (click)="closeGuestCountModalForOutlet(true)">
          {{'openReservation.cont' | translate}} </ion-button>
      </div>
    </div>
  </div>
</div>

<div id="certificateNotApplicableModal" class="customModal">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <ion-buttons>
        <ion-icon slot="end" (click)="closeCertificateNotApplicableModal(false)" class="ca-modal-close"></ion-icon>
      </ion-buttons>
      <div class="customModal-body ion-text-center">

        <div class="customModal-text">
          <div class="modalIcons">
            <ion-icon color="primary" class="ca-stroke-dine"></ion-icon>
          </div>
          <p>{{certificateNotApplicablePara}}</p>
        </div>
      </div>
      <div class="customModal-footer two-btn">
        <ion-button mode="ios" class="customModalButton primary-gradient" expand="full"
          (click)="closeCertificateNotApplicableModal(false)">
          {{'openReservation.back' | translate}}
        </ion-button>
        <ion-button mode="ios" class="customModalButton light-gradient" expand="full"
          (click)="closeCertificateNotApplicableModal(true)">
          {{'openReservation.cont' | translate}} </ion-button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="customModal" id="confirmReservation">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <!-- <ion-buttons>
        <ion-icon slot="end" class="ca-modal-close"></ion-icon>
      </ion-buttons> -->
      <div class="customModal-body ion-text-center">
        <div class="reservation-confirm-customModal-text">
          <h2 style="margin-bottom: 20px;font-weight: bolder;font-size: 14px;"
            [innerHTML]="reservationConfirmationPara"></h2>
          <p>{{confirmationNumberPara}}</p>
        </div>
      </div>
      <div class="customModal-footer">
        <ion-button mode="ios" class="customModalButton primary-gradient" (click)="proceed()" expand="full">
          {{'openReservation.got_it' | translate}}
        </ion-button>
      </div>
    </div>
  </div>
</div>