<div class="modalBg">
  <ion-card class="modalWrap">
    <ion-card-header class="modalHeader">
      <h2 class="modalTitle" *ngIf="editDataValue=='email'">{{'email_edit_mobile.email_update'|translate}}</h2>
      <h2 class="modalTitle" *ngIf="editDataValue=='mobile'">{{'email_edit_mobile.update_mobile_number'|translate}}</h2>
      <ion-icon class="modalClose ca-close-icon" (click)="closeModal()"></ion-icon>
    </ion-card-header>
    <form [formGroup]="editForm">
      <ion-card-content class="modalContent">

        <!-- Email Field -->
        <div class="formField" *ngIf="emailFeildShow">
          <ion-item mode="ios" lines="none">
            <ion-label mode="ios" class="input-label" position="stacked">{{'email_edit_mobile.enter_email'|translate}}</ion-label>
            <ion-input mode="ios" required type="email" formControlName="email"> </ion-input>
          </ion-item>
          <ion-item *ngIf="email.invalid && (email.touched)" class="height-auto" lines="none">
            <ion-text *ngIf="email.errors.required" class="form-error">
              {{'email_edit_mobile.enter_email_address'|translate}}
            </ion-text>
            <ion-text *ngIf="email.errors.pattern" class="form-error">
              {{'email_edit_mobile.valid_email_address'|translate}}
            </ion-text>
          </ion-item>
        </div>

        <!-- Mobile Number Field -->
        <div class="formField" *ngIf="phoneFeildShow">
          <ion-item mode="ios" lines="none">
            <ion-label mode="ios" class="input-label" position="stacked">{{'email_edit_mobile.mobile_number'|translate}}</ion-label>
            <div class="phoneWrap">
              <p class="countryValue">{{SelectCode}} <ion-icon class="ca-down-arrow-yellow-icon"></ion-icon>
              </p>
              <ion-select mode="md" class="countryCode" formControlName="countryCodeSelected"
                (ionChange)="CountryCodeChange()" cancelText="{{'email_edit_mobile.cancel'|translate}}" okText="{{'email_edit_mobile.ok'|translate}}">
                <ion-select-option *ngFor="let country of countries" [value]="country">
                  {{ country.dialCode }}
                  {{ country.name }}
                </ion-select-option>
              </ion-select>
              <ion-input mode="ios" type="tel" [maxlength]="17" formControlName="mobile"></ion-input>
            </div>
          </ion-item>
          <ion-item *ngIf="(editForm.errors && editForm.errors.isInvalidNumber) && editForm.controls.mobile.touched"
            class="height-auto" lines="none">
            <ion-text class="form-error" color="danger">
              {{'email_edit_mobile.valid_email_address'|translate}}
            </ion-text>
          </ion-item>
        </div>
      </ion-card-content>

      <!-- OTP Field -->
      
      <app-otp  *ngIf="!editForm.invalid" [sendDataForOtpData]="employeeDetails" [otpType]="editDataValue"
        (emailEditProfileUpdate)="updateEmplValueCheck($event)" [hideValidation]="editProfilePage"></app-otp>
     
      <!-- Update Button -->
      <ion-button mode="ios" expand="block" [disabled]="!updateEditProfileEnable"
        class="modalButton ion-no-margin primary-gradient" (click)="updatEmployeeApi()"> {{'email_edit_mobile.update'|translate}}
      </ion-button>
    </form>
  </ion-card>
</div>