<div class="feedbackContent">
  <h1 class="page-title for-web">Feedback & Response</h1>

  <ion-card class="default-card ratingCard">
    <!-- <ion-item (click)="hideContentView()" lines="none"
        *ngIf="ionicStorage?.userPageAccess?.userPageAccess?.includes('GET_FEEDBACK_COUNT')">
        <ion-label slot="start" class="fontSize">{{'feedBackPage.outlet_rating'| translate}}</ion-label>
        <h2 slot="end">{{outletResponse?.outletRatings?.averageRating}}&nbsp;<ion-icon class="ca-star-outline">
          </ion-icon>
        </h2>
        <ion-icon slot="end" color="primary" *ngIf="!hideContent" class="ca-down-arrow-yellow-icon"></ion-icon>
        <ion-icon slot="end" color="primary" *ngIf="hideContent" class="ca-up-arrow-yellow-icon"></ion-icon>
      </ion-item> -->
    <ion-item (click)="hideContentView()" lines="none">
      <ion-label slot="start" class="fontSize">{{'feedBackPage.outlet_rating'| translate}}</ion-label>
      <h2 slot="end">{{outletResponse?.outletRatings?.averageRating}}&nbsp;<ion-icon class="ca-star-outline">
        </ion-icon>
      </h2>
      <ion-icon slot="end" color="primary" *ngIf="!hideContent" class="ca-down-arrow-yellow-icon"></ion-icon>
      <ion-icon slot="end" color="primary" *ngIf="hideContent" class="ca-up-arrow-yellow-icon"></ion-icon>
    </ion-item>

    <ion-list lines="full" class="ion-no-padding ion-no-margin">
      <ion-item *ngIf="hideContent">
        <ion-label class="ion-text-wrap">{{outletResponse?.outletRatings?.fiveStarCount}}
          {{'feedBackPage.member'|translate}}
        </ion-label>
        <h6 slot="end" class="ion-text-wrap">{{'feedBackPage.five'|translate}}
          <ion-icon class="ca-star-outline smallStar"></ion-icon>
        </h6>
      </ion-item>
      <ion-item *ngIf="hideContent">
        <ion-label class="ion-text-wrap">{{outletResponse?.outletRatings?.fourStarCount}}
          {{'feedBackPage.member'|translate}}
        </ion-label>
        <h6 slot="end" class="ion-text-wrap">{{'feedBackPage.four'|translate}}
          <ion-icon class="ca-star-outline smallStar"></ion-icon>
        </h6>
      </ion-item>
      <ion-item *ngIf="hideContent">
        <ion-label class="ion-text-wrap">{{outletResponse?.outletRatings?.threeStarCount}}
          {{'feedBackPage.member'|translate}}
        </ion-label>
        <h6 slot="end" class="ion-text-wrap">{{'feedBackPage.three'|translate}}
          <ion-icon class="ca-star-outline smallStar"></ion-icon>
        </h6>
      </ion-item>
      <ion-item *ngIf="hideContent">
        <ion-label class="ion-text-wrap">{{outletResponse?.outletRatings?.twoStarCount}}
          {{'feedBackPage.member'|translate}}
        </ion-label>
        <h6 slot="end" class="ion-text-wrap">{{'feedBackPage.two'|translate}}
          <ion-icon class="ca-star-outline smallStar"></ion-icon>
        </h6>
      </ion-item>
      <ion-item *ngIf="hideContent">
        <ion-label class="ion-text-wrap">{{outletResponse?.outletRatings?.oneStarCount}}
          {{'feedBackPage.member'|translate}}
        </ion-label>
        <h6 slot="end" class="ion-text-wrap">{{'feedBackPage.one'|translate}}
          <ion-icon class="ca-star-outline smallStar"></ion-icon>
        </h6>
      </ion-item>
    </ion-list>
  </ion-card>

  <!-- https://tlcgroup.atlassian.net/browse/UATCH-3064 -->
  <!-- <ion-item lines="none" class="default-card ratingCard">
    <ion-label slot="start" class="fontSize">{{'feedBackPage.chef_compliment'| translate}}</ion-label>
    <h2 slot="end" class="labelFontSize">{{outletResponse?.complimentCount}}
      <ion-icon color="primary" class="ca-applauds-hand-icon"></ion-icon>
    </h2>
  </ion-item> -->

  <ion-item-group mode="md" class="customSearch">
    <ion-searchbar mode="md" class="custom-searchbar" [placeholder]="'memberSearchPage.placeholderName' | translate "
      showCancelButton="never" [(ngModel)]="filter">
    </ion-searchbar>
    <ion-label mode="md" class="searchIcon" (click)="searchApi()">
      <ion-icon class="ca-search-icon"></ion-icon>
    </ion-label>
  </ion-item-group>

  <ion-item lines="none" class="dateItem">
    <ion-buttons>
      <ion-button mode="ios" (click)="dateClick()">
        <ion-label *ngIf="disableSetDate">{{'feedBackPage.monthly' | translate}}</ion-label>
        <ion-label *ngIf="enableCalenderChange">{{ showsetModalData }}</ion-label>
        <ion-label *ngIf="enableDateFormet">{{ startDateShow }} - {{ endDateShow }}</ion-label>
        <ion-icon color="primary" class="ca-down-arrow-yellow-icon"></ion-icon>
      </ion-button>
      <ion-button (click)="openModalForRating()">
        {{'feedBackPage.rating'| translate}}
        <ion-icon color="primary" class="ca-down-arrow-yellow-icon"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-item>

  <ion-item-group *ngIf="!skelton">
    <ion-card class="default-card feedbackClass" *ngFor="let items of feedBackdata">
      <ion-item color="light" class="height-auto" lines="full">
        <ion-label slot="start">{{items?.feedbackGivenOnDate | date : 'dd MMMM '}}</ion-label>
      </ion-item>
      <ion-card-content>
        <ion-item-group *ngFor=" let feedBackData of  items.feedbackDetails ">
          <ion-item lines="none" class="ion-no-padding">
            <ion-label class="ion-text-wrap">
              <ion-text class="bigFont" color="medium">{{feedBackData?.starRating}}</ion-text>
              <ion-icon color="primary" class="ca-star-outline"></ion-icon>
              <ion-text color="primary">{{'feedBackPage.rating'| translate}}</ion-text>
            </ion-label>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding height-auto" *ngIf="feedBackData?.feedbackDescription">
            <ion-label color="medium" position="stacked" class="ion-text-wrap">
              <p>{{feedBackData?.feedbackDescription}}</p>
            </ion-label>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding">
            <!-- <ion-button
                *ngIf="feedBackData.responseToFeedback == '' && ionicStorage?.userPageAccess?.userPageAccess?.includes('POST_FEEDBACK_RESPONSE')"
                size="small" (click)="openModal('respond',feedBackData.feedbackID)" fill="outline">
                {{'feedBackPage.respond'| translate}}
              </ion-button> -->

            <ion-button *ngIf="feedBackData.responseToFeedback == ''" size="small"
              (click)="openModal('respond',feedBackData.feedbackID)" fill="outline">
              {{'feedBackPage.respond'| translate}}
            </ion-button>

            <!-- <ion-button size="small" (click)="openModal('respond',feedBackData.feedbackID)" fill="outline">
                {{'feedBackPage.respond'| translate}}
              </ion-button> -->

            <h2 slot="end" (click)="profile(feedBackData?.memberDetails?.membershipNumber)">
              {{feedBackData?.memberDetails?.member?.firstName}}&nbsp;{{feedBackData?.memberDetails?.member?.lastName}}
              <ion-icon color="warning" class="ca-star-filled"
                *ngIf="feedBackData?.memberDetails?.handleWithCare == 'true'">
              </ion-icon>
              <ion-icon color="success" class="ca-star-filled"
                *ngIf="feedBackData?.memberDetails?.highSpender == 'true'">
              </ion-icon>
            </h2>&nbsp;
            <ion-icon slot="end" color="primary" class="ca-right-arrow-icon"></ion-icon>
          </ion-item>
        </ion-item-group>

      </ion-card-content>
    </ion-card>

    <ion-infinite-scroll (ionInfinite)="doInfinite($event)">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ion-item-group>

  <!--skelton starts-->
  <ion-item-group *ngIf="skelton">
    <ion-card class="default-card feedbackClass">
      <ion-item color="light" class="height-auto" lines="full">
        <ion-label slot="start">
          <ion-skeleton-text animated width="35%"></ion-skeleton-text>
        </ion-label>
      </ion-item>
      <ion-card-content>

        <ion-item-group>
          <ion-item lines="none">
            <ion-label class="ion-text-wrap">
              <ion-skeleton-text animated width="40%"></ion-skeleton-text>
            </ion-label>
            <ion-skeleton-text slot="end" animated width="45%"></ion-skeleton-text>
          </ion-item>
          <ion-item lines="none">
            <ion-label color="medium" position="stacked">
              <p>
                <ion-skeleton-text animated width="100%"></ion-skeleton-text>
                <ion-skeleton-text animated width="98%"></ion-skeleton-text>
                <ion-skeleton-text animated width="95%"></ion-skeleton-text>
                <ion-skeleton-text animated width="90%"></ion-skeleton-text>
                <ion-skeleton-text animated width="50%"></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-skeleton-text slot="start" animated style="width: 23%; height: 34px; border-radius: 4px;">
            </ion-skeleton-text>
            <ion-skeleton-text slot="end" animated width="73%"></ion-skeleton-text>
          </ion-item>
        </ion-item-group>

        <ion-item-group>
          <ion-item lines="none">
            <ion-label>
              <ion-skeleton-text animated width="40%"></ion-skeleton-text>
            </ion-label>
            <ion-skeleton-text slot="end" animated width="45%"></ion-skeleton-text>
          </ion-item>
          <ion-item lines="none">
            <ion-label color="medium" position="stacked">
              <p>
                <ion-skeleton-text animated width="100%"></ion-skeleton-text>
                <ion-skeleton-text animated width="98%"></ion-skeleton-text>
                <ion-skeleton-text animated width="95%"></ion-skeleton-text>
                <ion-skeleton-text animated width="90%"></ion-skeleton-text>
                <ion-skeleton-text animated width="50%"></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-skeleton-text slot="start" animated style="width: 23%; height: 34px; border-radius: 4px;">
            </ion-skeleton-text>
            <ion-skeleton-text slot="end" animated width="73%"></ion-skeleton-text>
          </ion-item>
        </ion-item-group>

      </ion-card-content>
    </ion-card>
  </ion-item-group>
</div>
<!--skelton ends-->