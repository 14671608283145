<div class="modalBg">
  <ion-card class="modalWrap">
    <ion-card-header class="modalHeader">
      <ion-img class="logo" src="assets/imgs/mbow-logo.png"></ion-img>
      <h2 class="modalTitle">Menu</h2>
      <ion-button fill="clear" (click)="closeModal()">
        <ion-icon slot="icon-only" class="modalClose ca-close-icon" color="primary"></ion-icon>
      </ion-button>
    </ion-card-header>
    <ion-card-content class="modalContent">
      <ion-item-group class="widget-list" *ngFor="let item of menuAllData" >
        <ion-item lines="none" class="widget-title">
          <ion-label color="primary">{{item?.widgetName}}</ion-label>
        </ion-item>
        <ion-list mode="ios" class="catagory-list" lines="none">
          <div *ngFor="let itemCategory of item?.categoryData" >
          <ion-list-header class="catagory-title" >
            <ion-label>{{itemCategory?.categoryname}}</ion-label>
          </ion-list-header>
          <ion-item lines="none" mode="ios" class="menu-item" *ngFor="let itemAll of itemCategory?.itemDetails">
            <div class="menu">
              <ion-img *ngIf="itemAll.type =='Non Veg'" src="assets/icon/non-veg-icon.svg"></ion-img>
              <ion-img   *ngIf="itemAll.type =='Veg'" src="assets/icon/veg-icon.svg"></ion-img>
              <h4 class="item-name">{{itemAll?.itemName}}</h4>
            </div>
            <ion-text class="item-toprice" slot="end">{{showCurrency.currencySymbol}}{{itemAll?.price}}</ion-text>
          </ion-item>
        </div>
          <!-- <ion-item lines="none" mode="ios" class="menu-item">
            <div class="menu">
              <ion-img src="assets/icon/non-veg-icon.svg"></ion-img>
              <h4 class="item-name">Spicy Fish</h4>
            </div>
            <ion-text class="item-toprice" slot="end">1150</ion-text>
          </ion-item>
          <ion-item lines="none" mode="ios" class="menu-item">
            <div class="menu">
              <ion-img src="assets/icon/non-veg-icon.svg"></ion-img>
              <h4 class="item-name">Spicy Fish</h4>
            </div>
            <ion-text class="item-toprice" slot="end">1150</ion-text>
          </ion-item>
          <ion-item lines="none" mode="ios" class="menu-item">
            <div class="menu">
              <ion-img src="assets/icon/non-veg-icon.svg"></ion-img>
              <h4 class="item-name">Spicy Fish</h4>
            </div>
            <ion-text class="item-toprice" slot="end">1150</ion-text>
          </ion-item>
          <ion-item lines="none" mode="ios" class="menu-item">
            <div class="menu">
              <ion-img src="assets/icon/non-veg-icon.svg"></ion-img>
              <h4 class="item-name">Spicy Fish</h4>
            </div>
            <ion-text class="item-toprice" slot="end">1150</ion-text>
          </ion-item>
        </ion-list>
        <ion-list mode="ios" class="catagory-list" lines="none">
          <ion-list-header class="catagory-title">
            <ion-label>Catagory 02</ion-label>
          </ion-list-header>
          <ion-item lines="none" mode="ios" class="menu-item">
            <div class="menu">
              <ion-img src="assets/icon/non-veg-icon.svg"></ion-img>
              <h4 class="item-name">Spicy Fish</h4>
            </div>
            <ion-text class="item-toprice" slot="end">1150</ion-text>
          </ion-item>
          <ion-item lines="none" mode="ios" class="menu-item">
            <div class="menu">
              <ion-img src="assets/icon/non-veg-icon.svg"></ion-img>
              <h4 class="item-name">Spicy Fish</h4>
            </div>
            <ion-text class="item-toprice" slot="end">1150</ion-text>
          </ion-item>
          <ion-item lines="none" mode="ios" class="menu-item">
            <div class="menu">
              <ion-img src="assets/icon/non-veg-icon.svg"></ion-img>
              <h4 class="item-name">Spicy Fish</h4>
            </div>
            <ion-text class="item-toprice" slot="end">1150</ion-text>
          </ion-item> -->
        </ion-list>
      </ion-item-group>
      <!-- <ion-item-group class="widget-list">
        <ion-item lines="none" class="widget-title">
          <ion-label color="primary">Widget #02</ion-label>
        </ion-item>
        <ion-list mode="ios" class="catagory-list" lines="none">
          <ion-list-header class="catagory-title">
            <ion-label>Catagory 01</ion-label>
          </ion-list-header>
          <ion-item lines="none" mode="ios" class="menu-item">
            <div class="menu">
              <ion-img src="assets/icon/non-veg-icon.svg"></ion-img>
              <h4 class="item-name">Spicy Fish</h4>
            </div>
            <ion-text class="item-toprice" slot="end">1150</ion-text>
          </ion-item>
          <ion-item lines="none" mode="ios" class="menu-item">
            <div class="menu">
              <ion-img src="assets/icon/non-veg-icon.svg"></ion-img>
              <h4 class="item-name">Spicy Fish</h4>
            </div>
            <ion-text class="item-toprice" slot="end">1150</ion-text>
          </ion-item>
        </ion-list>
      </ion-item-group> -->

    </ion-card-content>
  </ion-card>
</div>