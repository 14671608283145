<ion-item-group class="page-content" *ngIf="!skelton">
  <div>
    <ion-card class="reservation-card">
      <ion-list>
        <ion-item lines="full" class="userItem">
          <ion-avatar slot="start" (click)="goToGuestProfile(responseReservationData?.memberDetails?.membershipNumber)">
            <img
              [src]="responseReservationData?.memberDetails?.Photo ? responseReservationData?.memberDetails?.Photo + '?' + currentDate:'assets/imgs/user-img.png' ">
          </ion-avatar>
          <ion-label class="ion-text-wrap">
            <h2>{{responseReservationData?.memberDetails?.firstName}}
              {{responseReservationData?.memberDetails?.lastName}}<ion-icon color="warning"
                *ngIf="responseReservationData?.memberDetails?.handleWithCare=='true'" class="ca-star-filled">
              </ion-icon>
              <ion-icon *ngIf="responseReservationData?.memberDetails?.highestSpender == 'true'"
                class="green ca-star-filled"></ion-icon>
            </h2>
            <p [hidden]="!responseReservationData?.memberDetails?.memberSince">
              {{'manageReservationPage.member_since'|translate}}
              {{responseReservationData?.memberDetails?.memberSince}}</p>
          </ion-label>
          <!-- <ion-icon slot="end" (click)="goToGuestProfile(responseReservationData?.memberDetails?.membershipNumber)"
              class="ca-right-arrow-icon"></ion-icon> -->
          <!-- *ngIf="inProgressStorage.userDetails.outletType =='restaurant'" -->
          <ion-item slot="end"
            *ngIf=" responseReservationData?.reservationStatus=='In Progress' && manageStoreData.userDetails.outletType =='Restaurant' "
            (click)="tableEditFun()" lines="none" class="numberIcon">
            <div class="numberLabel"> {{manageReservationTable}}</div>
            <ion-icon color="primary" class="ca-edit-icon__colored editClass"></ion-icon>
          </ion-item>
        </ion-item>

        <ion-item lines="full" class="reservation-item modifyDetails">
          <ion-label class="ion-text-wrap">
            <p>{{'manageReservationPage.reservation_details'|translate}}</p>
            <h2 *ngIf="responseReservationData">
              {{reservationDate}},&nbsp;{{responseReservationData?.reservationTime}},&nbsp;
              <span *ngIf="responseReservationData?.guestCount >= '1'">{{responseReservationData?.guestCount}}

                <span *ngIf="responseReservationData?.guestCount <= '1'"> {{'viewReservation.guest'|translate}}</span>
                <span *ngIf="responseReservationData?.guestCount > '1'"> {{'viewReservation.guests'|translate}}</span>
              </span>
            </h2>
            <h6>{{'manageReservationPage.confirmatioNumber' | translate}}
              {{responseReservationData?.confirmationNumber}}</h6>
            <h6 *ngIf="responseReservationData?.roomNumber!=''">{{'manageReservationPage.roomNumber' | translate}}
              {{responseReservationData?.roomNumber}}</h6>
          </ion-label>
          <!-- (click)="openModal('modify')" -->
          <ion-buttons
            *ngIf="responseReservationData?.reservationStatus!='In Progress' && responseReservationData?.reservationStatus!='Close Cheque' ">
            <ion-button color="primary" (click)="openModifyModal()"
              [disabled]="!manageStoreData?.userPageAccess?.userPageAccess?.includes('PUT_MODIFY_REG')">
              {{'manageReservationPage.modify'|translate}}
              <ion-icon class="ca-edit-icon__colored">
              </ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-item>

        <ion-item lines="full" class="" (click)="guestShowHide()" *ngIf="responseReservationData?.guestList!=''">
          <ion-label class="ion-text-wrap">
            <h2>{{'manageReservationPage.GuestList'|translate}}</h2>
          </ion-label>
          <ion-icon slot="end" *ngIf="!showGuestDiv" class="ca-up-arrow-yellow-icon"></ion-icon>
          <ion-icon slot="end" *ngIf="showGuestDiv" class="ca-down-arrow-yellow-icon"></ion-icon>
        </ion-item>
        <ion-item lines="full" class="reservation-item gestList" *ngIf="showGuestDiv">
          <ion-label class="ion-text-wrap">
            <h2 *ngFor="let guestObj of responseReservationData.guestList">{{guestObj.firstName}}
              {{guestObj.lastName}}</h2>
          </ion-label>
        </ion-item>

        <ion-item lines="full" class="reservation-item"
          *ngIf="responseReservationData?.celebration!= '' 
          && responseReservationData?.celebration.description != '' || responseReservationData?.celebration.occasion != '' ">
          <div class="label-wrap">
            <ion-label class="ion-text-wrap" *ngIf="responseReservationData?.celebration.description != '' ">
              <p>{{'manageReservationPage.celebration'|translate}}</p>
              <h2>{{responseReservationData?.celebration.description}}</h2>
            </ion-label>
            <ion-label class="ion-text-wrap" *ngIf="responseReservationData?.celebration.occasion != '' ">
              <p>{{'manageReservationPage.add_celebration_details'|translate}} </p>
              <h2> {{responseReservationData?.celebration.occasion}}</h2>
            </ion-label>
          </div>
        </ion-item>

        <!-- Spa Outlet -->
        <ion-item lines="full" class="reservation-item" *ngIf="responseReservationData?.spaTreatment != ''">
          <div class="label-wrap">
            <ion-label class="ion-text-wrap">
              <p>{{'manageReservationPage.treatment'|translate}}</p>
              <h2>{{responseReservationData?.spaTreatment}}</h2>
            </ion-label>
            <ion-label class="ion-text-wrap">
              <p>{{'manageReservationPage.spaDuration'|translate}}</p>
              <h2>{{responseReservationData?.spaDuration}}</h2>
            </ion-label>
          </div>
        </ion-item>

        <!-- End Spa outlet -->
        <ion-item lines="full" class="reservation-item" *ngIf="responseReservationData?.specialrequest !=''">
          <ion-label class="ion-text-wrap">
            <p>{{'manageReservationPage.specialRequest'|translate}}</p>
            <h2>{{responseReservationData?.specialrequest}}</h2>
          </ion-label>
        </ion-item>
        <ion-item lines="full" class="reservation-item foodPreferences"
          *ngIf="responseReservationData?.foodPreferences!=''">
          <ion-label class="ion-text-wrap">
            <p>{{'manageReservationPage.foodPreferences'|translate}}</p>
            <h2 *ngFor="let food of responseReservationData?.foodPreferences ; let isLast=last">
              {{food}}{{isLast ? '' : ', '}}</h2>

          </ion-label>
        </ion-item>

        <ion-item lines="full" class="reservation-item" *ngIf="responseReservationData?.beveragePreferences!=''">
          <ion-label class="ion-text-wrap">
            <p>{{'manageReservationPage.beveragePreferences'|translate}} </p>
            <h2 *ngFor="let beverage of responseReservationData?.beveragePreferences">{{beverage}}</h2>
          </ion-label>
        </ion-item>
        <!-- Table  Preference-->
        <ion-item lines="full" class="reservation-item" *ngIf="responseReservationData?.selectedTablePreference">
          <ion-label class="ion-text-wrap">
            <p>{{'manageReservationPage.tablePre'|translate}} </p>
            <h2>{{responseReservationData?.selectedTablePreference}}</h2>
          </ion-label>
        </ion-item>
        <!-- Hotel comment  -->
        <ion-item lines="full" class="reservation-item" *ngIf="responseReservationData?.hotelComment">
          <ion-label class="ion-text-wrap">
            <p>{{'manageReservationPage.HotelComment'|translate}} </p>
            <h2>{{responseReservationData?.hotelComment}}</h2>
          </ion-label>
        </ion-item>

        <ion-item lines="full" class="reservation-item" *ngIf="responseReservationData?.benefitCode ">
          <ion-button mode="ios" expand="block" class="btn-default primary-gradient buffetLunch-btn curved-style"
            (click)="buffetClick(responseReservationData)">
            {{responseReservationData?.benefitDescription}}
          </ion-button>
        </ion-item>

        <div
          *ngIf="responseReservationData?.reservationStatus =='In Progress' || responseReservationData?.reservationStatus =='Redeemed'  ">
          <ion-item lines="full" class="reservation-item"
            *ngIf="responseReservationData?.benefitCode && responseReservationData?.benefitType!='promocode'">
            <ion-label class="ion-text-wrap">
              <p class="transactionRedeem" *ngIf="reedeemData?.transactionCode">
                {{'manageReservationPage.transaction_code'|translate}}</p>
              <h2> {{reedeemData?.transactionCode}}</h2>
              <h2 class="requestRedeem" *ngIf="!reedeemData?.transactionCode">
                <ion-text color="danger">{{'manageReservationPage.request_guest_to_redeem'|translate}}</ion-text>
                <ion-icon color="danger" class="ca-delay-icon"></ion-icon>
              </h2>
            </ion-label>
          </ion-item>
        </div>



        <div
          *ngIf="responseReservationData?.reservationStatus=='In Progress' || responseReservationData?.reservationStatus =='Redeemed' ">
          <ion-buttons
            *ngIf="reedeemData?.transactionCode || !responseReservationData?.benefitCode || responseReservationData?.benefitType=='promocode'   "
            class="ion-justify-content-center btns-col">
            <ion-button color="primary" (click)="openModalCheque(responseReservationData)"
              [disabled]="!manageStoreData?.userPageAccess?.userPageAccess?.includes('POST_SETTLE_CHEQUE')"
              class="chequeBtn">
              {{'manageReservationPage.chequeSettled'|translate}}
            </ion-button>
            <ion-button color="danger" class="cencel"
              *ngIf="responseReservationData?.benefitType!='promocode' && responseReservationData.benefitCode!='' "
              (click)="cancelCloseChequeModal()">
              {{'closeChequePage.cancel'|translate}}
            </ion-button>
          </ion-buttons>
        </div>

      </ion-list>
    </ion-card>

    <!-- <ion-item class="bntItems" lines="none"> -->
    <ion-grid class="bntItems ion-no-padding">
      <ion-row class="ion-justify-content-center">
        <!-- under devolment  -->
        <!-- <ion-col size="6"  *ngIf="responseReservationData?.reservationStatus =='Confirmed'">
            <ion-button mode="ios"
              expand="block" fill="outline"
              class="btn-default" color="primary">
              Table Ready Alert
            </ion-button>
          </ion-col> -->
        <ion-col size="6"
          *ngIf="responseReservationData?.reservationStatus !='Close Cheque' && responseReservationData?.reservationStatus !='In Progress'  && responseReservationData?.reservationStatus !='Redeemed'  ">
          <ion-button *ngIf="responseReservationData?.memberDetails?.mobile!='' " mode="ios" expand="block"
            fill="outline" class="btn-default" color="primary"
            href="tel:{{responseReservationData?.memberDetails?.mobile}}">
            <ion-icon slot="start" class="ca-call"> </ion-icon>
            {{'manageReservationPage.call'|translate}}
          </ion-button>
        </ion-col>
        <ion-col size="6" class="mb-0">
          <ion-button mode="ios" *ngIf="responseReservationData?.reservationStatus =='Awaiting Confirmation' "
            expand="block" class="btn-default dark-gradient btnConfirm" (click)="openConfimModalFuntion()">
            <ion-icon slot="start" class="ca-confirm-outline"></ion-icon>
            {{'manageReservationPage.confirm'|translate}}
          </ion-button>

          <ion-button mode="ios" (click)="reserveStatusChangeApi(typeManage.noShow)"
            [disabled]="!manageStoreData?.userPageAccess?.userPageAccess?.includes('POST_REGSTATUS')"
            *ngIf="responseReservationData?.reservationStatus =='Confirmed'" expand="block" fill="outline"
            class="btn-default" color="primary">
            {{'manageReservationPage.no_show'|translate}}
          </ion-button>
        </ion-col>

        <ion-col size="12" class="mb-0 mt-2"
          *ngIf="responseReservationData?.reservationStatus =='Awaiting Confirmation' ">
          <ion-buttons>
            <ion-button mode="ios" color="danger" (click)="openModal('regret')">
              <ion-icon class="ca-cancel-outline"></ion-icon> {{'manageReservationPage.regret'|translate}}
            </ion-button>
          </ion-buttons>
        </ion-col>
      </ion-row>
    </ion-grid>
    <!-- </ion-item> -->
    <!-- <ion-button  mode="ios" expand="block" class="btn-default primary-gradient" *ngIf ="reedeemData?.transactionCode"  >
        {{'manageReservationPage.close_cheque'|translate}}
       </ion-button> -->
  </div>
</ion-item-group>

<!-- skelton -->
<ion-item-group *ngIf="skelton" class="page-content">
  <div>
    <ion-card class="reservation-card" style="margin-bottom: 10px;">
      <ion-list>
        <ion-item lines="full" class="userItem">
          <ion-avatar slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
            <p>
              <ion-skeleton-text animated style="width: 50%;"></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
        <ion-item lines="full" class="reservation-item modifyDetails">
          <ion-label>
            <p>
              <ion-skeleton-text animated style="width: 30%;"></ion-skeleton-text>
            </p>
            <h2>
              <ion-skeleton-text animated style="width: 50%;"></ion-skeleton-text>
            </h2>
            <h6>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h6>
          </ion-label>
        </ion-item>
        <ion-item lines="full" class="reservation-item gestList">
          <ion-label>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
          </ion-label>
        </ion-item>
        <ion-item lines="full" class="reservation-item gestList">
          <ion-label>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
          </ion-label>
        </ion-item>
        <ion-item lines="full" class="reservation-item gestList">
          <ion-label>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
          </ion-label>
        </ion-item>
        <ion-item lines="full" class="reservation-item gestList">
          <ion-label>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
          </ion-label>
        </ion-item>
        <ion-item lines="full" class="reservation-item gestList">
          <ion-label>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
          </ion-label>
        </ion-item>
        <ion-item lines="full" class="reservation-item gestList">
          <ion-label>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
          </ion-label>
        </ion-item>
        <ion-item lines="full" class="reservation-item gestList">
          <ion-label>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
          </ion-label>
        </ion-item>
        <ion-item lines="full" class="reservation-item gestList">
          <ion-label>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
          </ion-label>
        </ion-item>
        <ion-item lines="full" class="reservation-item gestList">
          <ion-label>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
          </ion-label>
        </ion-item>
        <ion-item lines="full" class="reservation-item gestList">
          <ion-label>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
          </ion-label>
        </ion-item>
        <ion-item lines="full" class="reservation-item gestList">
          <ion-label>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
          </ion-label>
        </ion-item>
        <ion-item lines="full" class="reservation-item gestList">
          <ion-label>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
            <h2>
              <ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text>
            </h2>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-card>
  </div>
</ion-item-group>



<!-- Modify Modal -->
<div class="customModal" id="modifyModal">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <!-- <ion-buttons>
        <ion-icon slot="end"></ion-icon>
      </ion-buttons> -->
      <div class="customModal-body ion-text-center">
        <div class="customModal-text">
          <p>{{'manageReservationPage.modifyMsg'|translate}}</p>
        </div>
      </div>
      <div class="customModal-footer two-btn">
        <ion-button mode="ios" class="customModalButton light-gradient" (click)="closeModifyModal()">
          {{'manageReservationPage.cancel'|translate}}
        </ion-button>
        <ion-button mode="ios" class="customModalButton primary-gradient" (click)="modifyFunc()">
          {{'manageReservationPage.continue'|translate}}
        </ion-button>
      </div>
    </div>
  </div>
</div>

<!-- Regretted Modal -->
<div class="customModal" id="regrettedModal">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <!-- <ion-buttons>
        <ion-icon slot="end"></ion-icon>
      </ion-buttons> -->
      <div class="customModal-body ion-text-center">
        <div class="customModal-text">
          <p>{{'manageReservationPage.regrettedMsg'|translate}}</p>
        </div>
      </div>
      <div class="customModal-footer two-btn">
        <ion-button mode="ios" class="customModalButton light-gradient" (click)="regretGotIt()">
          {{'manageReservationPage.cancel'|translate}}
        </ion-button>
        <ion-button mode="ios" class="customModalButton primary-gradient" (click)="regrettedApi()">
          {{'manageReservationPage.ok'|translate}}
        </ion-button>
      </div>
    </div>
  </div>
</div>

<!-- Confirmation Modal -->
<div class="customModal" id="confirmationModal">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <!-- <ion-buttons>
        <ion-icon slot="end"></ion-icon>
      </ion-buttons> -->
      <div class="customModal-body ion-text-center">
        <div class="customModal-text">
          <p>{{'manageReservationPage.successfullyConfimedMsg'|translate}}</p>
        </div>
      </div>
      <div class="customModal-footer two-btn">
        <ion-button mode="ios" class="customModalButton light-gradient" expand="full" (click)="confirmationClose()">
          {{'manageReservationPage.cancel' | translate}}
        </ion-button>
        <ion-button mode="ios" class="customModalButton primary-gradient" expand="full"
          (click)="reserveStatusChangeApi(typeManage.confirmed)">
          {{'manageReservationPage.ok' | translate}} </ion-button>
      </div>
    </div>
  </div>
</div>

<!-- NO Show Modal -->
<div class="customModal" id="noShowModal">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <!-- <ion-buttons>
        <ion-icon slot="end"></ion-icon>
      </ion-buttons> -->
      <div class="customModal-body ion-text-center">
        <div class="customModal-text">
          <p>{{'manageReservationPage.cancelReservation'|translate}}
          </p>
        </div>
      </div>
      <div class="customModal-footer">
        <ion-button mode="ios" class="customModalButton primary-gradient" expand="full" (click)="confirmNoShow()">
          {{'manageReservationPage.gotIt'|translate}}
        </ion-button>
      </div>
    </div>
  </div>
</div>

<!-- edit reservation -->
<div class="customModal" id="editReservation">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <ion-buttons>
        <ion-icon slot="end" (click)="editReservationModalClose()" class="ca-modal-close"></ion-icon>
      </ion-buttons>
      <div class="customModal-body ion-text-center">
        <div class="customModal-text">
          <ion-text color="primary">
            <h2 style="margin-bottom: 20px;">{{'manageReservationPage.updateTableNo'|translate}}</h2>
          </ion-text>
          <div class="position-relative">
            <ion-select class="tableNumberSelect"  (ngModelChange)="UpdateTableInput($event)"  [(ngModel)]="tableNumberNew" [placeholder]="manageReservationTable" interface="popover"
              [interfaceOptions]="popoverOptions">
              <ion-select-option *ngFor="let table of allVaccanTable" [value]='table'>{{table.tableNum}}</ion-select-option>
            </ion-select>
            <ion-icon slot="end" style="right:0" class="select-arrow ca-down-arrow-yellow-icon"></ion-icon>
          </div>
          <!-- <ion-input mode="ios" type="tel" (ionInput)="numberOnly($event)" class="tableinput ion-text-center" maxlength="3" [(ngModel)]="tableNo">
          </ion-input> -->
        </div>
      </div>
      <div class="customModal-footer">
        <ion-button mode="ios" class="customModalButton primary-gradient" expand="full"
          [disabled]="!eanbleModifiyTable" (click)="editReservationTable()">
          {{'manageReservationPage.ok'|translate}}
        </ion-button>
      </div>
    </div>
  </div>
</div>

<!-- close cheque -->
<div class="customModal" id="closeCheque">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <ion-buttons>
        <ion-icon slot="end" (click)="closeChequeModal()" class="ca-modal-close"></ion-icon>
      </ion-buttons>
      <div class="customModal-body ion-text-center">
        <div class="customModal-text">
          <p>{{'manageReservationPage.pleaseEnterTransaction'|translate}}
          </p>
        </div>
      </div>
      <div class="customModal-footer">
        <ion-button mode="ios" class="customModalButton primary-gradient" expand="full" (click)="closeChequeApi()">
          {{'manageReservationPage.gotIt'|translate}}
        </ion-button>
      </div>
    </div>
  </div>
</div>

<!-- Cancel Close Cheque -->
<div class="customModal" id="cancelCloseCheque">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <!-- <ion-buttons>
        <ion-icon slot="end"></ion-icon>
      </ion-buttons> -->
      <div class="customModal-body ion-text-center">
        <div class="customModal-text">
          <p>{{'manageReservationPage.cancelCloseChequq'|translate}}
        </div>
      </div>
      <div class="customModal-footer two-btn">
        <ion-button mode="ios" class="customModalButton light-gradient" expand="full"
          (click)="removeCloseChequeModal()">
          {{'manageReservationPage.cancel' | translate}}
        </ion-button>
        <ion-button mode="ios" class="customModalButton primary-gradient" expand="full"
          (click)="cancelCloseChequqGotIt()">
          {{'manageReservationPage.ok' | translate}} </ion-button>
      </div>
    </div>
  </div>
</div>

<!-- Notify Select past date -->
<div class="customModal" id="disabledPastDate">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <!-- <ion-buttons>
        <ion-icon slot="end"></ion-icon>
      </ion-buttons> -->
      <div class="customModal-body ion-text-center">
        <div class="customModal-text">
          <p>{{'manageReservationPage.disabledPastDate'|translate}}</p>
        </div>
      </div>
      <div class="customModal-footer ">
        <ion-button mode="ios" expand="full" class="customModalButton primary-gradient" (click)="gotItPastDateModal()">
          {{'manageReservationPage.gotIt'|translate}}
        </ion-button>
      </div>
    </div>
  </div>
</div>

<!-- Work in progress -->
<div class="customModal guestModal" id="alreadyTable">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <ion-buttons>
        <ion-icon slot="end" (click)="alreadyTablecloseModal()" class="ca-modal-close"></ion-icon>
      </ion-buttons>
      <div class="customModal-body ion-text-center">
        <div class="customModal-text">
          <h2 style="margin-bottom: 10px;">
            <ion-text color="primary">
              {{responseReservationData?.memberDetails?.firstName}}&nbsp;{{responseReservationData?.memberDetails?.lastName}}&nbsp;Has
              Arrived
            </ion-text>
          </h2>

          <ion-item mode="ios" lines="none" class="form-group height-auto"
            *ngIf="manageStoreData.userDetails.outletType =='Restaurant'">
            <ion-label>
              <p>{{'awaitArrival.enter_table_number' | translate}}</p>
            </ion-label>
            <div class="position-relative" style="margin-left: 10px;">
              <ion-select class="tableNumberSelect"  [disabled]="notDisabledTable" [(ngModel)]="tableNo" [placeholder]="manageReservationTable" interface="popover"
                [interfaceOptions]="popoverOptions">
                <ion-select-option *ngFor="let table of allVaccanTable" [value]='table'>{{table.tableNum}}</ion-select-option>
              </ion-select>
              <ion-icon slot="end" [hidden]="notDisabledTable" style="right:0" class="select-arrow ca-down-arrow-yellow-icon"></ion-icon>
            </div>
            <!-- <ion-input slot="end" (ionChange)="checkTableInput()"  (ionInput)="numberOnly($event)" mode="ios" class="ion-text-center smallInput" type="tel" maxlength="3"
              [(ngModel)]="tableNo" [disabled]="notDisabledTable">
            </ion-input> -->
          </ion-item>

          <ion-item mode="ios" lines="none" class="form-group height-auto">
            <ion-label class="ion-text-center">
              <p>{{'awaitArrival.enter_check_number' | translate}}</p>
            </ion-label>
            <ion-input slot="end" mode="ios" [disabled]="notDisabledCheckNo" class="ion-text-center smallInput"
              type="text" maxlength="10" [(ngModel)]="checkNo">
            </ion-input>
          </ion-item>

          <ion-item mode="ios" lines="none" class="form-group height-auto">
            <ion-label class="ion-text-center">
              <p>{{'closeChequePage.addnetAmount' | translate}} ({{showCurrency?.currencySymbol}})</p>
            </ion-label>
            <ion-input slot="end" mode="ios" maxlength="9" (ionInput)="onChange($event)" (ionChange)="check()"
              class="ion-text-center smallInput" type="text" [(ngModel)]="netAmount">
            </ion-input>
          </ion-item>

          <!-- Guest section  -->
          <ion-item mode="ios" lines="none" class="form-group height-auto
        guest-card" *ngIf="manageStoreData.userDetails.outletType =='Restaurant'
         || manageStoreData.userDetails.outletType=='Spa' 
         || manageStoreData.userDetails.outletType=='Accommodation'
         ">
            <ion-label slot="start" class="ion-text-wrap guest_count">
              {{guestCount}}
              <span *ngIf="guestCount>1">{{'reservationDetailsModal.guests'| translate}}</span>
              <span *ngIf="guestCount <= 1">{{'reservationDetailsModal.guest'| translate}}</span>

            </ion-label>
            <ion-buttons slot="end">
              <ion-button color="primary" fill="outline" (click)="onDecrement('restaurant','guest')">
                <ion-icon mode="ios" name="remove"></ion-icon>
              </ion-button>
              <ion-button color="primary" fill="outline" (click)="addCounter('restaurant','guest')">
                <ion-icon mode="ios" name="add"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
          <!-- Guest Section  End -->

          <!-- Adult and child section  -->
          <ion-item mode="ios" lines="none" class="form-group height-auto
        guest-card" *ngIf="manageStoreData.userDetails.outletType =='Pool'">
            <ion-label slot="start" class="ion-text-wrap guest_count">
              {{adultCount}}
              <span *ngIf="adultCount>1">{{'reservationDetailsModal.adults'| translate}}</span>
              <span *ngIf="adultCount <= 1">{{'reservationDetailsModal.adult'| translate}}</span>

            </ion-label>
            <ion-buttons slot="end">
              <ion-button color="primary" fill="outline" (click)="onDecrement('pool','adult')">

                <ion-icon mode="ios" name="remove"></ion-icon>
              </ion-button>
              <ion-button color="primary" fill="outline" (click)="addCounter('pool','adult')">

                <ion-icon mode="ios" name="add"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
          <ion-item mode="ios" lines="none" class="form-group height-auto
                  guest-card" *ngIf="manageStoreData.userDetails.outletType =='Pool'">
            <!-- <ion-label slot="start" class="ion-text-wrap guest_count">
                          {{childCount}}
                          <span *ngIf="childCount>1">{{'reservationDetailsModal.kids'| translate}} </span>
                          <span *ngIf="childCount == '0' || childCount == '1' ">{{'reservationDetailsModal.kid'|
                              translate}}
                          </span>

                      </ion-label> -->
            <!-- <ion-buttons slot="end">
                          <ion-button color="primary" fill="outline" (click)="onDecrement('pool','child')">

                              <ion-icon mode="ios" name="remove"></ion-icon>
                          </ion-button>
                          <ion-button color="primary" fill="outline" (click)="addCounter('pool','child')">

                              <ion-icon mode="ios" name="add"></ion-icon>
                          </ion-button>
                      </ion-buttons> -->
          </ion-item>
          <!-- Adult and child Section  End -->

        </div>
      </div>
      <div class="customModal-footer">

        <ion-button mode="ios" expand="full" class="customModalButton primary-gradient" *ngIf="manageStoreData.userDetails.outletType =='Restaurant'
         || manageStoreData.userDetails.outletType=='Spa' 
         || manageStoreData.userDetails.outletType=='Accommodation'
         " [disabled]=" checkNo?.length < 2 || !amountEnable || !tableNo ||  1 > guestCount  "
          (click)="confirmModalAreadyAssign()">
          {{'awaitArrival.confirm'|translate}}

        </ion-button>

        <ion-button mode="ios" expand="full" class="customModalButton primary-gradient"
          *ngIf="manageStoreData.userDetails.outletType =='Pool'"
          [disabled]="checkNo?.length < 2 || 1 > adultCount  || !amountEnable " (click)="confirmModalAreadyAssign()">
          {{'awaitArrival.confirm'|translate}}
        </ion-button>
      </div>
    </div>
  </div>
</div>


<!-- Update modify  -->
<div class="customModal" id="updatemodify">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <!-- <ion-buttons>
        <ion-icon slot="end" class="ca-modal-close"></ion-icon>
      </ion-buttons> -->
      <div class="customModal-body ion-text-center">
        <div class="reservation-confirm-customModal-text">
          <h2 style="margin-bottom: 20px;font-weight: bolder;font-size: 14px;"
            [innerHTML]="reservationConfirmationPara"></h2>
          <p>{{modifyRes?.statusMessage}}</p>
        </div>
      </div>
      <div class="customModal-footer">
        <ion-button mode="ios" class="customModalButton primary-gradient" (click)="proceedUpdate()" expand="full">
          Got it
        </ion-button>
      </div>
    </div>
  </div>
</div>