<div class="modalBg">
  <ion-card class="modalWrap">
    <ion-card-header class="modalHeader border-0">
      <ion-icon class="modalClose ca-close-icon" (click)="closeModal()"></ion-icon>
    </ion-card-header>
    <form [formGroup]="walkInForm">
      <ion-card-content class="modalContent">
        <div class="formField">
          <ion-item mode="ios" lines="none">
            <ion-label mode="ios" class="input-label" position="stacked">{{'walk_In.enter_name'|translate}}</ion-label>
            <ion-input mode="ios" autocapitalize type="text" class="input-field" placeholder="Abhishek Gupta">
            </ion-input>
          </ion-item>
        </div>
        <div class="formField">
          <ion-item mode="ios" lines="none">
            <ion-label mode="ios" class="input-label" position="stacked">{{'walk_In.enter_no_of_guest'|translate}}
            </ion-label>
            <ion-input type="tel" class="input-field" placeholder="02"></ion-input>
          </ion-item>
        </div>
        <div class="formField">
          <ion-item mode="ios" lines="none">
            <ion-label mode="ios" class="input-label" position="stacked"> {{'walk_In.enter_table_no'|translate}}
            </ion-label>
            <ion-input mode="ios" type="tel" class="input-field" placeholder="05"></ion-input>
          </ion-item>
        </div>
        <div class="formField">
          <ion-item mode="ios" lines="none">
            <ion-label mode="ios" class="input-label" position="stacked"> {{'walk_In.select_type'|translate}}
            </ion-label>
            <div class="selectBoxWrap">
              <ion-select mode="md" [(ngModel)]="informationType" [ngModelOptions]="{standalone: true}"
                (ionChange)="changeSelectInfo($event)" cancelText="{{'walk_In.cancel'|translate}}" okText="{{'walk_In.ok'|translate}}"  >
                <ion-select-option value="emailaddress">{{'walk_In.email_address'|translate}}</ion-select-option>
                <ion-select-option value="phoneNumber">{{'walk_In.ph_no'|translate}}</ion-select-option>
                <ion-select-option value="membershipNumber">{{'walk_In.membership_number'|translate}}
                </ion-select-option>
              </ion-select>
              <ion-icon slot="end" class="select-arrow ca-down-arrow-yellow-icon"></ion-icon>
            </div>
          </ion-item>
        </div>
        <div class="formField" *ngIf="informationType === 'emailaddress'">
          <ion-item mode="ios" lines="none">
            <ion-label mode="ios" class="input-label" position="stacked">{{'walk_In.enter_email_address'|translate}}
            </ion-label>
            <ion-input mode="ios" type="email" class="input-field" placeholder="{{'walk_In.email_place'|translate}}"
              formControlName="email"></ion-input>
          </ion-item>
        </div>
        <div class="formField" *ngIf="informationType === 'phoneNumber'">
          <ion-item mode="ios" lines="none">
            <ion-label mode="ios" class="input-label" position="stacked">{{'walk_In.enter_ph_no'|translate}}</ion-label>
            <div class="phoneWrap">
              <p class="countryValue">{{SelectCode}} <ion-icon class="ca-down-arrow-yellow-icon"></ion-icon>
              </p>
              <ion-select mode="md" class="countryCode" formControlName="countryCodeSelected"
                (ionChange)="countryCodeChange()" cancelText="{{'walk_In.cancel'|translate}}" okText="{{'walk_In.ok'|translate}}"  >
                <ion-select-option *ngFor="let country of countries" [value]="country">
                  {{ country.dialCode }}
                  {{ country.name }}
                </ion-select-option>
              </ion-select>
              <ion-input mode="ios" type="tel" class="input-field" placeholder="{{'walk_In.mob_place'|translate}}" formControlName="mobile">
              </ion-input>
            </div>
          </ion-item>
        </div>
        <div class="formField" *ngIf="informationType === 'membershipNumber'">
          <ion-item mode="ios" lines="none">
            <ion-label mode="ios" class="input-label" position="stacked">
              {{'walkinPage.enter_membership_no'| translate}}</ion-label>
            <ion-input mode="ios" type="tel" class="input-field" placeholder="{{'walk_In.membership'|translate}}" formControlName="memshipNo">
            </ion-input>
          </ion-item>
        </div>
      </ion-card-content>

      <!-- Update Button -->
      <ion-button mode="ios" expand="block" class="modalButton ion-no-margin primary-gradient" (click)="onClick()">
        {{'walk_In.confirm'|translate}}
      </ion-button>
    </form>
  </ion-card>
</div>