<div class="modalBg">
  <ion-card class="modalWrap">
    <ion-card-header class="modalHeader">
      <h2 class="modalTitle"></h2>
      <ion-icon class="modalClose ca-close-icon" (click)="closeModal()"></ion-icon>
    </ion-card-header>
    <form>
      <ion-card-content class="modalContent">
        <div class="formField">
          <ion-item mode="ios" lines="none">
        
       
            <ion-textarea mode="ios" maxlength="100"  rows="4" [(ngModel)]="feedBackModal" [ngModelOptions]="{standalone: true}" (ionInput)="restrictText($event)"
              placeholder="{{'respond_Modal.100_words_place'|translate}}" ></ion-textarea>
              <ion-label mode="ios" position="stacked" class="word_position" color="medium" *ngIf="!feedBackModal">
                {{'respond_Modal.100_words'|translate}}
              </ion-label>
              <ion-label mode="ios" position="stacked" color="medium" *ngIf=feedBackModal  class="word_position" >
                {{100 - feedBackModal.length}} {{'respond_Modal.words'|translate}}</ion-label>
          </ion-item>
          <!-- <ion-label position="stacked" class="no-margin validation-text">Enter OTP in the section below</ion-label> -->
        </div>
      </ion-card-content>
      <!-- Update Button -->
      <ion-button mode="ios" expand="block" class="modalButton ion-no-margin primary-gradient" (click)="onClick()">
        {{'respond_Modal.confirm'|translate}}
      </ion-button>
    </form>
  </ion-card>
</div>