<div class="modalBg">
  <ion-card class="modalWrap">
    <ion-card-header class="modalHeader">
      <h2 class="modalTitle">{{'activatePage.rating' | translate}}</h2>
      <ion-icon class="modalClose ca-close-icon" (click)="closeModal()"></ion-icon>
    </ion-card-header>
    <form>
      <ion-card-content class="modalContent">
        <!-- Email Field -->
        <ion-item lines="full">
          <div class="custom-radio">
            <input type="radio" name="reason" id="Noshow" class="custom-radio" value="1"
              (change)="onItemChange($event)">
            <label for="Noshow">
              <ion-icon class="ca-star-outline iconSizeArrow"></ion-icon>
            </label>
          </div>
        </ion-item>
        <ion-item lines="full">
          <div class="custom-radio">
            <input type="radio" name="reason" id="Option2" class="custom-radio" value="2"
              (change)="onItemChange($event)">
            <label for="Option2">
              <ion-icon class="ca-star-outline iconSizeArrow"></ion-icon>
              <ion-icon class="ca-star-outline iconSizeArrow"></ion-icon>
            </label>
          </div>
        </ion-item>
        <ion-item lines="full">
          <div class="custom-radio">
            <input type="radio" name="reason" id="Option3" class="custom-radio" value="3"
              (change)="onItemChange($event)">
            <label for="Option3">
              <ion-icon class="ca-star-outline iconSizeArrow"></ion-icon>
              <ion-icon class="ca-star-outline iconSizeArrow"></ion-icon>
              <ion-icon class="ca-star-outline iconSizeArrow"></ion-icon>
            </label>
          </div>
        </ion-item>
        <ion-item lines="full">
          <div class="custom-radio">
            <input type="radio" name="reason" id="Option4" class="custom-radio" value="4"
              (change)="onItemChange($event)">
            <label for="Option4">
              <ion-icon class="ca-star-outline iconSizeArrow"></ion-icon>
              <ion-icon class="ca-star-outline iconSizeArrow"></ion-icon>
              <ion-icon class="ca-star-outline iconSizeArrow"></ion-icon>
              <ion-icon class="ca-star-outline iconSizeArrow"></ion-icon>
            </label>
          </div>
        </ion-item>
        <ion-item lines="full">
          <div class="custom-radio">
            <input type="radio" name="reason" id="Option5" class="custom-radio" value="5"
              (change)="onItemChange($event)">
            <label for="Option5">
              <ion-icon class="ca-star-outline iconSizeArrow"></ion-icon>
              <ion-icon class="ca-star-outline iconSizeArrow"></ion-icon>
              <ion-icon class="ca-star-outline iconSizeArrow"></ion-icon>
              <ion-icon class="ca-star-outline iconSizeArrow"></ion-icon>
              <ion-icon class="ca-star-outline iconSizeArrow"></ion-icon>
            </label>
          </div>
        </ion-item>
      </ion-card-content>
      <!-- Update Button -->
      <ion-button mode="ios" expand="block" class="modalButton primary-gradient ion-no-margin" (click)="onClick()"
        [disabled]="!change">{{'activatePage.done' | translate}}
      </ion-button>
    </form>
  </ion-card>
</div>