<div class="modalBg">
  <ion-card class="modalWrap">
    <ion-card-header class="modalHeader">
      <h2 class="modalTitle">{{'set-datePage.set_Date' | translate}}</h2>
      <!-- <h2 class="modalTitle">Update Mobile Number</h2> -->
      <ion-buttons class="modalClose" (click)="closeModal()">
        <ion-button>
          <ion-icon class="ca-close-icon"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-card-header>
    <ion-card-content class="modalContent">

      <ion-item lines="full">
        <div class="custom-radio">
          <input name="date" [(ngModel)]="date" id="today" type="radio" value="Today" (change)="onItemChange($event)">
          <label for="today">{{'set-datePage.today' | translate}}</label>
        </div>
      </ion-item>

      <ion-item lines="full">
        <div class="custom-radio">
          <input name="date" [(ngModel)]="date" id="weekly" type="radio" value="Weekly" (change)="onItemChange($event)">
          <label for="weekly">{{'set-datePage.weekly' | translate}}</label>
        </div>
      </ion-item>

      <ion-item lines="full">
        <div class="custom-radio">
          <input name="date" [(ngModel)]="date" id="monthly" type="radio" value="Monthly"
            (change)="onItemChange($event)">
          <label for="monthly">{{'set-datePage.monthly' | translate}}</label>
        </div>
      </ion-item>

      <ion-item lines="none">
        <div class="custom-radio">
          <input name="date" [(ngModel)]="date" id="customDate" type="radio" value="Custom"
            (change)="onItemChange($event)">
          <label for="customDate">{{'set-datePage.custom' | translate}}</label>
        </div>
      </ion-item>


      <ion-grid class="dates-grid" *ngIf="date === 'Custom' && !typeCalender">
        <ion-row>
          <ion-col size="6">
            <ion-item mode="ios" class="dateItem height-auto">
              <ion-icon color="primary" class="ca-calendar-yellow-icon calendarIcon"></ion-icon>
              <!-- <ion-datetime mode="ios" (ionChange)="setStartDate($event)" [max]="maxDateEnable"
                monthShortNames="{{ monthShortNames }}" id="startDate" class="dateTime" name="startDate"
                displayFormat="DD/MMM/YYYY" placeholder="{{'set-datePage.start_date' | translate}}">
              </ion-datetime> -->
              <mbsc-datepicker class="dateTime customDate" (onChange)="setStartDate($event)"
                dateFormat="DD MMM YYYY" [controls]="['date']" [touchUi]="true"  [min]="validMinDate"
                [placeholder]="'set-datePage.start_date'|translate">
              </mbsc-datepicker>
            </ion-item>
          </ion-col>
          <ion-col size="6">
            <ion-item mode="ios" class="dateItem height-auto">
              <ion-icon color="primary" class="ca-calendar-yellow-icon calendarIcon"></ion-icon>
              <!-- <ion-datetime mode="ios" (ionChange)="setEndDate($event)" [max]="maxDateEnable" id="endDate"
                monthShortNames="{{ monthShortNames }}" [disabled]="!dateFrom" class="dateTime" name="startDate"
                displayFormat="DD/MMM/YYYY" placeholder="{{'set-datePage.end_date' | translate}}">
              </ion-datetime> -->
              <mbsc-datepicker class="dateTime customDate" id="endDate" (onChange)="setEndDate($event)"
                dateFormat="DD MMM YYYY" [controls]="['date']" [touchUi]="true" [max]="maxDateEnable"
                [placeholder]="'set-datePage.end_date'|translate" [disabled]="!dateFrom">
              </mbsc-datepicker>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!-- For Redemption Log -->
      <ion-grid class="dates-grid" *ngIf="date === 'Custom' && typeCalender === 'redemptionLog'">
        <ion-row>
          <ion-col size="6">
            <ion-item mode="ios" class="dateItem height-auto">
              <ion-icon color="primary" class="ca-calendar-yellow-icon calendarIcon"></ion-icon>
              <!-- <ion-datetime mode="ios" (ionChange)="setStartDate($event)"
                [min]="minDateEnableRedemptionLog" [max]="maxDateEnableRedemptionLog" id="startDate" class="dateTime"
                monthShortNames="{{ monthShortNames }}" name="startDate" displayFormat="DD/MMM/YYYY"
                placeholder="{{'set-datePage.start_date' | translate}}" [(ngModel)]="dateFrom">
              </ion-datetime> -->
              <mbsc-datepicker class="dateTime customDate" id="startDate" (onChange)="setStartDate($event)"
                dateFormat="DD MMM YYYY" [controls]="['date']" [touchUi]="true" [min]="minDateEnableRedemptionLog"
                [max]="maxDateEnableRedemptionLog" [placeholder]="'set-datePage.start_date'|translate"
                [(ngModel)]="dateFrom">
              </mbsc-datepicker>
            </ion-item>
          </ion-col>
          <ion-col size="6">
            <ion-item mode="ios" class="dateItem height-auto">
              <ion-icon color="primary" class="ca-calendar-yellow-icon calendarIcon"></ion-icon>
              <!-- <ion-datetime mode="ios" (ionChange)="setEndDate($event)" [max]="maxDateEnableRedemptionLog"
                [min]="dateFrom" id="endDate" [disabled]="!dateFrom" class="dateTime" name="startDate"
                monthShortNames="{{ monthShortNames }}" displayFormat="DD/MMM/YYYY"
                placeholder="{{'set-datePage.end_date' | translate}}" [(ngModel)]="dateTO">
              </ion-datetime> -->
              <mbsc-datepicker class="dateTime customDate" [(ngModel)]="dateTO" id="endDate"
                (onChange)="setEndDate($event)" dateFormat="DD MMM YYYY" [controls]="['date']" [touchUi]="true"
                [min]="dateFrom" [max]="maxDateEnableRedemptionLog" [placeholder]="'set-datePage.end_date'|translate"
                [disabled]="!dateFrom">
              </mbsc-datepicker>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!-- For Feedback History -->
      <ion-grid class="dates-grid" *ngIf="date === 'Custom' && typeCalender === 'feedbackHistory'">
        <ion-row>
          <ion-col size="6">
            <ion-item mode="ios" class="dateItem height-auto">
              <ion-icon color="primary" class="ca-calendar-yellow-icon calendarIcon"></ion-icon>
              <!-- <ion-datetime mode="ios" (ionChange)="setStartDate($event)" [min]="minDateEnableRedemptionLog"
                [max]="maxDateEnableRedemptionLog" id="startDate" class="dateTime"
                monthShortNames="{{ monthShortNames }}" name="startDate" displayFormat="DD/MMM/YYYY"
                placeholder="{{'set-datePage.start_date' | translate}}" [(ngModel)]="dateFrom">
              </ion-datetime> -->
              <mbsc-datepicker class="dateTime customDate" id="startDate" (onChange)="setStartDate($event)"
                dateFormat="DD MMM YYYY" [controls]="['date']" [touchUi]="true" [min]="minDateEnableRedemptionLog"
                [max]="maxDateEnableRedemptionLog" [placeholder]="'set-datePage.start_date'|translate"
                [(ngModel)]="dateFrom">
              </mbsc-datepicker>
            </ion-item>
          </ion-col>
          <ion-col size="6">
            <ion-item mode="ios" class="dateItem height-auto">
              <ion-icon color="primary" class="ca-calendar-yellow-icon calendarIcon"></ion-icon>
              <!-- <ion-datetime mode="ios" (ionChange)="setEndDate($event)" [max]="maxDateEnableRedemptionLog"
                [min]="minDateEnableRedemptionLog" id="endDate" [disabled]="!dateFrom" class="dateTime" name="startDate"
                monthShortNames="{{ monthShortNames }}" displayFormat="DD/MMM/YYYY"
                placeholder="{{'set-datePage.end_date' | translate}}" [(ngModel)]="dateTO">
              </ion-datetime> -->
              <mbsc-datepicker class="dateTime customDate" [(ngModel)]="dateTO" id="endDate"
                (onChange)="setEndDate($event)" dateFormat="DD MMM YYYY" [controls]="['date']" [touchUi]="true"
                [min]="minDateEnableRedemptionLog" [max]="maxDateEnableRedemptionLog"
                [placeholder]="'set-datePage.end_date'|translate" [disabled]="!dateFrom">
              </mbsc-datepicker>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!-- For View Reservations -->
      <ion-grid class="dates-grid" *ngIf="date === 'Custom' && typeCalender === 'ViewReservations'">
        <ion-row>
          <ion-col size="6">
            <ion-item mode="ios" class="dateItem height-auto">
              <ion-icon color="primary" class="ca-calendar-yellow-icon calendarIcon"></ion-icon>
              <!-- <ion-datetime mode="ios" (ionChange)="setStartDate($event)" [min]="minDate" [max]="maxData" id="startDate"
                class="dateTime" name="startDate" [(ngModel)]="dateFrom"
                placeholder="{{'set-datePage.start_date' | translate}}" displayFormat="DD/MMM/YYYY"
                monthShortNames="{{ monthShortNames }}">
              </ion-datetime> -->
              <mbsc-datepicker class="dateTime customDate" id="startDate" (onChange)="setStartDate($event)"
                dateFormat="DD MMM YYYY" [controls]="['date']" [touchUi]="true" 
                [placeholder]="'set-datePage.start_date'|translate" [(ngModel)]="dateFrom">
              </mbsc-datepicker>
            </ion-item>
          </ion-col>
          <ion-col size="6">
            <ion-item mode="ios" class="dateItem height-auto">
              <ion-icon color="primary" class="ca-calendar-yellow-icon calendarIcon"></ion-icon>
              <!-- <ion-datetime mode="ios" (ionChange)="setEndDate($event)" [max]="maxData" [min]="minDate" id="endDate"
                [disabled]="!dateFrom" class="dateTime" name="startDate" [(ngModel)]="dateTO"
                displayFormat="DD/MMM/YYYY" monthShortNames="{{ monthShortNames }}"
                placeholder="{{'set-datePage.end_date' | translate}}">
              </ion-datetime> -->
              <mbsc-datepicker class="dateTime customDate" [(ngModel)]="dateTO" id="endDate"
                (onChange)="setEndDate($event)" [min]="dateFrom"  dateFormat="DD MMM YYYY" [controls]="['date']" [touchUi]="true"
                 [placeholder]="'set-datePage.end_date'|translate"
                [disabled]="!dateFrom">
              </mbsc-datepicker>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-card-content>

    <!-- Update Button -->
    <ion-button mode="ios" expand="full" class="modalButton ion-no-margin primary-gradient"
      [disabled]=" !change || date =='Custom' && !dateTO " (click)="applyFilter()">
      {{'set-datePage.apply_filter' | translate}}</ion-button>
  </ion-card>
</div>