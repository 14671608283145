<!-- Edit profile and resetpage  when user comes this pages then hide validation  msg  -->
<div *ngIf="!hideValidation && !hideValidationReset &&!redeem">
  <ion-item lines="none" *ngIf="otpType === 'mobile' && !infoIconMob" class="no-padding height-auto">
    <!-- https://tlcgroup.atlassian.net/browse/PAT-1953 -->
    <!-- <ion-label position="stacked" class="validation-text ion-text-wrap">
      {{'activatePage.activation_msg_mob' | translate}}
    </ion-label> -->
  </ion-item>
</div>

<!-- Edit profile and resetpage  when user comes this pages then hide validation  msg  -->
<div *ngIf="!hideValidation && !hideValidationReset &&!redeem">
  <ion-item lines="none" *ngIf="otpType === 'email' && !infoIconEmail" class="no-padding height-auto">
    <!-- https://tlcgroup.atlassian.net/browse/PAT-1953 -->
    <!-- <ion-label position="stacked" class="validation-text  ion-text-wrap">
      {{'activatePage.activation_msg_email' | translate}}
    </ion-label> -->
  </ion-item>
</div>

<ion-item lines="none" class="otp-item-wrap no-padding ion-text-wrap" [ngClass]="{'inputFields': show === 'true'}">
  <div class="text-otp" *ngIf="!resendEnable">
    <ion-buttons>
      <ion-button class="btn-text" mode="ios" (click)="otpSend('requestOtp')">
        {{'activatePage.requst_otp' | translate}}
      </ion-button>
    </ion-buttons>
  </div>
  <div class="text-otp" *ngIf="resendEnable">
    <div>
      <ion-buttons>
        <ion-button class="btn-text" mode="ios" (click)="otpSend('resendOtp')" [disabled]="!enableResendAfterCount">
          {{'activatePage.resend_otp' | translate}}
        </ion-button>
      </ion-buttons>
      <span class="timer" *ngIf="!enableResendAfterCount">{{countDown}}</span>
    </div>
  </div>
  <ion-item class="inputFieldsWrap" lines="none">
    <ng-otp-input #otpAutoForReset (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
  </ion-item>
</ion-item>