<div class="customModal selectMemberShip" id="memberShipModal">
  <div class="customModal-dialog customModal-dialog-centered">
    <div class="customModal-content">
      <ion-buttons>
        <ion-icon slot="end" (click)="closeModal()" class="ca-modal-close"></ion-icon>
      </ion-buttons>
      <h3 class="customModal-title">{{'SpendersPage.select_member' | translate}}</h3>
      <div class="cardsWrap">
        <div *ngFor="let membership of memberships ; let i = index">
          <div class="membershipCardWrap" *ngFor="let items of membership.customerSets; let j = index">
            <div class="custom-radio">
              <input type="radio" [id]="i+'-'+j" name="radio" (click)="cardSelect(items,i)">
              <label [for]="i+'-'+j"></label>
            </div>
            <div class="membershipCard silverCard" [style.backgroundImage]="'url(' + items?.imageUrl + ')'">
              <div class="ca-memb-hotel__details">
                <div class="ca-member">
                  <div class="cardProfile">
                    <ion-avatar>
                      <img *ngIf="!response?.memberPhoto" [src]="'assets/imgs/user-img.png' ">
                      <img [src]="response?.memberPhoto" *ngIf="response?.memberPhoto">
                    </ion-avatar>
                    <div class="profileName">
                      <h2 class="prof">
                        {{response?.FirstName + '\t' + response?.LastName}}
                        <ion-icon color="primary" *ngIf="items?.highestSpender" class="ca-star-filled">
                        </ion-icon>
                        <ion-icon class="green ca-star-filled" *ngIf="items?.handleWithCare == 'true'">
                        </ion-icon>
                        <ion-icon color="warning" class="ca-star-filled" *ngIf="items?.highSpender == 'true'">
                        </ion-icon>
                      </h2>
                      <h5 class="id">{{items?.membershipNumber}}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cardName">
                <span>{{items?.level}}</span>
                <span class="validDate"> {{items?.validThruShow }}
                </span>
              </div>
            </div>

          </div>
        </div>
      </div>
      <ion-button mode="ios" expand="full" [disabled]="!memberProfileData" (click)="continueWithSelectedCard()"
        class="customModalButton primary-gradient">
        {{'SpendersPage.proceed' | translate}}
      </ion-button>
    </div>
  </div>
</div>