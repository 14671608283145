<div class="celebBackground">
    <ion-card class="modalWrap">
        <ion-card-header class="modalHeader">
            <h2 class="modalTitle">{{showHeading}}</h2>
            <ion-icon class="modalClose ca-close-icon" (click)="closeModal()"></ion-icon>
        </ion-card-header>
        <div class="scrolling-content">
            <ion-fab vertical="bottom" horizontal="end" *ngIf="showPDFUrl" slot="fixed">
                <ion-fab-button mini (click)="zoomIn()">
                    <ion-icon name="add"></ion-icon>
                </ion-fab-button>
                <ion-fab-button mini (click)="resetZoom()">
                    <ion-icon name="refresh"></ion-icon>
                </ion-fab-button>
                <ion-fab-button mini (click)="zoomOut()">
                    <ion-icon name="remove"></ion-icon>
                </ion-fab-button>
            </ion-fab>
            <pdf-viewer [src]="showPDFUrl" [zoom]="pdfZoom" [render-text]="true" [fit-to-page]="true"></pdf-viewer>
        </div>
    </ion-card>
</div>