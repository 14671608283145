import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { DashboardhomeService } from 'src/app/services/dashboardhome.service';
import { constManageReservationPage } from 'src/app/app.constants';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { RESERVATION_REGRET_MODAL } from 'src/app/app.constants';
@Component({
  selector: 'app-reservation-regret',
  templateUrl: './reservation-regret.component.html',
  styleUrls: ['./reservation-regret.component.scss'],
})
export class ReservationRegretComponent implements OnInit {
  resvationData: any = {};
  regretOptions: any;
  optionregretAll: any = [];
  buttonEnable: boolean;
  constRegret: any;
  getReservationSfid: any;

  constructor(
    private modalCtrl: ModalController,
    public appState: AppStateService,
    public dashboardService: DashboardhomeService,
    public common: CommonService,
    navParams: NavParams,
    public router: Router
  ) {
    this.getReservationSfid = navParams.get(RESERVATION_REGRET_MODAL.RESERVATION_SFID)
  }

  ngOnInit() {
    this.constRegret = constManageReservationPage
    this.regretOptions = this.appState.getAppData();
    this.optionregretAll = this.regretOptions.appInitData.regretReasons;  
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  chekcedFun() {
    this.buttonEnable = true;
    // //console.log("data==>", this.resvationData)
  }

  regrettedApi(statusReservation) {
    // this.common.presentLoader();
    this.dashboardService.resevationStatusType = statusReservation
    this.modalCtrl.dismiss({
      'reservationRegreatReason': this.resvationData
    })
  }

}
