<div *ngIf="!enableManageScreen">
  <div *ngIf="!skelton" class="fixed-width">
    <h1 class="for-web page-title">Awaiting Confirmation</h1>
    <ion-item lines="none" *ngIf="NolistMessage" class="available">
      <p class="messageShow">{{'closeChequePage.redemption_list_message'|translate}}</p>
    </ion-item>
    <ion-list class="reservations-list" *ngFor="let date of reservationDetailsArr">
      <ion-list-header class="reservation-date">
        {{'reservationAwaitingConfirmationPage.today'|translate}}{{date.reservationDate | date:' d MMMM, y'}}
      </ion-list-header>
      <ion-card class="reservation-card" *ngFor="let item of date.reservationDetails">
        <ion-item lines="none">
          <ion-avatar
            (click)="profile(item?.memberDetails.membershipNumber,item?.memberDetails.mobile,item?.memberDetails.email)"
            slot="start">
            <img [src]="item?.Photo ? item?.Photo +'?'+extenDate :'assets/imgs/user-img.png'">
          </ion-avatar>

          <ion-label class="ion-text-wrap"
            (click)="manageReservationNavigate(item.reservationID,item?.memberDetails.membershipNumber )">
            <h2>{{item?.memberDetails.firstName}} {{item?.memberDetails.lastName}}<ion-icon *ngIf="item.memberDetails.highestSpender =='true' "
                class="green ca-star-filled"></ion-icon>
              <ion-icon *ngIf="item.memberDetails.handleWithCare =='true' " class="ca-star-filled"></ion-icon>
            </h2>
            <p>{{item.reservationTime}}</p>
          </ion-label>

          <ion-icon (click)="manageReservationNavigate(item.reservationID,item?.memberDetails.membershipNumber )"
            slot="end" class="ca-right-arrow-icon">
          </ion-icon>
        </ion-item>
        <h6 class="delay-text" *ngIf="item?.reservationcreatedAt >= 1">{{item?.reservationcreatedAt}}
          {{'reservationAwaitingConfirmationPage.hour_delay'|translate}} <ion-icon class="ca-exclamation"></ion-icon>
        </h6>
      </ion-card>
    </ion-list>
    <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)">
      <ion-infinite-scroll-content loadingSpinner="bubbles"
        loadingText="{{'reservationAwaitingConfirmationPage.loading'|translate}}">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>
</div>

<div *ngIf="enableManageScreen">
  <ion-button class="backButton" fill="outline" (click)="backConfirmation()" mode="md" shape="round">
    <ion-icon mode="ios" class="ca-back"></ion-icon> &nbsp; Back
  </ion-button>
  <app-manage-reservation [sendSfid]="sendingSfid"></app-manage-reservation>
</div>

<!-- Skelton Text Loder -->
<div *ngIf="skelton" class="fixed-width">
  <ion-list class="reservations-list">

    <ion-list-header class="reservation-date">
      <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
    </ion-list-header>

    <ion-card class="reservation-card">
      <ion-item lines="none">
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <h2>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </h2>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
      <h6 class="delay-text">
        <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
      </h6>
    </ion-card>

    <ion-card class="reservation-card">
      <ion-item lines="none">
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <h2>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </h2>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
      <h6 class="delay-text">
        <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
      </h6>
    </ion-card>

    <ion-card class="reservation-card">
      <ion-item lines="none">
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <h2>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </h2>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
      <h6 class="delay-text">
        <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
      </h6>
    </ion-card>
  </ion-list>


  <ion-list class="reservations-list">

    <ion-list-header class="reservation-date">
      <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
    </ion-list-header>

    <ion-card class="reservation-card">
      <ion-item lines="none">
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <h2>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </h2>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
      <h6 class="delay-text">
        <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
      </h6>
    </ion-card>

    <ion-card class="reservation-card">
      <ion-item lines="none">
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <h2>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </h2>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
      <h6 class="delay-text">
        <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
      </h6>
    </ion-card>
  </ion-list>
</div>