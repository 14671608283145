
<div *ngIf="userDetailsView == false && orderDetailsView == false">
  <ion-item-group *ngIf="skeleton">
    <div *ngIf="titleShow=='Orders to be Booked' ">
      <div *ngFor="let item of tobeBookedArray ">
        <ion-item lines="none">
          <ion-label class="title">{{item.date | date:'d/MM/yyyy'}}</ion-label>
        </ion-item>
        <ion-list class="order-list" mode="ios" lines="none">
          <ion-item class="default-card order-item" *ngFor="let orderItem of item.data;">
            <ion-label slot="start" class="ion-text-wrap" >
              <span class="time">{{orderItem?.slotTime}}</span>
              <span (click)="userDetails(orderItem.memberId)">{{orderItem?.accountName}} </span>
            </ion-label>
            <ion-icon (click)="orderDetails(orderItem)" slot="start" color="primary" *ngIf="orderItem.orderType == 'Gifting'" class="ca-gift"></ion-icon>
            <ion-icon (click)="orderDetails(orderItem)" class="ca-takeaway" *ngIf="orderItem?.orderType=='Takeaway'" color="primary"></ion-icon>
            <ion-icon (click)="orderDetails(orderItem)" class="ca-delivery" *ngIf="orderItem?.orderType=='Delivery'" color="primary"></ion-icon>
            <!-- <ion-icon class="ca-gift" *ngIf="item.orderType=='delivery" color="success"></ion-icon> -->

            <ion-icon slot="end" color="primary" (click)="orderDetails(orderItem)" class="ca-right-arrow-icon">
            </ion-icon>
          </ion-item>
        </ion-list>
      </div>
    </div>


    
    <!-- all order , Deilvery, takeway  -->
    <div *ngIf="titleShow !='Orders to be Booked' ">
      <ion-item lines="none" *ngIf="orderListToday?.length!=0">
        <ion-label class="title"> Today, {{showDateToday | date:'d/MM/yyyy' }}</ion-label>
      </ion-item>
      <ion-list class="order-list" mode="ios" lines="none" *ngIf="orderListToday?.length!=0">
        <ion-item class="default-card order-item" *ngFor="let item of orderListToday;">
          <ion-label slot="start" class="ion-text-wrap" >
            <span class="time">{{item?.slotTime}}</span>
            <span (click)="userDetails(item.memberId)">{{item?.accountName}} </span>
          </ion-label>
          <ion-icon slot="start"  (click)="orderDetails(orderItem)" color="primary" *ngIf="item?.orderType== 'Gifting'" class="ca-gift"></ion-icon>
          <ion-icon class="ca-takeaway" (click)="orderDetails(orderItem)" *ngIf="item?.orderType=='Takeaway'" color="primary"></ion-icon>
          <ion-icon class="ca-delivery" (click)="orderDetails(orderItem)" *ngIf="item?.orderType=='Delivery'" color="primary"></ion-icon>
          <ion-icon slot="end" color="primary" (click)="orderDetails(item)" class="ca-right-arrow-icon"></ion-icon>
        </ion-item>
      </ion-list>

      <ion-item lines="none" *ngIf="orderListTomarrow?.length!=0">
        <ion-label class="title">Tomorrow, {{showDateTommorow | date:'d/MM/yyyy'}}</ion-label>
      </ion-item>

      <ion-list class="order-list" mode="ios" lines="none" *ngIf="orderListTomarrow?.length!=0">
        <ion-item class="default-card order-item" *ngFor="let items of orderListTomarrow;">
          <ion-label slot="start" class="ion-text-wrap">
            <span class="time">{{items?.slotTime}}</span>
            <span (click)="userDetails(items.memberId)">{{items?.accountName}} </span>
          </ion-label>
          <ion-icon slot="start" (click)="orderDetails(orderItem)" color="primary" *ngIf="items?.orderType == 'Gifting'" class="ca-gift"></ion-icon>
          <ion-icon class="ca-takeaway"  (click)="orderDetails(orderItem)" *ngIf="items?.orderType=='Takeaway'" color="primary"></ion-icon>
          <ion-icon class="ca-delivery" (click)="orderDetails(orderItem)"  *ngIf="items?.orderType=='Delivery'" color="primary"></ion-icon>
          <ion-icon slot="end" color="primary" (click)="orderDetails(items)" class="ca-right-arrow-icon"></ion-icon>
        </ion-item>
      </ion-list>
    </div>
  </ion-item-group>

  <ion-item-group *ngIf="!skeleton">
    <ion-item lines="none">
      <ion-label class="title">
        <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
      </ion-label>
    </ion-item>
    <ion-list class="order-list" mode="ios" lines="none">
      <ion-item class="default-card order-item">
        <ion-label slot="start" class="ion-text-wrap" style="display: flex; min-width: 95%;">
          <span style="width: 25%;" class="time">
            <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
          </span>
          <span style="width: 35%;">
            <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>
      <ion-item class="default-card order-item">
        <ion-label slot="start" class="ion-text-wrap" style="display: flex; min-width: 95%;">
          <span style="width: 25%;" class="time">
            <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
          </span>
          <span style="width: 35%;">
            <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>
      <ion-item class="default-card order-item">
        <ion-label slot="start" class="ion-text-wrap" style="display: flex; min-width: 95%;">
          <span style="width: 25%;" class="time">
            <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
          </span>
          <span style="width: 35%;">
            <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>
      <ion-item class="default-card order-item">
        <ion-label slot="start" class="ion-text-wrap" style="display: flex; min-width: 95%;">
          <span style="width: 25%;" class="time">
            <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
          </span>
          <span style="width: 35%;">
            <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>
      <ion-item class="default-card order-item">
        <ion-label slot="start" class="ion-text-wrap" style="display: flex; min-width: 95%;">
          <span style="width: 25%;" class="time">
            <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
          </span>
          <span style="width: 35%;">
            <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>
    </ion-list>
    <ion-item lines="none">
      <ion-label class="title">
        <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
      </ion-label>
    </ion-item>
    <ion-list class="order-list" mode="ios" lines="none">
      <ion-item class="default-card order-item">
        <ion-label slot="start" class="ion-text-wrap" style="display: flex; min-width: 95%;">
          <span style="width: 25%;" class="time">
            <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
          </span>
          <span style="width: 35%;">
            <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>
      <ion-item class="default-card order-item">
        <ion-label slot="start" class="ion-text-wrap" style="display: flex; min-width: 95%;">
          <span style="width: 25%;" class="time">
            <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
          </span>
          <span style="width: 35%;">
            <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>
      <ion-item class="default-card order-item">
        <ion-label slot="start" class="ion-text-wrap" style="display: flex; min-width: 95%;">
          <span style="width: 25%;" class="time">
            <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
          </span>
          <span style="width: 35%;">
            <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-item-group>
</div>
  <!-- <ion-infinite-scroll (ionInfinite)="doInfinite($event)">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll> -->
  
  <div *ngIf="userDetailsView == true" style="padding-top: 15px;">
    <ion-button class="backButton" fill="outline" (click)="bacToList('userDetails')" mode="md" shape="round">
      <ion-icon mode="ios" class="ca-back"></ion-icon> &nbsp; Back
    </ion-button>
    <app-userorder-details [sendUserorderView]="sendUserOrder"></app-userorder-details>
  </div>

  <div *ngIf="orderDetailsView== true" style="padding-top: 15px;">
    <ion-button class="backButton" fill="outline" (click)="bacToList('userOrderView')" mode="md" shape="round">
      <ion-icon mode="ios" class="ca-back"></ion-icon> &nbsp; Back
    </ion-button>
    <app-userview-order [sendOrderViewObj]="sendOrder"></app-userview-order>
  </div>
