import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { DashboardhomeService } from 'src/app/services/dashboardhome.service';
import { AppStateService } from 'src/app/services/init/app-state.service';

@Component({
  selector: 'app-delete-alert',
  templateUrl: './delete-alert.component.html',
  styleUrls: ['./delete-alert.component.scss'],
})
export class DeleteAlertComponent implements OnInit {
  sectionId;
  storageoutlet: any;
  deleteMessageRes: any;
  constructor(
    public modalCtrl: ModalController,
    public dashboardService: DashboardhomeService,
    private appState: AppStateService,
    private common: CommonService,
  ) { }

  ngOnInit() {
    

   }
  //  deleteOutletSection
  closeModal() {
    this.modalCtrl.dismiss();
  }

  

  delete(){
    this.storageoutlet = this.appState.getAppData();
    this.common.presentLoader();
    this.dashboardService.deleteOutletSection(this.storageoutlet.userDetails.outletId,this.sectionId).subscribe(res => {
      if (res) {
        console.log("res type",  res);
        this.common.dismissLoader();
        this.deleteMessageRes = res.statusMessage
        this.modalCtrl.dismiss(this.deleteMessageRes);
        this.closeModal();
      }
    }, err => {
      this.common.dismissLoader();
      this.common.errorHandler(err)
    })

  }

}