import {  ActivatedRouteSnapshot, CanActivate, CanDeactivate, NavigationEnd, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Component, Injectable} from '@angular/core';
import { LOGIN_PAGE } from '../app.constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

  export class DeactivateGuard implements CanActivate { 
    tokenLocal: string;

  canActivate(): Observable < boolean > {
    console.log("canDeactivate",localStorage.getItem(LOGIN_PAGE.loggedUser))
    this.tokenLocal = localStorage.getItem(LOGIN_PAGE.loggedUser)

    if (this.tokenLocal=='true') {
      console.log(" canDeactivate inside",this.tokenLocal)
      return Observable.of(false);
    
      
    } else {
      console.log(" canDeactivate else",this.tokenLocal)
     
      return Observable.of(true);
     
    }
  }


  // CanActivate() {
  
    
    
  // }

}