<ion-list class="mambers-list" *ngIf="!skeleton">
    <h1 class="for-web page-title">Redemption & Close</h1>
    <ion-item lines="none" *ngIf="listMessage" class="available">
        <p class="messageShow">{{'closeChequePage.redemption_list_message'|translate}}</p>
    </ion-item>
    <ion-card class="default-card member-card" *ngFor="let items of redeemListData ; let i = index ">
        <ion-item lines="full">
            <ion-avatar (click)="profile(items?.memberDetails?.membershipNumber)" slot="start">
                <img [src]="items?.memberDetails?.Photo || 'assets/imgs/user-img.png'" />
            </ion-avatar>
            <ion-label class="ion-text-wrap">
                <h2>{{items?.memberDetails?.firstName}}&nbsp;{{items?.memberDetails?.lastName}} </h2>
                <p *ngIf="items?.transactionCode!=''">
                    {{'closeChequePage.transaction_code'|translate}}:&nbsp;{{items?.transactionCode}}</p>
                <p class="offerText">{{items?.benefitDescription}}</p>
            </ion-label>
        </ion-item>
        <ion-buttons class="ion-justify-content-center btns-col">
            <ion-button color="primary" class="chequeBtn"
                [disabled]="!ionicStorageEmployeeId?.userPageAccess?.userPageAccess?.includes('POST_SETTLE_CHEQUE')"
                (click)="openModal(items,ionicStorageEmployeeId.userDetails.outletType,items?.memberDetails?.membershipNumber,i) ">
                {{'closeChequePage.cheque_settled'|translate}}
            </ion-button>

            <ion-button color="danger" class="cencel"
                [disabled]="!ionicStorageEmployeeId?.userPageAccess?.userPageAccess?.includes('POST_REDEEM_CANCEL')"
                (click)="cancelCloseChequeModalRedemption(items,i)">
                {{'closeChequePage.cancel'|translate}}
            </ion-button>
        </ion-buttons>
    </ion-card>
</ion-list>

<ion-list class="mambers-list" *ngIf="skeleton">
    <ion-card class="default-card member-card">
        <ion-item lines="full">
            <ion-avatar slot="start">
                <ion-skeleton-text width="80%"></ion-skeleton-text>
            </ion-avatar>
            <ion-label class="ion-text-wrap">
                <ion-label>
                    <h2>
                        <ion-skeleton-text animated width="40%"></ion-skeleton-text>
                    </h2>
                    <p>
                        <ion-skeleton-text animated width="80%"></ion-skeleton-text>
                    </p>
                    <p class="offerText">
                        <ion-skeleton-text animated width="70%"></ion-skeleton-text>
                    </p>
                </ion-label>
            </ion-label>
        </ion-item>
        <ion-buttons class="ion-justify-content-center">
            <h2 class="chequeBtn">
                <ion-skeleton-text animated width="40%" style="margin: 4px auto;"></ion-skeleton-text>
            </h2>
        </ion-buttons>
    </ion-card>
</ion-list>
<ion-infinite-scroll (ionInfinite)="doInfinite($event)">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
</ion-infinite-scroll>


<!-- Modal -->
<div class="customModal guestModal" id="customModal">
    <div class="customModal-dialog customModal-dialog-centered">
        <div class="customModal-content">
            <ion-buttons>
                <ion-icon slot="end" (click)="closeModal()" class="ca-modal-close"></ion-icon>
            </ion-buttons>
            <div class="customModal-body ion-text-center">
                <div class="customModal-text">
                    <h2 style="margin-bottom: 10px;">
                        <ion-text color="primary">
                            {{userDetailsModal?.memberDetails?.firstName}}&nbsp;{{userDetailsModal?.memberDetails?.lastName}}&nbsp;Has
                            Arrived
                        </ion-text>
                    </h2>
                    <ion-item mode="ios" lines="none" class="form-group height-auto"
                        *ngIf="ionicStorageEmployeeId.userDetails.outletType =='Restaurant'">
                        <ion-label class="ion-text-left">
                            <p>{{'awaitArrival.enter_table_number' | translate}}</p>
                        </ion-label>
                        <div class="position-relative" style="margin-left: 10px;">
                            <ion-select class="tableNumberSelect" [(ngModel)]="tableNo" [placeholder]="tableNo" interface="popover"
                                [interfaceOptions]="popoverOptions">
                                <ion-select-option *ngFor="let table of allVaccanTable" [value]="table?.tableSfid">{{table?.tableNum}}</ion-select-option>
                            </ion-select>
                            <ion-icon slot="end" style="right:0"
                                class="select-arrow ca-down-arrow-yellow-icon"></ion-icon>
                        </div>
                        <!-- <ion-input slot="end" mode="ios" class="ion-text-center smallInput" type="tel" maxlength="3"
                            [(ngModel)]="tableNo">
                        </ion-input> -->
                    </ion-item>
                    <ion-item mode="ios" lines="none" class="form-group height-auto">
                        <ion-label class="ion-text-center">
                            <p>{{'awaitArrival.enter_check_number' | translate}}</p>
                        </ion-label>
                        <ion-input slot="end" mode="ios" class="ion-text-center smallInput" type="text" maxlength="10"
                            [(ngModel)]="checkNo">
                        </ion-input>
                    </ion-item>

                    <ion-item mode="ios" lines="none" class="form-group height-auto">
                        <ion-label class="ion-text-center">
                            <p>{{'closeChequePage.addnetAmount' | translate}} ({{showCurrency.currencySymbol}})</p>
                        </ion-label>
                        <ion-input slot="end" mode="ios" maxlength="9" (keypress)="onChange($event)"
                            (ionChange)="check()" class="ion-text-center smallInput" type="text"
                            [(ngModel)]="netAmount">
                        </ion-input>
                    </ion-item>

                    <!-- Guest section  -->
                    <ion-item mode="ios" lines="none" class="form-group height-auto
          guest-card" *ngIf="ionicStorageEmployeeId.userDetails.outletType =='Restaurant'
           || ionicStorageEmployeeId.userDetails.outletType=='Spa' 
           || ionicStorageEmployeeId.userDetails.outletType=='Accommodation'
           ">
                        <ion-label slot="start" class="ion-text-wrap guest_count">
                            {{guestCount}}
                            <span *ngIf="guestCount>1">{{'reservationDetailsModal.guests'| translate}}</span>
                            <span *ngIf="guestCount <= 1">{{'reservationDetailsModal.guest'| translate}}</span>

                        </ion-label>
                        <ion-buttons slot="end">
                            <ion-button color="primary" fill="outline" (click)="onDecrement('restaurant','guest')">
                                <ion-icon mode="ios" name="remove"></ion-icon>
                            </ion-button>
                            <ion-button color="primary" fill="outline" (click)="addCounter('restaurant','guest')">
                                <ion-icon mode="ios" name="add"></ion-icon>
                            </ion-button>
                        </ion-buttons>
                    </ion-item>
                    <!-- Guest Section  End -->

                    <!-- Adult and child section  -->
                    <ion-item mode="ios" lines="none" class="form-group height-auto
          guest-card" *ngIf="ionicStorageEmployeeId.userDetails.outletType =='Pool'">
                        <ion-label slot="start" class="ion-text-wrap guest_count">
                            {{adultCount}}
                            <span *ngIf="adultCount>1">{{'reservationDetailsModal.adults'| translate}}</span>
                            <span *ngIf="adultCount <= 1">{{'reservationDetailsModal.adult'| translate}}</span>

                        </ion-label>
                        <ion-buttons slot="end">
                            <ion-button color="primary" fill="outline" (click)="onDecrement('pool','adult')">

                                <ion-icon mode="ios" name="remove"></ion-icon>
                            </ion-button>
                            <ion-button color="primary" fill="outline" (click)="addCounter('pool','adult')">

                                <ion-icon mode="ios" name="add"></ion-icon>
                            </ion-button>
                        </ion-buttons>
                    </ion-item>
                    <ion-item mode="ios" lines="none" class="form-group height-auto
                    guest-card" *ngIf="ionicStorageEmployeeId.userDetails.outletType =='Pool'">
                        <!-- <ion-label slot="start" class="ion-text-wrap guest_count">
                            {{childCount}}
                            <span *ngIf="childCount>1">{{'reservationDetailsModal.kids'| translate}} </span>
                            <span *ngIf="childCount == '0' || childCount == '1' ">{{'reservationDetailsModal.kid'|
                                translate}}
                            </span>

                        </ion-label>
                        <ion-buttons slot="end">
                            <ion-button color="primary" fill="outline" (click)="onDecrement('pool','child')">

                                <ion-icon mode="ios" name="remove"></ion-icon>
                            </ion-button>
                            <ion-button color="primary" fill="outline" (click)="addCounter('pool','child')">

                                <ion-icon mode="ios" name="add"></ion-icon>
                            </ion-button>
                        </ion-buttons> -->
                    </ion-item>
                    <!-- Adult and child Section  End -->

                </div>
            </div>
            <div class="customModal-footer">


                <!-- This button show when user comes from  Spa , Accommodation -->
                <ion-button mode="ios" expand="full" class="customModalButton primary-gradient" *ngIf="
                ionicStorageEmployeeId.userDetails.outletType=='Spa' 
           || ionicStorageEmployeeId.userDetails.outletType=='Accommodation'"
                    [disabled]="checkNo?.length < 2 || !amountEnable || 1 > guestCount  "
                    (click)="confirmModal(userDetailsModal.reservationID)">
                    {{'awaitArrival.confirm'|translate}}

                </ion-button>

                <!-- This button show when user comes from  Restaurant  -->

                <ion-button mode="ios" expand="full" class="customModalButton primary-gradient" *ngIf="
                ionicStorageEmployeeId.userDetails.outletType =='Restaurant' "
                    [disabled]="checkNo?.length < 2 || !amountEnable || 1 > guestCount || !tableNo "
                    (click)="confirmModal(userDetailsModal.reservationID)">
                    <!-- {{'awaitArrival.confirm'|translate}} -->
                    {{'awaitArrival.confirm'|translate}}
                </ion-button>

                <!-- This button show when user comes from pool  -->
                <ion-button mode="ios" expand="full" class="customModalButton primary-gradient"
                    *ngIf="ionicStorageEmployeeId.userDetails.outletType =='Pool' "
                    [disabled]="checkNo?.length < 2 || !amountEnable || 1 > adultCount "
                    (click)="confirmModal(userDetailsModal.reservationID)">
                    {{'awaitArrival.confirm'|translate}}
                </ion-button>
            </div>
        </div>
    </div>
</div>




<!-- Modal -->

<div class="customModal" id="closeChequeId">
    <div class="customModal-dialog customModal-dialog-centered">
        <div class="customModal-content">

            <ion-buttons>
                <ion-icon slot="end" (click)="closeChequeModal()" class="ca-modal-close"></ion-icon>
            </ion-buttons>
            <div class="customModal-body ion-text-center">
                <div class="customModal-text">
                    <p>{{'manageReservationPage.pleaseEnterTransaction'|translate}}
                    </p>
                </div>
            </div>
            <div class="customModal-footer">
                <ion-button mode="ios" class="customModalButton primary-gradient" expand="full"
                    (click)="openModalCheque(userDetailsModal?.reservationID,userDetailsModal?.memberDetails?.membershipNumber)">
                    {{'closeChequePage.got_it'|translate}}
                </ion-button>
            </div>
        </div>
    </div>
</div>


<!-- Cancel Close Cheque -->
<div class="customModal" id="cancelCloseCheque">
    <div class="customModal-dialog customModal-dialog-centered">
        <div class="customModal-content">
            <!-- <ion-buttons>
          <ion-icon slot="end"></ion-icon>
        </ion-buttons> -->
            <div class="customModal-body ion-text-center">
                <div class="customModal-text">
                    <p>{{'manageReservationPage.cancelCloseChequq'|translate}}
                </div>
            </div>
            <div class="customModal-footer two-btn">
                <ion-button mode="ios" class="customModalButton light-gradient" expand="full"
                    (click)="removeCloseChequeModal()">
                    {{'manageReservationPage.cancel' | translate}}
                </ion-button>
                <ion-button mode="ios" class="customModalButton primary-gradient" expand="full"
                    (click)="cancelCloseChequqGotIt()">
                    {{'manageReservationPage.ok' | translate}} </ion-button>
            </div>
        </div>
    </div>
</div>

<!-- Modal  already table assign -->
<div class="customModal guestModal" id="alreadyTable">
    <div class="customModal-dialog customModal-dialog-centered">
        <div class="customModal-content">
            <ion-buttons>
                <ion-icon slot="end" (click)="alreadyTablecloseModal()" class="ca-modal-close"></ion-icon>
            </ion-buttons>
            <div class="customModal-body ion-text-center">
                <div class="customModal-text">
                    <h2 style="margin-bottom: 10px;">
                        <ion-text color="primary">
                            {{userDetailsModal?.memberDetails?.firstName}}&nbsp;{{userDetailsModal?.memberDetails?.lastName}}&nbsp;Has
                            Arrived
                        </ion-text>
                    </h2>
                    <ion-item mode="ios" lines="none" class="form-group height-auto"
                        *ngIf="ionicStorageEmployeeId.userDetails.outletType =='Restaurant'">
                        <ion-label class="ion-text-left">
                            <p>{{'awaitArrival.enter_table_number' | translate}}</p>
                        </ion-label>
                        <div class="position-relative" style="margin-left: 10px;">
                            <ion-select class="tableNumberSelect"  [disabled]="tableNo !=''" [placeholder]="tableNo" interface="popover"
                                [interfaceOptions]="popoverOptions">
                                <ion-select-option *ngFor="let table of allVaccanTable" [value]="table?.tableSfid">{{table?.tableNum}}</ion-select-option>
                            </ion-select>
                            <ion-icon slot="end" style="right:0"  [hidden]="tableNo !=''"
                                class="select-arrow ca-down-arrow-yellow-icon"></ion-icon>
                        </div>
                        <!-- <ion-input slot="end" mode="ios" *ngIf="tableNo !='' " class="ion-text-center smallInput"
                            type="tel" maxlength="3" [(ngModel)]="tableNo" [disabled]="tableNo !='' ">
                        </ion-input> -->
                    </ion-item>
                    <ion-item mode="ios" lines="none" class="form-group height-auto">
                        <ion-label class="ion-text-center">
                            <p>{{'awaitArrival.enter_check_number' | translate}}</p>
                        </ion-label>
                        <ion-input slot="end" mode="ios"  class="ion-text-center smallInput"
                            type="text" maxlength="10" [(ngModel)]="checkNo" >
                        </ion-input>
                    </ion-item>

                    <ion-item mode="ios" lines="none" class="form-group height-auto">
                        <ion-label class="ion-text-center">
                            <p>{{'closeChequePage.addnetAmount' | translate}} ({{showCurrency.currencySymbol}})</p>
                        </ion-label>
                        <ion-input slot="end" mode="ios" maxlength="9" (keypress)="onChange($event)"
                            (ionChange)="check()" class="ion-text-center smallInput" type="text"
                            [(ngModel)]="netAmount">
                        </ion-input>
                    </ion-item>

                    <!-- Guest section  -->
                    <ion-item mode="ios" lines="none" class="form-group height-auto
          guest-card" *ngIf="ionicStorageEmployeeId.userDetails.outletType =='Restaurant'
           || ionicStorageEmployeeId.userDetails.outletType=='Spa' 
           || ionicStorageEmployeeId.userDetails.outletType=='Accommodation'
           ">
                        <ion-label slot="start" class="ion-text-wrap guest_count">
                            {{guestCount}}
                            <span *ngIf="guestCount>1">{{'reservationDetailsModal.guests'| translate}}</span>
                            <span *ngIf="guestCount <= 1">{{'reservationDetailsModal.guest'| translate}}</span>

                        </ion-label>
                        <ion-buttons slot="end">
                            <ion-button color="primary" fill="outline" (click)="onDecrement('restaurant','guest')">
                                <ion-icon mode="ios" name="remove"></ion-icon>
                            </ion-button>
                            <ion-button color="primary" fill="outline" (click)="addCounter('restaurant','guest')">
                                <ion-icon mode="ios" name="add"></ion-icon>
                            </ion-button>
                        </ion-buttons>
                    </ion-item>
                    <!-- Guest Section  End -->

                    <!-- Adult and child section  -->
                    <ion-item mode="ios" lines="none" class="form-group height-auto
          guest-card" *ngIf="ionicStorageEmployeeId.userDetails.outletType =='Pool'">
                        <ion-label slot="start" class="ion-text-wrap guest_count">
                            {{adultCount}}
                            <span *ngIf="adultCount>1">{{'reservationDetailsModal.adults'| translate}}</span>
                            <span *ngIf="adultCount <= 1">{{'reservationDetailsModal.adult'| translate}}</span>

                        </ion-label>
                        <ion-buttons slot="end">
                            <ion-button color="primary" fill="outline" (click)="onDecrement('pool','adult')">

                                <ion-icon mode="ios" name="remove"></ion-icon>
                            </ion-button>
                            <ion-button color="primary" fill="outline" (click)="addCounter('pool','adult')">

                                <ion-icon mode="ios" name="add"></ion-icon>
                            </ion-button>
                        </ion-buttons>
                    </ion-item>

                    <!-- Adult and child Section  End -->

                </div>
            </div>
            <div class="customModal-footer">

                <!-- This button show when user comes from  Spa , Accommodation -->

                <ion-button mode="ios" expand="full" class="customModalButton primary-gradient" *ngIf="ionicStorageEmployeeId.userDetails.outletType=='Spa' 
           || ionicStorageEmployeeId.userDetails.outletType=='Accommodation'"
                    [disabled]="checkNo?.length < 2  || !amountEnable  ||   1 > guestCount "
                    (click)="confirmModalAreadyAssign(userDetailsModal.reservationID)">
                    {{'awaitArrival.confirm'|translate}}
                </ion-button>

                <!-- This button show when user comes from Restaurant-->

                <ion-button mode="ios" expand="full" class="customModalButton primary-gradient"
                    *ngIf="ionicStorageEmployeeId.userDetails.outletType =='Restaurant'"
                    [disabled]="checkNo?.length < 2  || !amountEnable  ||   1 > guestCount  || !tableNo"
                    (click)="confirmModalAreadyAssign(userDetailsModal.reservationID)">
                    {{'awaitArrival.confirm'|translate}}
                </ion-button>
                <!-- This button show when user comes from pool  -->

                <ion-button mode="ios" expand="full" class="customModalButton primary-gradient"
                    *ngIf="ionicStorageEmployeeId.userDetails.outletType =='Pool'"
                    [disabled]="checkNo?.length < 2  || !amountEnable  ||   1 > adultCount "
                    (click)="confirmModalAreadyAssign(userDetailsModal.reservationID)">
                    {{'awaitArrival.confirm'|translate}}
                </ion-button>

            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="customModal" id="confirSettleCheque">
    <div class="customModal-dialog customModal-dialog-centered">
        <div class="customModal-content">
            <!-- <ion-buttons>
          <ion-icon slot="end" class="ca-modal-close"></ion-icon>
        </ion-buttons> -->
            <div class="customModal-body ion-text-center">
                <div class="reservation-confirm-customModal-text">
                    <h2 style="margin-bottom: 20px;font-weight: bolder;font-size: 14px;"
                        [innerHTML]="reservationConfirmationPara"></h2>
                    <p>{{reschequesettle?.statusMessage}}</p>
                </div>
            </div>
            <div class="customModal-footer">
                <ion-button mode="ios" class="customModalButton primary-gradient" (click)="settleChequePopup()"
                    expand="full">
                    Got it
                </ion-button>
            </div>
        </div>
    </div>
</div>