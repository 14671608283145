import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeDuplicateItems'
})
export class RemoveDuplicateItemsPipe implements PipeTransform {

  // transform(value: any, args?: any): any {
  //   ////console.log('pipeCalled =>',value)
  //   return null;
  // }

  transform(areaList : any): any[] {
    if (areaList) {
      ////console.log('PIPE ==>',areaList)
      return;
        // return areaList.filter((listing: any) => listing.area_name === areaname);
    }
}

}
