<ion-item-group class="page-content fixed-width" *ngIf="!enablecreateReservation">
  <div [formGroup]="membershipDetailForm">

    <h1 class="page-title for-web">Make a Reservation</h1>

    <!-- Room number Card Start-->
    <ion-card class="default-card">
      <ion-item mode="ios" lines="none" class="roomNumberItem">
        <ion-input type="text" maxlength="5" formControlName="roomNumber" [placeholder]="'Enter Room number'">
        </ion-input>
        <!-- <ion-label position="stacked" color="danger" class="validation-text">{{'addClubPage.validation_email_pattern'
            | translate}}</ion-label> -->
      </ion-item>
    </ion-card>
    <!-- Room number Card End-->

    <!-- Mobile Card -->
    <ion-card class="default-card account_card">

      <ion-card-content (click)="changeFocus('mobile')">
        <ion-item lines="none" class="height-auto ion-no-padding">
          <ion-label style="min-width: 75%;" class="ion-no-margin">
            <p class="input-label">{{'loginPage.enter_with_mob' | translate}}</p>
            <p class="input-value" *ngIf="mobileVerified && expandBlock !== 'mobile'">
              {{ ('+' + membershipDetailForm?.value?.countryCodeSelected?.dialCode +
              membershipDetailForm?.value?.mobile) | mask: 'mobile' }}
            </p>
          </ion-label>
          <ion-label class="ion-no-margin text-right verified-text" *ngIf="mobileVerified && expandBlock !== 'mobile'">
            <ion-text color="primary">{{'activatePage.verified' | translate}}</ion-text>
            <ion-icon class="ca-verified "></ion-icon>
          </ion-label>
        </ion-item>

        <div *ngIf="!showMakeReservation">
          <ion-item mode="ios" lines="none" *ngIf="expandBlock === 'mobile'" class="no-padding">
            <div class="phoneWrap">
              <p class="countryValue">{{'+' + membershipDetailForm?.value?.countryCodeSelected?.dialCode}} &nbsp;
                <ion-icon class="ca-down-arrow-yellow-icon"></ion-icon>
              </p>
              <ion-select class="countryCode" formControlName="countryCodeSelected"
                okText="{{'loginPage.ok'|translate}}" cancelText="{{'loginPage.cancel'|translate}}" mode="md"
                [interfaceOptions]="countryCodeInterface">
                <ion-select-option *ngFor="let country of countries" [value]="country">
                  {{'+' + country?.dialCode + '\t' + country?.name}}</ion-select-option>
              </ion-select>
              <ion-input mode="ios" clearOnEdit="false" (ngModelChange)="onInputTwo('mobile')" formControlName="mobile"
                name="userMobileNum" [placeholder]="'activatePage.mobile_number' | translate" type="tel" class="phone"
                maxlength="16">
              </ion-input>
            </div>
            <ion-label position="stacked" color="danger"
              *ngIf="membershipDetailForm?.value?.mobile && membershipDetailForm?.errors && membershipDetailForm?.errors?.isInvalidNumber"
              class="validation-text">{{'loginPage.validation_msg_mobile' | translate}}</ion-label>
          </ion-item>
        </div>

        <div *ngIf="showMakeReservation">
          <ion-item mode="ios" lines="none" *ngIf="expandBlock === 'mobile'" class="no-padding">

            <div class="phoneWrap">
              <p class="countryValue">{{'+' + membershipDetailForm?.value?.countryCodeSelected?.dialCode}} &nbsp;
                <ion-icon class="ca-down-arrow-yellow-icon"></ion-icon>
              </p>
              <ion-select class="countryCode" formControlName="countryCodeSelected" (ionChange)="getcountryCode()"
                okText="{{'loginPage.ok'|translate}}" cancelText="{{'loginPage.cancel'|translate}}" mode="md"
                [interfaceOptions]="countryCodeInterface">
                <ion-select-option *ngFor="let country of countries" [value]="country">
                  {{'+' + country?.dialCode + '\t' + country?.name}}</ion-select-option>
              </ion-select>
              <ion-input mode="ios" clearOnEdit="false" (ionInput)="onInput('mobile',$event)" formControlName="mobile"
                name="userMobileNum" [placeholder]="'activatePage.mobile_number' | translate" type="tel" class="phone"
                maxlength="16">
              </ion-input>
            </div>
            <ion-label position="stacked" color="danger"
              *ngIf="membershipDetailForm?.value?.mobile && membershipDetailForm?.errors && membershipDetailForm?.errors?.isInvalidNumber"
              class="validation-text">{{'loginPage.validation_msg_mobile' | translate}}</ion-label>

          </ion-item>
        </div>
      </ion-card-content>

      <div *ngIf="!showMakeReservation">
        <app-otp [otpType]="'mobile'" [openReservation]="'true'" (openReservationOtpVerified)="checkOtpVerified($event)"
          [hideValidation]="'true'"
          *ngIf="membershipDetailForm?.value?.mobile && !membershipDetailForm?.errors && !membershipDetailForm?.errors?.isInvalidNumber && expandBlock === 'mobile'"
          [resetRequest]="'false'" [sendDataForOtpData]="true">
        </app-otp>
      </div>


    </ion-card>
    <!-- End Mobile card  -->

    <!-- Membership Number -->
    <ion-card class="default-card account_card" [hidden]="!membershipDetailForm?.value?.membershipNumber">

      <ion-card-content>
        <ion-item lines="none" class="height-auto ion-no-padding">
          <ion-label style="min-width: 75%;" class="ion-no-margin">
            <p class="input-label">{{'walkinPage.membership' | translate}}</p>
            <p class="input-value">{{ membershipDetailForm?.value?.membershipNumber }}</p>
          </ion-label>
        </ion-item>
      </ion-card-content>

    </ion-card>

    <!-- Name Card Start-->
    <ion-card mode="ios" class="default-card userNameCard">
      <ion-card-content mode="ios" class="">
        <ion-label class="inputLabel ion-no-margin" (click)="changeFocus('name')">
          <p class="input-label">{{'walkinPage.enter_name' | translate}}</p>
          <p class="input-value"
            *ngIf="expandBlock !== 'name'&& membershipDetailForm?.value?.firstName && membershipDetailForm?.controls['firstName']?.valid">
            {{
            (membershipDetailForm?.value?.salutation?.Value || '') + '\t' +
            membershipDetailForm?.value?.firstName + '\t' +
            membershipDetailForm?.value?.lastName
            }}
          </p>
        </ion-label>
        <div class="userNameBlock" [hidden]="expandBlock !== 'name'">
          <div class="selectWrapBox">
            <ion-select class="selectField" mode="md" [interfaceOptions]="salutationInterface"
              [disabled]="disalbedNameCard" [placeholder]="salutations && salutations[0] ? salutations[0]?.Value : ''"
              formControlName="salutation">
              <ion-select-option *ngFor="let salutation of salutations" [value]="salutation">{{salutation?.Value}}
              </ion-select-option>
            </ion-select>
            <ion-icon color="primary" class="ca-down-arrow-yellow-icon select-arrow"></ion-icon>
          </div>
          <div class="userName">
            <div class="userFirstName">
              <ion-input mode="ios" [disabled]="disalbedNameCard" autocapitalize name="userFirstName"
                formControlName="firstName" type="text" class="inputField"
                [placeholder]="'activateReservationPage.first' | translate" maxlength="25">
              </ion-input>
              <p class="errornamemsg"
                *ngIf="membershipDetailForm?.value?.firstName && membershipDetailForm?.controls['firstName']?.errors">
                <ion-text color="danger">{{'openReservation.invalidFirstName' | translate}}</ion-text>
              </p>
            </div>
            <div class='userLastName'>
              <ion-input mode="ios" autocapitalize name="userLastName" [disabled]="disalbedNameCard"
                formControlName="lastName" type="text" class="inputField"
                [placeholder]="'activateReservationPage.last' | translate" maxlength="25">
              </ion-input>
              <p class="errornamemsg"
                *ngIf="membershipDetailForm?.value?.lastName && membershipDetailForm?.controls['lastName']?.errors">
                <ion-text color="danger">{{'openReservation.invalidLastName' | translate}}</ion-text>
              </p>
            </div>
          </div>
        </div>
      </ion-card-content>
    </ion-card>
    <!-- Name Card End-->

    <!-- Start Email card  -->
    <!-- Email Without otp  -->
    <ion-card class="default-card" *ngIf="showMakeReservation">
      <ion-card-content>
        <ion-item lines="none" class="height-auto ion-no-padding"
          (click)="(!memberProfileData) ? changeFocus('email') : ''">
          <ion-label style="min-width: 75%;" class="ion-no-margin">
            <p class="input-label">{{'loginPage.enter_email_key' | translate}}</p>
            <p class="input-label" *ngIf="!emailVerified && expandBlock !== 'email'">
              {{membershipDetailForm?.value?.email}}</p>
            <p class="input-value" *ngIf="expandBlock !== 'email' && emailVerified">
              {{membershipDetailForm?.value?.email | mask: 'email'}}</p>
          </ion-label>
          <ion-label class="ion-no-margin text-right verified-text" *ngIf="expandBlock !== 'email' && emailVerified">
            <ion-text color="primary">{{'activatePage.verified' | translate}}</ion-text>
            <ion-icon class="ca-verified "></ion-icon>
          </ion-label>
        </ion-item>
        <ion-item mode="ios" lines="none" *ngIf="expandBlock === 'email'" class="no-padding">
          <ion-input type="email" [disabled]="disalbedNameCard" formControlName="email"
            (ngModelChange)="onInput('email')"></ion-input>
          <ion-label position="stacked" color="danger"
            *ngIf="membershipDetailForm?.value?.email && membershipDetailForm?.controls['email'].errors"
            class="validation-text">{{'addClubPage.validation_email_pattern' | translate}}</ion-label>
        </ion-item>
      </ion-card-content>
      <!-- <app-otp [otpType]="'email'" [openReservation]="'true'" (openReservationOtpVerified)="checkOtpVerified($event)"
        [hideValidation]="'true'" *ngIf="expandBlock === 'email' && membershipDetailForm?.controls['email'].valid "
        [resetRequest]="'false'" [sendDataForOtpData]="true" [hidden]='disalbedNameCard'>
      </app-otp> -->
    </ion-card>

    <!-- Email with OTP  -->
    <ion-card class="default-card" *ngIf="!showMakeReservation">
      <ion-card-content>
        <ion-item lines="none" class="height-auto ion-no-padding"
          (click)="(!memberProfileData) ? changeFocus('email') : ''">
          <ion-label style="min-width: 75%;" class="ion-no-margin">
            <p class="input-label">{{'loginPage.enter_email_key' | translate}}</p>
            <p class="input-value" *ngIf="expandBlock !== 'email' && emailVerified">
              {{membershipDetailForm?.value?.email | mask: 'email'}}</p>
          </ion-label>
          <ion-label class="ion-no-margin text-right verified-text" *ngIf="expandBlock !== 'email' && emailVerified">
            <ion-text color="primary">{{'activatePage.verified' | translate}}</ion-text>
            <ion-icon class="ca-verified "></ion-icon>
          </ion-label>
        </ion-item>
        <ion-item mode="ios" lines="none" *ngIf="expandBlock === 'email'" class="no-padding">
          <ion-input type="email" [disabled]="disalbedNameCard" formControlName="email"
            (ngModelChange)="onInputTwo('email')" [placeholder]="'loginPage.enter_email_key' | translate"></ion-input>
          <ion-label position="stacked" color="danger"
            *ngIf="membershipDetailForm?.value?.email && membershipDetailForm?.controls['email'].errors"
            class="validation-text">{{'addClubPage.validation_email_pattern' | translate}}</ion-label>
        </ion-item>
      </ion-card-content>

      <app-otp [otpType]="'email'" [openReservation]="'true'" (openReservationOtpVerified)="checkOtpVerified($event)"
        [hideValidation]="'true'" *ngIf="expandBlock === 'email' && membershipDetailForm?.controls['email'].valid "
        [resetRequest]="'false'" [sendDataForOtpData]="true" [hidden]='disalbedNameCard'>
      </app-otp>
    </ion-card>

    <!-- End email card  -->

    <!-- Restaurant Card -->
    <ion-card class="default-card">
      <ion-item lines="none" class="roomNumberItem">
        <ion-label style="display: none;">{{'benefitsModal.restaurantName' | translate}}</ion-label>
        <ion-select class="restaurantSelect" mode="md" formControlName="selectedOutlet"
          [placeholder]="'benefitsModal.restaurantName' | translate">
          <ion-select-option *ngFor="let outlet of outletsList" [value]="outlet?.outletId">{{outlet?.outletName}}
          </ion-select-option>
        </ion-select>
        <ion-icon class="select-arrow ca-down-arrow-yellow-icon"></ion-icon>
      </ion-item>
    </ion-card>

    <ion-card class="default-card selectBenefitsCard" id="benefitCard"
      *ngIf="mobileVerified && emailVerified && membershipDetailForm?.controls['firstName'].valid && membershipDetailForm?.controls['lastName'].valid">
      <ion-item lines="none" (click)="expandBenefits('promocode')">
        <ion-label>{{'openReservation.selectBenefits' | translate}}</ion-label>
        <ion-icon class="ca-down-arrow-yellow-icon"></ion-icon>
      </ion-item>
      <ion-list class="ion-no-padding" id="selectBenefits" style="display: none;">
        <ion-radio-group name="auto" value="memberBenefits">
          <ion-item mode="ios" lines="none" #promocode (click)="selectBenefits('promocode')">
            <ion-label mode="md">{{'activitiesTab.promocode' | translate}}</ion-label>
            <ion-radio mode="md" slot="start" [value]="'promocode'"></ion-radio>
          </ion-item>
          <ion-item mode="ios" lines="none" #memberBenefits (click)="selectBenefits('memberBenefits')"
            *ngIf="cardBenefits?.length || certificateBenefits?.length">
            <ion-label mode="md">{{'openReservation.memberBenefits' | translate}}</ion-label>
            <ion-radio mode="md" slot="start" [value]="'memberBenefits'"></ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </ion-card>

    <!-- Promocode Card -->
    <div class="promocode-card" id="promocode"
      [hidden]="membershipDetailForm?.value?.benefitType !== 'promocode' || (!mobileVerified) || (!emailVerified) || membershipDetailForm?.controls['firstName'].invalid || membershipDetailForm?.controls['lastName'].invalid">
      <div class="promoWrap">
        <ion-card class="default-card">
          <ion-item lines="none">
            <ion-input type="text" formControlName="promocode"
              [placeholder]="'openReservation.enterPromocode' | translate"
              (ionChange)="ChangePromo($event.target.value)" autocapitalize="characters"></ion-input>
            <ion-icon class="ca-verified"
              *ngIf="membershipDetailForm.value.promocode && membershipDetailForm.value.promocodeDetails"></ion-icon>
          </ion-item>
        </ion-card>
        <ion-button fill="outline" *ngIf="!membershipDetailForm.value.promocodeDetails"
          [disabled]="membershipDetailForm?.controls['promocode'].invalid" (click)="applyPromocode()" class="applyBtn">
          {{'openReservation.apply' | translate}}
        </ion-button>
        <ion-button fill="outline" (click)="applyPromocode()" *ngIf="membershipDetailForm.value.promocodeDetails"
          [disabled]="membershipDetailForm?.value.promocodeDetails" class="applyBtn">{{'openReservation.Applied' |
          translate}}</ion-button>
        <!-- <ion-button class="applyBtn" (click)="onClick()">
          Proceed
        </ion-button> -->
      </div>
      <p class="errornamemsg"
        *ngIf="membershipDetailForm.value.promocode && membershipDetailForm.value.promocodeDetails">
        {{'openReservation.promocodeValidated' | translate}}</p>
      <p class="errornamemsg"
        *ngIf="membershipDetailForm?.value?.promocode && membershipDetailForm?.controls['promocode'].invalid">
        <ion-text color="danger">{{'openReservation.enterValidPromocode' | translate}}</ion-text>
      </p>
    </div>

    <!-- Available Benefits -->
    <ion-item-group *ngIf="cardBenefits?.length || certificateBenefits?.length" class="benefitsCards"
      id="memberBenefits" style="display: none;"
      [hidden]="membershipDetailForm?.value?.benefitType !== 'memberBenefits'">
      <h2>{{'openReservation.availableBenefits' | translate}}</h2>
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <ion-label color="primary">{{'openReservation.cardBenefits' | translate}} </ion-label>
          </ion-col>
          <ion-col size="12" *ngIf="!cardBenefits.length">
            <ion-card class="default-card mt-0">
              <ion-card-content>
                <p>Card Benefit not available</p>
              </ion-card-content>
            </ion-card>
          </ion-col>
          <ion-col size="6" *ngFor="let cardBenefit of cardBenefits">
            <ion-card class="certificate-card no-bg" (click)="selectBenefit(cardBenefit, 'Card')">
              <img [src]="cardBenefit?.aemData?.cardBenefitImage" />
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-label color="primary">{{'openReservation.certificateBenefits' | translate}}</ion-label>
          </ion-col>
          <ion-col size="12" *ngIf="!certificateBenefits.length">
            <ion-card class="default-card mt-0">
              <ion-card-content>
                <p>Certificate Benefit not available</p>
              </ion-card-content>
            </ion-card>
          </ion-col>
          <ion-col size="6" *ngFor="let certificate of certificateBenefits">
            <ion-card class="certificate-card no-bg" (click)="selectBenefit(certificate , 'Certificate' )">
              <img [src]="certificate?.aemData?.certificateSearchImage" />
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-item-group>

  </div>
</ion-item-group>

<ion-footer *ngIf="!enablecreateReservation">
  <!-- disable if benefit type is selected and no benefit details (memberBenefit or promocodeDetails) are available -->
  <!-- [disabled]="membershipDetailForm?.invalid ||
    (membershipDetailForm?.value?.benefitType == 'memberBenefits' && !membershipDetailForm?.value?.memberBenefit) ||
    (membershipDetailForm?.value?.benefitType == 'promocode' && !membershipDetailForm?.value?.promocodeDetails)" -->
  <!-- <div *ngIf="(cardBenefits?.length < 0  || certificateBenefits?.length < 0) || benefitsTypeSelected ==true">
    <ion-button mode="ios" class="btn-default primary-gradient"
      [disabled]="membershipDetailForm?.invalid || !mobileVerified || !emailVerified" (click)="goToReservation()"
      expand="block">
      {{'openReservation.next' | translate}}
    </ion-button>
  </div> -->
  <!-- && (cardBenefits?.length || certificateBenefits?.length)  -->
  <ion-button mode="ios" class="btn-default primary-gradient" [disabled]="namefield || membershipDetailForm?.invalid "
    (click)="goToReservation()" expand="block">
    {{'openReservation.next' | translate}}
  </ion-button>
</ion-footer>

<div *ngIf="enablecreateReservation">
  <ion-button (click)="backToMakereservation()" fill="outline" class="backButton" mode="md" shape="round">
    <ion-icon mode="ios" class="ca-back"></ion-icon> &nbsp; Back
  </ion-button>
  <app-createreservation [sendMembershipDetailsObj]="sendDetailsmember"></app-createreservation>
</div>